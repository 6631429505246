import React, { useState } from 'react';
import { Ripple } from 'primereact/ripple';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { About } from './components';
import Actions from '../../../../reducer/actions';
import './styles.css';
import Translator from '../../../Translator';

const Buttons = (props) => {
	const { left, width, bottom } = props;

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const language = useSelector((state) => state.language);

	const darkMode = useSelector((state) => state.darkMode);

	const setAboutPage = (payload) => dispatch({ type: Actions.SetAboutPage, payload });

	const currentPage = useSelector((state) => state.currentPage);
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });
	const [showRegistrationForm, setShowRegistrationForm] = useState(false);

	return (
		<div className="buttons">
			<div
				className="menu-option"
				role="button"
				aria-label="menu"
				tabIndex="0"
				onClick={() => {
					setCurrentPage('CountryProfiles');
					navigate('/CountryProfiles');
				}}
			>
				<div
					role="tab"
					aria-label="menu"
					className="p-ripple"
					id="countryProfiles"
				>
					<p style={currentPage === 'CountryProfiles' ? { color: '#FDC82FFF' } : {}}><Translator text={'Country Profiles'} /></p>
					<Ripple />
				</div>
			</div>
			<div
				className="menu-option"
				role="button"
				aria-label="menu"
				tabIndex="0"
				onClick={() => {
					setCurrentPage('GlobalTools');
					navigate('/GlobalTools');
				}}
			>
				<div
					className="p-ripple"
					id="globalTools"
				>
					<p style={currentPage === 'GlobalTools' ? { color: '#FDC82FFF' } : {}}><Translator text={'Global Tools'} /></p>
					<Ripple />
				</div>
			</div>
			<div
				className="menu-option"
				role="button"
				aria-label="menu"
				tabIndex="0"
				// onClick={() => {
				// 	setCurrentPage('About');
				// 	navigate('/About');
				// }}
			>
				<div
					className="p-ripple"
					id="about"
				>
					<p style={currentPage === 'About' ? { color: '#FDC82FFF' } : {}}><Translator text={'About'} /></p>
					<Ripple />
				</div>
				<div className="sub-menu" id="mode">
					<div
						className="p-ripple"
						role="button"
						aria-label="menu"
						tabIndex="0"
						onClick={() => {
							setCurrentPage('Platform');
							// setAboutPage('about');
							navigate('/Platform');
							document.activeElement.blur();
						}}
					>
						<p><Translator text={'The Platform'} /></p>
						<Ripple />
					</div>
					<div className="divider" />
					<div
						className="p-ripple"
						role="button"
						aria-label="menu"
						tabIndex="0"
						onClick={() => {
							setCurrentPage('Approach');
							// setAboutPage('how');
							navigate('/Approach');
							document.activeElement.blur();
						}}
					>
						<p><Translator text={'The Approach'} /></p>
						<Ripple />
					</div>
					<div className="divider" />
					<div
						className="p-ripple"
						role="button"
						aria-label="menu"
						tabIndex="0"
						onClick={() => {
							setCurrentPage('Team');
							// setAboutPage('Team');
							navigate('/Team');
							document.activeElement.blur();
						}}
					>
						<p><Translator text={'The Team'} /></p>
						<Ripple />
					</div>
				</div>
			</div>
			<div
				className="menu-option"
				role="button"
				aria-label="menu"
				tabIndex="0"
				onClick={() => {
					setCurrentPage('Resources');
					navigate('/Resources');
				}}
			>
				<div
					className="p-ripple"
					id="resources"
				>
					<p style={currentPage === 'Resources' ? { color: '#FDC82FFF' } : {}}><Translator text={'Resources'} /></p>
					<Ripple />
				</div>
			</div>
			<div className="bar" style={darkMode ? { backgroundColor: '#FDC82FFF', left, width, top: (bottom - 2), transition: 'left 0.3s' } : { backgroundColor: '#FDC82FFF', left, width, top: (bottom - 2), transition: 'left 0.3s' }} />
		</div>
	);
};

export default Buttons;
