import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../../../../../reducer/actions';
import CountryService from '../../../../../../../../services/httpService/countryService';
import Translator from '../../../../../../../../components/Translator';

const MapOptions = () => {
	const dispatch = useDispatch();

	const whereFiltersSelections = useSelector((state) => state.whereFiltersSelections);

	const country = useSelector((state) => state.country);
	const setWhereFilterSelections = (payload) => dispatch({ type: Actions.SetWhereFilterSelections, payload });

	const [conflict, setConflict] = useState('');
	const [climate, setClimate] = useState('');
	const [socioeconomic, setSocioeconomic] = useState('');
	const [climateOptions, setClimateOptions] = useState([]);

	useEffect(
		() => {
			CountryService.getScatterGraphData(country.id)
				.then((res) => {
					if (res.graph_info) {
						const temp = res.graph_info.map((item) => {
							return item.category;
						});
						setClimateOptions(temp);
					}
				});
		}, [country]
	);

	useEffect(
		() => {
			const temp = { ...whereFiltersSelections };
			temp.conflict = conflict;
			temp.climate = climate;
			temp.socioeconomic = socioeconomic;
			setWhereFilterSelections(temp);
		}, [conflict, climate, socioeconomic]
	);

	const itemTemplate = (item) => {
		return (
			<Translator text={item} />
		);
	};

	return (
		<>
			<div className="dropdown conflict">
				<p className="open-sans"><Translator text="Conflict Cluster" /></p>
				<Dropdown
					value={conflict}
					options={['High conflict', 'Moderate conflict', 'Limited conflict']}
					onChange={(e) => setConflict(e.value)}
					itemTemplate={itemTemplate}
					valueTemplate={itemTemplate}
				/>
			</div>
			<div className="dropdown climate">
				<p className="open-sans"><Translator text="Climate Cluster" /></p>
				<Dropdown value={climate} options={climateOptions} onChange={(e) => setClimate(e.value)} />
			</div>
			<div className="dropdown socioeconomic">
				<p className="open-sans"><Translator text="Socioeconomic Categories" /></p>
				<MultiSelect
					value={socioeconomic}
					options={['Inequality', 'Migration', 'Low productivity', 'Undernutrition']}
					onChange={(e) => setSocioeconomic(e.value)}
					itemTemplate={itemTemplate}
					valueTemplate={itemTemplate}
				/>
			</div>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<p
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setWhereFilterSelections({ ...whereFiltersSelections, conflict: '', climate: '', socioeconomic: [] });
					setConflict('');
					setClimate('');
					setSocioeconomic([]);
				}}
			><Translator text="Reset all filters" />
			</p>
		</>
	);
};

export default MapOptions;
