/* eslint-disable react/react-in-jsx-scope */
import Translator from '../../../../../../../../components/Translator';

const data = [
	{
		title: 'Fulani pastoralists in Louga',
		howData: [
			{
				pathwayTitle: 'Pathway 1:  Climate change threatens past progress in managing herder conflicts for access to natural resources.',
				text: ' Transhumance livestock herding remains the most widely adopted production system in Fulani pastoralist communities across northern Senegal. Pastoralists on the move during the driest periods commonly compete with local communities over access to pasture and watering holes, and damages to crops by livestock are a frequent source of disputes. Throughout the last decade, the strengthening of institutional arrangements, mainly pastoral units, have supported herding populations in collectively managing resources by establishing local regulation codes for resource use and transhumance practices. However, local communities recognize significant challenges to the positive impacts achieved so far by pastoral units. Under climate change, more frequent and longer droughts, coupled with increasing rainfall variability, has led to a rise in the amount of pastoralists coming from the north. In a context whereby the availability of water and pasture are reduced due to climate change, this poses a risk of overwhelming the institutions that are in place to manage conflict.',
			},
			{
				pathwayTitle: 'Pathway 2:  The loss of livelihoods due to climate effects increases the adoption of illicit activities among the youth.',
				text: 'A vulnerability assessment indicated that there are three main climate threats that populations are mainly exposed to: strong winds, droughts and floods. The death of livestock during the dry season is an increasingly common occurrence. As dry-spells often coincide with the time of year when people are facing harsh conditions in terms of food reserves and financial savings, people´s ability to replace the animal or buy food at the market is threatened. Due to low incomes and food security, people are more likely to resort to petty crime. For example, an increase in the theft of cattle in Louga was associated with the onset of dry-spells. The loss of livelihoods, coupled with low availability of alternative employment, also increases the adoption by young populations of illicit activities, including illegal logging, banditry and theft.',
			},
			{
				pathwayTitle: 'Pathway 3:  The loss of livelihood security is also associated with increasing migration towards urban areas within Senegal and beyond national borders.',
				text: 'Migration processes are thought to be related to insecurity risks faced by migrants, as people moving into Senegalese cities and foreign countries commonly face risks associated to settling under vulnerable conditions, including low employment opportunities, poverty, climate disasters and crime.',
			},
		],
		whatText: [
			<p><Translator text="Following solutions for the defined pathways are suggested by community members:" /></p>,
			<p><Translator text="In order to ensure that previous successes around the management of natural resource-based conflicts involving Senegalese herders and farmers are maintained and strengthened, governance systems regulating natural resource management and transhumance processes require a collaborative approach across livelihood groups and across government and non-governmental entities." /></p>,

			<p><Translator text="1. The protection of herding-based livelihoods across agro-pastoral regions in Senegal was deemed to require a set of activities, including interventions related to social protection against the occurrence of climate-related disaster. Bundled credit and index-based insurance schemes were proposed as means of protecting cattle dependent livelihoods." />
			</p>,
			<p><Translator text="2. Herding communities recognize that the effects of pastural units have been curtailed by incoherent regulations across municipalities, thereby reducing the capacity of herders on the move to comply with local rules. Regulations implemented in specific municipalities, however, tend to be tailored towards context-specific territorial conditions. A collaborative governance approach across municipalities, hence, could contribute towards defining locally-tailored regulations while maintaining a degree of consistency across main migratory routes." />
			</p>,
			<p><Translator text="3. There are still many villages and pastoral communities which have not yet adopted the collaborative approach to resource governance proposed by pastoral units. Increasing engagement across municipal governments could also escalate the practice." />
			</p>,
			<p><Translator text="4. Practical solutions to the low-degree of compliance of local regulations by migrant herders included strengthening the system of migratory corridors currently in place, and to delineate pastoral corridors in regions where they have not yet been set. Strengthening these systems could be achieved in part by implementing measures that support incoming pastoralists in understanding local regulations to which they need to abide." />
			</p>,
		],
		latlng: [14.9398658807064, -15.0607356807474],
	},
	{
		title: 'Wolof farmers in Kaffrine',
		howData: [
			{
				pathwayTitle: 'Pathway 1: Climate change can exacerbate farmer-herder conflicts around access to land and the protection of crops.',
				text: 'Climate change can exacerbate farmer-herder conflicts around access to land and the protection of crops. Rising temperatures and increasing rainfall variability has impacted both agricultural and livestock productivity, increasing the need of farmers and pastoralists to secure access to land. Farmers cope with increasing water scarcity by expanding their fields, often encroaching transhumance corridors and land that is devoted to pastoralism or that was previously managed communally. Farm expansion frequently obstructs the mobility of herders and curtails access to water and pasture, and the wandering of cattle into farmed areas is perceived as more frequent given that fertilisation increases the availability of grass. These land-based interactions lead to tensions between both groups and sporadic instances of violence, mainly as a form of revenge after payments are imposed for the destruction of crops by livestock. As a response, local governments in Kaffrine commonly impose restrictions on pastoralist groups regarding when in the year can they enter a given territory, usually after harvest. However, increasing exposure to drought may reduce the willingness of herders in abiding to these restrictions.',
			},
			{
				pathwayTitle: 'Pathway 2: Climate-related shocks and variability have a direct impact over people´s livelihood through the loss of crops and livestock, and a reduction in forest productivity.',
				text: ' They also threaten food security directly, by reducing agricultural yields, and indirectly, by increasing the cost of food. Furthermore, some staple crops on which people rely cannot longer be cultivated due to longer dry periods, off-seasonal storms, and more concentrated precipitation patters during the rainy season. In addition, the more frequent and intense occurrence of floods, across all Senegal regions, curtails people´s ability to access markets, thereby furthering risks of food insecurity. Due to the loss of livelihoods, social capital and collaborative capacity among neighbours is also thought to be hindered.',
			},
			{
				pathwayTitle: 'Pathway 3: The loss of livelihood security is also associated with increasing migration towards urban areas within Senegal and beyond national borders. ',
				text: 'The loss of livelihood security is also associated with increasing migration towards urban areas within Senegal and beyond national borders. Migration processes are thought to be related to insecurity risks faced by migrants, as people moving into Senegalese cities and foreign countries commonly face risks associated to settling under vulnerable conditions, including low employment opportunities, poverty, climate disasters and crime.',
			},
		],
		whatText: [
			<p><Translator text="Following solutions for the defined pathways are suggested by community members:" /></p>,

			<p><Translator text="In order to ensure that previous successes around the management of natural resource-based conflicts involving Senegalese herders and farmers are maintained and strengthened, governance systems regulating natural resource management and transhumance processes require a collaborative approach across livelihood groups and across government and non-governmental entities." /></p>,

			<p><Translator text="1. Increasing engagement across livelihood groups was also deemed a necessary solution to transhumance-related conflict risks. The definition of local regulations in farming regions, such as dates before which herders are not allowed into a specific locality, should be defined by accounting for perceptions from both livelihood groups. Once again, increasing coordination between and amongst municipalities, local authorities, and community-driven organisations (e.g. pastoral units and agricultural associations), could support the designing of regulations which all livelihood groups are willing to accept, even under increasing climate threats." />
			</p>,
			<p><Translator text="2. Easy access to irrigation water is required for people to adopt dry season agriculture and increase labour opportunities for young people. There is a need to foster the availability of longer-term credits that allow populations to make more significant investment in their businesses. People in Kaffrine, for example, would be willing to adopt a years-long credit or joint credit scheme that allows them to install irrigation technology." />
			</p>,
			<p><Translator text="3. Implement a programme to increase the availability of information regarding the occurrence of transhumance processes. A system of gathering and sharing information, involving WhatsApp, radios and an increasing role collaborative entities like the pastoral units, could support the dissemination of information on transhumance processes in ways that increase coordination between local and incoming populations. Furthermore, these system should integrate also climate-related information that supports herders and farmers in preparing for the following season." />
			</p>,
			<p><Translator text="4. Adding value to agricultural products was a main priority to farming populations in Kaffrine. There is currently very little added value through the processing of farming products. Peanut, millet, maize, beans could all be processed into added value products. Higher value crops, such as vegetables, baobab fruits, and other local fruits, could also be planted. This, however, requires investing in processing infrastructure and building capacities among local populations." />
			</p>,
			<p><Translator text="5. Increase the capacity of local communities to coordinate with migrants within and abroad Senegal. A community-level organisation that coordinates the sharing of migratory experiences and practices between those who stay and those who leave, could strengthen the capacity of community members to use migration as a resilience building strategy, while further developing local opportunities through innovative productive initiatives." />
			</p>,
		],
		latlng: [13.9137353896719, -15.6681776695918],
	},
	{
		title: 'Jola fisherfolk in the Casamance River',
		howData: [
			{
				pathwayTitle: 'Pathway 1: A history of conflict undermines collaborative and adaptive capacities for resilience building in Ziguinchor.',
				text: 'Senegal is one of the most stable countries in the Sahel and all of Africa. However, during the conflict in Casamance, mainly the 1990s, there were numerous attacks to Jola communities in the islands within the Casamance river, including Diogué, Carabane and Niomoune. This conflict disrupted fishing activity in the region, which was economically thriving at the time, leading to the interruption of public and financial services and access to markets. A significant fraction of the population migrated elsewhere, and only returned at the end of the decade when violence had subsided. Fishing communities in the region today evidence a high-level of social capital and enjoy an exceedingly peaceful society. They have organized for the implementation of measures to control coastal erosion, including soft solutions like sand dykes, mangrove restoration and beach rehabilitation. However, these efforts have been overwhelmed by accelerating coastal erosion with the turn of the millennium. The effects of climate change, coupled with other external forces such industrial overfishing, currently undermines Jola people´s collective capacity to holistically rebuild their society, recover cultural traditions, and strengthen resilience to climate variability and sea-level rise.',
			},
			{
				pathwayTitle: 'Pathway 2: Fisheries in the Casamance river estuary evidence increasing tensions between local fisherfolk and foreign industrial fishers. ',
				text: 'Over the past two decades, however, the fishing industry has suffered an acute crisis in Senegal, witnessing a reduction in fish stocks along with a decline of the fishing sector\'s contribution to Senegal\'s GDP. The reduction of fish stocks has been highly affected by the industrial fishing operations led by foreign vessels and by illegal fishing. Climate effects can further impact the availability of fish stocks and exacerbate existing conflicts and tensions over the access and use of resources between different users, such artisanal and industrial fisherfolk. Disputes between local and industrial fishers have not involved any form of violence, but resentment from local populations towards industrial fishing are gradually increasing. Furthermore, a reduced availability of fish stocks has pushed some fishermen to cope with the hardship by going deeper into sea, a practice which is associated with risk of injury, loss of equipment and death. ',
			},
		],
		whatText: [
			<p><Translator text="Following solutions for the defined pathways are suggested by community members:" /></p>,

			<p><Translator text="In Ziguinchor and the Casamance River estuary, community-based organisations currently work on strengthening social cohesion, fostering community representation in local government, facilitating collective action for climate adaptation, and preventing resource- and land-related disputes emerging during periods of hardship. These are crucial factors for a sustainable peacebuilding in the region, which could be further strengthened through climate action and nature conservation that accounts for a post-conflict setting." /></p>,

			<p><Translator text="1. Collaborative coastal management and planning was proposed as a potential solution to maintain high-levels of social capital and protect fishing populations in Casamance. Strengthening local capacities for the coordination of a community-level organisation was deemed an effective strategy to increase the representation of coastal communities towards government authorities. This organisation would be charged with mapping coastal erosion and other climate change effects, identifying and classifying local knowledge on climatic and social developments, managing consultation processes to define locally-suitable responses, and engaging with technical or government organisations for the effective implementation of solutions." />
			</p>,
			<p><Translator text="2. Infrastructural and technical assistance is critical to support the resettlement process of former IDPs in the villages surrounding Ziguinchor. Apart from requiring support for building houses and clearing land, returnee communities will need useful infrastructures to establish decent living conditions and climate-adapted food production systems. The provision of water retention systems seems to be a priority for these villages, both for supporting households needs and agricultural production." />
			</p>,
			<p><Translator text="3. Local knowledge valuation will be critical in ensuring that all livelihood systems in the region, including farmers, herders, arboriculturists, horticulturalists and fisherfolk, benefit from climate action programs and are not excluded. Elevating the local culture and protection of these traditional systems is an adapted response allowing for social cohesion and preventing potential tensions. it will be crucial to strengthen current production systems, such as kitchen gardens for women, while relying on local food preferences and crops complementary to the existing diets." />
			</p>,
			<p><Translator text="4. Increase the capacity of local communities to coordinate with migrants within and abroad Senegal. A community-level organisation that coordinates the sharing of migratory experiences and practices between those who stay and those who leave, could strengthen the capacity of community members to use migration as a resilience building strategy, while further developing local opportunities through innovative productive initiatives." />
			</p>,
		],
		latlng: [12.5388799858337, -16.6969550300533],
	},
];

export default data;
