import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Context, How, What, Where, Who } from './components';
import './styles.css';
import CountryService from '../../../../services/httpService/countryService';
import Actions from '../../../../reducer/actions';
import ComingSoon from '../../../../components/ComingSoon';

const Body = () => {
	const location = useLocation();

	const dispatch = useDispatch();

	const tab = useSelector((state) => state.tab);
	const setTab = (payload) => dispatch({ type: Actions.SetTab, payload });

	const country = useSelector((state) => state.country);

	const setMegapixelsGeoJson = (payload) => dispatch({ type: Actions.SetMegapixelsGeoJson, payload });

	const setWhereSelectedFeature = (payload) => dispatch({ type: Actions.SetWhereSelectedFeature, payload });

	const countries = [
		{ id: 'KEN', name: 'Kenya' },
		{ id: 'SEN', name: 'Senegal' },
		{ id: 'ZWE', name: 'Zimbabwe' },
		{ id: 'UGA', name: 'Uganda' },
		{ id: 'NGA', name: 'Nigeria' },
		{ id: 'GTM', name: 'Guatemala' },
		{ id: 'MLI', name: 'Mali' },
		{ id: 'PHL', name: 'Philippines' },
		{ id: 'ZMB', name: 'Zambia' },
		{ id: 'ETH', name: 'Ethiopia' },
		{ id: 'SDN', name: 'Sudan' },
		{ id: 'BFA', name: 'Burkina-Faso' },
		{ id: 'NER', name: 'Niger' },
	];

	useEffect(
		() => {
			// if (country.id === 'NER' || country.id === 'BFA') {
			// 	setTab('Where');
			// }
			return () => setTab('Context');
		}, [country]
	);

	useEffect(
		() => {
			const temp = countries.find((item) => item.name === location.pathname.split('/')[2]);
			CountryService.getMegapixelsOfCountry(temp.id)
				.then((res) => {
					console.log(res);
					setMegapixelsGeoJson(res.graph_info);
				});
		}, [country]
	);
	
	const renderTab = () => {
		switch (tab) {
		case 'Context': return <Context />;
		case 'Where': return <Where />;
		case 'Who': return <Who />;
		case 'How': return <How />;
		case 'What': return <What />;
		default: return <Context />;
		}
	};

	return (
		<div className="body-container">
			{renderTab()}
		</div>
	);
};

export default Body;
