import React, { useEffect, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import {
	financeImage,
	multiLevelImage,
	policyImage, programmaticImage, researchImage,
} from '../../../../../../../../../../../../assets/images/CountryInformationPage/What/policyProgramatic/img';
import './styles.css';

const SenegalContent = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [tab, setTab] = useState(0);

	useEffect(
		() => {
			changeTabColor(tab, 'black');
			setTab('multi');
			changeTabColor('multi', '#FDC82F');
		}, []
	);

	const headerTemplate = (options, image, header) => (
		<div role="button" tabIndex="0" onClick={options.onClick} className="tabs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
			{image}
			<p>{header}</p>
		</div>
	);

	const changeTabColor = (target, color) => {
		const selectedtabImage = document.getElementById(target);
		if (selectedtabImage) {
			selectedtabImage.fill = color;
			let paths = selectedtabImage.getElementsByTagName('path');
			paths = [...paths];
			paths.map((item) => {
				// eslint-disable-next-line no-param-reassign
				item.style.fill = color;
			});
		}
	};

	return (
		<TabView
			activeIndex={activeIndex}
			onTabChange={(e) => {
				setActiveIndex(e.index);
				changeTabColor(tab, 'black');
				switch (e.index) {
				case 0: setTab('multi'); changeTabColor('multi', '#FDC82F'); break;
				case 1: setTab('policy'); changeTabColor('policy', '#FDC82F'); break;
				case 2: setTab('programmatic'); changeTabColor('programmatic', '#FDC82F'); break;
				case 3: setTab('research'); changeTabColor('research', '#FDC82F'); break;
				default: setTab('finance'); changeTabColor('finance', '#FDC82F'); break;
				}
			}
			}
		>
			<TabPanel header="multi" headerTemplate={(options) => headerTemplate(options, multiLevelImage, 'Multi-level goveranance')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							A community of practice for climate security in Senegal will make it possible to enrich and make more effective the old practices of environmental protection that did not integrate the security aspect. It will also enable the implementation of collaborative actions that take into account the concerns of the population at all levels of intervention, since the approach will be inclusive and the proposed solutions will come from the population itself, for greater sustainability.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Create a Climate Security Observatory platform.</li>
							<li>Identify existing national and sub-national organizations working on climate-related security issues.</li>
							<li>Map existing sub-national and national platforms and assess their needs in order to identify actions needed to include a climate security perspective in their approach.</li>
							<li>Give actors the possibility to define the scope of the issue they want to work on and engage with.</li>
							<li>Propose multi-level governance.</li>
							<li>Delineate the scope of the Climate Security Observatory platform.</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="policy" headerTemplate={(options) => headerTemplate(options, policyImage, 'Policy frameworks')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							Whilst more specific research will be required to determine specific areas where capacities are lacking, it appears that very few programs and projects reflect on the need to be conflict sensitive or how their interventions may – if articulated as such and planned for – could contribute to social cohesion and peace. Given that in Senegal, peace and security actions have so far been concentrated in the south of the country, particularly in Casamance, and that the consequences of these security crises on natural resources have received little attention, it is important to define new trajectories for integrating climate security into national concerns.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Integrate climate security into the National Adaptation Plan (NAP) in Senegal.</li>
							<li>Establish a directory of the environmental legal and political framework in Senegal incorporating the security aspect.</li>
							<li>Work with sub-national authorities on the development and implementation of strategies and policies for sustainable development, environmental protection and climate security.</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="programmatic" headerTemplate={(options) => headerTemplate(options, programmaticImage, 'Programmatic planning')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							For coordinated action on climate security in Senegal, it is essential to carry out strategic planning of programmes to have a clear vision of the activities and initiatives to be carried out at all levels of intervention. It is important that programmes are adapted to local needs and coordinated with existing actions. The integration of the security aspect into community climate resilience initiatives should engage both climate and peacekeeping actors to ensure synergy of actions and continuity of engagement.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Identify existing adaptation strategies and programmes that can integrate climate security aspects. </li>
							<li>Propose inclusive TORs and action plans.</li>
							<li>Develop a better understanding of community-based risk and conflict management strategies to develop climate security action plans</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="research" headerTemplate={(options) => headerTemplate(options, researchImage, 'Research and evidence gaps')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							The general consensus during the workshop was the need to strengthen empirical studies on the issue of climate security in Senegal. Indeed, being a new concept with little literature, climate security in Senegal requires better documentation and therefore more frequent field visits to collect empirical information. The newly established Climate Security Observatory platform will be a useful tool to empirically support evidence gap maps and facilitate the dissemination of this information.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Analyse local perceptions of climate insecurity in Senegalese communities. </li>
							<li>Establish the links, direct or indirect, between climate change and security risks.</li>
							<li>Documentation of local adaptation strategies.</li>
							<li>Decentralize and democratize information. </li>
							<li>Capitalize on studies already conducted, compare and corroborate results.</li>
							<li>Diversify the sites of intervention and scale up field surveys.</li>
							<li>Integrate a gender dimension into climate security risk management strategies. </li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="finance" headerTemplate={(options) => headerTemplate(options, financeImage, 'Finance')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							Senegal is in the prevention stage when it comes to security. Good climate resilient practices already exist in traditional societies, but they must be framed, supported, and allowed to spread. This is why funding is critical not only for conducting climate security research, but also for proposing and implementing projects to combat climate security threats. Climate security does not necessarily need additional funding streams as the goal should be to align the existing ones from the humanitarian and development sector together with the ones for climate action.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Ensure that fundraising strategies are co-designed.</li>
							<li>Measure the project&apos;s resilience contributing potential to better attract investors.</li>
							<li>Develop strong partnerships between research and funding structures.</li>
							<li>Build the capacity of local, sub-national and national organizations to access green finance. </li>
							<li>Integrate climate security financing needs into public budgets. </li>
							<li>Invest in climate smart agriculture (CSA) to reduce the risks of climate vulnerability and insecurity. </li>
						</ul>
					</div>

				</div>
			</TabPanel>
		</TabView>
	);
};

export default SenegalContent;
