import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, RowWithImage, DataSourcesDialogContent, MethodsDialogContent, CountryProfilesDialogContent, PapersDialogContent, BriefsDialogContent, OtherDialogContent } from './components';
import DataSourcesIcon from '../../assets/images/Resources/DataSourcesIcon.svg';
import CountryProfilesIcon from '../../assets/images/Resources/CountryProfilesIcon.svg';
import MethodsIcon from '../../assets/images/Resources/MethodsIcon.svg';
import PapersImage from '../../assets/images/Resources/PapersImage.png';
import PolicyBriefsImage from '../../assets/images/Resources/PolicyBriefsImage.png';
import Other from '../../assets/images/Resources/Other.png';
import resources from '../../assets/resourcesFinal.json';
import data_sources from '../../assets/resourcesDataSources.json';
import texts from './texts';
import StaticService from '../../services/httpService/staticService';
import './styles.css';
import Actions from '../../reducer/actions';
import Translator from '../../components/Translator';

const Resources = () => {
	const [data, setData] = useState(null);

	const dispatch = useDispatch();
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	useEffect(
		() => {
			// StaticService.getResources()
			// 	.then((res) => {
			// 		setData(res);
			// 	});
			// const dataSources = [data_sources];
			const methods = resources.filter((item) => item.Category === 'Methods');
			const countryReports = resources.filter((item) => item.Category === 'Country Reports');
			const papers = resources.filter((item) => item.Category === 'Journal Article');
			const policyBriefs = resources.filter((item) => item.Category === 'Policy Briefs');
			const other = resources.filter((item) => item.Category === 'Others');
			const newData = {
				// data_sources: dataSources,
				methods,
				country_profiles: countryReports,
				papers,
				policy_briefs: policyBriefs,
				other,
			};
			console.log(newData);
			setData(newData);
			setCurrentPage('Resources');
			setShowMenus(true);
		}, []
	);

	const renderRows = () => {
		if (!data) return null;
		return (
			<>
				<Row icon={DataSourcesIcon} title={<Translator text={texts.dataSources.title} />} subtitle={texts.dataSources.subtitle} content={<Translator text={texts.dataSources.content} />} buttonLabel={<Translator text={texts.dataSources.buttonLabel} />}>
					<DataSourcesDialogContent data={data_sources.data} />
				</Row>
				<Row icon={MethodsIcon} title={<Translator text={texts.methods.title} />} subtitle={texts.methods.subtitle} content={<Translator text={texts.methods.content} />} buttonLabel={<Translator text={texts.methods.buttonLabel} />} reversed>
					<MethodsDialogContent data={data.methods} />
				</Row>
				<Row icon={CountryProfilesIcon} title={<Translator text={texts.countryProfiles.title} />} subtitle={texts.countryProfiles.subtitle} content={texts.countryProfiles.content} buttonLabel={<Translator text={texts.countryProfiles.buttonLabel} />}>
					<CountryProfilesDialogContent data={data.country_profiles} />
				</Row>
				<RowWithImage image={PapersImage} title={<Translator text={texts.papers.title} />} subtitle={texts.papers.subtitle} content={texts.papers.content} buttonLabel={<Translator text={texts.papers.buttonLabel} />} reversed>
					<PapersDialogContent data={data.papers} />
				</RowWithImage>
				<RowWithImage image={PolicyBriefsImage} title={<Translator text={texts.briefs.title} />} subtitle={texts.briefs.subtitle} content={texts.briefs.content} buttonLabel={<Translator text={texts.briefs.buttonLabel} />}>
					<BriefsDialogContent data={data.policy_briefs} />
				</RowWithImage>
				<RowWithImage image={Other} title={<Translator text={texts.other.title} />} subtitle={texts.other.subtitle} content={texts.other.content} buttonLabel={<Translator text={texts.other.buttonLabel} />} reversed>
					<OtherDialogContent data={data.other} />
				</RowWithImage>
			</>
		);
	};

	return (
		<div className="resources-container">
			{renderRows()}
		</div>
	);
};

export default Resources;
