import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Climate, Conflict, GlobalMetrics } from './components';
import Actions from '../../../../../../reducer/actions';
import LearnMoreDialogContent from '../LearnMoreDialogContent';
import dataSource from './dataSource.json';
import SummaryDownloadButton from '../SummaryDownloadButton';
import Translator from '../../../../../../components/Translator';
import ComingSoon from '../../../../../../components/ComingSoon';

const Context = () => {
	const dispatch = useDispatch();
	
	const contextSubPageSelection = useSelector((state) => state.contextSubPageSelection);
	const setContextSubPageSelection = (payload) => dispatch({ type: Actions.SetContextSubPageSelection, payload });

	const country = useSelector((state) => state.country);

	const setContextFilterSelections = (payload) => dispatch({ type: Actions.SetContextFilterSelections, payload });

	const [displayDialog, setDisplayDialog] = useState(false);

	const renderSubPage = () => {
		switch (contextSubPageSelection) {
		case 'climate': return <Climate />;
		case 'conflict': return <Conflict />;
		case 'metrics': return <GlobalMetrics />;
		default: return null;
		}
	};

	return (
		<div className="tab context">
			{country.id === 'BFA' || country.id === 'NER' ? (

				<ComingSoon comingSoonContent={''} />

			)
				: (
					<>
						<p className="open-sans tab-header">
							<Translator text="Contextual factors determine how sensitive a nation or region is to hazards related to climate
				security. There are many different variables that determine whether and to what extent climate
				variability acts as a threat multiplier to conflict, including climate vulnerability and adaptation
				readiness, past and present conflict dynamics, existing inequalities, and high dependence on
				climate-related activities."
							/>
						</p>
						<div className="options">
							<Button label=<Translator text="Climate" /> onClick={() => setContextSubPageSelection('climate')} style={contextSubPageSelection === 'climate' ? { backgroundColor: '#FDC82F', color: '#2d2d2d' } : { backgroundColor: '#2d2d2d', color: '#FDC82F' }} />
							<Button label=<Translator text="Conflict" /> onClick={() => setContextSubPageSelection('conflict')} style={contextSubPageSelection === 'conflict' ? { backgroundColor: '#FDC82F', color: '#2d2d2d' } : { backgroundColor: '#2d2d2d', color: '#FDC82F' }} />
							<Button label=<Translator text="Global Metrics" /> onClick={() => setContextSubPageSelection('metrics')} style={contextSubPageSelection === 'metrics' ? { backgroundColor: '#FDC82F', color: '#2d2d2d' } : { backgroundColor: '#2d2d2d', color: '#FDC82F' }} />
						</div>
						{renderSubPage()}
						<Button label=<Translator text="Learn More" /> onClick={() => setDisplayDialog(true)} />
						<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
							<LearnMoreDialogContent dataSource={dataSource.data} />
						</Dialog>
						<SummaryDownloadButton />
					</>

				)

			}

		</div>
	);
};

export default Context;
