import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import './styles.css';

const Row = (props) => {
	const { title, icon, subtitle, content, children, buttonLabel, reversed } = props;

	const [toggleDialog, setToggleDialog] = useState();

	return (
		<div className="resources-row" style={reversed ? { flexDirection: 'row-reverse', backgroundColor: '#f7f7f7' } : { flexDirection: 'row' }}>
			<p style={reversed ? { right: '15%' } : { left: '15%' }} className="main-heading">{title}</p>
			<img style={reversed ? { right: 0 } : { left: 0 }} className="background-image" src={icon} />
			<p className="subtitle">{subtitle}</p>
			<div className="column">
				<p className="content">{content}</p>
				<Button label={buttonLabel} onClick={() => setToggleDialog(true)} />
			</div>
			<Dialog className="row-layout-dialog" visible={toggleDialog} style={{ width: '80vw', height: '90vh' }} onHide={() => setToggleDialog(false)}>
				<div className="dialog-content">
					{children}
				</div>
			</Dialog>
		</div>
	);
};

export default Row;
