/* eslint-disable no-param-reassign,jsx-a11y/no-static-element-interactions */
import * as echarts from 'echarts';
import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputSwitch } from 'primereact/inputswitch';
import { graphic } from 'echarts';
import './styles.css';
import { MultiSelect } from 'primereact/multiselect';

const MockGraph = (props) => {
	const { data } = props;
	const [selectedNodes, setSelectedNodes] = useState([]);
	const [checked, setChecked] = useState(true);
	const [calculateGraph, setCalculateGraph] = useState(0);
	const [displayButton, setDisplayButton] = useState(true);
	const [expandedRows, setExpandedRows] = useState([]);

	const [myChart, setMyChart] = useState(null);
	const [zoom, setZoom] = useState(0);

	const displayDefaultVariables = () => {
		const categories = [];
		data.nodes.map((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});

		const dominants = data.nodes.filter((item) => item.dominant);
		const temp = categories.map((category) => {
			const newIndexes = [];
			const dominantNodes = dominants.filter((item) => item.category === category);
			dominantNodes.map((nodeIndex) => {
				const indexes = data.nodes.findIndex((element) => nodeIndex.id === element.id);
				newIndexes.push(indexes);
			});

			const valueObj = {};
			newIndexes.forEach((key) => {
				valueObj[key] = { checked: true, partialChecked: false };
			});

			return { category, value: { ...valueObj }, nodes: dominantNodes };
		});
		setSelectedNodes(temp);
		setCalculateGraph((prev) => prev + 1);
	};

	useEffect(
		() => {
			displayDefaultVariables();
		}, []
	);

	useEffect(() => {
		const chartDom = document.getElementById('chart-container');
		checked ? setZoom(5) : setZoom(1);
		setMyChart(echarts.init(chartDom));
	}, [calculateGraph, checked]);
	useEffect(
		() => {
			if (zoom) {
				const option = {
					series: {
						zoom,
					},
				};
				myChart.setOption(option);
			}
		}, [zoom]
	);
	useEffect(() => {
		// const myChart = echarts.init(chartDom);
		if (!myChart) return;

		myChart.showLoading();
		myChart.hideLoading();

		if (!selectedNodes.length) return;

		let graphNodes = [];
		selectedNodes.map((it) => {
			it.nodes.map((item) => {
				graphNodes.push({ ...item });
			});
		});

		graphNodes = graphNodes.map((node) => {
			const index = data.categories.findIndex((item) => item.name === node.category);
			const newNode = node;
			newNode.category = index;
			return newNode;
		});
		const graph = JSON.parse(JSON.stringify(data));
		const forceNodes = [];
		graphNodes.map((node) => {
			let size = 10;
			let value = 0;
			let forceNodeSize = 1;
			const links = data.links.filter((link) => link.target === node.id || link.source === node.id);
			links.map((item) => {
				const temp = graphNodes.find((graphnode) => graphnode.id === item.target);
				const temp2 = graphNodes.find((graphnode) => graphnode.id === item.source);
				if (temp && temp2) {
					size += 2;
					if (forceNodeSize < 10) {
						forceNodeSize += 1;
					}
					value += 1;
				}
			});

			node.symbolSize = size;
			node.value = value;
			node.label = {
				show: false,
			};
			// node.tooltip = {
			// 	formatter: `"${node.name}" links: ${node.value}`,
			// 	textStyle: {
			// 		fontFamily: 'Montserrat',
			// 		fontWeight: '400',
			// 	},
			// };
			const forceNode = { ...node };
			forceNode.symbolSize = forceNodeSize;
			forceNode.value = value;
			forceNode.label = {
				show: false,
			};
			forceNodes.push(forceNode);
		});
		graph.nodes = graphNodes;

		const graphLinks = [];
		data.links.map((link) => {
			if (graphNodes.find((item) => item.id === link.source) && graphNodes.find((item) => item.id === link.target)) {
				graphLinks.push(link);
			}
		});
		graphLinks.map((link) => {
			const sourceNode = data.nodes.find((item) => item.id === link.source);
			const sourceColor = data.categories.find((item) => item.name === sourceNode.category)?.color;
			const targetNode = data.nodes.find((item) => item.id === link.target);
			const targetColor = data.categories.find((item) => item.name === targetNode.category)?.color;
			link.lineStyle = { ...link.lineStyle,
				color: new graphic.LinearGradient(0, 0, 1, 1, [
					{
						offset: 1,
						color: sourceColor || '#000000',
					},
					{
						offset: 0,
						color: targetColor || '#000000',
					},
				]),
				type: 'linear',
				curveness: 0.2,
				width: Math.round(Math.abs(link.value) * 10) + 1,
			};
			link.tooltip = {
				formatter: `"${sourceNode.name}" - "${targetNode.name}" correlation: ${link.value}`,
				textStyle: {
					fontFamily: 'Montserrat',
					fontWeight: '400',
				},
			};
		});

		const option = {
			title: {
				text: 'Network Analysis',
				subtext: 'Default layout',
				top: 'bottom',
				left: 'right',
			},
			color: data.categories.map((category) => category.color),
			tooltip: {},
			legend: [
				{
					data: graph.categories.map((a, index) => {
						return a.name;
					}),
				},
			],
			animationDuration: 1500,
			animationEasingUpdate: 'quinticInOut',
			series: [
				{
					name: 'Network Analysis',
					type: 'graph',
					layout: checked ? 'force' : 'circular',
					data: checked ? forceNodes : graph.nodes,
					links: graphLinks,
					categories: graph.categories,
					draggable: true,
					// scrollable: false,
					roam: 'move',
					zoom: checked ? 5 : 1,
					force: {
						repulsion: 10,
						layoutAnimation: true,
						gravity: 0.1,
						friction: 0.1,
					},
					label: {
						show: false,
						fontSize: 12,
					},
					labelLayout: {
						hideOverlap: true,
					},
					emphasis: {
						focus: 'adjacency',
					},
					lineStyle: {
						width: 5,
						color: new graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0,
								color: 'source',
							},
							{
								offset: 1,
								color: 'source',
							}]),
						type: 'linear',
					},
				},
			],
		};
		myChart.setOption(option);
		// myChart.on('highlight', (params) => {
		// 	console.log(params);
		// });
		// eslint-disable-next-line consistent-return
		return () => myChart.dispose();
	}, [myChart, calculateGraph, checked]);

	const leftContents = (
		<>

		</>
	);

	const rightContents = (
		<Button label="Calculate Graph" onClick={() => setCalculateGraph((prev) => prev + 1)} />
	);

	const panelHeader = (
		<div style={{ padding: '0.5rem 1rem', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
			<h3 style={{ fontSize: '20px', fontWeight: '500' }}>Choose Variables</h3>
			{displayButton
				? <Button label="Select All Variables" onClick={() => selectAllVariables()} />
				: <Button label="Deselect All Variables" onClick={() => deselectAllVariables()} />
			}
			<Button label="Select Default Variables" onClick={() => displayDefaultVariables()} />
		</div>

	);

	const selectAllVariables = () => {
		const categories = [];
		data.nodes.map((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});

		const temp = categories.map((category) => {
			const allVariablesNodes = data.nodes.filter((item) => item.category === category);
			const newIndexes = [];
			allVariablesNodes.map((nodeIndex) => {
				const indexes = data.nodes.findIndex((element) => nodeIndex.id === element.id);
				newIndexes.push(indexes);
			});

			const valueObj = {};
			newIndexes.forEach((key) => {
				valueObj[key] = { checked: true, partialChecked: false };
			});
			return { category, value: { ...valueObj }, nodes: allVariablesNodes };
		});
		setSelectedNodes(temp);
		setDisplayButton(false);
	};
	const deselectAllVariables = () => {
		const categories = [];
		data.nodes.map((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});
		const temp = categories.map((item) => {
			return { category: item, value: [], nodes: [] };
		});
		setDisplayButton(true);
		setSelectedNodes(temp);
	};
	const template = (options) => {
		const className = `${options.className} p-jc-between`;
		return (
			<div className={className}>
				<div>
					<span>
						Variables Correlation
					</span>
				</div>
				<div className={className} style={{ gap: '8px' }}>
					<p>{checked ? 'Force' : 'Circular' }</p>
					<InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
				</div>
			</div>
		);
	};
	const renderOptions = () => {
		const categories = [];
		data.nodes.map((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});
		const categorizedData = categories.map((category) => {
			const temp = [];
			data.nodes.map((item, index) => {
				if (item.category === category) {
					temp.push({ ...item, key: index, children: [], label: item.name });
				}
			});
			return { category, nodes: temp };
		});
		const changeSelectedNodes = (e, category) => {
			const indexes = Object.getOwnPropertyNames(e.value);
			const nodes = indexes.map((item) => {
				return data.nodes[item];
			});
			const newValue = { category, value: e.value, nodes };
			const index = selectedNodes.findIndex((item) => item.category === category);
			const newSelectedNodes = [...selectedNodes];
			newSelectedNodes.splice(index, 1, newValue);
			setSelectedNodes(newSelectedNodes);
		};
		if (!selectedNodes) return null;
		const expansionTemplate = (rowData) => {
			return (
				<Tree
					value={rowData.nodes}
					selectionMode="checkbox"
					selectionKeys={selectedNodes.find((node) => node.category === rowData.category)?.value || []}
					onSelectionChange={(e) => changeSelectedNodes(e, rowData.category)}
				/>
			);
		};
		return (
			<div className="p-col">
				<DataTable
					value={categorizedData}
					responsiveLayout="scroll"
					expandedRows={expandedRows}
					dataKey="category"
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={expansionTemplate}
				>
					<Column
						expander
						style={{ width: '3em' }}
					/>
					<Column
						field="category"
					/>
				</DataTable>
				<MultiSelect
					value={categorizedData}
					// options={groupedCities}
					onChange={(e) => changeSelectedNodes(e.data)}
					dataKey="category"
					optionLabel="label"
					optionGroupLabel="label"
					optionGroupChildren="items"
					optionGroupTemplate={expansionTemplate}
					// placeholder="Select Variables"
				/>
				
			</div>
		);

		// return categorizedData.map((item, index) => {
		// 	return (
		// 		<div className="p-col">
		// 			{/* <h4>{item.category}</h4> */}
		// 			<Tree
		// 				value={item.nodes}
		// 				selectionMode="checkbox"
		// 				selectionKeys={selectedNodes.find((node) => node.category === item.category)?.value || []}
		// 				onSelectionChange={(e) => changeSelectedNodes(e, item.category)}
		// 			/>
		// 		</div>
		// 	);
		// });
	};
	return (
		<>
			<Panel toggleable headerTemplate={panelHeader}>
				<div className="p-grid">
					{renderOptions()}

				</div>
				<Toolbar left={leftContents} right={rightContents} />
			</Panel>
			<Panel header="Variables Correlation" className="na-chart p-mt-6" headerTemplate={template}>
				<div className="zoom-buttons">
					<div className="zoom plus" onClick={() => setZoom(zoom + 1)}>
						<i className="fa-solid fa-plus" />
					</div>
					<div
						className="zoom minus"
						onClick={() => {
							if (zoom > 1) {
								setZoom(zoom - 1);
							}
						}}
					>
						<i className="fa-solid fa-minus" />
					</div>
				</div>
				<div id="chart-container" style={{ width: '100%', height: '700px' }} />
			</Panel>
		</>
	);
};
export default MockGraph;
