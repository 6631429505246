/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { GuatemalaContent, KenyaContent, SenegalContent } from './components';
import './styles.css';
import Translator from '../../../../../../../../../../components/Translator';

const PolicyProgrammatic = () => {
	const displayTabsPerCountry = () => {
		if (window.location.href.includes('Kenya')) {
			return (
				<KenyaContent />
			);
		}
		if (window.location.href.includes('Ethiopia')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Senegal')) {
			return (
				<SenegalContent />
			);
		}
		if (window.location.href.includes('Zambia')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Mali')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Philippines')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Uganda')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Sudan')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Zimbabwe')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Nigeria')) {
			return (
				<div>
					<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
				</div>
			);
		}
		if (window.location.href.includes('Guatemala')) {
			return (
				<GuatemalaContent />
			);
		}
	};

	return (
		<div className="policy-coherence-container">
			<h2><Translator text="Policy and Programmatic Recommendations" /></h2>
			<div>
				{displayTabsPerCountry()}
			</div>
		</div>
	);
};
export default PolicyProgrammatic;
