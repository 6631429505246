// eslint-disable-next-line import/no-cycle
import { useTranslate } from '@tolgee/react';
import makeKey from './makeKey';

const useCustomTranslate = (defaultString, customKey) => {
	const { t } = useTranslate();
	if (customKey) {
		return t(makeKey(customKey), defaultString);
	}
	return t(makeKey(defaultString), defaultString);
};

export default useCustomTranslate;
