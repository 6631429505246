/* eslint-disable consistent-return */
import React from 'react';
import { Image } from 'primereact/image';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import summary from '../../../../../../../../assets/images/CountryInformationPage/How/summary.png';
import summaryKenya from '../../../../../../../../assets/images/CountryInformationPage/How/SummaryKEN.jpg';
import graphKenya from '../../../../../../../../assets/images/CountryInformationPage/How/KEN_GRAPH.png';
import summarySenegal from '../../../../../../../../assets/images/CountryInformationPage/How/SummarySEN.jpg';
import graphSenegal from '../../../../../../../../assets/images/CountryInformationPage/How/SEN_GRAPH.png';
import graphGuatemala from '../../../../../../../../assets/images/CountryInformationPage/How/GTM_GRAPH.png';
import graphZambia from '../../../../../../../../assets/images/CountryInformationPage/How/CP_zambia_Graph.png';
import './styles.css';
import Translator from '../../../../../../../../components/Translator';

const SummaryDiagram = () => {
	const country = useSelector((state) => state.country);
	
	const imagesKenya = [
		{
			id: '1',
			imageUrlSum: graphKenya,
		},
		{
			id: '2',
			imageUrlSum: summaryKenya,
		},
	];

	const imagesSenegal = [
		{
			id: '1',
			imageUrlSum: graphSenegal,
		},
		{
			id: '2',
			imageUrlSum: summarySenegal,
		},
	];

	const imagesGuatemala = [
		{
			id: '1',
			imageUrlSum: graphGuatemala,
		},
		// {
		// 	id: '2',
		// 	imageUrlSum: '',
		// },
	];

	const imagesZambia = [
		{
			id: '1',
			imageUrlSum: graphZambia,
		},
	];

	const itemTemplateKenya = (carouselItem) => {
		return (
			<div className="carousel-container border-1 surface-border border-round m-2 text-center py-5 px-3">
				<img src={carouselItem.imageUrlSum} />
			</div>
		);
	};

	const itemTemplateSenegal = (carouselItem) => {
		return (
			<div className="carousel-container border-1 surface-border border-round m-2 text-center py-5 px-3">
				<img src={carouselItem.imageUrlSum} />
			</div>
		);
	};
	const itemTemplateGuatemala = (carouselItem) => {
		return (
			<div className="carousel-container border-1 surface-border border-round m-2 text-center py-5 px-3">
				<img src={carouselItem.imageUrlSum} />
			</div>
		);
	};

	const itemTemplateZambia = (carouselItem) => {
		return (
			<div className="carousel-container border-1 surface-border border-round m-2 text-center py-5 px-3">
				<img src={carouselItem.imageUrlSum} />
			</div>
		);
	};

	const renderContent = () => {
		switch (country.name) {
		case 'Kenya': return (
			<div className="summary-img">
				{/* <img src={summaryKenya} alt="Image" width="100%" /> */}
				<Carousel value={imagesKenya} numVisible={1} numScroll={1} itemTemplate={itemTemplateSenegal} />
			</div>
		);
		case 'Senegal': return (
			<div className="summary-img">
				<Carousel value={imagesSenegal} numVisible={1} numScroll={1} itemTemplate={itemTemplateKenya} />
			</div>
		);
		case 'Guatemala': return (
			<div className="summary-img">
				<Carousel value={imagesGuatemala} numVisible={1} numScroll={0} itemTemplate={itemTemplateGuatemala} />
			</div>
		);
		case 'Zambia': return (
			<div className="summary-img">
				<Carousel value={imagesZambia} numVisible={1} numScroll={0} itemTemplate={itemTemplateZambia} />
			</div>
		);
		default: return (
			<div>
				<h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>
			</div>
		);
		}
	};
	const renderDownloadButton = () => {
		switch (country.name) {
		case 'Kenya': return (

			<a className="p-button" href="https://cgspace.cgiar.org/handle/10568/127878" target="_blank" rel="noreferrer">Download full country profile</a>

		);
		case 'Senegal': return (
			<a className="p-button" href="https://cgspace.cgiar.org/handle/10568/127618" target="_blank" rel="noreferrer">Download full country profile</a>
		);
		// case 'Guatemala': return (
		// 	<a className="p-button" href="#" disabled target="_blank" rel="noreferrer">Download full country profile</a>
		// );
		default: return null;
		}
	};

	return (
		<div className="summary-diagram">
			{renderDownloadButton()}
			{renderContent()}
		</div>

	);
};

export default SummaryDiagram;
