import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Tolgee, DevTools, TolgeeProvider, FormatSimple, BackendFetch } from '@tolgee/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormatIcu } from '@tolgee/format-icu';
import NotFound from './pages/NotFound';
import reducer from './reducer/reducer';
import App from './App';
import Loader from './components/Loader';
import './index.css';

const tolgee = Tolgee()
	.use(DevTools())
	.use(FormatSimple())
	.use(FormatIcu())
	.use(BackendFetch())
	.init({
		language: 'en',
		// for development
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
	});
// tolgee.use(FormatIcu());

const store = createStore(reducer);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<TolgeeProvider
			tolgee={tolgee}
			fallback={<Loader />}
		>
			<Provider store={store}>
				<App />
			</Provider>
		</TolgeeProvider>
	</React.StrictMode>
);
