/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
// import './styles.css';

const Comments = (props) => {
	const { rowData, setUserInput, userInput } = props;

	const [value, setValue] = useState(userInput[`${rowData.submechanism.slice(0, 3)}comment`]);

	useEffect(
		() => {
			const newInput = { ...userInput };
			const temp = rowData.id;
			newInput[`${temp}comment`] = value;
			setUserInput(newInput);
		}, [value]
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'center' }}>
			<InputTextarea value={value} onChange={(e) => setValue(e.target.value)} autoResize style={{ width: '100%' }} />
		</div>
	);
};

export default Comments;
