import React from 'react';
// import climateSecurityImage from '../../../../../../../../assets/images/CountryInformationPage/What/Dialog/climateSecurity.svg';
import climateSecurityImage from '../../../../../../../../assets/images/CountryInformationPage/What/actor_big.jpg';
import Translator from '../../../../../../../../components/Translator';

const ActorIntervention = () => {
	return (
		<>
			<img style={{ width: '100%' }} src={climateSecurityImage} />
			<h2 style={{ fontSize: '30px', color: '#FDC82F' }}>
				<Translator text="Collaborate with Actors at the Intersection of Climate and Security" />
			</h2>
			<h2><Translator text="Coming Soon" /></h2>
			<div className="text-container">
				<p>
					<Translator text="Multidisciplinary and cross-sectoral collaboration is essential to
					addressing climate security-related risks and developing climate security-sensitive
					solutions. The actor and intervention mapping explores the web of implementation
					areas and thematic and geographical foci of key players around the climate security
					nexus from the local to international level."
					/>
				</p>
			</div>
		</>
	);
};

export default ActorIntervention;
