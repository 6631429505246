import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import LearnMoreDialogContent from '../LearnMoreDialogContent';
import dataSource from './dataSource.json';
import SummaryDownloadButton from '../SummaryDownloadButton';
import Translator from '../../../../../../components/Translator';

const Who = () => {
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayTutorial, setDisplayTutorial] = useState(false);

	return (
		<div className="tab who">
			<p className="title">
				<span><Translator text="WHO" /></span> <Translator text="are the vulnerable groups to climate and security risks that should be targeted?" />
			</p>
			<p className="open-sans tab-header">
				<Translator text="The hotspot map identifies where climate security hotspots are and how the vulnerable communities living in these hotspots are characterized." />
			</p>
			<div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
				<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
				<Button label={<Translator text="Tutorial" />} onClick={() => setDisplayTutorial(true)} />
			</div>
			<Dialog className="learn-more" header="Learn More" visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
				<LearnMoreDialogContent dataSource={dataSource.data} />
			</Dialog>
			<Dialog header="CSO Tutorial" visible={displayTutorial} style={{ width: '90vw', height: '90vh' }} onHide={() => setDisplayTutorial(false)}>
				<iframe
					src="https://player.vimeo.com/video/828236011?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					style={{ width: '100%', height: '100%' }}
					title="Context_Kenya_Maximum_Temperature"
				/>
			</Dialog>
			<SummaryDownloadButton />
		</div>
	);
};

export default Who;
