/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import CGIARFocusClimateSecurityLogo from '../../assets/images/Footer/CGIARFocusClimateSecurityLogo.svg';
import FragilityConflictMigrationLogoWhite from '../../assets/images/Footer/FragilityConflictMigrationLogoWhite.png';
import climberLogo from '../../assets/images/Footer/Initiative - Climate Resilience-03.png';
import Livestock from '../../assets/images/Footer/Livestock.png';
import Agrilac from '../../assets/images/Footer/Agrilac.png';
import './styles.css';
import Translator from '../Translator';
import useCustomTranslate from '../../utils/translateFunction';
import terms from './terms';
import privacy from './privacy';

const Footer = () => {
	const [toggleAcknowledgements, setToggleAcknowledgements] = useState(false);
	const [toggleTerms, setToggleTerms] = useState(false);
	const [togglePrivacy, setTogglePrivacy] = useState(false);

	const renderTerms = () => {
		return terms.map((item) => {
			return <p className={item.type} style={{ width: item.type !== 'title' ? '100%' : '' }}><Translator text={item.text} params={item.params} /></p>;
		});
	};

	const renderPrivacy = () => {
		return privacy.map((item) => {
			return <p className={item.type} style={{ width: item.type !== 'title' ? '100%' : '' }}><Translator text={item.text} params={item.params} /></p>;
		});
	};

	return (
		<div className="footer">
			<div className="logos">
				<img src={CGIARFocusClimateSecurityLogo} alt="logo" />
				<img src={climberLogo} alt="logo" />
				<img src={FragilityConflictMigrationLogoWhite} alt="logo" />
				<img src={Livestock} alt="logo" />
				<img src={Agrilac} alt="logo" />
			</div>
			<div className="footer-right-elements">
				<div className="privacy-terms">
					<p onClick={() => setTogglePrivacy(true)}><Translator text={'Privacy'} /></p>
					<p onClick={() => setToggleTerms(true)}><Translator text={'Terms'} /></p>
					<p onClick={() => setToggleAcknowledgements(true)}><Translator text={'Acknowledgements'} /></p>
				</div>
				<div className="powered-by">
					<p><Translator text={'powered by '} /> <a href="https://scio.systems" target="_blank" style={{ display: 'inline', color: '#fff', textDecoration: 'none !important' }} rel="noreferrer">SCiO</a></p>
				</div>
			</div>
			<Dialog className="footer-acknowledgements" header={useCustomTranslate('Acknowledgements')} visible={toggleAcknowledgements} style={{ width: '50vw' }} onHide={() => setToggleAcknowledgements(false)}>
				<Translator
					text={'This work was developed under the <a1>CGIAR Initiative on Climate Resilience, ClimBeR</a1>' +
						', and the <a2>CGIAR Initiative on Fragility, Conflict, and Migration</a2>' +
						', and was supported by the <a3>Initiative on Livestock and Climate and the Initiative on AgriLAC Resilience</a3>. ' +
						'We would like to thank all funders who supported this research through their contributions to the CGIAR Trust Fund: ' +
						'<a4>Funders</a4>'}
					params={{
						a1: (content) => { return <a href="https://www.cgiar.org/initiative/climate-resilience/" target="_blank" rel="noopener noreferrer">{content}</a>; },
						a2: (content) => {
							return (
								<a
									href="https://www.cgiar.org/initiative/fragility-conflict-and-migration/#:~:text=The%20CGIAR%20Initiative%20on%20Fragility,equity%2C%20and%20fostering%20social%20inclusion."
									target="_blank"
									rel="noopener noreferrer"
								>{content}
								</a>
							); 
						},
						a3: (content) => { return <a href="https://www.cgiar.org/initiative/climate-resilience/" target="_blank" rel="noopener noreferrer">{content}</a>; },
						a4: (content) => { return <a href="https://www.cgiar.org/funders/" target="_blank" rel="noopener noreferrer">{content}</a>; },
					}}
				/>
			</Dialog>
			<Dialog className="footer-terms privacy-terms" header={useCustomTranslate('Terms of Use')} visible={toggleTerms} style={{ width: '50vw' }} onHide={() => setToggleTerms(false)}>
				<div className="container">
					{renderTerms()}
				</div>
			</Dialog>
			<Dialog className="footer-privacy privacy-terms" header={useCustomTranslate('Privacy Policy')} visible={togglePrivacy} style={{ width: '50vw' }} onHide={() => setTogglePrivacy(false)}>
				<div className="container">
					{renderPrivacy()}
				</div>
			</Dialog>
		</div>
	);
};

export default Footer;
