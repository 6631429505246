/* eslint-disable consistent-return,no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './styles.css';
import { replace } from '@amcharts/amcharts5/.internal/core/util/Array';
import summaries from '../../../CountryInformationPage/components/Body/components/Context/summaries';

const GlobeCountries = (props) => {
	const { toggled } = props;

	const navigate = useNavigate();

	useEffect(() => {
		const root = am5.Root.new('chartdiv');

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create the map chart
		// https://www.amcharts.com/docs/v5/charts/map-chart/
		const chart = root.container.children.push(am5map.MapChart.new(root, {
			panX: 'rotateX',
			panY: 'rotateY',
			wheelX: 'none',
			wheelY: 'none',
			projection: am5map.geoOrthographic(),
			paddingBottom: 20,
			paddingTop: 20,
			paddingLeft: 20,
			paddingRight: 20,
		}));

		// Create series for background fill
		// https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon

		const backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
		backgroundSeries.mapPolygons.template.setAll({
			fill: '#fafafa',
			// fillOpacity: 0.4,
			strokeOpacity: 0.5,
		});
		backgroundSeries.data.push({
			geometry: am5map.getGeoRectangle(90, 180, -90, -180),
		});

		// const newListCountries = () => {
		// 	const newGeoDataFeatures = am5geodata_worldLow.features.map((item) => {
		// 		let newItem = item;
		// 		if (item.properties.id === 'BF') {
		// 			newItem = { ...item, properties: { name: 'Burkina-Faso', id: 'BF' } };
		// 		}
		// 		return newItem;
		// 	});
		// 	const newGeoData = { ...am5geodata_worldLow, features: newGeoDataFeatures };
		// 	console.log(newGeoData);
		// 	return newGeoData;
		// };

		// Create main polygon series for countries
		// https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/

		const polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
			geoJSON: am5geodata_worldLow,
			include: [
				'KE',
				'SN',
				'ZW',
				'UG',
				'NG',
				'GT',
				'ML',
				'PH',
				'ZM',
				'ET',
				'SD',
				'BF',
				'NE',
			],
			fill: '#ffd868',
			fillOpacity: 1,
		}));
		// console.log(polygonSeries);

		const globeSeries = chart.series.push(
			am5map.MapPolygonSeries.new(root, {
				geoJSON: am5geodata_worldLow,
				exclude: [
					'KE',
					'SN',
					'ZW',
					'UG',
					'NG',
					'GT',
					'ML',
					'PH',
					'ZM',
					'ET',
					'SD',
					'BF',
					'NE',
				],
				fill: am5.color('#d9d9d9'),
			})
		);

		polygonSeries.mapPolygons.template.setAll({
			tooltipText: '{name}',
			toggleKey: 'active',
			interactive: true,
		});

		polygonSeries.mapPolygons.template.states.create('hover', {
			fill: am5.color('#FDC82F'),
			fillOpacity: 1,
		});

		const pointSeries = chart.series.push(
			am5map.MapPointSeries.new(root, {
				polygonIdField: 'polygonId',

			})
		);

		pointSeries.bullets.push(() => {
			return am5.Bullet.new(root, {
				sprite: am5.Label.new(root, {
					fontSize: 16,
					centerX: am5.p50,
					centerY: am5.p50,
					text: '{name}',
					populateText: true,
				}),
			});
		});

		let previousPolygon;

		polygonSeries.mapPolygons.template.on('active', (active, target) => {
			if (previousPolygon && previousPolygon != target) {
				previousPolygon.set('active', false);
			} else {
				chart.goHome();
			}
			previousPolygon = target;
		});

		// Set clicking on "water" to zoom out
		// chart.chartContainer.get('background').events.on('click', () => {
		// 	chart.goHome();
		// });

		polygonSeries.mapPolygons.template.events.on('click', (event) => {
			if (event.target._dataItem.dataContext.name === 'Burkina Faso') {
				navigate(`/Information/Burkina-Faso`);
			} else {
				navigate(`/Information/${event.target._dataItem.dataContext.name}`);
			}
		});

		// Create graticule series
		// https://www.amcharts.com/docs/v5/charts/map-chart/graticule-series/
		// const graticuleSeries = chart.series.push(am5map.GraticuleSeries.new(root, {}));
		// graticuleSeries.mapLines.template.setAll({ strokeOpacity: 0.1, stroke: root.interfaceColors.get('alternativeBackground') });

		// Rotate animation
		chart.animate({
			key: 'rotationX',
			from: 0,
			to: 360,
			duration: 60000,
			loops: Infinity,
		});

		// Make stuff animate on load
		chart.appear(1000, 100);
		return () => root.dispose();
	}, [toggled]);

	return (
		<div className="globe-countries" id="chartdiv" style={{ width: toggled ? 'calc(100vw - 780px)' : 'calc(100vw - 420px)', height: '95%', paddingTop: '25px', transition: 'all 0.3 ease' }} />
	);
};

export default GlobeCountries;
