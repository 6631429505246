/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// import econometricModeling from '../../../../../../../../assets/images/CountryInformationPage/How/Dialog/econometricModeling.png';
import econometricModeling from '../../../../../../../../assets/images/CountryInformationPage/How/em_big.jpg';
import { MockGraph } from '../NetworkModelContent/components';
import LearnMoreDialogContent from '../../../../../SideBar/components/LearnMoreDialogContent';
import dataSource from './dataSource.json';
import Translator from '../../../../../../../../components/Translator';

const EconometricModeling = () => {
	const [toggleContent, setToggleContent] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);

	const renderContent = () => {
		if (toggleContent) {
			if (window.location.href.includes('Kenya')) {
				return (
					<iframe
						src="https://embed.kumu.io/1e3052054a0e629f1eb231b47e10931c"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Ethiopia')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/ethiopia-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Philippines')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/the-philippines-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Guatemala')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/econometric-analysis-guatemala"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Zimbabwe')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/zimbabwe-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Mali')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/mali-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Sudan')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/sudan-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '100%', height: '100vh', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 6px 25px' }}
					/>
				);
			}

			if (window.location.href.includes('Nigeria')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/nigeria-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '75vw', height: '100vh' }}
					/>
				);
			}

			if (window.location.href.includes('Zambia')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/zambia-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '75vw', height: '100vh' }}
					/>
				);
			}

			if (window.location.href.includes('Uganda')) {
				return (
					<iframe
						src="https://kumu.io/CGIARFocusClimateSecurity/uganda-visualization"
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '75vw', height: '100vh' }}
					/>
				);
			}

			if (window.location.href.includes('Senegal')) {
				return (
					<iframe
						src="                             "
						width="100%"
						height="100%"
						frameBorder="0"
						style={{ width: '75vw', height: '100vh' }}
					/>
				);
			}
		}

		const getReportsByCountry = () => {
			if (window.location.href.includes('Kenya')) {
				return dataSource.kenyaReports;
			}
			if (window.location.href.includes('Mali')) {
				return dataSource.maliReports;
			}
			if (window.location.href.includes('Nigeria')) {
				return dataSource.nigeriaReports;
			}
			if (window.location.href.includes('Senegal')) {
				return dataSource.senegalReports;
			}
			if (window.location.href.includes('Sudan')) {
				return dataSource.sudanReports;
			}
			if (window.location.href.includes('Uganda')) {
				return dataSource.ugandaReports;
			}
			if (window.location.href.includes('Zimbabwe')) {
				return dataSource.zimbabweReports;
			}
		};

		return (
			<>
				<img src={econometricModeling} width="100%" />
				<h3><Translator text="Econometric Modeling" /></h3>
				<Button className="open-tool-button" label={<Translator text="Open Tool" />} onClick={() => setToggleContent(true)} />
				<div className="text-container">
					<p>
						<Translator text="Statistically-based econometric analysis and modeling is used to obtain an even deeper
						understanding of the linkages related to climate-vulnerable food, land, and water systems,
						helping to further quantify the Climate-Security Nexus. This approach focusses on testing
						whether and how climate variability and conflict risk are correlated using an analysis of
						highly localized food and nutrition insecurity dynamics. This analysis applies a causal
						mediation model to better understand the direct and indirect mechanisms that link climate
						variability to insecurity and the risk of conflict."
						/>
					</p>
				</div>
				<div className="learn-more-wrap">
					<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
					<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
						<LearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} dataReports={getReportsByCountry()} />
					</Dialog>
				</div>
			</>
		);
	};

	return (
		<div className="dialog-content">
			{renderContent()}
		</div>
	);
};

export default EconometricModeling;
