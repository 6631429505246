import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SvgAnimated, SvgPlayer } from './components';
import Actions from '../../../../reducer/actions';
import './styles.css';

const Approach = () => {
	const dispatch = useDispatch();
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });
	useEffect(
		() => {
			setCurrentPage('Approach');
			setShowMenus(true);
		}, []
	);

	return (
		<SvgPlayer />
	);
};

export default Approach;
