import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from 'primereact/carousel';
import Actions from '../../../../reducer/actions';
import './styles.css';
import Translator from '../../../../components/Translator';

const Platform = () => {
	const dispatch = useDispatch();
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });
	const [speed, SetSpeed] = useState(null);

	useEffect(
		() => {
			setCurrentPage('Platform');
			setShowMenus(true);
		}, []
	);

	useEffect(
		() => {
			const imgUrl = 'https://images.unsplash.com/photo-1653315344755-6eb86f5812f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8dG9waWNzfHx8fHx8MTY4NTQzOTQ0Mg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080';
			const size = 95800;

			const speedDetection = () => {
				window.setTimeout(MeasureConnectionSpeed, 1);
			};

			if (window.addEventListener) {
				window.addEventListener('load', speedDetection, false);
			} else if (window.attachEvent) {
				window.attachEvent('onload', speedDetection);
			}

			function MeasureConnectionSpeed() {
				let endTime;
				const download = new Image();
				download.onload = function () {
					endTime = (new Date()).getTime();
					showResults();
				};

				const startTime = (new Date()).getTime();
				const cacheBuster = `?nnn=${startTime}`;
				download.src = imgUrl + cacheBuster;

				function showResults() {
					const duration = (endTime - startTime) / 1000;
					const bitsLoaded = size * 8;
					const speedBps = (bitsLoaded / duration).toFixed(2);
					const speedKbps = (speedBps / 1024).toFixed(2);
					const speedMbps = (speedKbps / 1024).toFixed(2);

					SetSpeed(speedMbps);
				}
			}
		}, []
	);

	const data = [
		{
			id: '1',
			heading: <Translator text="About the Platform" />,
			text: <Translator text="The Climate Security Observatory is an evidence-based decision support tool helping researchers, policy makers and other practitioners working at the intersection of climate, peace and security to understand and respond to climate-related security risks." />,
		},
		{
			id: '2',
			heading: <Translator text="Why it is important" />,
			text: <Translator text="Research shows that extreme climate variability amplifies existing risks and insecurities, impacting already highly vulnerable water, land and food systems. In conflict-prone marginalized communities this can lead to a deepening of the underlying grievances and tensions that affect peace and security." />,
		},
		{
			id: '3',
			heading: <Translator text="What is the Climate Security Nexus" />,
			text: <Translator text="It describes the complex relationships and interactions across 5 systems:" />,
			listItemClimate: <Translator text="Climate and Environmental system" />,
			listItemFood: <Translator text="Food and agricultural system" />,
			listItemEconomic: <Translator text="Economic system" />,
			listItemSocio: <Translator text="Socio-economic system" />,
			listItemPolitical: <Translator text="Political and institutional system" />,
		},
		{
			id: '3',
			heading: <Translator text="How we aim to help" />,
			text: <Translator text="Climate acting as a threat multiplier is a widely recognized concept, but still there is a lack of robust, localized and contextualized and policy-relevant evidence on the climate security-nexus. The CSO aims to contribute filling this gap." />,
		},
	];

	const itemTemplate = (carouselItem) => {
		return (
			<div className="carousel-container">
				<h2>{carouselItem.heading}</h2>
				<p className="carousel-text">{carouselItem.text}</p>
				<ul className="carousel-list">
					{carouselItem.listItemClimate ? <li>{carouselItem.listItemClimate}</li> : null}
					{carouselItem.listItemFood ? <li>{carouselItem.listItemFood}</li> : null}
					{carouselItem.listItemClimate ? <li>{carouselItem.listItemEconomic}</li> : null}
					{carouselItem.listItemEconomic ? <li>{carouselItem.listItemSocio}</li> : null}
					{carouselItem.listItemPolitical ? <li>{carouselItem.listItemPolitical}</li> : null}
				</ul>
			</div>
		);
	};

	return (
		<div className={speed <= 0.20 ? 'bg-image-platform' : 'platform-container'}>
			{speed > 0.20
				? (
					<iframe
						src="https://player.vimeo.com/video/826829376?background=1&muted=1&autoplay=1&loop=1&h=d502b07322&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						title="About Option 1"
					/>
				) : null}
			<Carousel
				value={data}
				numVisible={1}
				numScroll={1}
				itemTemplate={itemTemplate}
				autoplayInterval={8000}
				circular
			/>
		</div>
	);
};
export default Platform;
