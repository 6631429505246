/* eslint-disable consistent-return */
import React from 'react';
import './styles.css';
import summaries from '../../summaries';
import Translator from '../../../../../../../../components/Translator';

const NoVariableSelectedConflict = (props) => {
	const { title } = props;

	const renderContent = () => {
		if (window.location.href.includes('Kenya')) {
			return (
				summaries.kenya.conflictText
			);
		}
		if (window.location.href.includes('Senegal')) {
			return (
				summaries.senegal.conflictText
			);
		}
		if (window.location.href.includes('Guatemala')) {
			return (
				summaries.guatemala.conflictText
			);
		}
		if (window.location.href.includes('Philippines')) {
			return (
				summaries.philippines.conflictText
			);
		}
		if (window.location.href.includes('Zambia')) {
			return (
				summaries.zambia.conflictText
			);
		}
		if (window.location.href.includes('Zimbabwe')) {
			return (
				summaries.zimbabwe.conflictText
			);
		}
		if (window.location.href.includes('Sudan')) {
			return (
				summaries.sudan.conflictText
			);
		}
		if (window.location.href.includes('Nigeria')) {
			return (
				summaries.nigeria.conflictText
			);
		}
		if (window.location.href.includes('Mali')) {
			return (
				summaries.mali.conflictText
			);
		}
		if (window.location.href.includes('Ethiopia')) {
			return (
				summaries.ethiopia.conflictText
			);
		}
	};

	return (
		<div className="no-variable">
			<p className="title">{title}</p>
			{/* <p className="open-sans"> */}
			{/*	conflictgorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut */}
			{/*	labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco */}
			{/*	laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in */}
			{/*	voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat */}
			{/*	non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
			{/* </p> */}
			{renderContent()}
			<div className="warning">
				<i className="fa-regular fa-circle-info" />
				<p className="open-sans"><Translator text="Select Variables to view some data" /></p>
			</div>
		</div>
	);
};

export default NoVariableSelectedConflict;
