/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import './styles.css';

const CheckBoxes = (props) => {
	const { rowData, setUserInput, userInput } = props;

	const [checked, setChecked] = useState(userInput[rowData.id]);

	useEffect(
		() => {
			const newInput = { ...userInput };
			const temp = rowData.id;
			newInput[temp] = checked;
			setUserInput(newInput);
		}, [checked]
	);
	
	return (
		<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'center' }}>
			<div className="checkbox" onClick={() => setChecked(0)} style={checked === 0 ? { backgroundColor: '#4A4A4A', color: '#FDC82F' } : {}}>
				<p>0</p>
			</div>
			<div className="checkbox" onClick={() => setChecked(0.5)} style={checked === 0.5 ? { backgroundColor: '#4A4A4A', color: '#FDC82F' } : {}}>
				<p>0.5</p>
			</div>
			<div className="checkbox" onClick={() => setChecked(1)} style={checked === 1 ? { backgroundColor: '#4A4A4A', color: '#FDC82F' } : {}}>
				<p>1</p>
			</div>
		</div>
	);
};

export default CheckBoxes;
