import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MultipleLines, MultipleBars } from './components';
import './styles.css';
import CountryService from '../../../../../../../../../../services/httpService/countryService';
import countryInformation from './chartInformation';

const Conflict = () => {
	const [data, setData] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [chartLabels, setChartLabels] = useState('');

	const whereFiltersSelections = useSelector((state) => state.whereFiltersSelections);

	const country = useSelector((state) => state.country);

	useEffect(
		() => {
			// CountryService.getClusterDefinitionBinnedData(country.id)
			// 	.then((res) => {
			// 		setData(res.graph_info);
			// 		console.log(res.graph_info);
			// 	});
			CountryService.getWhereLineChartData(country.id)
				.then((res) => {
					setData(res.graph_info);
					console.log(res.graph_info);
				});
		}, [country]
	);

	useEffect(
		() => {
			if (data) {
				switch (whereFiltersSelections.variable) {
				case 'Type Richness': assignData('TYPE_RICHNESS'); assignLabels('TYPE_RICHNESS'); break;
				case 'Events': assignData('EVENTS'); assignLabels('EVENTS'); break;
				case 'Subtype Richness': assignData('SUBTYPE_RICHNESS'); assignLabels('SUBTYPE_RICHNESS'); break;
				case 'Actor1 Richness': assignData('ACTOR1_RICHNESS'); assignLabels('ACTOR1_RICHNESS'); break;
				case 'Actor2 Richness': assignData('ACTOR2_RICHNESS'); assignLabels('ACTOR2_RICHNESS'); break;
				case 'Fatalities': assignData('FATALITIES'); assignLabels('FATALITIES'); break;
				default: break;
				}
			}
		}, [whereFiltersSelections, data]
	);

	const assignLabels = (variable) => {
		switch (variable) {
		case 'EVENTS': setChartLabels('Number of events'); break;
		case 'FATALITIES': setChartLabels('Number of fatalities'); break;
		case 'SUBTYPE_RICHNESS': setChartLabels('Sub-event type richness'); break;
		case 'ACTOR1_RICHNESS': setChartLabels('Actor 1 type richness'); break;
		case 'ACTOR2_RICHNESS': setChartLabels('Actor 2 type richness'); break;
		case 'TYPE_RICHNESS': setChartLabels('Event type richness'); break;
		default: break;
		}
	};

	const assignData = (variable) => {
		if (!data) return;
		const limitedConflictData = data.limited_conflict.find((item) => item.variable === variable)?.data;
		const moderateConflictData = data.moderate_conflict.find((item) => item.variable === variable)?.data;
		const highConflictData = data.high_conflict.find((item) => item.variable === variable)?.data;
		let maxY = 0;
		let maxX = 0;
		let label = '';
		switch (variable) {
		case 'EVENTS': label = 'Events'; break;
		case 'FATALITIES': label = 'Fatalities'; break;
		case 'SUBTYPE_RICHNESS': label = 'Sub-event type richness'; break;
		case 'ACTOR1_RICHNESS': label = 'Actor 1 type richness'; break;
		case 'ACTOR2_RICHNESS': label = 'Actor 2 type richness'; break;
		case 'TYPE_RICHNESS': label = 'Event type richness'; break;
		default: break;
		}
		const newChartData = {
			chartData: [
				{
					category: 'Limited Conflict',
					data: limitedConflictData,
				},
				{
					category: 'Moderate Conflict',
					data: moderateConflictData,
				},
				{
					category: 'High Conflict',
					data: highConflictData,
				},
			],
		};
		const flat = [...limitedConflictData, ...moderateConflictData, ...highConflictData];
		flat.map((item) => {
			// if (item.value > maxX) {
			// 	maxX = item.value;
			// }
			if (item.frequency > maxY) {
				maxY = item.frequency;
			}
		});

		newChartData.chartData.map((item) => {
			let index = item.data.length - 1;
			let flag = true;
			while (flag && index > 0) {
				if (item.data[index].frequency === 1) {
					index -= 1;
				} else {
					flag = false;
				}
			}
			if (item.data[index].bucket > maxX) {
				maxX = item.data[index].bucket;
			}
		});

		newChartData.maxY = maxY;
		newChartData.maxX = Number(maxX);
		newChartData.label = label;

		setChartData(newChartData);
	};

	const renderCountryInformation = () => {
		return countryInformation[country.id];
	};

	const renderCharts = () => {
		if (chartData) {
			return chartData.chartData.map((item, index) => {
				const temp = item.data.map((it) => {
					return { ...it, value: `${it.value}` };
				});
				return <MultipleBars data={temp} category={item.category} id={index} label={chartLabels} />;
			});
		}
		return null;
	};

	return (
		<div className="conflict">
			{/* {whereFiltersSelections.variable ? <p>Histogram showing {whereFiltersSelections.variable}</p> : null} */}
			{/* {chartData ? <MultipleLines data={chartData} id="1" /> : null } */}
			<p className="open-sans">
				{renderCountryInformation()}
			</p>
			<div className="charts">
				{renderCharts()}
			</div>
			{/* {chartData ? <MultipleBars data={chartData} category={} id="1" /> : null } */}
		</div>
	);
};

export default Conflict;
