import React from 'react';

const x = [
	{
		type: '',
		text: 'The CGIAR System Organization, as the Owner of the Site, and the CGIAR FOCUS Climate Security as the administrator of the Site, may collect, store, and use certain types of information about the Users of the Site.',
		params: '',
	},
	{
		type: 'title',
		text: '1. General aspects',
		params: '',
	},
	{
		type: '',
		text: 'These terms address what happens to any personal data or personal information that you give to us, or any that we may collect from or about you, your affiliation, your results, name of the project or program, email, at this Site, hosted at www.cso.cgiar.org. It explains how, through the different features our Site hosts, we collect, use, protect or otherwise handle Personal Data or Personally Identifiable Information. Thus, it applies to all products and services, and instances where we collect such personal data and information.',
		params: '',
	},
	{
		type: '',
		text: 'Read this Policy carefully before you provide any Personal Data or Personally Identifiable Information to us. Please proceed if you find the following terms agreeable. Otherwise, you are always welcome to peruse our Site without sharing your Personal Data with us.',
		params: '',
	},
	{
		type: 'title',
		text: '2. Key definitions',
		params: '',
	},
	{
		type: '',
		text: 'These are key terms used in this Policy:',
		params: '',
	},
	{
		type: '',
		text: '“Personally Identifiable Information” or “Personal Data” means any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, psychological, genetic, mental, economic, cultural, or social identify of that natural person.',
		params: '',
	},
	{
		type: '',
		text: '“Data Controller” means the natural or legal person, public authority, agency or other bodies which, alone or jointly with others, determines the purposes and means of the processing of personal data.',
		params: '',
	},
	{
		type: '',
		text: '“Data Processor” means a natural or legal person, public authority, agency or other bodies which processes personal data on behalf of the controller.',
		params: '',
	},
	{
		type: '',
		text: '“Data Subject” is the natural person whose personal data is collected and/or processed.',
		params: '',
	},
	{
		type: 'title',
		text: '3. Type of Information collected',
		params: '',
	},
	{
		type: 'sub-title',
		text: 'Personal information',
		params: '',
	},
	{
		type: '',
		text: 'You can access and use the Site without providing any personal information. If you provide Personal Data through one of the forms available on the Site, you as Data Subject may be asked for information that helps to identify you, such as your name, email address, and name of your organization.',
		params: '',
	},
	{
		type: 'sub-title',
		text: 'Non-personally identifiable information',
		params: '',
	},
	{
		type: '',
		text: 'We may also collect information about results, publications, data and information generated in projects, participation in events and activities, communications through or with the different components of the Site, and uploads or posts.',
		params: '',
	},
	{
		type: '',
		text: 'We may ask you to share results obtained by you when running analytical tools available at our Site. In this case, you are completely free to refuse or to accept sharing such results with us. Refusing to share your results with the CSO Site does not affect in any way your access to our tools and the ability to run the analyses and obtain your results. If you agree to share your results with us, such results will be showcased at the CSO Site (or Sites) and will be used as per the conditions applicable to “Contributed Materials ” described in the Terms of Use  of the Site.',
		params: '',
	},
	{
		type: '',
		text: 'We may also track and analyse information about how Site visitors use the Site by using cookies and collecting IP address information. This information helps us learn about the geographical distribution of our Site visitors and the technology they use to access the Site.',
		params: '',
	},
	{
		type: '',
		text: 'Only summaries of visitors’ behaviour are produced. We use Google Analytics to generate anonymous usage information. The information collected cannot be traced back to a particular individual and is never connected with any personal information you supply to us. Examples of this type of information include statistics on most popular and least popular pages.',
		params: '',
	},
	{
		type: '',
		text: 'You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Site and may be required to re-enter information more frequently to use certain services on the Site.',
		params: '',
	},
	{
		type: 'title',
		text: '4. Points for collection of information',
		params: '',
	},
	{
		type: '',
		text: 'Information can be collected when you register on the Site, fill out a form, decide in favour of sharing analytical results obtained with tools available at the Site, use an available tool or service, or enter/upload information on the Site. It is also collected when you provide feedback on the Site’s products or services. While not all these points may require you to provide information, we note them for your awareness.',
		params: '',
	},
	{
		type: 'title',
		text: '5. Use of the collected information',
		params: '',
	},
	{
		type: '',
		text: 'Personally Identifiable Information collected is used internally by the CGIAR FOCUS Climate Security at the CGIAR System Organization as Data Controller and Data Processor to process your and other users use of the Site, to process your communication with us and is not shared with third parties. The CGIAR FOCUS Climate Security at the CGIAR System Organization manages information collected in this Site in accordance with the research and innovation strategy of the CGIAR System Organization.',
		params: '',
	},
	{
		type: '',
		text: 'We may use such information in the following ways:',
		params: '',
	},
	{
		type: '',
		text: '•    To personalize your experience and to allow us to deliver the type of content, tools and services in which you are most interested or need the most.',
		params: '',
	},
	{
		type: '',
		text: '•    To help diagnose and solve issues with the site in order to improve it and better serve you.',
		params: '',
	},
	{
		type: '',
		text: '•    To follow up with you after correspondence (live chat or email inquiries), send information, respond to inquiries, and/or other requests or questions.',
		params: '',
	},
	{
		type: '',
		text: 'Non-personal information is used to generate usage statistics for the Site so that the we can better understand the preferences of the Site’s visitors and improve the Site.',
		params: '',
	},
	{
		type: '',
		text: 'Information such as project scoring results obtained by you while using tools available at the Site will be used to build up a database of interventions to be showcases at the CSO Site in future versions.',
		params: '',
	},
	{
		type: '',
		text: 'In particular, the CGIAR System Organization retains information for “archiving purposes in the public interest, scientific or historical research purposes or statistical purposes”, as per the scope at point a, of the article 17, paragraph 3, of the Regulation (EU) 2016/679 of the European parliament and of the Council “General Data Protection Regulation (GDPR)” of 27th April 2016.',
		params: '',
	},
	{
		type: 'title',
		text: '6. Change of purpose of use',
		params: '',
	},
	{
		type: '',
		text: 'We will only use your Personal Data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.',
		params: '',
	},
	{
		type: '',
		text: 'If we need to use your Personal Data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.',
		params: '',
	},
	{
		type: '',
		text: 'Please note that we may process your Personal Data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.',
		params: '',
	},
	{
		type: 'title',
		text: '7. Use of cookies',
		params: '',
	},
	{
		type: '',
		text: 'Cookies are small files that a site or its service provider transfers to your computer&apos;s hard drive through your Web browser (if you allow) that enables the site&apos;s or service provider&apos;s systems to recognize your browser and capture and remember certain information.',
		params: '',
	},
	{
		type: '',
		text: 'You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this Site may become inaccessible or not function properly. We use cookies for a variety of reasons detailed below:',
		params: '',
	},
	{
		type: '',
		text: '•    Login related cookies:',
		params: '',
	},
	{
		type: '',
		text: 'We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.',
		params: '',
	},
	{
		type: '',
		text: '•    Email newsletters related cookies:',
		params: '',
	},
	{
		type: '',
		text: 'This Site may offer newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users. By providing your email address, you consent to receive such communications. If at any time you would like to unsubscribe from receiving future emails, you can email the provided “unsubscribe” email on the applicable page, and we will promptly remove you from all correspondence.',
		params: '',
	},
	{
		type: '',
		text: '•    Surveys related cookies:',
		params: '',
	},
	{
		type: '',
		text: 'From time to time we offer User surveys and questionnaires to provide you with interesting insights, helpful tools, or to understand our User base more accurately. These surveys may use cookies to remember who has already taken part in a survey or to provide you with accurate results after you change pages.',
		params: '',
	},
	{
		type: '',
		text: '•    Forms related cookies:',
		params: '',
	},
	{
		type: '',
		text: 'When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence or interaction with you.',
		params: '',
	},
	{
		type: '',
		text: '•    Site preferences cookies:',
		params: '',
	},
	{
		type: '',
		text: 'In order to provide you with a better experience on this Site, we provide the functionality to set your preferences for how this Site runs when you use it. In order to remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.',
		params: '',
	},
	{
		type: '',
		text: '•    Performance cookies:',
		params: '',
	},
	{
		type: '',
		text: 'They collect data on the performance of the website, such as the number of visitors, the time spent by visitors on the web page and error messages. These performance cookies are session cookies (this means that these are automatically deleted when shutting down your browser) or persistent cookies (these files stay in one of your browser’s subfolders until you delete them manually or your browser deletes them based on the duration period contained within the persistent cookie’s file).',
		params: '',
	},
	{
		type: '',
		text: 'In some special cases we also use cookies provided by trusted third parties, such as :',
		params: '',
	},
	{
		type: '',
		text: '•    Google Analytics. These performance cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.',
		params: '',
	},
	{
		type: '',
		text: '•    Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the Site or pages you visit which helps us to understand how we can improve the Site for you.',
		params: '',
	},
	{
		type: '',
		text: '•    From time to time we test new features and make subtle changes to the way that the Site is delivered. When we are still testing new features, these cookies may be used to ensure that you receive a consistent experience whilst on the Site whilst ensuring we understand which optimisations our users appreciate the most.',
		params: '',
	},
	{
		type: '',
		text: '•    We employ several web services designed to improve the user experience and expand CSO capabilities. For these to work, the web services will set cookies through the Site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.',
		params: '',
	},
	{
		type: '',
		text: '•    We use social media buttons and/or plugins on this site that allow you to connect with some social media sites, including Twitter and YouTube. These sites use their own cookies. The cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.',
		params: '',
	},
	{
		type: 'title',
		text: '8. Sharing information',
		params: '',
	},
	{
		type: '',
		text: 'Personal Data is:',
		params: '',
	},
	{
		type: '',
		text: '•    shared internally in order to process communications, offer services and tools, process research and innovation-related information.',
		params: '',
	},
	{
		type: '',
		text: 'We also may share your data with:',
		params: '',
	},
	{
		type: '',
		text: '•    A partner and member of the CGIAR, which includes CGIAR Centers and research programs;',
		params: '',
	},
	{
		type: '',
		text: '•    A trusted suppliers, e.g. service providers, consultancy firms or independent consultants, and to entities hired to process the data (Data Processors) on behalf of us, as the Data Controller.',
		params: '',
	},
	{
		type: '',
		text: 'We may disclose your Personal Data to third parties:',
		params: '',
	},
	{
		type: '',
		text: '•    Where we are under a duty to disclose or share your Personal Data in order to comply with a legal obligation, enforce our terms, protect the rights, property or safety of Data Controller, our customers or others. This includes exchanging information for fraud prevent and to prevent cybercrime.',
		params: '',
	},
	{
		type: '',
		text: 'Non-personal information may be shared within the CGIAR System Organization, with Centers and programs or intiatives, and with companies and/or consultants involved in publications, disclosure or communications activities. Notice also that Non-personal information may also be shared by Google with its partners.',
		params: '',
	},
	{
		type: 'title',
		text: '9. Storage of information',
		params: '',
	},
	{
		type: '',
		text: 'For some of our internal systems, your Personal Data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology.',
		params: '',
	},
	{
		type: '',
		text: 'Personal Data that is collected and stored by third-party services is protected according to the privacy policies of each of these providers. Non-personal information is stored on Google servers in accordance with their security and data protection policies and procedures.',
		params: '',
	},
	{
		type: 'title',
		text: '10. Retention time',
		params: '',
	},
	{
		type: '',
		text: 'Personal information is retained for the length of time it is needed for use and for as long as is reasonably necessary to achieve the purposes for which it is collected and for our legal entitlements and legitimate business interests.',
		params: '',
	},
	{
		type: '',
		text: 'Information such as IP address, the one collected through cookies, the URL/domain name of any referring website, and so on is maintained indefinitely in aggregated form.',
		params: '',
	},
	{
		type: 'title',
		text: '11. Third-party links',
		params: '',
	},
	{
		type: '',
		text: 'At our discretion, we may include third-party links on our Site. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. To avoid any doubt, we do not assume responsibility for the information you may provide to that third party or on/at that website, or for how that third party may use or disclose any information you may provide to them. Nonetheless, we seek to protect the integrity of our Site and welcome any feedback about these sites.',
		params: '',
	},
	{
		type: 'title',
		text: '12. Data Subject Rights',
		params: '',
	},
	{
		type: '',
		text: 'Data Subject is the natural person whose personal data is collected and/or processed. As such, you can, at any time: access your Personal Data to know the details of treatment of them, update them, rectify them in the event of being inaccurate, incorrect or outdated, and cancel or delete it when it is considered not required for any of the purposes indicated in this privacy policy, or are being used for purposes not consented.',
		params: '',
	},
	{
		type: '',
		text: 'In case any of the above mentioned circumstances apply,  please contact us , or write or communicate to the physical address stated below. The CSO team will attend your claim as soon as possible.',
		params: '',
	},
	{
		type: '',
		text: 'If there are any questions regarding this privacy policy, you may contact us using the information below.',
		params: '',
	},
	{
		type: '',
		text: 'CGIAR System Organization',
		params: '',
	},
	{
		type: '',
		text: '1000, Avenue Agropolis',
		params: '',
	},
	{
		type: '',
		text: 'F-34394 Montpellier cedex 5',
		params: '',
	},
	{
		type: '',
		text: 'France',
		params: '',
	},
	{
		type: '',
		text: 'P: +33 (0)4 67 04 78 00',
		params: '',
	},

];

export default x;
