/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Timeline } from 'react-twitter-widgets';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Actions from '../../reducer/actions';
import CardDialoguesImage from '../../assets/images/Home/HomeCardDialoguesImg.svg';
import './styles.css';
import Translator from '../../components/Translator';

const Home = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [selectedCountry, setSelectedCountry] = useState(null);
	const [toggled, setToggled] = useState(true);
	const [toolButtonDialog, setToolButtonDialog] = useState(false);
	const [displayBasic, setDisplayBasic] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);

	const language = useSelector((state) => state.language);
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });

	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });
	
	const countries = ['Kenya', 'Senegal', 'Nigeria', 'Uganda', 'Zimbabwe', 'Guatemala', 'Mali', 'Philippines', 'Zambia', 'Ethiopia', 'Sudan'];

	useEffect(
		() => {
			setCurrentPage('Home');
			setShowMenus(true);
		}, []
	);
	useEffect(
		() => {
			if (selectedCountry) {
				navigate(`/Information/${selectedCountry}`);
			}
		}, [selectedCountry]
	);

	const headerDialoguesCard = (
		<img alt="Card-image" src={CardDialoguesImage} />
	);

	// const footerDialoguesCard = (
	// 	<Button
	// 		label={<Translator text={language.HomePageClimateSecurityDialoguesButtonLabel} />}
	// 		className="dialogues-card-read-more"
	// 		onClick={() => {
	// 			setDisplayBasic(true);
	// 			setToolButtonDialog(false);
	// 		}}
	// 	/>
	// );

	// const changeStyles = () => {
	// 	const menu = document.getElementById('side-menu');
	// 	if (menu) {
	// 		if (toggled) {
	// 			menu.style.width = '0';
	// 			menu.style.minWidth = '0';
	// 			setToggled(false);
	// 		} else {
	// 			menu.style.width = '400px';
	// 			menu.style.minWidth = '400px';
	// 			menu.style.background = '#F7F7F7';
	// 			setToggled(true);
	// 		}
	// 	}
	// };

	// const itemTemplate = (item) => {
	// 	return (
	// 		<Translator text={item} />
	// 	);
	// };
	// const renderMarkdownToHTML = (twitters) => {
	// 	const twitterHtml = { __html: { twitters } };
	// 	// eslint-disable-next-line react/no-danger
	// 	return <div dangerouslySetInnerHTML={twitterHtml} />;
	// };

	return (
		<div className="home">
			<h1><Translator text="The climate security observatory" /></h1>
			<div className="home-content">
				<div className="country-profiles">
					<h2><Translator text="Country Profiles" /></h2>
					<p><Translator text="Addressing our guiding questions - HOW, WHERE WHO and WHAT with qualitative and quantitative analyses at the country level." /></p>
					<div className="explore-button" onClick={() => navigate('/CountryProfiles')}>
						<p><Translator text="Explore country profiles" /></p>
						<i className="fa-regular fa-arrow-right" />
					</div>
				</div>
				<div className="climate-security-tools">
					<h2><Translator text="Climate Security Tools" /></h2>
					<p className="climate-security-text"><Translator text="Practical global tools for climate security-sensitive decision making" /></p>
					<div className="explore-button" onClick={() => navigate('/GlobalTools')}>
						<p><Translator text="Explore global tools" /></p>
						<i className="fa-regular fa-arrow-right" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default Home;
