/* eslint-disable consistent-return */
import React from 'react';
import { useSelector } from 'react-redux';
import { MinimumTemperature, MaximumTemperature } from './components';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import NoVariableSelectedClimate from '../NoVariableSelectedClimate';
import './styles.css';
import Translator from '../../../../../../../../components/Translator';
import useCustomTranslate from '../../../../../../../../utils/translateFunction';

const Climate = () => {
	const contextFilterSelections = useSelector((state) => state.contextFilterSelections);

	if (!contextFilterSelections.climateVariable) {
		return <NoVariableSelectedClimate title={<Translator text="Climate" />} />;
	}

	const renderPage = () => {
		switch (contextFilterSelections.climateVariable) {
		case 'Minimum temperature': return <MinimumTemperature />;
		case 'Maximum temperature': return <MaximumTemperature />;
		default: return null;
		}
	};

	return (
		<div className="subpage climate">
			<p className="title"><Translator text="Climate" /></p>
			<div className="header">
				{/* eslint-disable-next-line react-hooks/rules-of-hooks */}
				<p className="sub-title"><Translator text={contextFilterSelections.climateVariable} />
					{/* <Translator text={contextFilterSelections.climateVariable} /> */}
				</p>
			</div>
			{renderPage()}
		</div>
	);
};

export default Climate;
