/* eslint-disable react/jsx-no-useless-fragment */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import { Theme as am5themes_Animated } from '@amcharts/amcharts5';
import React, { useLayoutEffect, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import Translator from '../../../../../../../../../../components/Translator';

const Gauge = (props) => {
	const { data, id, category, description } = props;
	const [toggleDialog, setToggleDialog] = useState(false);

	useLayoutEffect(() => {
		const root = am5.Root.new(id);

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/radar-chart/
		const chart = root.container.children.push(
			am5radar.RadarChart.new(root, {
				panX: false,
				panY: false,
				startAngle: 180,
				endAngle: 360,
			})
		);
		// chart.getNumberFormatter().set('numberFormat', '#\'%\'');

		// Create axis and its renderer
		// https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
		const axisRenderer = am5radar.AxisRendererCircular.new(root, {
			innerRadius: -40,
		});

		axisRenderer.grid.template.setAll({
			stroke: root.interfaceColors.get('background'),
			visible: true,
			strokeOpacity: 0.8,
		});

		const xAxis = chart.xAxes.push(
			am5xy.ValueAxis.new(root, {
				maxDeviation: 0,
				min: data.min,
				max: data.max,
				strictMinMax: true,
				renderer: axisRenderer,
			})
		);

		// Add clock hand
		// https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
		const axisDataItem = xAxis.makeDataItem({});

		const clockHand = am5radar.ClockHand.new(root, {
			pinRadius: 50,
			radius: am5.percent(100),
			innerRadius: 50,
			bottomWidth: 0,
			topWidth: 0,
		});

		clockHand.pin.setAll({
			fillOpacity: 0,
			strokeOpacity: 0.5,
			stroke: am5.color(0x000000),
			strokeWidth: 1,
			strokeDasharray: [2, 2],
		});
		clockHand.hand.setAll({
			fillOpacity: 0,
			strokeOpacity: 0.5,
			stroke: am5.color(0x000000),
			strokeWidth: 0.5,
		});

		const bullet = axisDataItem.set(
			'bullet',
			am5xy.AxisBullet.new(root, {
				sprite: clockHand,
			})
		);

		xAxis.createAxisRange(axisDataItem);

		const label = chart.radarContainer.children.push(
			am5.Label.new(root, {
				centerX: am5.percent(50),
				textAlign: 'center',
				centerY: am5.percent(50),
				fontSize: '1.5em',
			})
		);

		axisDataItem.set('value', data.value);
		bullet.get('sprite').on('rotation', () => {
			let value = axisDataItem.get('value');
			value = value.toString();
			value = value.slice(0, 4);
			if (value[3] === '.') {
				value = value.slice(0, 3);
			}
			label.set('text', value);
		});

		chart.bulletsContainer.set('mask', undefined);

		const colorSet = am5.ColorSet.new(root, {});

		const axisRange0 = xAxis.createAxisRange(
			xAxis.makeDataItem({
				above: true,
				value: data.min,
				endValue: data.value,
			})
		);

		axisRange0.get('axisFill').setAll({
			visible: true,
			fill: '#285e69',
			stroke: '#285e69',
		});

		axisRange0.get('label').setAll({
			forceHidden: true,
		});

		const axisRange1 = xAxis.createAxisRange(
			xAxis.makeDataItem({
				above: true,
				value: data.value,
				endValue: data.max,
			})
		);

		axisRange1.get('axisFill').setAll({
			visible: true,
			fill: '#ffffff',
			stroke: '#285e69',
		});

		axisRange1.get('label').setAll({
			forceHidden: true,
		});

		// Make stuff animate on load
		chart.appear(1000, 100);

		return () => root.dispose();
	}, [data]);

	return (
		<div className="chart-container" style={{ width: '33%' }}>
			<div id={id} style={{ width: '100%', height: '22vw' }} />
			<div className="info">
				<p><Translator text={category} /></p>
				{/* <Tooltip target={`.info-icon-${id}`} content={`${description}`} style={{ maxWidth: '13%', minWidth: '100px' }} position="left" /> */}
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<i className={`info-icon-${id} fa-light fa-circle-info info-icon`} onClick={() => setToggleDialog(true)} />
			</div>
			<Dialog visible={toggleDialog} style={{ width: '50vw' }} onHide={() => setToggleDialog(false)}>
				<div className="variable-descriptions">
					{description}
				</div>
			</Dialog>
		</div>
	);
};
export default Gauge;
