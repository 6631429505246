import Actions from './actions';

const initState = {
	language: {},
	currentPage: 'home',
	darkMode: false,
	aboutPage: 'about',
	country: '',
	tab: 'Context',
	whoSelectedFeature: {},
	whereSubpageSelection: 'map',
	whereFiltersSelections: { conflict: '', climate: '', socioeconomic: '', cluster: '', variable: '', yAxis: '', xAxis: '', routesClass: '' },
	whereSelectedFeature: {},
	contextSubPageSelection: 'climate',
	contextFilterSelections: { climateVariable: '', climateDuration: [2, 350], conflictVariable: { variabele: '', data: [] }, conflictDuration: ['', ''], globalVariable: '', globalDuration: ['', ''] },
	megapixelsGeoJson: null,
	toggled: true,
	showMenus: true,
	livestockSelected: false,
	generalMappingSelected: false,
	routeClass: [{ label: 'Major Functional', featureClass: 'Major functional' },
		{ label: 'Major Functional Seasonal', featureClass: 'Major functional seasonal' },
		{ label: 'Minor Functional', featureClass: 'Minor functional' },
		{ label: 'Minor Non-Functional', featureClass: 'Minor non-functional' },
		{ label: 'Major Non-Functional', featureClass: 'Major non-functional' },
		{ label: 'Other', featureClass: 'Other' }],
	whereSwitchValues: false,
	conflictLayersClass: [],
	showAcledMap: false,
	minDate: 0,
	maxDate: 0,
	tileLayersClass: [],
	livestockDataNdvi: null,
	livestockDataRainfall: null,
	tileLayersRainfallClass: [],
	sliderValue: [0, 0],
};

// eslint-disable-next-line default-param-last
const reducer = (currentState = initState, action) => {
	switch (action.type) {
	case Actions.SetLanguage:
		return {
			...currentState,
			language: action.payload,
		};
	case Actions.SetCurrentPage:
		return {
			...currentState,
			currentPage: action.payload,
		};
	case Actions.SetDarkMode:
		return {
			...currentState,
			darkMode: action.payload,
		};
	case Actions.SetAboutPage:
		return {
			...currentState,
			aboutPage: action.payload,
		};
	case Actions.SetCountry:
		return {
			...currentState,
			country: action.payload,
		};
	case Actions.SetTab:
		return {
			...currentState,
			tab: action.payload,
		};

	case Actions.SetToggled:
		return {
			...currentState,
			toggled: action.payload,
		};
	case Actions.SetWhoSelectedFeature:
		return {
			...currentState,
			whoSelectedFeature: action.payload,
		};
	case Actions.SetWhereSubPageSelection:
		return {
			...currentState,
			whereSubpageSelection: action.payload,
		};
	case Actions.SetWhereFilterSelections:
		return {
			...currentState,
			whereFiltersSelections: action.payload,
		};
	case Actions.SetWhereSelectedFeature:
		return {
			...currentState,
			whereSelectedFeature: action.payload,
		};
	case Actions.SetContextSubPageSelection:
		return {
			...currentState,
			contextSubPageSelection: action.payload,
		};
	case Actions.SetContextFilterSelections:
		return {
			...currentState,
			contextFilterSelections: action.payload,
		};
	case Actions.SetMegapixelsGeoJson:
		return {
			...currentState,
			megapixelsGeoJson: action.payload,
		};
	case Actions.SetShowMenus:
		return {
			...currentState,
			showMenus: action.payload,
		};
	case Actions.SetLivestockSelected:
		return {
			...currentState,
			livestockSelected: action.payload,
		};
	case Actions.SetGeneralMappingSelected:
		return {
			...currentState,
			generalMappingSelected: action.payload,
		};
	case Actions.SetRouteClass:
		return {
			...currentState,
			routeClass: action.payload,
		};
	case Actions.SetWhereSwitchValues:
		return {
			...currentState,
			whereSwitchValues: action.payload,
		};
	case Actions.SetConflictLayersClass:
		return {
			...currentState,
			conflictLayersClass: action.payload,
		};
	case Actions.SetShowAcledMap:
		return {
			...currentState,
			showAcledMap: action.payload,
		};
	case Actions.SetMinDate:
		return {
			...currentState,
			minDate: action.payload,
		};
	case Actions.SetMaxDate:
		return {
			...currentState,
			maxDate: action.payload,
		};
	case Actions.SetTileLayersClass:
		return {
			...currentState,
			tileLayersClass: action.payload,
		};
	case Actions.SetLivestockDataNdvi:
		return {
			...currentState,
			livestockDataNdvi: action.payload,
		};
	case Actions.SetLivestockDataRainfall:
		return {
			...currentState,
			livestockDataRainfall: action.payload,
		};
	case Actions.SetTileLayersRainfallClass:
		return {
			...currentState,
			tileLayersRainfallClass: action.payload,
		};
	case Actions.SetSliderValue:
		return {
			...currentState,
			sliderValue: action.payload,
		};
	default: return currentState;
	}
};

export default reducer;
