/* eslint-disable no-underscore-dangle */
import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { Theme as am5themes_Animated } from '@amcharts/amcharts5';
import React, { useEffect, useRef, useState } from 'react';
import * as am5xy from '@amcharts/amcharts5/xy';
import { useSelector } from 'react-redux';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { backgroundColors as defaultColorsKenya } from '../../../../../../../../../../utils/palleteKenya';
import { backgroundColors as defaultColorsSenegal } from '../../../../../../../../../../utils/palleteSenegal';
import { backgroundColors as defaultColorsZimbabwe } from '../../../../../../../../../../utils/palleteZimbabwe';
import { backgroundColors as defaultColorsUganda } from '../../../../../../../../../../utils/palleteUganda';
import { backgroundColors as defaultColorsNigeria } from '../../../../../../../../../../utils/palleteNigeria';
import { backgroundColors as defaultColorsGuatemala } from '../../../../../../../../../../utils/palleteGuatemala';
import { backgroundColors as defaultColorsMali } from '../../../../../../../../../../utils/palleteMali';
import { backgroundColors as defaultColorsPhilippines } from '../../../../../../../../../../utils/palletePhilipines';
import { backgroundColors as defaultColorsZambia } from '../../../../../../../../../../utils/palleteZambia';
import { backgroundColors as defaultColorsEthiopia } from '../../../../../../../../../../utils/palleteEthiopia';
import { backgroundColors as defaultColorsSudan } from '../../../../../../../../../../utils/palleteSudan';
import Translator from '../../../../../../../../../../components/Translator';

const BoxPlot = (props) => {
	const { data, label, category, id, climateValue, description, pixelData } = props;
	const [toggleDialog, setToggleDialog] = useState(false);

	const country = useSelector((state) => state.country);
	const defaultColors = (filters) => {
		switch (country.id) {
		case 'KEN': return defaultColorsKenya(filters);
		case 'SEN': return defaultColorsSenegal(filters);
		case 'ZWE': return defaultColorsZimbabwe(filters);
		case 'UGA': return defaultColorsUganda(filters);
		case 'NGA': return defaultColorsNigeria(filters);
		case 'GTM': return defaultColorsGuatemala(filters);
		case 'MLI': return defaultColorsMali(filters);
		case 'PHL': return defaultColorsPhilippines(filters);
		case 'ZMB': return defaultColorsZambia(filters);
		case 'ETH': return defaultColorsEthiopia(filters);
		case 'SDN': return defaultColorsSudan(filters);
		default: return defaultColorsKenya(filters);
		}
	};

	useEffect(() => {
		const root = am5.Root.new(id);

		data[0].category = '';

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				focusable: true,
				panX: true,
				panY: true,
				wheelX: 'panX',
				wheelY: 'zoomX',
			})
		);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		// const xAxis = chart.xAxes.push(
		// 	am5xy.DateAxis.new(root, {
		// 		baseInterval: { timeUnit: 'day', count: 1 },
		// 		renderer: am5xy.AxisRendererX.new(root, {}),
		// 		tooltip: am5.Tooltip.new(root, {}),
		// 	})
		// );

		const xRenderer = am5xy.AxisRendererX.new(root, {
			minGridDistance: 30,
		});

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: 'category',
				renderer: xRenderer,
			})
		);

		xAxis.data.setAll([{
			category: '',
		}]);

		xRenderer.labels.template.setAll({
			oversizedBehavior: 'wrap',
			textAlign: 'center',
		});

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {}),
			})
		);

		const color = root.interfaceColors.get('background');

		// Add series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
		const series = chart.series.push(
			am5xy.CandlestickSeries.new(root, {
				name: 'MDXI',
				xAxis,
				yAxis,
				valueYField: 'lower_quartile',
				openValueYField: 'upper_quartile',
				lowValueYField: 'minimum',
				highValueYField: 'maximum',
				// closeValueYField: 'lower_whisker',
				categoryXField: 'category',
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: 'horizontal',
					labelText: 'Upper whisker: {highValueY}\n3rd quartile: {openValueY}\nMedian: {median},\n1st quartile: {valueY},\nLower whisker: {lowValueY}',
				}),
			})
		);

		series.columns.template.adapters.add('fill', (fill, target) => {
			const temp = defaultColors(climateValue);
			return temp.backgroundColor;
		});
		series.columns.template.adapters.add('stroke', (fill, target) => {
			const temp = defaultColors(climateValue);
			return temp.backgroundColor;
		});

		// mediana series
		const medianaSeries = chart.series.push(
			am5xy.StepLineSeries.new(root, {
				xAxis,
				yAxis,
				valueYField: 'median',
				categoryXField: 'category',
				noRisers: true,
				fill: '#000000',
				stroke: '#000000',
				stepWidth: am5.percent(50),
			})
		);

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			xAxis,
		}));
		cursor.lineY.set('visible', false);

		series.data.setAll(data);
		medianaSeries.data.setAll(data);

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		series.appear(1000, 100);
		// eslint-disable-next-line no-unused-expressions
		medianaSeries.appear;

		return () => root.dispose();
	}, []);

	return (
		<div className="chart-container">
			<p>{label} of selected megapixel: {pixelData}</p>
			<div id={id} style={{ width: '100%', height: '500px' }} />
			<div className="info">
				<p><Translator text={label} /></p>
				{/* <Tooltip target={`.info-icon-${id}`} content={`${description}`} style={{ maxWidth: '13%', minWidth: '100px' }} position="left" /> */}
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<i className={`info-icon-${id} fa-light fa-circle-info info-icon`} onClick={() => setToggleDialog(true)} />
			</div>
			<Dialog visible={toggleDialog} style={{ width: '50vw' }} onHide={() => setToggleDialog(false)}>
				<div className="variable-descriptions">
					{description}
				</div>
			</Dialog>
		</div>
	);
};
export default BoxPlot;
