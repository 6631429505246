/* eslint-disable react/destructuring-assignment,consistent-return,react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import dataSources from '../../../../../../../../../../assets/images/CountryInformationPage/dataSources.svg';
import methods from '../../../../../../../../../../assets/images/CountryInformationPage/methods.svg';
import pdf from '../../../../../../../../../../assets/pdf/what-sm/CSO_Social Media Analysis 2023.pdf';
import './styles.css';
import Translator from '../../../../../../../../../../components/Translator';
import useCustomTranslate from '../../../../../../../../../../utils/translateFunction';
import reports from '../../../../../../../../../../assets/images/CountryInformationPage/reports.svg';

const ConceptualLearnMoreDialogContent = (props) => {
	const { dataSource, dataMethods, dataReports } = props;

	const downloadData = () => {
		const x = [dataSource, dataMethods];
		const url = window.URL.createObjectURL(
			// new Blob([JSON.stringify(dataSourcesMockData), JSON.stringify(methodsMockData), JSON.stringify(reportsMockData)], { type: 'text/json' })
			new Blob([JSON.stringify(x)], { type: 'text/json' })
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			'data.txt'
		);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	const sourceTemplate = (data) => {
		// const renderSources = () => {
		// 	return data.sources.map((item) => {
		// 		return (
		// 			<a href={item} target="_blank" rel="noreferrer">{item}</a>
		// 		);
		// 	});
		// };
		return (
			(data.url_na ? <p>{data.url_na}</p>
				: (
					<>
						<a href={data.url} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a><br /><br />
						{data.url_b
							? (
								<a href={data.url_b} target="_blank" rel="noreferrer" aria-label="link">
									<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
								</a>
							) : null }
					</>
				)
			)

		);
	};

	const descriptionTemplate = (data) => {
		return (
			<>
				<p>{data.description}</p>
				<a href={data.description_link} target="_blank" rel="noreferrer">{data.description_link}</a>
			</>
		);
	};

	const urlTemplate = (data) => {
		return (
			(data.url_na ? <p>{data.url_na}</p>
				: (
					<a href={data.url} target="_blank" rel="noreferrer" aria-label="link">
						<i className="fa-regular fa-file-pdf" style={{ fontSize: '35px', color: '#fdc82f' }} />
					</a>
				)

			)

		);
	};
	const rowGroupHeaderTemplate = (data) => {
		return (
			<div className="row-group-header">
				<p>{data.category}</p>
			</div>
		);
	};
	const displayCountryBasedTable = () => {
		if (window.location.href.includes('Ethiopia')) {
			return (
				<DataTable value={dataSource.Ethiopia} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Guatemala')) {
			return (
				<DataTable value={dataSource.Guatemala} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Kenya')) {
			return (
				<DataTable value={dataSource.Kenya} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Mali')) {
			return (
				<DataTable value={dataSource.Mali} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Nigeria')) {
			return (
				<DataTable value={dataSource.Nigeria} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Philippines')) {
			return (
				<DataTable value={dataSource.Philippines} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Senegal')) {
			return (
				<DataTable value={dataSource.Senegal} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Sudan')) {
			return (
				<DataTable value={dataSource.Sudan} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Zambia')) {
			return (
				<DataTable value={dataSource.Zambia} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
		if (window.location.href.includes('Zimbabwe')) {
			return (
				<DataTable
					value={dataSource.Zimbabwe}
					responsiveLayout="scroll"
					stripedRows
					style={{ textAlign: 'center' }}
				>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="author" header={<Translator text="Author" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column header={<Translator text="Url" />} body={sourceTemplate} />
				</DataTable>
			);
		}
	};

	const titleTemplate = (data) => {
		return (
			<p><Translator text={data.title} /></p>
		);
	};

	const yearTemplate = (data) => {
		return (
			<p><Translator text={data.year} /></p>
		);
	};

	return (
		<div className="learn-more-content">
			<div className="data-sources row">
				<div className="header">
					<img src={dataSources} alt="data-sources-icon" />
					<p className="title"><Translator text="Data Sources" /></p>
				</div>
				{displayCountryBasedTable()}
				{/* <DataTable value={dataSource} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}> */}
				{/*	<Column field="title" header="Title" /> */}
				{/*	/!* <Column body={descriptionTemplate} header="Description" style={{ textAlign: 'justify' }} /> *!/ */}
				{/*	<Column field="author" header="Author" /> */}
				{/*	<Column field="year" header="Year" /> */}
				{/*	<Column header="Source" body={sourceTemplate} /> */}
				{/* </DataTable> */}
			</div>
			<div className="methods row">
				<div className="header">
					<img src={methods} alt="methods-icon" />
					<p className="title"><Translator text="Methods" /></p>
				</div>
				<DataTable value={dataMethods} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column field="title" header={<Translator text="Title" />} />
					<Column field="year" header={<Translator text="Year" />} />
					<Column field="url" body={urlTemplate} header={<Translator text="Url" />} />
				</DataTable>
			</div>
			<div className="reports row">
				<div className="header">
					<img src={reports} alt="reports-icon" />
					<p className="title"><Translator text="Reports" /></p>
				</div>
				<DataTable value={dataReports} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column body={titleTemplate} header={<Translator text="Title" />} />
					<Column body={yearTemplate} header={<Translator text="Year" />} />
					<Column field="url" body={sourceTemplate} header={<Translator text="Url" />} />
				</DataTable>

			</div>
		</div>

	);
};
export default ConceptualLearnMoreDialogContent;
