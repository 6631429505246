import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Climate, Conflict, GlobalMetrics } from './components';
import './styles.css';
import Actions from '../../../../../../reducer/actions';
import ComingSoon from '../../../../../../components/ComingSoon';

const Context = () => {
	const dispatch = useDispatch();

	const country = useSelector((state) => state.country);

	const contextSubPageSelection = useSelector((state) => state.contextSubPageSelection);

	const setContextSubPageSelection = (payload) => dispatch({ type: Actions.SetContextSubPageSelection, payload });

	const setContextFilterSelections = (payload) => dispatch({ type: Actions.SetContextFilterSelections, payload });

	useEffect(
		() => {
			return () => {
				setContextSubPageSelection('climate');
				setContextFilterSelections({
					climateVariable: '',
					climateDuration: [2, 350],
					conflictVariable: { variabele: '', data: [] },
					conflictDuration: ['', ''],
					globalVariable: '',
					globalDuration: ['', ''],
				});
			};
		}, []
	);
	const renderPage = () => {
		switch (contextSubPageSelection) {
		case 'climate': return <Climate />;
		case 'conflict': return <Conflict />;
		case 'metrics': return <GlobalMetrics />;
		default: return null;
		}
	};

	return (
		<div className="context">

			{ country.id === 'BFA' || country.id === 'NER' ? (

				<ComingSoon comingSoonContent={'Coming Soon!'} />

			)
				: (
					<>
						{renderPage()}
					</>

				)

			}

		</div>
	);
};

export default Context;
