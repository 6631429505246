import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import Actions from '../../../../../../../../reducer/actions';
import CountryService from '../../../../../../../../services/httpService/countryService';
import Translator from '../../../../../../../../components/Translator';

const Conflict = () => {
	const dispatch = useDispatch();

	const contextFilterSelections = useSelector((state) => state.contextFilterSelections);

	const country = useSelector((state) => state.country);
	const setContextFilterSelections = (payload) => dispatch({ type: Actions.SetContextFilterSelections, payload });

	const [variable, setVariable] = useState(contextFilterSelections.conflictVariable);
	const [startYear, setStartYear] = useState(contextFilterSelections.conflictDuration[0]);
	const [endYear, setEndYear] = useState(contextFilterSelections.conflictDuration[1]);
	const [data, setData] = useState(null);
	const [durationOptions, setDurationOptions] = useState([]);

	useEffect(
		() => {
			console.log(country.name);
			CountryService.getAcledChartData(country.id)
				.then((res) => {
					setData(res.data_info);
				});
		}, [country]
	);

	useEffect(
		() => {
			if (data) {
				let temp;
				switch (variable) {
				case 'Fatalities': temp = data.fatalities; break;
				case 'Events': temp = data.events; break;
				default: break;
				}
				if (temp) {
					const newDurationOptions = temp.map((item) => {
						return item.year;
					});
					setDurationOptions(newDurationOptions);
					setStartYear(newDurationOptions[0]);
					setEndYear(newDurationOptions[newDurationOptions.length - 1]);
				}
			}
		}, [data, variable]
	);

	useEffect(
		() => {
			if (data) {
				const temp = { ...contextFilterSelections };
				let conflictVariable;
				switch (variable) {
				case 'Fatalities': conflictVariable = data.fatalities; break;
				case 'Events': conflictVariable = data.events; break;
				default: break;
				}
				temp.conflictVariable = { variable, data: conflictVariable };
				temp.conflictDuration = [startYear, endYear];
				setContextFilterSelections(temp);
			}
		}, [variable, startYear, endYear]
	);

	const itemTemplate = (item) => {
		return (
			<Translator text={item} />
		);
	};

	return (
		<div className="filters">
			<div className="dropdown">
				<p className="open-sans"><Translator text="Variable" /></p>
				<Dropdown
					value={variable}
					options={['Fatalities', 'Events']}
					onChange={(e) => setVariable(e.value)}
					placeholder={<Translator text="Please select" />}
					itemTemplate={itemTemplate}
					valueTemplate={itemTemplate}
				/>
			</div>
			<div className="dropdown multiple">
				<p className="open-sans"><Translator text="Duration" /></p>
				<div className="during">
					<Dropdown value={startYear} options={durationOptions} onChange={(e) => setStartYear(e.value)} />
					<Dropdown value={endYear} options={durationOptions} onChange={(e) => setEndYear(e.value)} />
				</div>
			</div>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<p
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setVariable('');
					setStartYear('');
					setEndYear('');
				}}
			><Translator text="Reset all filters" />
			</p>
		</div>
	);
};

export default Conflict;
