/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../../../components/Translator';
import Actions from '../../../../reducer/actions';

const Question = (props) => {
	const { image, question, text, id, index, style } = props;
	const dispatch = useDispatch();

	useEffect(
		() => {
			let paths = document.getElementsByTagName('path');
			paths = [...paths];
			paths.map((item) => {
				// eslint-disable-next-line no-param-reassign
				item.style.fill = 'white';
			});
			// const temp = document.getElementById(id);
			// temp.className = 'image-container active';
			
			// setTimeout(() => {
			// 	temp.className = 'image-container';
			// }, 6500 + 3000 + 3800 * index);
		}, []
	);

	return (
		<div className="flip-card" style={style}>
			<div className="image-container" id={id}>
				{image}
				<div className="question-rectangle">
					<p>
						<Translator
							text={`<span>${question} </span>${text}`}
							params={{
								span: (content) => <span>{content}</span>,
							}}
						/>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Question;
