/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// import conceptualModeling from '../../../../../../../../assets/images/CountryInformationPage/How/Dialog/conceptualModeling.png';
import { useSelector } from 'react-redux';
import conceptualModeling from '../../../../../../../../assets/images/CountryInformationPage/How/cm_big.jpg';
import { ConceptualLearnMoreDialogContent } from './components';
import dataSource from './dataSource.json';
import generalImage from '../../../../../../../../assets/images/CountryInformationPage/Where/general.svg';
import livestockImage from '../../../../../../../../assets/images/CountryInformationPage/Where/livestock.svg';
import Translator from '../../../../../../../../components/Translator';
import './styles.css';

const ConceptualModeling = () => {
	const [toggleContent, setToggleContent] = useState(false);
	const [openLivestock, setOpenLivestock] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);

	const country = useSelector((state) => state.country);

	const style = { width: '100%', height: '70vh', boxShadow: '0px 6px 25px rgba(0,0,0,0.5)' };

	const renderContent = () => {
		if (toggleContent) {
			if (window.location.href.includes('Kenya')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/cbfcc0449dbdcc32ee5b57b6ca842033"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Ethiopia')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/d5286536b4705e25c5b3951138c9f4a8"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Philippines')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/0863a38268eb3881498b633e34994098"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Guatemala')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/2397703336d13fa13dd8797e25596bdb"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Zimbabwe')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/8558c5c0aeb774c41199dc52e36388a1"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Mali')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/a4c2f31d456a00b623ce113449bdca47"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Sudan')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/73beb460b2da472e8030959ab03fbb18"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Nigeria')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/0da6f567ecf389df148e85ab91287172"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Zambia')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/1fffc3b41fade3ae8f999ce050238ef8"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Uganda')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setToggleContent(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/780935d854836bec1522c5227ecfb5bb#untitled-map"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>
				);
			}

			if (window.location.href.includes('Senegal')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
		}

		if (openLivestock) {
			if (window.location.href.includes('Kenya')) {
				return (
					<div className="iframe-container">
						<Button icon="pi pi-times" onClick={() => setOpenLivestock(false)} className="p-button-text" />
						<iframe
							src="https://embed.kumu.io/276eedfb3c3bbe93b9da79f27d22d778#untitled-map"
							width="100%"
							height="100%"
							frameBorder="0"
							style={style}
						/>
					</div>

				);
			}
			if (window.location.href.includes('Senegal')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Philippines')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Mali')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Ethiopia')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Zimbabwe')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Uganda')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Sudan')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Guatemala')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Nigeria')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
			if (window.location.href.includes('Zambia')) {
				return (
					<div>
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
					</div>
				);
			}
		}

		const getReportsByCountry = () => {
			if (window.location.href.includes('Kenya')) {
				return dataSource.kenyaReports;
			}
			if (window.location.href.includes('Mali')) {
				return dataSource.maliReports;
			}
			if (window.location.href.includes('Nigeria')) {
				return dataSource.nigeriaReports;
			}
			if (window.location.href.includes('Senegal')) {
				return dataSource.senegalReports;
			}
			if (window.location.href.includes('Sudan')) {
				return dataSource.sudanReports;
			}
			if (window.location.href.includes('Uganda')) {
				return dataSource.ugandaReports;
			}
			if (window.location.href.includes('Zimbabwe')) {
				return dataSource.zimbabweReports;
			}
			if (window.location.href.includes('Guatemala')) {
				return dataSource.guatemalaReports;
			}
			if (window.location.href.includes('Ethiopia')) {
				return dataSource.ethiopiaReports;
			}
		};

		return (
			<div>

				{

					(country.id === 'KEN') ? (
						<>
							<img src={conceptualModeling} width="100%" />
							<h2><Translator text="Conceptual modeling" /></h2>
							<div className="modeling">
								<div className="general">
									<div className="header">
										<img src={generalImage} />
										<h3>General climate security pathway </h3>
									</div>
									<div className="text-container">
										<p><Translator
											text="Drawing on a literature review and content analysis guided by relevant data and supplemented
						with expert insight and first-hand information from practitioners and people directly affected
						by conflict, a conceptual model of how climate acts as a threat multiplier is constructed.
						With a particular emphasis on food, land, and water systems, a climate security pathway
						analysis is employed to distill and map out prospective climate security pathways, detailing
						the intricate relationships between climate, conflict, and system (food, land, water)
						vulnerabilities. The critical pathway relationships and principle drivers identified through
						this modeling serve as the foundation for subsequent quantitative studies by informing
						the selection of appropriate indicators from the Climate Security spectrum."
										/>
										</p>
									</div>
									<Button className="open-tool-button" label={<Translator text="Open General Model" />} onClick={() => setToggleContent(true)} />
								</div>
								<div className="livestock">
									<div className="header">
										<img src={livestockImage} />
										<h3>Livestock climate security pathway </h3>
									</div>
									<div className="text-container">
										<p><Translator
											text="The Livestock Climate Security Pathways delve into the unique climate security challenges faced by pastoral and agro-pastoral communities, centering on a comprehensive examination of literature and content analysis, complemented by fieldwork case studies. The analysis aims to elaborate on connections between climate-induced effects on pivotal elements for pastoralists, such as food, land, and water systems. Additionally, it includes structural barriers that impede pastoral livelihoods. Through a climate security pathway analysis, the research unveils the compounding impacts intensifying human security risks and conflicts among pastoralist communities and farmer-herder conflicts."
										/>
										</p>
									</div>
									<Button className="open-tool-button" label={<Translator text="Open Livestock Model" />} onClick={() => setOpenLivestock(true)} />
								</div>
							</div>

							<div className="learn-more-wrap">
								<Button
									style={{ marginTop: '50px' }}
									label={(
										<Translator
											text="Learn More"
										/>
									)}
									onClick={() => setDisplayDialog(true)}
								/>
								<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
									<ConceptualLearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} dataReports={getReportsByCountry()} />
								</Dialog>
							</div>
						</>
					) : (
						<>
							<img src={conceptualModeling} width="100%" />
							<div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
								<h2><Translator text="Conceptual modeling" /></h2>
								<Button className="open-tool-button" label={<Translator text="Open Model" />} onClick={() => setToggleContent(true)} />
								<p><Translator
									text="Drawing on a literature review and content analysis guided by relevant data and supplemented
						with expert insight and first-hand information from practitioners and people directly affected
						by conflict, a conceptual model of how climate acts as a threat multiplier is constructed.
						With a particular emphasis on food, land, and water systems, a climate security pathway
						analysis is employed to distill and map out prospective climate security pathways, detailing
						the intricate relationships between climate, conflict, and system (food, land, water)
						vulnerabilities. The critical pathway relationships and principle drivers identified through
						this modeling serve as the foundation for subsequent quantitative studies by informing
						the selection of appropriate indicators from the Climate Security spectrum."
								/>
								</p>
							</div>

							<div className="learn-more-wrap">
								<Button
									style={{ marginTop: '50px' }}
									label={(
										<Translator
											text="Learn More"
										/>
									)}
									onClick={() => setDisplayDialog(true)}
								/>
								<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
									<ConceptualLearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} dataReports={getReportsByCountry()} />
								</Dialog>
							</div>

						</>
					)
				}

			</div>
		);
	};

	return (
		<div className="dialog-content">
			{renderContent()}
		</div>
	);
};

export default ConceptualModeling;
