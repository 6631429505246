import React from 'react';
import Translator from '../../../../../../../../../../components/Translator';

const x = {
	KEN: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	SEN: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	GTM: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (2018-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	PHL: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (2016-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	ZMB: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	ZWE: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	ETH: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	SDN: <p>The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022) and are characterized as follows:
		<br /><br />
		The high conflict cluster has high values of the total number of conflict events (68 median count), high values of t
		{/* eslint-disable-next-line react/jsx-closing-tag-location */}
	</p>,
	NGA: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	MLI: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,
	UGA: <p><Translator text="The conflict clusters are based on six conflict-related variables from the ACLED database (1997-2022). Select a conflict variable to see the differences among the limited, moderate and high conflict clusters." /></p>,

};

export default x;
