/* eslint-disable class-methods-use-this */
import { http } from '../index';

class StaticService {
	getResources = async () => {
		const result = await http.get(`/api/resources`);
		return result.data;
	};
}

export default new StaticService();
