/* eslint-disable react/jsx-no-useless-fragment,no-underscore-dangle */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import Translator from '../../../../../../../../../../components/Translator';
import './styles.css';

const BarChart = (props) => {
	const { data, id, category, label, pixelData, description } = props;
	const [toggleDialog, setToggleDialog] = useState(false);

	useEffect(() => {
		const root = am5.Root.new(id);

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: true,
			panY: true,
			wheelX: 'panX',
			wheelY: 'zoomX',
			pinchZoomX: true,
		}));

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
		cursor.lineY.set('visible', false);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
		xRenderer.labels.template.setAll({
			rotation: -90,
			centerY: am5.p50,
			centerX: am5.p100,
			paddingRight: 15,
		});

		xRenderer.grid.template.setAll({
			location: 1,
		});

		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			maxDeviation: 0.3,
			categoryField: 'bucket',
			renderer: xRenderer,
			tooltip: am5.Tooltip.new(root, {}),
		}));

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			maxDeviation: 0.3,
			renderer: am5xy.AxisRendererY.new(root, {
				strokeOpacity: 0.1,
			}),
		}));

		// xAxis.children.moveValue(am5.Label.new(root, {
		// 	text: label,
		// 	x: am5.p50,
		// 	centerX: am5.p50,
		// }), xAxis.children.length - 1);
		yAxis.children.moveValue(am5.Label.new(root, {
			rotation: -90,
			y: am5.p50,
			centerX: am5.p50,
		}), 0);

		const assignColor = () => {
			switch (category) {
			case 'Limited conflict': return 'rgba(115,169,143,0.3)';
			case 'Moderate conflict': return 'rgba(246,224,159,0.3)';
			case 'High conflict': return 'rgba(230,151,138,0.30)';
			default: return '#fff';
			}
		};

		const assignStrokeColor = () => {
			switch (category) {
			case 'Limited conflict': return '#5b9379';
			case 'Moderate conflict': return '#efc160';
			case 'High conflict': return '#d94c4a';
			default: return '#fff';
			}
		};
		
		// Create series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
		const series = chart.series.push(am5xy.ColumnSeries.new(root, {
			name: 'Series 1',
			xAxis,
			yAxis,
			valueYField: 'frequency',
			sequencedInterpolation: true,
			categoryXField: 'bucket',
			fill: assignStrokeColor(),
			stroke: assignStrokeColor(),
			tooltip: am5.Tooltip.new(root, {
				labelText: '{valueY}',
			}),
		}));

		series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
		// series.columns.template.adapters.add('fill', (fill, target) => {
		// 	let temp = target._dataItem.dataContext.bucket;
		// 	temp = temp.split('-');
		// 	if (pixelData >= temp[0] && pixelData <= temp[1]) {
		// 		return '#2d2d2d';
		// 	}
		// 	return assignStrokeColor();
		// });
		//
		// series.columns.template.adapters.add('stroke', (stroke, target) => {
		//
		// });
		//
		// series.columns.template.setAll({
		// 	width: am5.percent(90),
		// 	tooltipY: 0,
		// 	width: am5.p100,
		// });

		xAxis.data.setAll(data);
		series.data.setAll(data);

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		series.appear(1000);
		chart.appear(1000, 100);

		return () => root.dispose();
	}, [data]);

	return (
		<div className="chart-container">
			<p><Translator text={label} /> <Translator text="of selected megapixel:" /> <Translator text={pixelData.toString()} /></p>
			<p className="rotate-label"><Translator text="Frequency (number of pixels)" /></p>
			<div id={id} style={{ width: '100%', height: '500px' }} />
			<div className="info">
				<p><Translator text={label} /></p>
				{/* <Tooltip target={`.info-icon-${id}`} content={`${description}`} style={{ maxWidth: '13%', minWidth: '100px' }} position="left" /> */}
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<i className={`info-icon-${id} fa-light fa-circle-info info-icon`} onClick={() => setToggleDialog(true)} />
			</div>
			<Dialog visible={toggleDialog} style={{ width: '50vw' }} onHide={() => setToggleDialog(false)}>
				<div className="variable-descriptions">
					{description}
				</div>
			</Dialog>
		</div>
	);
};
export default BarChart;
