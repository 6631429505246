/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../../../../../reducer/actions';
import CountryService from '../../../../../../../../services/httpService/countryService';
import Translator from '../../../../../../../../components/Translator';

const ClusterDefinionOptions = () => {
	const dispatch = useDispatch();

	const whereFiltersSelections = useSelector((state) => state.whereFiltersSelections);

	const country = useSelector((state) => state.country);
	const setWhereFilterSelections = (payload) => dispatch({ type: Actions.SetWhereFilterSelections, payload });

	const [cluster, setCluster] = useState(whereFiltersSelections.cluster || '');
	const [chart, setChart] = useState('');
	const [variable, setVariable] = useState(whereFiltersSelections.variable || '');
	const [colouring, setColouring] = useState('');
	const [xAxis, setXAxis] = useState(whereFiltersSelections.xAxis || '');
	const [yAxis, setYAxis] = useState(whereFiltersSelections.xAxis || '');
	const [variableOptions, setVariableOptions] = useState(null);

	useEffect(
		() => {
			if (!variable) {
				setVariable('Events');
			}
		}, []
	);

	useEffect(
		() => {
			CountryService.getScatterGraphData(country.id)
				.then((res) => {
					if (res.graph_info) {
						const temp = res.graph_info[0].data[0];
						setVariableOptions(temp.map((item) => {
							return { label: item.label, variable: item.variable };
						}));
					}
				});
		}, [country]
	);

	useEffect(
		() => {
			const temp = { ...whereFiltersSelections };
			temp.cluster = cluster;
			setWhereFilterSelections(temp);
		}, [cluster]
	);
	useEffect(
		() => {
			const temp = { ...whereFiltersSelections };
			temp.variable = variable;
			setWhereFilterSelections(temp);
		}, [variable]
	);
	useEffect(
		() => {
			const temp = { ...whereFiltersSelections };
			temp.yAxis = yAxis;
			setWhereFilterSelections(temp);
		}, [yAxis]
	);
	useEffect(
		() => {
			const temp = { ...whereFiltersSelections };
			temp.xAxis = xAxis;
			setWhereFilterSelections(temp);
		}, [xAxis]
	);
	const renderExtraOptions = () => {
		switch (cluster) {
		case 'Conflict Data': {
			return (
				<>
					<div className="dropdown conflict">
						<p className="open-sans">Variable</p>
						<Dropdown value={variable} options={['Events', 'Fatalities', 'Type Richness', 'Subtype Richness', 'Actor1 Richness', 'Actor2 Richness']} onChange={(e) => setVariable(e.value)} />
					</div>
					<p
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setWhereFilterSelections({ ...whereFiltersSelections, cluster: '', variable: '' });
							setCluster('');
							setVariable('');
						}}
					>Reset all filters
					</p>
				</>
			);
		}
		case 'Climate Data': {
			if (!variableOptions) return null;
			return (
				<>
					<div className="dropdown conflict">
						<p className="open-sans">Y Axis - Variable</p>
						<Dropdown value={yAxis} options={variableOptions} onChange={(e) => setYAxis(e.value)} optionLabel="label" />
					</div>
					<div className="dropdown climate">
						<p className="open-sans">X - Axis Variable</p>
						<Dropdown value={xAxis} options={variableOptions} onChange={(e) => setXAxis(e.value)} optionLabel="label" />
					</div>
					<p
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setWhereFilterSelections({ ...whereFiltersSelections, cluster: '', yAxis: '', xAxis: '' });
							setYAxis('');
							setXAxis('');
							setCluster('');
						}}
					>Reset all filters
					</p>
				</>
			);
		}
		default: return null;
		}
	};

	return (
		<>
			<div className="dropdown conflict">
				<p className="open-sans"><Translator text="Cluster Data" /></p>
				<Dropdown value={cluster} options={['Conflict Data', 'Climate Data']} onChange={(e) => setCluster(e.value)} />
			</div>
			{renderExtraOptions()}
			{/* <div className="dropdown climate"> */}
			{/*	<p>Colouring</p> */}
			{/*	<Dropdown value={colouring} options={['Conflict Cluster']} onChange={(e) => setColouring(e.value)} /> */}
			{/* </div> */}
		</>
	);
};

export default ClusterDefinionOptions;
