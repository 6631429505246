const pallete = [
	'#5b9379',
	'#e4c169',
	'#e17a64',
	'#799e73',
	'#efc160',
	'#dc5b53',
	'#97aa6d',
	'#ecad60',
	'#d94c4a',
];

export default pallete;
