/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Body, SideBar } from './components';
import NotFound from '../NotFound';
import Actions from '../../reducer/actions';
import './styles.css';
import CountryService from '../../services/httpService/countryService';

const CountryInformationPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });

	const country = useSelector((state) => state.country);
	const setCountry = (payload) => dispatch({ type: Actions.SetCountry, payload });

	const setMegapixelsGeoJson = (payload) => dispatch({ type: Actions.SetMegapixelsGeoJson, payload });

	const setWhereSelectedFeature = (payload) => dispatch({ type: Actions.SetWhereSelectedFeature, payload });

	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	const setTab = (payload) => dispatch({ type: Actions.SetTab, payload });

	const [stop, setStop] = useState(false);

	const countries = [
		{ id: 'KEN', name: 'Kenya', iso2: 'KE' },
		{ id: 'SEN', name: 'Senegal', iso2: 'SN' },
		{ id: 'ZWE', name: 'Zimbabwe', iso2: 'ZW' },
		{ id: 'UGA', name: 'Uganda', iso2: 'UG' },
		{ id: 'NGA', name: 'Nigeria', iso2: 'NG' },
		{ id: 'GTM', name: 'Guatemala', iso2: 'GT' },
		{ id: 'MLI', name: 'Mali', iso2: 'ML' },
		{ id: 'PHL', name: 'Philippines', iso2: 'PH' },
		{ id: 'ZMB', name: 'Zambia', iso2: 'ZM' },
		{ id: 'ETH', name: 'Ethiopia', iso2: 'ET' },
		{ id: 'SDN', name: 'Sudan', iso2: 'SD' },
		{ id: 'BFA', name: 'Burkina-Faso', iso2: 'BF' },
		{ id: 'NER', name: 'Niger', iso2: 'NE' },
	];
	
	useEffect(
		() => {
			const temp = countries.find((item) => item.name === location.pathname.split('/')[2]);
			if (temp) {
				setCountry(temp);
			}

			// if (location.pathname === '/Information/Burkina%20Faso') {
			// 	return (
			// 		navigate('/Information/Burkina-Faso')
			// 	);
			// }

			setShowMenus(true);
			setCurrentPage('');
			return () => 	setCountry('');
		}, [location]
	);

	if (!countries.find((item) => item.name === location.pathname.split('/')[2])) {
		return <NotFound />;
	}

	return (
		<div className="information-page">
			<SideBar />
			<Body />

		</div>
	);
};

export default CountryInformationPage;
