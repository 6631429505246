/* eslint-disable react/react-in-jsx-scope */
import Translator from '../../../../../../../../components/Translator';

const data = [
	{
		title: 'Maya Chʼortiʼ farmers in Chiquimula',
		howData: [
			{
				pathwayTitle: 'Pathway 1: The effects of climate change interact with structural drivers of marginalisation and antagonism, potentially leading to higher conflict risks in the future. ',
				text: 'Reduced agricultural productivity due to climate change, coupled with the fact that land leasing agreements are most often short-term and insecure, usually lasting a single harvesting season, has undermined the capacity of people towards accessing the necessary land to produce enough food for self-subsistence, thereby threatening food security. Land tenure arrangements perceived as unequal and unfair are associated with the fuelling of grievances  between land owners and land leasers. Supported by international development projects, the community has attempted to adopt and escalate agricultural conservation practices in countering diminishing productivity. However, insecure land tenure implies that the adoption of these practices has been limited to small plots in which vegetable gardening takes place, mainly by women. Under current land tenure agreements, neither land owners nor farmers have the proper incentives to adopt longer-term conservation practices.',
			},
			{
				pathwayTitle: 'Pathway 2: Climate change can exacerbate inter-community conflicts revolving around access to natural resources. ',
				text: 'The territorial limit between the communities of Shupá and La Lima has been in dispute since the 1980s. Over the last two decades, members of Shupá have forcefully shifted the territorial limit to increase their access to the communal forest between both communities, and have violently attacked members of La Lima community who venture near the displaced limit. Despite a reduced access to the forest, populations in La Lima increasingly rely on its resources due to dwindling agricultural livelihoods. Women in particular report feeling vulnerable to abuse and violence when entering the forest. Several dialogue processes have been attempted by the municipal government, without any long-term agreement having been reached. ',
			},
			{
				pathwayTitle: 'Pathway 3: The loss of livelihood security is associated with increasing human mobility, in turn linked to risks of insecurity for migrant populations.',
				text: 'Seasonal mobility to coffee plantations, both in Guatemala and Honduras, is the most common form of migration in La Lima. Under conditions of reduced agricultural productivity, the dependence of community members on migrating to partake in coffee harvesting activities has increased in recent years. Due to increasing demand over short-term employment in coffee plantations, an increasing number of people are forced to find alternative sources of income during the dry periods, mainly migrating to sugarcane plantations to the southwest of Guatemala or to the city in search of an urban-based livelihood. Migration is associated with security risks, as people on the move are more prone to assault, theft and kidnapping. Although there is currently small-scale production of coffee within La Lima community, farmers are not organized in accessing regional markets, hence relying on selling directly to intermediary actors who periodically visit the community. Furthermore, the effects of coffee rust over productivity are increasingly being felt across the region. These problems limit the potential of local coffee production as a source of income and employment to La Lima population.',
			},
			{
				pathwayTitle: 'Pathway 4: The loss of livelihoods due to climate effects increases the adoption of illicit activities among youth populations. ',
				text: 'More intense and frequent tropical storms and mid-summer droughts (canícula), have increased soil erosion and reduced nutrient content. These climate effects are reported to have reduced agricultural productivity throughout the region, including from both an increased risk of crop losses and diminishing yields. Due to low incomes, employment opportunities and food security, people in La Lima are more likely to resort to illegal activities, mainly the farming of illegal crops. The loss of livelihoods, coupled with low availability of alternative employment, also increases the adoption by young populations of other illicit activities, including assault and theft.',
			},
		],
		whatText: [
			<p><Translator text="Climate adaptation strategies proposed by members of La Lima community centred around the protection of agricultural livelihoods, access to markets, and addressing the structural drivers of inequality within their community." /></p>,

			<p><Translator text="1. Establish formal leasing agreements between land owners and farmers. Development projects currently operating in the region could focus in engaging both owners and lessors in defining more secure leasing conditions that protect the rights of all interested parties. Furthermore, these spaces of dialogue could serve as engagement processes to raise awareness around the importance of conservation agriculture, thereby potentially fostering its adoption by both land owners and farmers. These solution was also meant as a strategy to strengthen livelihood opportunities of farmers and prevent future conflicts around access to land." />
			</p>,
			<p><Translator text="2. Establish an association of local coffee producers meant to increase collaboration among producers in the community. The main goal is to strengthen their access to regional markets by pooling their produce together and finding alternative means of processing and transportation. The association would also foster conservation agriculture practices, meant to protect soil fertility and reduce the incidence of coffee rust. This could strengthen coffee production within the community, thereby generating employment opportunities and reducing the community's dependence on seasonal and rural-urban migration." />
			</p>,
			<p><Translator text="3. Develop and implement an integrated forest management plan based on collective action between members of La Lima community, neighbouring communities and the municipal government. An endorsement by the municipal government of a collaborative management plan could reduce the opportunity for violence as perpetrated by Shupá community members within the forest. Furthermore, the direct involvement of Shupá representatives in developing and enforcing the management plan could potentially foster dialogue and collaboration between the two communities, thereby strengthening a sense of interdependence and shared identity over the communal forest." />
			</p>,
		],
		latlng: [14.891413, -89.317223],
	},
	{
		title: 'Farmers, ranchers and sand harvesters in Izabal',
		howData: [
			{
				pathwayTitle: 'Pathway 1: Unequal distribution of resilience building support by the international community is undermining social cohesion and collaborative capacities for climate adaptation. ',
				text: 'The community of Tenedores has become increasingly threatened by floods from the Motagua river. After the Eta and Iota storms in November 2020, international cooperation projects have successfully worked with the community to establish an early warning and response protocol. However, humanitarian and resilience building efforts are perceived to be unequally distributed throughout local populations. Benefits from projects have to a large extent focused on households living near the river, which are also the most exposed. Nonetheless, neighbours inhabiting higher land claim that they face the same socio-economic challenges currently affecting those who have benefited more. Furthermore, they play a crucial role in disaster reduction, as they are meant to house and support their neighbours living in lower lands during extreme events. This unequal distribution of climate adaptation benefits is already perceived to be impacting social cohesion within the community, and could pose a challenge for future resilience building efforts.',
			},
			{
				pathwayTitle: 'Pathway 2: Extreme events have reduced community access to public and security services. ',
				text: 'Due to intense flooding and infrastructure destruction during the Eta and Iota storms, police services previously positioned in Tenedores have relocated to neighbouring localities, thereby frequently rendering their response to crime ineffective. Community members in Tenedores, mainly women, report feeling a higher sense of insecurity after the relocation of the police. Although crime perpetrators have been apprehended, for example after cases of domestic violence, the delayed arrival by police forces implies that preventive measures and immediate responses are today less viable. Furthermore, the community does not count with the suitable flood response infrastructure, mainly a community shelter to which populations can attend when threatened by river levels.',
			},
			{
				pathwayTitle: 'Pathway 3: The effects of climate change interact with structural drivers of marginalisation and antagonism, potentially leading to higher conflict risks in the future.',
				text: 'Increasing rains during the rainy season, coupled with more frequent flooding, has led to a significant reduction of agricultural and livestock productivity. Cattle owners are forced to sell their cows before the rainy season, which leads to a drop in cattle market prices and profitability. Similarly, farmers are also struggling to secure a profit under higher risks of crop losses and increasing prices of agricultural inputs. Exacerbating this issue, land tenure is highly insecure and irregular throughout the community, relying on short-term and informal leasing agreements. The availability of alternative livelihood strategies within the community -including sand harvesting, regulated temporal migration to Canada, and working for the banana plantations- have so far mitigated this problem, but community members recognize that the low levels of land availability and tenure are a potential source of conflict amongst neighbours.',
			},
		],
		whatText: [
			<p><Translator text="Community members in Tenedores, Izabal, prioritized climate adaptation solutions that strengthened the community´s collaborative capacity to maintain social cohesion through collective action, increase joint access of local farmers to regional markets, and increase the formality of employment and land leasing agreements." /></p>,

			<p><Translator text="1. Strengthen engagement capacities of the local coordination office for disaster risk reduction (Coordinadora Local para la Reducción de Desastres, COLRED), to engage with the municipal government and account for people´s perception regarding the unfair distribution of humanitarian support after the occurrence of flooding; as well as fostering more integrated disaster risk reduction planning, which also accounts for the needs of neighbours in the higher areas of the community." />
			</p>,
			<p><Translator text="2. Establish a suitable location for temporary shelter during extreme events. This proposal relies on using a nearby plot owned by the municipal government to build a new temporary shelter, which could also host new headquarters for the municipal police." />
			</p>,
			<p><Translator text="3. Formalize the Farmers Association of Tenedores (Asociación de Campesinos de Tenedores), which would be made up of community representatives, mainly local farmers and livestock herders. This association would focus in 1) strengthening access to markets by local farmers; 2) finding alternative arrangements between cattle keepers and farmers that increase land tenure across the community; and 3) fostering land conservation practices across the locality. For example, under conditions whereby cattle keeping is no longer profitable, owners of cattle, who also commonly own large plots of land across the region, could work together with farmers to lease the land under longer-term contracts, while incentivising the adoption of conservation and climate-smart agricultural farming techniques." />
			</p>,
		],
		latlng: [15.560617, -88.627227],
	},
	{
		title: 'Farmers in Huehuetenango',
		howData: [
			{
				pathwayTitle: 'Pathway 1: Increasingly scarce natural resources can lead to higher risks of inter- and intra-community conflict. ',
				text: 'Increasingly scarce natural resources can lead to higher risks of inter- and intra-community conflict. Water for household consumption and irrigation in El Carpintero comes entirely from waterpoints around the locality. Although the community owns several of these waterpoints, most of them were sold decades ago by the municipal government to surrounding municipalities looking to secure water provision in larger urban areas. These has led to a limited amount of springs within El Carpintero remaining to be acquired by the community, all of which are currently located within private lands of community members. Under changing precipitation patters, populations perceive a reduction in the flow of water springs, mainly during the dry season and mid-summer droughts. The community recognizes the need to acquire the remaining waterpoints within its local territory, so as to secure future water access. However, the private nature of the springs, coupled with the low willingness of community members to pool resources in buying the land, has made this objective a challenging endeavour. Low accessibility to water, the private nature of the springs, and increasing interests by external actors to acquire the remaining waterpoints, are thought to implicate a risk of conflict both within and beyond the community. In fact, several instances of conflict have already taken place with differing degrees of violence.',
			},
			{
				pathwayTitle: 'Pathway 2: Climate effects compound with increasing resentment by community members due to unfair commercial agreements with private agricultural companies.',
				text: 'Climate effects compound with increasing resentment by community members due to unfair commercial agreements with private agricultural companies. Several factors interplay to undermined agricultural livelihoods as a stable and profitable income strategy in El Carpintero. These include the rise in agricultural input prices and supply chain disruptions, the lack of agricultural subsidy programs, dwindling agricultural productivity, limited options to access regional markets and an overdependence in intermediary private actors to access supply chains. Commercial agreements with private companies, which provide agricultural inputs and acquire harvests, are perceived as unfavourable towards local farmers. This is because any form of disruption implies that the producer loses out in their investment.  Private companies have furthermore failed in occasions to comply with contractual obligations, arguing that payment was provided to company representatives within the community, supposedly charged with delivering it to contract beneficiaries but who often disappear. Perceptions of unfair contractual agreements under limited alternatives, and of extractive practices by the private sector, fuel the community´s resentment towards government authorities. ',
			},
			{
				pathwayTitle: 'Pathway 3: The loss of livelihood security is associated with increasing human mobility, in turn linked to risks of insecurity for migrant populations. ',
				text: 'A widespread perception that agriculture is no longer a suitable livelihood strategy was also associated with an increasing reliance on unregulated migration, mainly to the United States. Unregulated migration, although in general considered beneficiary for the community, is also associated with security risks, as people on the move are more prone to assault, theft and kidnapping. Furthermore, ample opportunities to migrate has led to an absence of available workers to employ in the fields, thereby exacerbating sentiments around agriculture as an uncertain livelihood strategy.',
			},
			{
				pathwayTitle: 'Pathway 4: Lower availability of natural resources contributes towards diminishing collaborative capacities for resilience building.',
				text: 'Study participants deem it more challenging at the present to foster collective action around, for example, joint-credit schemes for irrigation and water management. This is in part because many conflicts in relation to water access have emerged in previous years. Furthermore, higher prices for agricultural inputs coupled with lower crop prices, have made the community less confident about irrigated agriculture as a main source of income. Under lower capacities for collaboration, people are frequently unwilling to abide by water rationing regulations defined by the community-driven water management and irrigation committees, and their enforcement is deemed practically impossible, given that no means of measuring water consumption at household and farm levels are in place.\n' +
					'Study participants deem it more challenging at the present to foster collective action around, for example, joint-credit schemes for irrigation and water management. This is in part because many conflicts in relation to water access have emerged in previous years. Furthermore, higher prices for agricultural inputs coupled with lower crop prices, have made the community less confident about irrigated agriculture as a main source of income. Under lower capacities for collaboration, people are frequently unwilling to abide by water rationing regulations defined by the community-driven water management and irrigation committees, and their enforcement is deemed practically impossible, given that no means of measuring water consumption at household and farm levels are in place.',
			},
		],
		whatText: [
			<p><Translator text="Members of the El Carpintero community favoured climate adaptation practices that could foster better relations and trust within the community, as well as with external actors. They prioritized an integrated approach to water management, as decreasing availability of water was deemed the most pressing climate threat. They also focused in protecting small-scale agricultural livelihoods as a strategy to reduce security risks associated with the loss of income." /></p>,

			<p><Translator text="1. Strengthen the role of water-related committees within community governance to foster their engagement with local populations and capacity for conflict management. Engagement processes meant to foster dialogue between community members around the threats of water scarcity could potentially increase the willingness of neighbours to collaborate, for example, in joint-credit schemes to finance the acquisition of new waterpoints. In order to increase trust among community members, relevant committees need to act as more neutral conflict management institutions, whereby disagreements arising from water use and access could be managed in accordance to a formally institutionalized protocol." />
			</p>,
			<p><Translator text="2. Foster a more integrated and collaborative water management approach across the community. Among the several solutions proposed to be pursued for better water management included the installation of water meters in households and irrigation systems. Under conditions of increasing drought, the rationing of water during certain periods of the year will become ever more necessary, and it is crucial that the community monitors water consumption." />
			</p>,
			<p><Translator text="3. Increase access to climate information systems to strengthen integrated water management. This entails seeking the community´s participation in the department-level Agro-climatic Technical Committees, which are managed by the Ministry of Agriculture and work as dialogue platforms for the exchange of information around climate patterns and climate adaptation practices." />
			</p>,
			<p><Translator text="4. Increase the organization of agricultural producers in order to seek better market access, promote the dignity of farmers, and reduce dependence on agricultural inputs. Securing a more direct access to market and reducing dependence on informal arrangements with private companies was deemed a priority in this regard. Ongoing coordination with cooperative organisations has evidenced some initial progress, as commercial conditions are perceived as fairer. Better organisation for the collective transport and joint commercialisation of produce was also proposed." />
			</p>,
		],
		latlng: [15.363688, -91.504738],
	},
];

export default data;
