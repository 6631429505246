/* eslint-disable import/no-unresolved,consistent-return */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../reducer/actions';
import { Question } from './components';
import Translator from '../../components/Translator';
import { how, where, who, what, howImage, whereImage, whoImage, whatImage } from '../../assets/images/images';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './styles.css';

const LandingPage = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const language = useSelector((state) => state.language);

	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	const [isEmpty, setIsEmpty] = useState(true);
	const [speed, SetSpeed] = useState(null);
	const [showTitle, setShowTitle] = useState(false);
	const [showSubTitle, setShowSubTitle] = useState(false);
	const [showIcons, setShowIcons] = useState(false);
	const [showHow, setShowHow] = useState(false);
	const [showWhere, setShowWhere] = useState(false);
	const [showWho, setShowWho] = useState(false);
	const [showWhat, setShowWhat] = useState(false);
	const [showGetStarted, setShowGetStarted] = useState(false);

	const toast = useRef(null);
	const previousInputValue = useRef('');

	useEffect(
		() => {
			setTimeout(() => {
				setShowTitle(true);
				setTimeout(() => {
					setShowSubTitle(true);
					setTimeout(() => {
						setShowIcons(true);
						setShowHow(true);
						setTimeout(() => {
							setShowWhere(true);
							setTimeout(() => {
								setShowWho(true);
								setTimeout(() => {
									setShowWhat(true);
									setTimeout(() => {
										setShowGetStarted(true);
									}, 3800);
								}, 3800);
							}, 3800);
						}, 3800);
					}, 1500);
				}, 2000);
			}, 3000);
		}, []
	);
	useEffect(
		() => {
			setCurrentPage('');
			const w = window.innerWidth;
			if (w < 520) {
				toast.current.show({ severity: 'warn', summary: 'Attention', detail: 'For a better experience exploring the tool, we recommend using a device with a larger screen', life: 6000 });
			}
			setShowMenus(true);
		}, []
	);

	useEffect(
		() => {
			const imgUrl = 'https://images.unsplash.com/photo-1653315344755-6eb86f5812f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8dG9waWNzfHx8fHx8MTY4NTQzOTQ0Mg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080';
			const size = 95800;

			const speedDetection = () => {
				window.setTimeout(MeasureConnectionSpeed, 1);
			};

			if (window.addEventListener) {
				window.addEventListener('load', speedDetection, false);
			} else if (window.attachEvent) {
				window.attachEvent('onload', speedDetection);
			}

			function MeasureConnectionSpeed() {
				let endTime;
				const download = new Image();
				download.onload = function () {
					endTime = (new Date()).getTime();
					showResults();
				};

				const startTime = (new Date()).getTime();
				const cacheBuster = `?nnn=${startTime}`;
				download.src = imgUrl + cacheBuster;

				function showResults() {
					const duration = (endTime - startTime) / 1000;
					const bitsLoaded = size * 8;
					const speedBps = (bitsLoaded / duration).toFixed(2);
					const speedKbps = (speedBps / 1024).toFixed(2);
					const speedMbps = (speedKbps / 1024).toFixed(2);
					// ShowProgressMessage([
					// 	'Your connection speed is:',
					// 	`${speedBps} bps`,
					// 	`${speedKbps} kbps`,
					// 	`${speedMbps} Mbps`,
					// ]);
					SetSpeed(speedMbps);
				}
			}
		}, []
	);
	return (
		<div className={speed <= 0.20 ? 'bg-image-landing' : 'landing-page'}>
			<Toast ref={toast} />
			<div className="title-subtitle" style={{ opacity: showTitle ? 1 : 0 }}>
				<h1><Translator text={'Climate Security Observatory'} /></h1>
			</div>
			<p className="open-sans subtitle" style={{ opacity: showSubTitle ? 1 : 0 }}>
				<Translator
					text={'A decision support tool helping stakeholders understand and <br></br>' +
					'respond to climate-related security risks'}
					params={{
						br: <br />,
					}}
				/>

			</p>
			<div className="icons" style={{ opacity: showIcons ? 1 : 0 }}>
				<Question style={{ opacity: showHow ? 1 : 0 }} question="HOW" text="does climate worsen the root causes of conflict?" image={how} id="question-how" index={0} />
				<Question style={{ opacity: showWhere ? 1 : 0 }} question="WHERE" text="are the most vulnerable areas to climate induced insecurities and risks?" image={where} id="question-where" index={1} />
				<Question style={{ opacity: showWho ? 1 : 0 }} question="WHO" text="are the vulnerable groups to climate and security risks that should be targeted?" image={who} id="question-who" index={2} />
				<Question style={{ opacity: showWhat ? 1 : 0 }} question="WHAT" text="can be done to break the vicious cycle between climate and conflict" image={what} id="question-what" index={3} />
			</div>
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
			<div className="landing-button" role="button" tabIndex="0" onClick={() => navigate('/Home')} style={{ opacity: showGetStarted ? 1 : 0 }}><Translator text={'Get Started'} /></div>
			{speed > 0.20
				? (
					<iframe
						src="https://player.vimeo.com/video/826829434?background=1&muted=1&autoplay=1&loop=1&h=3eab5d5fa3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						title="Landing Option 1"
					/>
				) : null
			}
		</div>
	);
};
export default LandingPage;
