import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../reducer/actions';

const Settings = (props) => {
	const { languageValue, setLanguageValue } = props;

	const dispatch = useDispatch();

	const darkMode = useSelector((state) => state.darkMode);
	const setDarkMode = (payload) => dispatch({ type: Actions.SetDarkMode, payload });

	return (
		<div className="settings" tabIndex="0" role="button" aria-label="settings">
			<i className="gear fa-duotone fa-gear" />
			<div className="options" id="mode">
				<div className="darkmode">
					<i className="fa-duotone fa-circle-half-stroke" />
					<InputSwitch
						checked={darkMode}
						onChange={(e) => {
							setDarkMode(e.value);
							document.getElementById('mode').blur();
						}}
					/>
				</div>
				<Dropdown
					value={languageValue}
					options={['English']}
					onChange={(e) => {
						setLanguageValue(e.value);
						document.getElementById('mode').blur();
					}}
				/>
			</div>
		</div>
	);
};

export default Settings;
