import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoVariableSelectedConflict from '../NoVariableSelectedConflict';
import { BubbleChart } from './components';
import './styles.css';
import Translator from '../../../../../../../../components/Translator';

const Conflict = () => {
	const contextFilterSelections = useSelector((state) => state.contextFilterSelections);

	const country = useSelector((state) => state.country);

	const [chartData, setChartData] = useState(null);
	const [max, setMax] = useState(0);

	useEffect(
		() => {
			if (contextFilterSelections.conflictVariable) {
				let newMax = 0;
				let newChartData = contextFilterSelections.conflictVariable?.data;
				if (contextFilterSelections.conflictDuration[0] && contextFilterSelections.conflictDuration[1]) {
					newChartData = contextFilterSelections.conflictVariable?.data.filter((item) => {
						if (item.year >= contextFilterSelections.conflictDuration[0] && item.year <= contextFilterSelections.conflictDuration[1]) {
							return true;
						}
						return false;
					});
				}
				// newChartData = newChartData.map((item) => {
				// 	const temp = item.data.map((it) => {
				// 		if (!it.value) {
				// 			return { ...it, value: '' };
				// 		}
				// 		return it;
				// 	});
				// 	return { ...item, data: temp };
				// });
				// console.log(newChartData);
				newChartData.map((item) => {
					item.data.map((it) => {
						if (it.value > newMax) {
							newMax = it.value;
						}
					});
				});
				console.log(newMax);
				setMax(newMax);
				setChartData(newChartData);
			}
		}, [contextFilterSelections]
	);
	
	if (!contextFilterSelections.conflictVariable?.variable) {
		return <NoVariableSelectedConflict title={<Translator text="Conflict" />} />;
	}

	return (
		<div className="subpage conflict">
			<p className="title"><Translator text="Conflict" /></p>
			<div className="header">
				{/* <p className="sub-title">{contextFilterSelections.conflictVariable}</p> */}
				{/* <p> */}
				{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt */}
				{/*	ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco */}
				{/*	laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit */}
				{/*	in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat */}
				{/*	cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
				{/* </p> */}
				<p className="sub-title"><Translator text={contextFilterSelections.conflictVariable?.variable} /></p>
				{/* <p> */}
				{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt */}
				{/*	ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco */}
				{/*	laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit */}
				{/*	in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat */}
				{/*	cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
				{/* </p> */}
			</div>
			{chartData && chartData.length ? <BubbleChart chartData={chartData} countryId={country.iso2} max={max} variable={contextFilterSelections.conflictVariable?.variable} /> : null}
		</div>
	);
};

export default Conflict;
