import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { useTranslate } from '@tolgee/react';
import { sortArrayOfObjectsByPropertyValue } from '../../../../utils/functions';
import './styles.css';
import Translator from '../../../../components/Translator';
import useCustomTranslate from '../../../../utils/translateFunction';
import translatePureFunction from '../../../../utils/translatePureFunction';

const CountryProfilesDialogContent = (props) => {
	const { data } = props;
	const { t } = useTranslate();

	const [value, setValue] = useState([]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		Country: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
	});

	useEffect(() => {
		let temp = sortArrayOfObjectsByPropertyValue(data, 'Country');
		temp = temp.map((item, index) => {
			return { ...item, id: index };
		});
		setValue(temp);
	}, []);

	const urlTemplate = (lineData) => {
		return (
			<a href={lineData.url} target="_blank" rel="noopener noreferrer" aria-label="link">
				<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
			</a>
		);
	};
	console.log(sortArrayOfObjectsByPropertyValue(data, 'Country'));

	const header = () => {
		return (
			<div className="flex justify-content-end filter-input">
				<p><Translator text="Country" /></p>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={'Search by Country'} />
				</span>
			</div>
		);
	};

	const onGlobalFilterChange = (e) => {
		const v = e.target.value;
		const newFilters = { ...filters };
		newFilters.global.value = v;
		setFilters(newFilters);
		setGlobalFilterValue(v);
	};

	const countryTemplate = (item) => {
		return (
			<p><Translator text={item.Country} /></p>
		);
	};

	const titleTemplate = (item) => {
		return (
			<p><Translator text={item.Title} /></p>
		);
	};

	const yearTemplate = (item) => {
		return (
			<p><Translator text={item.Year} /></p>
		);
	};

	return (
		<div className="resources-dialog-content">
			<p className="title"><Translator text="Country Profiles" /></p>
			<DataTable value={value} globalFilterFields={['Country']} dataKey={'id'} filters={filters} responsiveLayout="scroll" stripedRows>
				<Column body={countryTemplate} header={header} style={{ width: '10%' }} />
				<Column body={titleTemplate} header={<Translator text="Title" />} />
				<Column body={yearTemplate} header={<Translator text="Year" />} />
				<Column field="url" body={urlTemplate} header={<Translator text="Url" />} />
			</DataTable>
		</div>
	);
};

export default CountryProfilesDialogContent;
