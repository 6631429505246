import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import CheckBoxes from '../CheckBoxes';
import Comments from '../Comments';
import Translator from '../../../../../Translator';

const TrustAndCooperation = (props) => {
	const { userInput, setUserInput } = props;
	const data = [
		{ submechanism: '3.1 Involve both high and grass-root levels while minimizing transboundary contacts', category: '3. Building trust and cooperation', id: '3.1' },
		{ submechanism: '3.2 Foster intercommunal trust and create shared identities', category: '3. Building trust and cooperation', id: '3.2' },
		{ submechanism: '3.3 Enhance social cohesion and empower vulnerable groups', category: '3. Building trust and cooperation', id: '3.3' },
	];

	const infoTemplate = (rowData) => {
		const id = rowData.id.slice(2, 3);
		const description = () => {
			switch (id) {
			case '1': return 'Secure food production: provision of necessary inputs, irrigation sources, climate information \n' +
					'Diversify income and livelihood: spread farm operations, mixed-systems approach, analyze market value chains to address bottlenecks and identify opportunities for added value \n' +
					'Restore degraded infrastructures: sustain/introduce irrigation systems, mechanization technologies';
			case '2': return 'Introduce intercommunal infrastructures: develop shared collecting/ storing/ processing/ transporting facilities for produce\n' +
					'Facilitate access to intercommunal resources: extend fallow areas/pastures';
			case '3': return 'Bolster equitable and efficient delivery of public services: monitor funds allocation, increase availability of extension services\n' +
					'Increase government revenues from natural resource management: increase available resources for the provision of public goods and services, foster foreign investment';
			default: return '';
			}
		};
		return (
			<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'center' }}>
				<Tooltip target={`.info-icon-${id}`} content={description()} position="left" />
				<i className={`info-icon-${id} fa-light fa-circle-info info-icon`} />
			</div>
		);
	};

	const notesTemplate = (rowData) => {
		return (
			<Comments rowData={rowData} setUserInput={setUserInput} userInput={userInput} />
		);
	};

	const presentTemplate = (rowData) => {
		return (
			<CheckBoxes rowData={rowData} setUserInput={setUserInput} userInput={userInput} />
		);
	};

	const submechanismTemplate = (submData) => {
		return (
			<p><Translator text={submData.submechanism} /></p>
		);
	};

	return (
		<div className="step">
			<div className="header">
				<p className="sub-title">
					<Translator text="Step 2 - Assess to which Climate-Peace Mechanisms  your proposed intervention is contributing to" />
				</p>
				<p className="open-sans">
					<Translator text="This step assesses a set of climate-peace mechanisms and indicators." />
					<br /><br />
					<Translator text="For each of mechanism and examples of practices for that mechanism (indicators), indicate if the listed criteria are fulfilled (=1), somehow fulfilled (=0.5) or not fulfilled (=0) and add a note describing how your program may fulfil the category." />
				</p>
			</div>
			<p className="open-sans"><Translator text="3. Building trust and cooperation" /></p>
			<DataTable
				value={data}
				responsiveLayout="scroll"
				stripedRows
			>
				<Column field="submechanism" body={submechanismTemplate} header={<Translator text="Submechanism" />} />
				<Column field="info" header={<Translator text="Info" />} body={infoTemplate} />
				<Column field="notes" header={<Translator text="Notes" />} body={notesTemplate} />
				<Column field="present" header={<Translator text="Present" />} body={presentTemplate} />
			</DataTable>
		</div>
	);
};

export default TrustAndCooperation;
