/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import './styles.css';
import Translator from '../../../../../../components/Translator';

const SummaryDownloadButton = () => {
	const country = useSelector((state) => state.country);
	const [displayDialog, setDisplayDialog] = useState(false);

	const renderSummariesButton = () => {
		switch (country.name) {
		case 'Kenya': return (
			<a className="p-button" href="https://cgspace.cgiar.org/handle/10568/127878" target="_blank" rel="noreferrer"><Translator text="Download summary for policymakers" /></a>
		);
		case 'Senegal': return (
			<a className="p-button" href="https://cgspace.cgiar.org/handle/10568/127618" target="_blank" rel="noreferrer"><Translator text="Download summary for policymakers" /></a>
		);
		case 'Mali': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '40vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Guatemala': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Ethiopia': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Nigeria': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Philippines': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Sudan': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Uganda': return (
			<>
				<Button label={<Translator text="Download summary for policymakers" />} onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header={<Translator text="Download summary for policymakers" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Zambia': return (
			<>
				<Button label="Download summary for policymakers" onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header="Download summary for policymakers" visible={displayDialog} style={{ width: '40vw' }} onHide={() => setDisplayDialog(false)}>
					<h3><Translator text="Coming Soon!" /></h3>
				</Dialog>
			</>

		);
		case 'Zimbabwe': return (
			<>
				<Button label="Download summary for policymakers" onClick={() => setDisplayDialog(true)} />
				<Dialog className="download-summary-header" header="Download summary for policymakers" visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
					<h3>Coming Soon!</h3>
				</Dialog>
			</>

		);
		default: return null;
		}
	};

	return (
		<div>
			{renderSummariesButton()}
		</div>
	);
};

export default SummaryDownloadButton;
