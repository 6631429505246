import React from 'react';
import { useSelector } from 'react-redux';
import { Conflict, Climate, NoClusterSelected } from './components';
import './styles.css';

const ClusterDefinition = () => {
	const whereFiltersSelections = useSelector((state) => state.whereFiltersSelections);

	const renderGraph = () => {
		switch (whereFiltersSelections.cluster) {
		case 'Conflict Data': return <Conflict />;
		case 'Climate Data': return <Climate />;
		default: return <NoClusterSelected />;
		}
	};
	return (
		<div className="cluster-definition">
			{renderGraph()}
		</div>
	);
};

export default ClusterDefinition;
