/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
// import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useSelector } from 'react-redux';
import Translator from '../../../../../../../../../../components/Translator';

const MaxiumumTemperature = () => {
	const country = useSelector((state) => state.country);
	const renderContent = () => {
		switch (country.name) {
		case 'Kenya': return (
			<>
				<p><Translator text="Past, current and future maximum temperature timelapse of Kenya from 2000 - 2050. Average maximum temperatures have risen and are projected to further rise especially in the North-western and Eastern parts of Kenya." /></p>
				<iframe
					src="https://player.vimeo.com/video/826829462?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					style={{ width: '100%', height: '100%' }}
					title="Context_Kenya_Maximum_Temperature"
				/>
				<script src="https://player.vimeo.com/api/player.js" />
			</>
		);
		default: return <h2 className="coming-soon-heading"><Translator text="Coming Soon!" /></h2>;
		}
	};

	return renderContent();
};

export default MaxiumumTemperature;
