import React, { useEffect } from 'react';
import './styles.css';

const Cards = (props) => {
	const { image, title, name, lastName, iconHow, iconTextHow, iconWhat, iconTextWhat, iconWhere, iconTextWhere, iconTextWho, iconWho } = props;

	return (
		<div className="card p-card">
			<div>
				{image ? <img src={image} alt="image" /> : null}
			</div>
			<div className="card-info">
				<div className="card-header">
					{name ? <h2>{name} {lastName ? <strong>{ lastName }  </strong> : null} </h2> : null}
					{title ? <p className="open-sans">{title}</p> : null}
				</div>
				<div className="icons-container">
					{
						iconHow
							? (
								<div>
									{iconHow || null}
									{iconTextHow ? <p className="open-sans">{iconTextHow}</p> : null}
								</div>
							)
							: null
					}
					{
						iconWhat
							? (
								<div>
									{iconWhat || null}
									{iconTextWhat ? <p className="open-sans">{iconTextWhat}</p> : null}
								</div>
							)
							: null
					}
					{
						iconWhere
							? (
								<div>
									{iconWhere || null}
									{iconTextWhere ? <p className="open-sans">{iconTextWhere}</p> : null}
								</div>
							)
							: null
					}
					{
						iconWho
							? (
								<div>
									{iconWho || null}
									{iconTextWho ? <p className="open-sans">{iconTextWho}</p> : null}
								</div>
							)
							: null
					}
				</div>
			</div>

		</div>
	);
};

export default Cards;
