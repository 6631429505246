/* eslint-disable react/jsx-no-useless-fragment */
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';

const Series = (props) => {
	const { data, icons, setData, country } = props;

	const selectedMarker = 'M34.4769 13.7249V13.7601L34.4815 13.7929L34.4815 13.7931L34.4816 13.7933L34.4816 13.7939L34.4817 13.7944L34.4821 13.7976C34.4827 13.803 34.4839 13.813 34.4852 13.8275C34.488 13.8565 34.4918 13.9035 34.4948 13.9681C34.5009 14.0972 34.5041 14.2968 34.4902 14.5635C34.4624 15.0965 34.3665 15.8987 34.0889 16.9426C33.5339 19.0292 32.2501 22.0922 29.3182 25.9087L29.3166 25.9108C27.9936 27.6517 25.5588 31.1074 23.4605 34.1191C22.41 35.6269 21.4419 37.0263 20.7364 38.0487C20.4412 38.4766 20.1919 38.8385 20.0018 39.1147C19.8135 38.8386 19.5666 38.4773 19.2741 38.0502C18.5738 37.0276 17.6118 35.6279 16.5654 34.1199C14.4765 31.1096 12.0404 27.6519 10.6808 25.9074C7.74962 22.0915 6.46601 19.0289 5.91112 16.9426C5.63347 15.8987 5.53755 15.0965 5.50981 14.5635C5.49593 14.2968 5.4991 14.0972 5.50519 13.9681C5.50823 13.9035 5.51201 13.8565 5.51477 13.8275C5.51614 13.813 5.51726 13.803 5.51791 13.7976L5.51836 13.7939L5.51845 13.7932L5.51846 13.7931L5.51849 13.7929L5.52314 13.7601V13.7249C5.52314 6.43828 12.0021 0.5 20 0.5C28.0623 0.5 34.4769 6.49732 34.4769 13.7249ZM11.7417 13.9722C11.7417 18.1807 15.4452 21.5819 20 21.5819C24.5489 21.5819 28.3258 18.1861 28.3258 13.9722C28.3258 9.69912 24.5518 6.30062 20 6.30062C15.5127 6.30062 11.7417 9.70181 11.7417 13.9722Z';
	const marker = 'M21.8605 8.57805C21.8605 3.86399 17.6862 0 12.5 0C7.35592 0 3.13946 3.82535 3.13946 8.57805C3.13946 8.57805 2.71781 11.5533 6.4283 16.3833C8.11488 18.5471 12.5 25 12.5 25C12.5 25 16.9273 18.5471 18.5717 16.3833C22.2822 11.5533 21.8605 8.57805 21.8605 8.57805ZM12.5 13.1762C9.80147 13.1762 7.65107 11.1669 7.65107 8.73261C7.65107 6.25966 9.84363 4.25039 12.5 4.25039C15.1985 4.25039 17.3911 6.25966 17.3911 8.73261C17.3911 11.1669 15.1985 13.1762 12.5 13.1762Z';
	const selectedMarkerFill = '#FDC82F';
	const markerFill = '#2D2D2D';

	useEffect(() => {
		const root = am5.Root.new('map-chart');

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create the map chart
		// https://www.amcharts.com/docs/v5/charts/map-chart/
		const chart = root.container.children.push(
			am5map.MapChart.new(root, {
				panX: 'translateX',
				panY: 'translateY',
				projection: am5map.geoMercator(),
			})
		);

		// Create main polygon series for countries
		// https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
		const polygonSeries = chart.series.push(
			am5map.MapPolygonSeries.new(root, {
				geoJSON: am5geodata_worldLow,
				include: [
					country,
				],
				fill: '#d9d9d9',
				fillOpacity: 1,
			})
		);

		// Create line series for trajectory lines
		// https://www.amcharts.com/docs/v5/charts/map-chart/map-line-series/
		const lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
		lineSeries.mapLines.template.setAll({
			stroke: root.interfaceColors.get('alternativeBackground'),
			strokeOpacity: 0.6,
		});

		// Create point series for markers
		// https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
		const originSeries = chart.series.push(
			am5map.MapPointSeries.new(root, { idField: 'id' })
		);

		const originCities = icons.map((item, index) => {
			return 			{
				id: index,
				title: item.title,
				geometry: { type: 'Point', coordinates: [item.latlng[1], item.latlng[0]] },
				zoomLevel: 1,
				zoomPoint: { longitude: item.latlng[1], latitude: item.latlng[0] },
			};
		});

		originSeries.data.setAll(originCities);

		function selectOrigin(id) {
			currentId = id;
			const dataItem = originSeries.getDataItemById(id);
			const { dataContext } = dataItem;
			chart.zoomToGeoPoint(dataContext.zoomPoint, dataContext.zoomLevel, true);
			const temp = icons.find((item, index) => index === id);
			originSeries.bullets.clear();
			originSeries.bullets.push((x, series, item) => {
				if (item.dataContext.id === id) {
					const icon = am5.Graphics.new(root, {
						svgPath: selectedMarker,
						scale: 1,
						cursorOverStyle: 'pointer',
						centerY: am5.p50,
						centerX: am5.p50,
						fill: selectedMarkerFill,
						stroke: markerFill,
					});

					icon.events.on('click', (e) => {
						selectOrigin(e.target.dataItem.get('id'));
					});
					return am5.Bullet.new(root, {
						sprite: icon,
					});
				}
				const icon = am5.Graphics.new(root, {
					svgPath: marker,
					scale: 1,
					cursorOverStyle: 'pointer',
					centerY: am5.p50,
					centerX: am5.p50,
					fill: markerFill,
				});

				icon.events.on('click', (e) => {
					selectOrigin(e.target.dataItem.get('id'));
				});
				return am5.Bullet.new(root, {
					sprite: icon,
				});
			});
			setData(temp);
		}

		let currentId = 0;

		originSeries.events.on('datavalidated', () => {
			selectOrigin(currentId);
		});

		// Make stuff animate on load
		chart.appear(1000, 100);

		return () => root.dispose();
	}, []);

	return (
		<div id="map-chart" style={{ width: '50%', height: '500px' }} />
	);
};
export default Series;
