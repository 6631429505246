/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const Treemap = (props) => {
	const { treeData, filteredTable, policyCoherenceTopics } = props;

	useEffect(() => {
		const root = am5.Root.new('treemapId');
		console.log(treeData);
		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create wrapper container
		const container = root.container.children.push(
			am5.Container.new(root, {
				width: am5.percent(100),
				height: am5.percent(100),
				layout: root.verticalLayout,
			})
		);

		// Create series
		// https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
		const series = container.children.push(
			am5hierarchy.Treemap.new(root, {
				singleBranchOnly: false,
				downDepth: 1,
				upDepth: -1,
				initialDepth: 2,
				valueField: 'value',
				categoryField: 'name',
				childDataField: 'children',
				nodePaddingOuter: 0,
				nodePaddingInner: 0,
			})
		);

		series.rectangles.template.setAll({
			strokeWidth: 2,

		});

		series.rectangles.template.events.on('click', (event) => {
			const clickedData = event.target.dataItem.dataContext.name;
			filteredTable(clickedData);
			document.getElementById('table-documents').scrollIntoView({
				behavior: 'smooth',
			});
		});

		series.labels.template.adapters.add('fill', (fill, target) => {
			if (target.dataItem) {
				const bgcolor = target.dataItem.get('rectangle').get('fill');
				return am5.Color.alternative(bgcolor, am5.color(0x000000), am5.color(0x000000));
			}
			return fill;
		});

		// series.tooltip.autoTextColor = false;
		// series.tooltip.label.fill = am5.color('#FFFFFF');

		// series.labels.template.setAll({
		// 	 oversizedBehavior: 'wrap',
		// });
		
		// Generate and set data
		// https://www.amcharts.com/docs/v5/charts/hierarchy/#Setting_data
		const data = {
			name: 'Root',
			children: treeData,
		};

		series.data.setAll([data]);
		series.set('selectedDataItem', series.dataItems[0]);
		return () => root.dispose();
	}, []);

	return (
		<>
			<div id="treemapId" style={{ width: '800px', height: '600px' }} />
			<div className="legend-container">
				{
					policyCoherenceTopics[0] ? (
						<div className="legends">
							<div className="legend-box" style={{ background: '#6771dc' }} />
							<p>{policyCoherenceTopics[0]}</p>
						</div>
					) : null
				}
				{
					policyCoherenceTopics[1] ? (
						<div className="legends">
							<div className="legend-box" style={{ background: '#a367dc' }} />
							<p>{policyCoherenceTopics[1]}</p>
						</div>
					) : null
				}
				{
					policyCoherenceTopics[2] ? (
						<div className="legends">
							<div className="legend-box" style={{ background: '#dc67ce' }} />
							<p>{policyCoherenceTopics[2]}</p>
						</div>
					) : null
				}
				{
					policyCoherenceTopics[3] ? (
						<div className="legends">
							<div className="legend-box" style={{ background: '#dc6788' }} />
							<p>{policyCoherenceTopics[3]}</p>
						</div>
					) : null
				}

				{
					policyCoherenceTopics[4] ? (
						<div className="legends">
							<div className="legend-box" style={{ background: '#dc8c67' }} />
							<p>{policyCoherenceTopics[4]}</p>
						</div>

					) : null
				}

				{
					policyCoherenceTopics[5] ? (
						<div className="legends">
							<div className="legend-box" style={{ background: '#dcd267' }} />
							<p>{policyCoherenceTopics[5]}</p>
						</div>
					) : null
				}

			</div>
		</>

	);
};

export default Treemap;
