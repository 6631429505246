/* eslint-disable react/react-in-jsx-scope */
import Translator from '../../../../../../../../components/Translator';

const data = [
	{
		title: 'Yaaku pastoralists in Laikipia',
		howData: [
			{
				pathwayTitle: 'Pathway 1: Increasingly scarce natural resources have led to a higher frequency and intensity of inter- and intra-community conflict.',
				text: 'Conflicts between the Yaaku and other pastoral communities around access and use of Mukogodo Forest are intensifying because of recent droughts in the region. Climate threats have led to substantial stress over livelihoods and food security of pastoral communities. Longer migratory routes, adopted by pastoralists due to a lack of pasture in the more arid regions to the north, imply that a higher number of pastoralists now come in contact within and around Mukogodo. In recent years, members of the Yaaku report a noticeable increase in the frequency and intensity of rustling conducted by neighbouring communities, which they directly relate to the impacts of drought over livelihoods and the availability of resources.',
			},
			{
				pathwayTitle: 'Pathway 2: The interloping impacts of climate change and conflict undermine livelihoods, erode social cohesion within the community and increase vulnerabilities to future climate threats. ',
				text: 'The loss of livelihoods from both climate and conflict impacts have reduced social cohesion and hindered people\'s ability to collaborate in adapting to climate change. Maladaptive income strategies by some community members are perceived as negatively affecting others, such as charcoal burning and illegal brewing. Unequal benefits from these activities and different interests around forest conservation, have led to an increase in fines, arrests, and conflicts within and beyond the community. Women in particular highlighted how young people have limited alternatives for livelihoods, and are more prone to leaving school because of a lack of prospects. School dropouts, early pregnancies, substance abuse, crime and prostitution, are all perceived to have increased in recent years, and were readily correlated to the effects of drought, violence, and the loss of livelihoods.',
			},
			{
				pathwayTitle: 'Pathway 3: Weak governance and political instigation of violence impair efforts for conflict resolution and resilience building. ',
				text: 'Traditional conflict management mechanisms have been today undermined by a series of societal and demographic factors. These rapid changes in local authority distribution, under conditions of fragility and low-State presence, have led to an institutional gap for conflict management. Furthermore, limited institutional capacity of State authorities to address vulnerabilities in this marginalised region can increase resentment of community members towards government. Rent seeking, corruption, active instigation of violence, and conflict-insensitive interventions by government are all perceived as common occurrences. Institutional practices for resource management are also deemed as extractive and exclusive, thereby exacerbating already existing climate vulnerabilities and land-based conflict risks between local communities, conservancies, and government organisations.',
			},
		],
		whatText: [
			<p><Translator text="Following solutions for the defined pathways are suggested by community members:" /></p>,
			<p><Translator text="Yaaku community members proposed to develop collaborative arrangements between the ethnic groups for the collective management of forest resources, by transferring ownership of the land to communities and creating a conservation reserve within the forest, hence shifting towards tourism-based livelihoods and reducing their dependence on livestock. Kenya Content-ethnic management committees for forest management would also work hand-in-hand with a community patrol reserve conformed by recruits from all ethnic groups, which would be charged with monitoring violations to forest resource use bylaws and the occurrence of cattle rustling and village raiding." /></p>,

			<p><Translator text="1. Formally implement Kenya´s Community Land Act (2016) through collaborative arrangements between
			 ethnic groups around Mukogodo, with the goal of transferring ownership of forest resources to communities, and formalise a collaborative conservation reserve managed by all ethnic groups to foster livelihood strategies based on sustainable tourism."
			/>
			</p>,
			<p><Translator text="2. Implement a Collaborative Kenyan Police Reserve (KPR) around Mukogodo area that recruits members
				from all neighbouring ethnic groups. This entity would monitor and prevent both the occurrence of
				cattle rustling and illegal resource use and extraction from Mukogodo forest."
			/>
			</p>,
			<p><Translator text="3. Inaugurate a vocational training centre to foster livelihood strategies that serve as alternatives
				to cattle herding and scale these for the disarmament of young raiders."
			/>
			</p>,
			<p><Translator text="4. Foster gender-inclusive livelihood diversification by strengthening of cultural 'manyatta' for
				cultural-based tourism."
			/>
			</p>,
			<p><Translator text="5. Mobilisation of the community to demand that the Yaaku are identified as an independent tribe
				and to foster the registering for identity and voter cards by young populations. Important
					community-level events -such as events related to the surrounding territory- would be used to foster
						political participation through talks and communal meetings."
			/>
			</p>,
			<p><Translator text="6. Establish inter-community boarding schools that foster sustainable livelihood strategies and the
				renewal of Indigenous cultures."
			/>
			</p>,
		],
		latlng: [0.395290433668544, 37.1647909754528],
	},
	{
		title: 'Banyala fisherfolk in Busia',
		howData: [
			{
				pathwayTitle: 'Pathway 1: Climate change is forcing Banyala populations into a livelihood activity that is directly associated with cross-border insecurity risks. ',
				text: 'Ugandan and Kenyan governments have introduced regulations regarding the use of specific fishing gear to prevent the catching of small fish. Many Kenyan fisherfolk have been unable to adapt to these new regulations. Being uncapable of complying with the law, fisherfolk are forced to conduct illegal fishing both within Kenya and across the border with Uganda, where they are subjected to risk of arrest, torture, destruction of property and death by Ugandan authorities, pirates and other fishers. In Ugandan waters, arrests are also associated with violations of human rights, like torture and arbitrary jailing. Conflicts between Ugandan and Kenyan fisherfolk are also reported to be very common, mainly as a result of competition over access to fisheries and from increasing instances of equipment theft. The loss of livestock and crops due to increasing flooding greatly impairs the capacity of Banyala community members to diversify their livelihoods towards farming, livestock herding and other non-agricultural activities, thereby maintaining a dependence on fishing by local populations and reducing their adaptive capacity towards future climate change hazards.',
			},
			{
				pathwayTitle: 'Pathway 2:  The increasing frequency and intensity of rapid onset floods leads to temporary displacement across the community.',
				text: 'Considered among the most flood prone regions in Kenya, Bunyala lies between Lake Victoria and the Nzoia river estuary, and has been subjected to harsh flooding almost every two years since the turn of the millennium. Despite ongoing efforts for disaster risk management, most of which are led by community-level organisations, many challenges remain for Banyala populations from recurring floods. More frequent and intense flooding is associated with the loss of lives and the widespread loss of property and infrastructure. Important community places, such as schools, have also been relocated due to recurring floods. Because water logging is common after flooding, the community faces an increased incidence of waterborne diseases, including outbreaks of cholera and typhoid. Furthermore, temporary settlement in displacement camps is associated with various forms of insecurity risks, including an increase in the incidence of crime and sexual abuse, substance abuse, among others. ',
			},
		],
		whatText: [
			<p><Translator text="Following solutions for the defined pathways are suggested by community members:" /></p>,
			<p><Translator text="Given the nature of insecurity problems faced by the Banyala, participants in Bunyala sub-county opted
			for solutions that enhanced State-society relations and protected livelihoods, rather than a sense of
			belonging and shared identity between conflictive groups across territorial boundaries."
			/>
			</p>,

			<p><Translator text="1. Diversify livelihood alternatives. An alternative livelihood option meant to reduce population
			dependence on fishing was proposed as the scaling of fish farming as a business model. This would imply
			the implementation of fish ponds and fish cages within Lake Victoria. The targeting of women regarding
			the investment and owning of cages and fish ponds was proposed to reduce the reliance over fish from the
			lake when selling harvest in local markets."
			/>
			</p>,
			<p><Translator text="2. Foster livelihood diversification through conservation and climate smart agriculture, including the
			adoption of flood resistant crops, the planting of fruit trees, and agropastoral systems that reduce
			reliance on agricultural inputs."
			/>
			</p>,
			<p><Translator text="3. Strengthen the capacity of local institutions for fishery management and enforcement of bylaws
			regulating fishing practices. Capacity building programmes that also offer subsidized fishing equipment
			in compliance with new regulations would contribute to reducing fishing-related insecurity risks, as
			better capacity to comply with laws and on proper methods could reduce the risk of fishers getting
			arrested. It would also strengthen livelihood strategies of Banyala fishers."
			/>
			</p>,
			<p><Translator text="4. Implement a programme for the restoration of the swamp so that it facilitates the flow of water, and
			protects surrounding farming land from flooding. During the rainy season, there are crops capable of
		    growing in the swamp, along with naturally occurring weeds and water lilies. A selection of crops to be
			planted as part of the swamp restoration efforts could also increase the provision of ecosystem services
			and foster the diversification of livelihoods."
			/>
			</p>,
			<p><Translator text="5. Fostering community participation in decision making spaces, so as to work towards the harmonization
			of fishing regulations between Kenya and Uganda."
			/>
			</p>,
			<p><Translator text="6. Increasing cross-border collaboration in reducing the instances of corruption, theft, torture and
			killings. Kenyan fisher communities need to do policy advocacy for the Kenyan government to act as
			arbitration for the Ugandan government to respect human rights in the Lake."
			/>
			</p>,
		],
		latlng: [0.0961773188358406, 33.9775732984368],
	},
	{
		title: 'Endorois agropastoralists in Baringo',
		howData: [
			{
				pathwayTitle: 'Pathway 1: Political boundary-based conflicts are exacerbated due to the effects of climate change over livelihoods and income strategies. ',
				text: 'Since 2005, the Endorois Indigenous Peoples have been subjected to violent attacks from the neighbouring Pokot communities to the north of their land. The onset of the conflict coincided with tribal territorial conflicts across Kenya, driven by electoral-based violence, and has continued to revolve around political boundaries, access to land and the interests of political and economic elites. The Endorois mostly understand the conflict as fuelled by political and economic interests in the value of their land. They also perceive how high levels of vulnerability, poverty and marginalisation among young Pokot populations makes them more susceptible to recruitment. Pokot vulnerability to recruitment is thought to be enhanced by the effects of climate change, mainly through the loss of Pokot livelihoods during extreme droughts. Government interventions have actually exacerbated antagonistic sentiments due to perceptions of favoured groups. Previously organized dialogue processes have usually resulted in further violence, leading to a very low willingness by both groups of formally engaging in peace agreement dialogues.',
			},
			{
				pathwayTitle: 'Pathway 2: The effects of conflict have worsened the vulnerability of Endorois populations towards coping with climate and environmental threats.',
				text: 'The community, whose livelihoods depend mostly on farming and pastoralism, has been subjected to increasingly severe droughts and floods, unpredictable rainfall patterns, livestock and crop pests, and the uncontrolled spreading of invasive species which reduce the availability of land and displace people from their homes. These impacts have led to widespread crop failure, livestock death, loss of livelihoods and unregulated deforestation as an alternative source of income. Coupled with increasing food and input prices, these effects have also increased the threat of food insecurity among the Endorois. Furthermore, the presence of conflict currently impairs the ability of endorois populations to cope with climate-related risks. The effects of conflict include dozens of thousands of internally displaced people (IDPs) within Endorois territory, many hundreds have been killed or maimed, and over 10,000 livestock head stolen or killed. Hospitals, water tanks, roads and other infrastructure has also been destroyed. As a consequence, family dysfunctionality, mental illness and crime rate have increased in the community. These impacts reduce people´s capacity to cope with climate change and to collaborate with each other, and have rendered populations unemployed and without any alternative income strategies.',
			},
			{
				pathwayTitle: 'Pathway 3: The effects of climate change over livelihoods and wellbeing interact with historical grievances held by the community towards government authorities.',
				text: 'The Endorois community have inhabited the area around Lake Bogoria for several centuries, but where forcefully displaced by Kenya´s government in the 1970s for the creation of Lake Bogoria Game Reserve. The community has since the late 1980s mobilized, with iterative success and failure, in fighting for the protection of their rights and retribution for past injustices. The failing of the Kenyan government to implement the 2010 African Commission on Human and Peoples’ Rights´ recommendations in favour of the Endorois´ claims to access Lake Bogoria Game Reserve has significantly affected well-being and development among the Endorois community. Unemployment, illiteracy, poverty, food insecurity, lack of access to healthcare, natural resource scarcity, all affect the Endorois in above-average manners as related to regional trends. These issues are also today exacerbated by the effects of climate change, which have compounded towards increasing resentment towards government authorities.',
			},
		],
		whatText: [
			<p><Translator text="Following solutions for the defined pathways are suggested by community members:" /></p>,
			<p><Translator text="The conflict between the Endorois and the Pokot evidences traits of other protracted conflicts, such as culturally internalized resentment and low willingness for dialogue. The conflict is also highly intensive in its effects over population wellbeing. For these reasons, the Endorois were sceptical about deploying dialogue platforms meant to foster sustainable peacebuilding between the Pokot and Endorois communities. However, they still proposed promising solutions that are in line with environmental peacebuilding principles:" /></p>,

			<p><Translator text="1. Implement a landscape restoration programme that employs young people from both ethnic groups, and which operates in both sides of the boundary limit, hence fostering technical engagement between the two groups around the management of natural resources, increasing a sense of inter-dependence, and creating alternative livelihoods for those who reduce their dependency over cattle." />
			</p>,
			<p><Translator text="2. Endorois participants discussed climate-smart agriculture as a way of reducing dependencies on cattle, so as to make populations less susceptible to rustling. There is a need to provide capacity building, livelihood opportunities and access to land to people who agree to get rid of cattle. Alternative sources of employment include the fostering of agroforestry practices, such as the planting of fruit trees and horticulture like the cultivation of mangoes. The combination of small-scale pasturing with high value trees could also support soil fertility and reduced required chemical inputs for farming." />
			</p>,
			<p><Translator text="3. Ensure that every household within Endorois territory has 2 acres of pasture farming from communally managed land. These includes internally displaced families from the conflict, which would support their integration into the community. People without cows can sell the grass, while those with cows can use it for pasture." />
			</p>,
			<p><Translator text="4. Establishment of peace and cultural festivals between conflicting communities that use the organisation of neutral spaces, such as sporting competitions and cultural traditions around natural resources like Lake Bogoria. These events should integrate peace dialogues and awareness raising activities that enhance interaction and cohesion amongst the conflicting communities." />
			</p>,
			<p><Translator text="5. Formalize a community-level Water Resource Association, an institution made up by representatives from neighbouring communities sharing watershed resources, including the Endorois, Pokots and Illchamus. These association is meant to act as a neutral space for engagement in solving issues around water pollution, access and scarcity, which all of the communities are currently facing." />
			</p>,
			<p><Translator text="6. Reduce the amount of animals in relation to the carrying capacity of the land, which would also imply a reduced risk of banditry. Alternative sources of employment include the fostering of agroforestry practices, such as the planting of fruit trees and horticulture like the cultivation of mangoes. The combination of small-scale pasturing with high value trees could also support soil fertility and reduced required chemical inputs for farming." />
			</p>,
		],
		latlng: [0.272063267247021, 36.0669938024324],
	},
];

export default data;
