import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { BuildingCapacity, EconomicDevelopment, BuildingInstitutions, TrustAndCooperation, ResourcesSustainability, EnhancingKnowledge, CountryAndRegion } from './components';
import './styles.css';
import Translator from '../../../Translator';

const Steps = (props) => {
	const { setSubPage, userInput, setUserInput, csstData } = props;

	const [currentStep, setCurrentStep] = useState(1);

	const steps = [1, 2, 3, 4, 5, 6, 7];

	const disableNext = (step) => {
		if (step === 1) {
			if (userInput.country && csstData) {
				if (userInput.country.id === 'GTM' || userInput.country.id === 'NER') {
					if (userInput.region && userInput.municipality) {
						return false;
					}
				} else if (userInput.region) {
					return false;
				} else if (!csstData.regions) {
					return false;
				}
			}
		} else if (step - 1 === 6) {
			if ((userInput[`${step - 1}.1`] >= 0) && (userInput[`${step - 1}.2a`] >= 0) && (userInput[`${step - 1}.2b`] >= 0) && (userInput[`${step - 1}.3`] >= 0)) {
				return false;
			}
		} else if (step - 1 === 5) {
			if ((userInput[`${step - 1}.1`] >= 0) && (userInput[`${step - 1}.2`] >= 0)) {
				return false;
			}
		} else if ((userInput[`${step - 1}.1`] >= 0) && (userInput[`${step - 1}.2`] >= 0) && (userInput[`${step - 1}.3`] >= 0)) {
			return false;
		}
		return true;
	};

	const renderSteps = () => {
		const circleBackground = (step) => {
			if (step < currentStep) {
				return { backgroundColor: '#2d2d2d' };
			}
			if (step === currentStep) {
				return { backgroundColor: '#FDC82F' };
			}
			return { backgroundColor: '#939393' };
		};

		const disableClick = (step) => {
			let flag = false;
			// eslint-disable-next-line no-plusplus
			for (let i = 1; i <= step; i++) {
				if (disableNext(i)) {
					flag = true;
				}
			}
			return flag;
		};

		return steps.map((item) => {
			return (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div className="step-circle" style={circleBackground(item)} onClick={disableClick(item - 1) ? () => {} : () => setCurrentStep(item)} />
			);
		});
	};

	const renderStep = () => {
		switch (currentStep) {
		case 1: return <CountryAndRegion csstData={csstData} userInput={userInput} setUserInput={setUserInput} />;
		case 2: return <EconomicDevelopment userInput={userInput} setUserInput={setUserInput} />;
		case 3: return <BuildingInstitutions userInput={userInput} setUserInput={setUserInput} />;
		case 4: return <TrustAndCooperation userInput={userInput} setUserInput={setUserInput} />;
		case 5: return <ResourcesSustainability userInput={userInput} setUserInput={setUserInput} />;
		case 6: return <EnhancingKnowledge userInput={userInput} setUserInput={setUserInput} />;
		case 7: return <BuildingCapacity userInput={userInput} setUserInput={setUserInput} />;
		default: return null;
		}
	};

	return (
		<div className="sub-page steps">
			{renderStep()}
			<div className="navigation">
				<div className="navigation-buttons">
					{currentStep === 1 ? <Button label={<Translator text="Back" />} onClick={() => setSubPage('introduction')} /> : <Button label={<Translator text="Back" />} onClick={() => setCurrentStep(currentStep - 1)} />}
					{currentStep === 7 ? <Button label={<Translator text="Finish" />} disabled={disableNext(currentStep)} onClick={() => setSubPage('results')} /> : <Button label={<Translator text="Next" />} disabled={disableNext(currentStep)} onClick={() => setCurrentStep(currentStep + 1)} />}
				</div>
				<div className="stepper">
					{renderSteps()}
				</div>
			</div>
		</div>
	);
};

export default Steps;
