import React, { useEffect, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import {
	financeImage,
	multiLevelImage,
	policyImage, programmaticImage, researchImage,
} from '../../../../../../../../../../../../assets/images/CountryInformationPage/What/policyProgramatic/img';
import './styles.css';
import Translator from '../../../../../../../../../../../../components/Translator';

const KenyaContent = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [tab, setTab] = useState(0);

	useEffect(
		() => {
			changeTabColor(tab, 'black');
			setTab('multi');
			changeTabColor('multi', '#FDC82F');
		}, []
	);

	const headerTemplate = (options, image, header) => (
		<div role="button" tabIndex="0" onClick={options.onClick} className="tabs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
			{image}
			<p><Translator text={header} /></p>
		</div>
	);

	const changeTabColor = (target, color) => {
		const selectedtabImage = document.getElementById(target);
		if (selectedtabImage) {
			selectedtabImage.fill = color;
			let paths = selectedtabImage.getElementsByTagName('path');
			paths = [...paths];
			paths.map((item) => {
				// eslint-disable-next-line no-param-reassign
				item.style.fill = color;
			});
		}
	};

	return (
		<TabView
			activeIndex={activeIndex}
			onTabChange={(e) => {
				setActiveIndex(e.index);
				changeTabColor(tab, 'black');
				switch (e.index) {
				case 0: setTab('multi'); changeTabColor('multi', '#FDC82F'); break;
				case 1: setTab('policy'); changeTabColor('policy', '#FDC82F'); break;
				case 2: setTab('programmatic'); changeTabColor('programmatic', '#FDC82F'); break;
				case 3: setTab('research'); changeTabColor('research', '#FDC82F'); break;
				default: setTab('finance'); changeTabColor('finance', '#FDC82F'); break;
				} 
			}
			}
		>
			<TabPanel header="multi" headerTemplate={(options) => headerTemplate(options, multiLevelImage, 'Multi-level goveranance')}>
				<div className="panel-container">
					<div>
						<h3><Translator text="Description" /></h3>
						<p className="m-0">
							<Translator text="Implementing participatory spaces for collective conversation can support the establishment of policy networks composed of interdependent actors who then learn to operate in greater synergy, thereby effectively becoming a system. To modify current practices for climate adaptation and peacebuilding towards integrating a climate security sensitive approach – and for successful approaches to be scaled up where possible – governance efforts must adopt conscious strategies to develop multi-actor agreements and shared perceptions of climate security risks that span across policy sectors and political-administrative levels." />
						</p>
					</div>
					<div>
						<h3><Translator text="Key recommendations" /></h3>
						<ul>
							<li><Translator text="Identify existing multi-stakeholder platforms at national and sub-national levels that may serve as a base towards integrating a climate security focus on both climate action and peacebuilding strategies." /></li>
							<li><Translator text="Conduct a needs assessment of the designated multi-stakeholder platforms to identify actions required for increasing their capacity to include a climate security perspective. Identify organizational mandates within the platforms that could be complemented with a climate- security oriented action and cluster actors in thematic areas in relation to expertise and mandates." /></li>
							<li><Translator text="Develop a multi-level governance strategy for the participating platforms that ensure the effective participation and recognition of county- and community-level priorities for climate security action." /></li>
							<li><Translator text="Co-develop an agenda and clear terms of reference for the community of practice that indicate coordinating mandates, priority areas of action and mechanisms for collaboration and building stakeholder capacity." /></li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="policy" headerTemplate={(options) => headerTemplate(options, policyImage, 'Policy frameworks')}>
				<div className="panel-container">
					<div>
						<h3><Translator text="Description" /></h3>
						<p className="m-0">
							<Translator text="Given that governance frameworks for climate change and peace have traditionally evolved independently due to inadequate cross-sectoral collaboration, a significant degree of institutional learning is required to effectively integrate climate security as a topic of concern in Kenya’s policies and governance systems. Recommendations by workshop participants for initiating a national policy dialogue to facilitate the adoption of climate action as an entry point for conflict prevention, conflict transformation, and peacebuilding focused on identifying policies, strategies and action plans at national and sub-national levels that could be potentially updated through a climate security lens." />
						</p>
					</div>
					<div>
						<h3><Translator text="Key recommendations" /></h3>
						<ul>
							<li><Translator text="Define key priorities and actions towards integrating climate security as a topic of concern in the updated National Climate Change Action Plan (NCCAP 2023-2027)" /></li>
							<li><Translator text="Map existing legal and policy frameworks relevant for climate and security at the national level and assess their coherence and integration in terms of climate security." /></li>
							<li><Translator text="Map existing sub-national policies and action plans that may be suitable to support county-level actions for resilience that contribute to peacebuilding efforts." /></li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="programmatic" headerTemplate={(options) => headerTemplate(options, programmaticImage, 'Programmatic planning')}>
				<div className="panel-container">
					<div>
						<h3><Translator text="Description" /></h3>
						<p className="m-0">
							<Translator text="There is a need to design climate adaptation programmes and initiatives that proactively contribute to sustaining peace actions. Similarly, peace and security actors should undertake programme planning with a climate perspective. Such efforts should crucially be responsive to specific local contexts and needs. Integrating climate and security risk analyses into the design of resilience and peace dividend projects across Kenya therefore demands significant engagement and coordination across sectors and scales of governance, along with increasing capacity assessment where needed." />
						</p>
					</div>
					<div>
						<h3><Translator text="Key recommendations" /></h3>
						<ul>
							<li><Translator text="Map existing climate action and peacebuilding programmes throughout Kenya that may be relevant to address climate security risks." /></li>
							<li><Translator text="Build upon a better understanding of community-level risk coping and conflict management strategies towards developing climate security action plans." /></li>
							<li><Translator text="Strengthen peacebuilding and climate actors’ capacity to conduct conflict assessments that integrate a climate perspective and vulnerability assessments that account for conflict risks respectively." /></li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="research" headerTemplate={(options) => headerTemplate(options, researchImage, 'Research and evidence gaps')}>
				<div className="panel-container">
					<div>
						<h3><Translator text="Description" /></h3>
						<p className="m-0">
							<Translator text="The workshop emphasized the importance of upgrading and expanding present empirical research on climate security in Kenya. Given the diverse findings and scattered evidence, empirical research to date has been unable to provide coherent insights on the climate and conflict nexus." />
						</p>
					</div>
					<div>
						<h3><Translator text="Key recommendations" /></h3>
						<ul>
							<li><Translator text="Gaining a better understanding of how various types of climatic extremes and variability enhance multiple and diverse conflict risks, such as resource-based, inter-ethnic or territorial conflicts, banditry and criminality, drug trafficking, and recruitment by non-State armed groups; along with their connection with the weakening of agricultural and resource-dependent livelihoods, different forms of mobility, State- society relations, and inter- and intra-communal relations." /></li>
							<li><Translator text="Expanding research focus both beyond and within the ASALs region." /></li>
							<li><Translator text="Developing further evidence on the indirect linkages between climate and conflict – expanding on the role of migration and displacement, political drivers of conflict or loss of livelihoods." /></li>
							<li><Translator text="Investigating further the gender dimensions of climate security in Kenya, especially as gender roles are rapidly shifting in both rural and urban contexts." /></li>
							<li><Translator text="Co-producing context-specific climate security assessments relying on community voices that account for traditional coping strategies." />
								<Translator text="Expand climate modeling capabilities to gain a better understanding of future risks by linking current dynamics of climate security with future hazards, while emphasizing the uncertainties inherent in modeling work, as well as the complex and non-linear interactions that are essential to decision-making processes." />
							</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="finance" headerTemplate={(options) => headerTemplate(options, financeImage, 'Finance')}>
				<div className="panel-container">
					<div>
						<h3><Translator text="Description" /></h3>
						<p className="m-0">
							<Translator text="Understanding that conflict-affected areas receive significantly less climate action investment than those viewed as secure (UNDP & the Climate Security Mechanism, 2021), workshop participants acknowledged the need for investments with co-benefits for both adaptation and peacebuilding in Kenya’s climate security hotspots. Even though climate adaptation action has traditionally avoided conflict-affected regions due to their high-risk profile and security concerns, there is an opportunity to link investment initiatives with climate security hotspots." />
						</p>
					</div>
					<div>
						<h3><Translator text="Key recommendations" /></h3>
						<ul>
							<li><Translator text="In conflict-affected areas, climate-smart agricultural investments can help mitigate the drivers of resource-related violence." /></li>
							<li><Translator text="Rather than create new and adjacent organizing structures, climate security practitioners should leverage pre-existing networks and multi-stakeholder platforms to support the development, implementation, and scaling of financial interventions." /></li>
							<li><Translator text="A co-design process is critical for ensuring the validity, accuracy, and local ownership of climate security investments." /></li>
							<li><Translator text="More work is needed to measure climate security risks in order to structure financial products so that they are attractive to investors, allowing finance to flow to insecure populations. Climate security should feature more prominently in the public budget." /></li>
							<li><Translator text="Enhance the grant writing and fundraising skills of organizations working at the intersection of climate and security." /></li>
						</ul>
					</div>

				</div>
			</TabPanel>
		</TabView>
	);
};

export default KenyaContent;
