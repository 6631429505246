import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import './styles.css';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Chip } from 'primereact/chip';
import { useSelector } from 'react-redux';
import Translator from '../../../../../../../../../../components/Translator';
import { Treemap } from './components';
import texts from './components/texts';

FilterService.register('custom_variables', (value, filters) => {
	if (!filters) {
		return true;
	}
	console.log(value);
	const topic = value.map((item) => item.parent);
	if (topic.includes(filters)) {
		return true;
	}
	return false;
});

FilterService.register('custom_subTopic', (values, filter) => {
	if (!filter) {
		return true;
	}

	const subTopic = values.map((item) => item);
	if (subTopic.includes(filter)) {
		return true;
	}

	return false;
});

const Toolkit = (props) => {
	const { policyCoherenceDocuments, policyCoherenceTopics, policyCoherenceSubTopics, policyCoherenceTreeData } = props;
	const country = useSelector((state) => state.country);
	const [headerStyle, setHeaderStyle] = useState(false);
	const [checked, setChecked] = useState(false);
	const dt = useRef(null);
	const [documents, setDocuments] = useState(null);
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		sector: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		sub_sector: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		variables: { value: null, matchMode: FilterMatchMode.CUSTOM },
		subTopic: { value: null, matchMode: FilterMatchMode.CUSTOM },
		// sub_topic: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
	});

	useEffect(() => {
		console.log(policyCoherenceDocuments);
		setDocuments(policyCoherenceDocuments);
	}, []);

	const getSectorsList = () => {
		const sectors = policyCoherenceDocuments.map((item) => item.sector);

		let newSectors = [...new Set(sectors)];

		newSectors = newSectors.filter((item) => item !== '');

		return newSectors;
	};

	const getSubSectorsList = () => {
		const subSectors = policyCoherenceDocuments.map((item) => item.sub_sector);

		let newSubSectors = [...new Set(subSectors)];

		newSubSectors = newSubSectors.filter((item) => item !== '');

		return newSubSectors;
	};

	const getTopicsList = () => {
		const topics = policyCoherenceTopics.map((item) => item);

		let newTopics = [...new Set(topics)];

		newTopics = newTopics.filter((item) => item !== '');
		return newTopics;
	};

	const getSubTopicsList = () => {
		const subTopics = policyCoherenceSubTopics.map((item) => item);

		let newSubTopics = [...new Set(subTopics)];

		newSubTopics = newSubTopics.filter((item) => item !== '');
		return newSubTopics;
	};

	const exportCSV = (selectionOnly) => {
		dt.current.exportCSV({ selectionOnly });
	};
	
	const itemTemplate = (option) => {
		return <span className={`customer-badge status-${option}`}>{option}</span>;
	};

	const sectorRowFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={getSectorsList()} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={itemTemplate} placeholder="Select" className="p-column-filter" showClear />;
	};

	const subSectorRowFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={getSubSectorsList()} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={itemTemplate} placeholder="Select" className="p-column-filter" showClear />;
	};

	const topicsRowFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={getTopicsList()} onChange={(e) => { options.filterApplyCallback(e.value); console.log(e); }} itemTemplate={itemTemplate} placeholder="Select" className="p-column-filter" showClear />;
	};

	const subTopicsRowFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={getSubTopicsList()} onChange={(e) => { options.filterApplyCallback(e.value); console.log(e); }} itemTemplate={itemTemplate} placeholder="Select" className="p-column-filter" showClear />;
	};

	const renderHeader = () => {
		return (
			<>
				<div style={{ textAlign: 'right' }}>
					<Button type="button" onClick={() => exportCSV(false)} data-pr-tooltip="CSV">Export Table</Button>
				</div>
				{/* <div className="flex justify-content-end"> */}
				{/*	<span className="p-input-icon-left"> */}
				{/*		<i className="pi pi-search" /> */}
				{/*		/!* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" /> *!/ */}
				{/*	</span> */}
				{/* </div> */}
			</>

		);
	};

	const urlTemplate = (data) => {
		return (
			(data.link)
				? (
					<>
						<a href={data.link} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a><br /><br />
					</>
				) : null

		);
	};

	const authorTemplate = (data) => {
		return (
			(data.author !== '')
				? (
					<p><Translator text={data.author} /></p>

				) : (
					<p><Translator text="N/A" /></p>
				)

		);
	};

	const sectorTemplate = (data) => {
		return (
			(data.sector !== '')
				? (
					<p><Translator text={data.sector} /></p>

				) : (
					<p><Translator text="N/A" /></p>
				)

		);
	};

	const subSectorTemplate = (data) => {
		return (
			(data.sub_sector !== '')
				? (
					<p><Translator text={data.sub_sector} /></p>

				) : (
					<p><Translator text="N/A" /></p>
				)

		);
	};

	const topicTemplate = (data, index) => {
		let finalList;
		let restOfTheList;
		if (data.variables.length !== 0) {
			const colors = (color) => {
				switch (color) {
				case 'Climate': return '#6771dc';
				case 'Security': return '#a367dc';
				case 'Climate and Security': return '#dc67ce';
				case 'Climate Security and Marginalized Groups': return '#dc6788';
				case 'Climate Assessment and Measures': return '#dc8c67';
				case 'Conflict-sensitive Assessment and Measures': return '#dcd267';
				default: return '#ccc';
				}
			};

			const list = data.variables.map((topic) => topic.parent);

			const newTopics = [...new Set(list)];

			const lastElement = newTopics[newTopics - 1];

			if (newTopics.length > 3) {
				const smallerList = newTopics.slice(0, 3);
				const secondHalfList = newTopics.slice(3, lastElement);

				finalList = smallerList.map((item) => {
					return (
						<Chip
							label={item}
							style={{
								background: colors(item),
								color: '#fff',
								marginBottom: '10px',
								marginRight: '10px',
							}}
						/>
					);
				});
				restOfTheList = secondHalfList.map((item) => {
					return (
						<Chip
							label={item}
							style={{
								background: colors(item),
								color: '#fff',
								marginBottom: '10px',
								marginRight: '10px',
							}}
						/>
					);
				});
			} if (newTopics.length <= 3) {
				finalList = newTopics.map((item) => {
					return <Chip label={item} style={{ background: colors(item), color: '#fff', marginBottom: '10px', marginRight: '10px' }} />;
				});

				return finalList;
			}
		} if (data.variables.length === 0) {
			return <p><Translator text="N/A" /></p>;
		}
		return (
			<>
				{finalList}
				{/* <div className="tooltip-box">{restOfTheList}</div> */}
				<Tooltip target={`#tooltip-${index.rowIndex}`} position="bottom" className="tooltip-box">{restOfTheList}</Tooltip>
				<div id={`tooltip-${index.rowIndex}`}>...</div>
			</>

		);
	};

	const subTopicTemplate = (data, index) => {
		if (!data.variables || data.variables.length === 0) {
			return <p><Translator text="N/A" /></p>;
		}

		const colorsSubTopic = (color) => {
			switch (color) {
			case 'Climate': return '#6771dc';
			case 'Security': return '#a367dc';
			case 'Climate and Security': return '#dc67ce';
			case 'Climate Security and Marginalized Groups': return '#dc6788';
			case 'Climate Assessment and Measures': return '#dc8c67';
			case 'Conflict-sensitive Assessment and Measures': return '#dcd267';
			default: return '#ccc';
			}
		};

		const newSubTopics = data.variables.map((item) => item);
		const firstThreeSubTopics = newSubTopics.slice(0, 3);

		const finalSubTopics = firstThreeSubTopics.map((item) => {
			return (
				<Chip
					key={item.child}
					label={item.child}
					style={{
						background: colorsSubTopic(item.parent),
						color: '#fff',
						marginBottom: '10px',
						marginRight: '10px',
					}}
				/>
			);
		});

		const restSubTopics = newSubTopics.slice(3);

		const finalTooltipList = restSubTopics.map((item) => {
			return (
				<Chip
					key={item.child}
					label={item.child}
					style={{
						background: colorsSubTopic(item.parent),
						color: '#fff',
						marginBottom: '10px',
						marginRight: '10px',
					}}
				/>
			);
		});

		return (
			<>
				{finalSubTopics}
				{finalTooltipList.length > 0 && (
					<>
						<Tooltip target={`#tooltip2-${index.rowIndex}`} position="bottom" className="tooltip-box">
							{finalTooltipList}
						</Tooltip>
						<div id={`tooltip2-${index.rowIndex}`}>...</div>
					</>

				)}

			</>
		);
	};

	const filterTable = (clickedData) => {
		const filteredData = policyCoherenceDocuments.filter((doc) => doc.subTopic.some((item) => item === clickedData));
		setDocuments(filteredData);
	};

	const header = renderHeader();

	return (
		(country.id === 'KEN' || country.id === 'ZMB') ? (
			<div className="toolkit-container">
				<h2><Translator text="Climate Security Policy Explorer" /></h2>
				<p><Translator text="A semi-interactive dataset that allows people to identify which policies and strategies match their variables of interest." /></p>
				<div className="accordions">
					<h3>Are Climate and Security Policies Coherent and Integrated in {country.id === 'KEN' ? 'Kenya?' : 'Zambia?'} A Policy Coherence Analysis</h3>
					<Accordion activeIndex={0} onTabOpen={() => setHeaderStyle(true)} onTabClose={() => setHeaderStyle(false)}>
						{/* {renderAccordionTabs()} */}
						<AccordionTab header="Policy Frameworks">
							<div className="text">
								<p className="open-sans">{country.id === 'KEN' ? texts.kenya.policyFrameworks : texts.zambia.policyFrameworks}</p>
							</div>
						</AccordionTab>
						<AccordionTab header="Multi-level Governance">
							<div className="text">
								<p className="open-sans">{country.id === 'KEN' ? texts.kenya.multilevelGovernance : texts.zambia.multilevelGovernance}</p>
							</div>
						</AccordionTab>
					</Accordion>
				</div>
				<div className="query">
					<h3>Find relevant policy documents</h3>
					<div className="switch">
						<p>Graphical Querying</p>
						<InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
						<p>Advanced Querying</p>
					</div>
					{
						!checked ? (
							<div className="warning-graph-container">
								<div className="warning">
									<i className="fa-regular fa-circle-info" />
									<p className="open-sans"><Translator text="Click through the tree graph to find relevant policy documents" /></p>
								</div>
								<div className="treemap">
									<Treemap treeData={policyCoherenceTreeData} filteredTable={filterTable} policyCoherenceTopics={policyCoherenceTopics} />

								</div>
							</div>

						) : (
							<div className="warning-filter-container">
								<div className="warning">
									<i className="fa-regular fa-circle-info" />
									<p className="open-sans"><Translator text="Use the dropdown filter to find relevant policy documents" /></p>
								</div>
							</div>

						)
					}
				</div>
				<div id="table-documents">
					<h3>Policy Documents</h3>
					<DataTable ref={dt} responsiveLayout="scroll" paginator rows={5} stripedRows style={{ textAlign: 'center' }} filterDisplay="row" value={documents} header={header} filters={filters}>
						<Column field="title" header="Title" />
						<Column field="language" header="Language" />
						<Column field="policymaking" header="Policy Level" />
						<Column field="author" header="Author" body={authorTemplate} />
						<Column field="sector" header="Sector" body={sectorTemplate} filter={!!checked} filterElement={sectorRowFilterTemplate} showFilterMenu={false} />
						<Column field="sub_sector" header="Sub-sector" body={subSectorTemplate} filter={!!checked} filterElement={subSectorRowFilterTemplate} showFilterMenu={false} />
						<Column field="variables" body={topicTemplate} filterElement={topicsRowFilterTemplate} filter={!!checked} header="Topic" showFilterMenu={false} />
						<Column field="subTopic" body={subTopicTemplate} filterElement={subTopicsRowFilterTemplate} filter={!!checked} header="Sub-topic" showFilterMenu={false} />
						<Column field="year" header="Year" />
						<Column field="link" body={urlTemplate} header="Link" />
					</DataTable>
				</div>
			</div>
		) : (
			<h2 style={{ fontSize: '30px', color: '#FDC82F' }}><Translator text="Coming Soon!" /></h2>
		)
	);
};
export default Toolkit;
