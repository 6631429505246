import React, { useEffect, useState } from 'react';
import './styles.css';
import { useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { MapGraph, DataDisplay } from './components';
import kenyaIcons from './kenyaIcons';
import senegalIcons from './senegalIcons';
import zambiaIcons from './zambiaIcons';
import comingSoon from './comingSoon';
import guatemalaIcons from './guatemalaIcons';
import Translator from '../../../../../../../../components/Translator';

const ModelContent = (props) => {
	const { textProperty } = props;

	const [icons, setIcons] = useState(null);
	const [data, setData] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('');

	const country = useSelector((state) => state.country);

	useEffect(
		() => {
			switch (country.name) {
			case 'Kenya': {
				setIcons(kenyaIcons);
				setData(kenyaIcons[0]);
				setSelectedCountry('KE');
				break;
			}
			case 'Senegal': {
				setIcons(senegalIcons);
				setData(senegalIcons[0]);
				setSelectedCountry('SN');
				break;
			}
			case 'Guatemala': {
				setIcons(guatemalaIcons);
				setData(guatemalaIcons[0]);
				setSelectedCountry('GT');
				break;
			}
			case 'Zambia': {
				setIcons(zambiaIcons);
				setData(zambiaIcons[0]);
				setSelectedCountry('ZM');
				break;
			}
			default: {
				setIcons(comingSoon);
				break;
			}
			}
		}, [country]
	);

	return (
		<div className="voices-field-content">
			<h2 style={{ fontSize: '30px', color: '#FDC82F' }}>{textProperty === 'whatText' ? <Translator text="Foster Inclusive Participatory Research" /> : <Translator text="Voices from the Field" />}</h2>
			<p><Translator text={data.title} /></p>
			<div className="map-and-info" id="voices-tab">
				{icons ? <MapGraph data={data} icons={icons} setData={setData} country={selectedCountry} /> : null}
				<DataDisplay data={data} textProperty={textProperty} />
			</div>
		</div>
	);
};

export default ModelContent;
