/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import dataSources from '../../../../../../assets/images/CountryInformationPage/dataSources.svg';
import methods from '../../../../../../assets/images/CountryInformationPage/methods.svg';
import reports from '../../../../../../assets/images/CountryInformationPage/reports.svg';
import './styles.css';
import Translator from '../../../../../../components/Translator';

const LearnMoreDialogContent = (props) => {
	const { dataSource, dataMethods, dataReports } = props;

	const downloadData = () => {
		const x = [dataSource, dataMethods];
		const url = window.URL.createObjectURL(
			// new Blob([JSON.stringify(dataSourcesMockData), JSON.stringify(methodsMockData), JSON.stringify(reportsMockData)], { type: 'text/json' })
			new Blob([JSON.stringify(x)], { type: 'text/json' })
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			'data.txt'
		);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};
	const sourceTemplate = (data) => {
		return (
			(data.source_na ? <p>{data.source_na}</p>
				: (
					<>
						<a href={data.source} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a><br /><br />
						{data.source_b
							? (
								<a href={data.source_b} target="_blank" rel="noreferrer" aria-label="link">
									<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
								</a>
							) : null }
					</>
				)
			)

		);
	};
	const descriptionTemplate = (data) => {
		return (
			<>
				<p><Translator text={data.description} /></p>
				{data.description_link
					? (
						<a href={data.description_link} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a>
					) : null}
			</>
		);
	};
	const urlTemplate = (data) => {
		return (
			(data.url_na ? <p>{data.url_na}</p>
				: (
					<a href={data.url} target="_blank" rel="noreferrer" aria-label="link">
						<i className="fa-regular fa-file-pdf" style={{ fontSize: '35px', color: '#fdc82f' }} />
					</a>
				)

			)
		);
	};

	const labelTemplate = (data) => {
		return (
			<p><Translator text={data.label} /></p>
		);
	};

	const unitTemplate = (data) => {
		return (
			<p><Translator text={data.type_unit} /></p>
		);
	};

	const spatialTemplate = (data) => {
		return (
			<p><Translator text={data.spatial_resolution_aggregation} /></p>
		);
	};

	const aggregationTemplate = (data) => {
		return (
			<p><Translator text={data.temporal_aggregation} /></p>
		);
	};

	const titleTemplate = (data) => {
		return (
			<p><Translator text={data.title} /></p>
		);
	};

	const yearTemplate = (data) => {
		return (
			<p><Translator text={data.year} /></p>
		);
	};

	// const rowGroupHeaderTemplate = (data) => {
	// 	return (
	// 		<div className="row-group-header">
	// 			<p>{data.category}</p>
	// 		</div>
	// 	);
	// };

	return (
		<div className="learn-more-content">
			<div className="data-sources row">
				<div className="header">
					<img src={dataSources} alt="data-sources-icon" />
					<p className="title"><Translator text="Data Sources" /></p>
				</div>

				<DataTable value={dataSource} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column body={labelTemplate} header={<Translator text="Label" />} />
					<Column body={descriptionTemplate} header={<Translator text="Description" />} style={{ textAlign: 'justify' }} />
					<Column body={unitTemplate} header={<Translator text="Type/Unit" />} />
					<Column body={spatialTemplate} header={<Translator text="Spatial Resolution/Aggregation" />} />
					<Column body={aggregationTemplate} header={<Translator text="Temporal Aggregation" />} />
					<Column header={<Translator text="Source" />} body={sourceTemplate} />
				</DataTable>
			</div>
			<div className="methods row">
				<div className="header">
					<img src={methods} alt="methods-icon" />
					<p className="title"><Translator text="Methods" /></p>
				</div>
				<DataTable value={dataMethods} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column body={titleTemplate} header={<Translator text="Title" />} />
					<Column body={yearTemplate} header={<Translator text="Year" />} />
					<Column field="url" body={urlTemplate} header={<Translator text="Url" />} />
				</DataTable>
			</div>
			<div className="reports row">
				<div className="header">
					<img src={reports} alt="reports-icon" />
					<p className="title"><Translator text="Reports" /></p>
				</div>

				<DataTable value={dataReports} responsiveLayout="scroll" stripedRows style={{ textAlign: 'center' }}>
					<Column body={titleTemplate} header={<Translator text="Title" />} />
					<Column body={yearTemplate} header={<Translator text="Year" />} />
					<Column field="url" body={sourceTemplate} header={<Translator text="Url" />} />
				</DataTable>

			</div>
		</div>

	);
};

export default LearnMoreDialogContent;
