/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Button } from 'primereact/button';
import L from 'leaflet';
import { defaultColors as defaultColorsKenya } from '../../../../../../../../utils/palleteKenya';
import { defaultColors as defaultColorsSenegal } from '../../../../../../../../utils/palleteSenegal';
import { defaultColors as defaultColorsZimbabwe } from '../../../../../../../../utils/palleteZimbabwe';
import { defaultColors as defaultColorsUganda } from '../../../../../../../../utils/palleteUganda';
import { defaultColors as defaultColorsNigeria } from '../../../../../../../../utils/palleteNigeria';
import { defaultColors as defaultColorsGuatemala } from '../../../../../../../../utils/palleteGuatemala';
import { defaultColors as defaultColorsMali } from '../../../../../../../../utils/palleteMali';
import { defaultColors as defaultColorsPhilippines } from '../../../../../../../../utils/palletePhilipines';
import { defaultColors as defaultColorsZambia } from '../../../../../../../../utils/palleteZambia';
import { defaultColors as defaultColorsEthiopia } from '../../../../../../../../utils/palleteEthiopia';
import { defaultColors as defaultColorsSudan } from '../../../../../../../../utils/palleteSudan';
import { defaultColors as defaultColorsBurkina } from '../../../../../../../../utils/palleteBurkinaFaso';
import { defaultColors as defaultColorsNiger } from '../../../../../../../../utils/palleteNiger';
import Actions from '../../../../../../../../reducer/actions';
import './styles.css';
import nineColorPallete from '../../../../../../../../utils/nineColorPallete';
import twelveColorPallete from '../../../../../../../../utils/twelveColorPallete';
import Translator from '../../../../../../../../components/Translator';

const Map = (props) => {
	const { configuration, climateCluster, conflictCluster, reset } = props;

	const dispatch = useDispatch();

	const whoSelectedFeature = useSelector((state) => state.whoSelectedFeature);
	const setWhoSelectedFeature = (payload) => dispatch({ type: Actions.SetWhoSelectedFeature, payload });

	const megapixelsGeoJson = useSelector((state) => state.megapixelsGeoJson);

	const country = useSelector((state) => state.country);

	const [map, setMap] = useState(null);

	const [informationWindow, setInformationWindow] = useState(false);
	const [closeLegend, setCloseLegend] = useState(false);

	// const megapixels = { features: megapixelsJson.features, name: megapixelsJson.name, type: megapixelsJson.type };

	const mapRef = (element) => {
		setMap(element);
	};

	const featureClick = (feature, localLayer) => {
		const id = `${feature.properties.NAME_1} / ${feature.properties.NAME_2} / ${feature.properties.NAME_3}`;

		localLayer.on(
			{
				click(e) {
					setWhoSelectedFeature({ id, properties: e.target.feature.properties });
					setInformationWindow(true);

					map.eachLayer((item) => {
						if (item.options.id === 2) {
							map.removeLayer(item);
						}
					});
					const highlightLayer = L.geoJSON(e.target.feature, {
						pane: `pane-2`,
						id: 2,
						style: {
							fillOpacity: 0,
							weight: 4,
							color: 'black',
						},
						onEachFeature(feat, highlight) {
							highlight.on(
								{
									click(ev) {
										setWhoSelectedFeature({ id, properties: e.target.feature.properties });
										setInformationWindow(true);
									},
								}
							);
						},
					});
					map.addLayer(highlightLayer);
				},
			}
		);
	};

	const defaultColors = (filters) => {
		switch (country.id) {
		case 'KEN': return defaultColorsKenya(filters);
		case 'SEN': return defaultColorsSenegal(filters);
		case 'ZWE': return defaultColorsZimbabwe(filters);
		case 'UGA': return defaultColorsUganda(filters);
		case 'NGA': return defaultColorsNigeria(filters);
		case 'GTM': return defaultColorsGuatemala(filters);
		case 'MLI': return defaultColorsMali(filters);
		case 'PHL': return defaultColorsPhilippines(filters);
		case 'ZMB': return defaultColorsZambia(filters);
		case 'ETH': return defaultColorsEthiopia(filters);
		case 'SDN': return defaultColorsSudan(filters);
		case 'BFA': return defaultColorsBurkina(filters);
		case 'NER': return defaultColorsNiger(filters);
		default: return defaultColorsKenya(filters);
		}
	};

	useEffect(
		() => {
			if (!map) return;
			map.eachLayer((layer) => {
				if (layer.options.id === 1 || layer.options.id === 2) {
					map.removeLayer(layer);
				}
			});

			const layer = L.geoJSON(megapixelsGeoJson.features, {
				pane: `pane-1`,
				id: 1,
				style(feature) {
					const climateOrder = feature.properties.clim_cluster_short_label;
					const conflictLevel = feature.properties.conflict_clust_label;
					return defaultColors({ conflict: conflictLevel, climate: climateOrder });
					// if (climateCluster === '' && conflictCluster === '') {
					// 	const climateOrder = feature.properties.clim_cluster_short_label;
					// 	const conflictLevel = feature.properties.conflict_clust_label;
					// 	return defaultColors(climateOrder, conflictLevel);
					// } if (climateCluster === '') {
					// 	if (feature.properties.conflict_clust_label === conflictCluster) {
					// 		return defaultColors(feature.properties.clim_cluster_short_label, conflictCluster);
					// 	} return { fillOpacity: 0, opacity: 0 };
					// } if (conflictCluster === '') {
					// 	if (feature.properties.clim_cluster_short_label === climateCluster) {
					// 		return defaultColors(climateCluster, feature.properties.conflict_clust_label);
					// 	} return { fillOpacity: 0, opacity: 0 };
					// }
					// if (feature.properties.clim_cluster_short_label === climateCluster && feature.properties.conflict_clust_label === conflictCluster) {
					// 	return defaultColors(climateCluster, conflictCluster);
					// } return { fillOpacity: 0, opacity: 0 };
				},
				onEachFeature(feature, localLayer) {
					featureClick(feature, localLayer);
					// if (climateCluster === '' && conflictCluster === '') {
					// 	featureClick(feature, localLayer);
					// } if (climateCluster === '') {
					// 	if (feature.properties.conflict_clust_label === conflictCluster) {
					// 		featureClick(feature, localLayer);
					// 	}
					// } if (conflictCluster === '') {
					// 	if (feature.properties.clim_cluster_short_label === climateCluster) {
					// 		featureClick(feature, localLayer);
					// 	}
					// }
					// if (feature.properties.clim_cluster_short_label === climateCluster && feature.properties.conflict_clust_label === conflictCluster) {
					// 	featureClick(feature, localLayer);
					// }
				},
			});
			map.addLayer(layer);
		}, [map, climateCluster, conflictCluster, reset, megapixelsGeoJson]
	);
	useEffect(
		() => {
			if (whoSelectedFeature.id) {
				setInformationWindow(true);
			}
		}, [whoSelectedFeature]
	);

	useEffect(
		() => {
			setInformationWindow(false);
			setWhoSelectedFeature({});
		}, [climateCluster, conflictCluster, reset]
	);

	const onEachFeature = (feature, layer, property) => {
		// eslint-disable-next-line max-len
		layer.bindPopup(feature.properties[property], { closeButton: false, offset: L.point(0, -20), autoPan: false });
		layer.on('mouseover', () => { layer.openPopup(); });
		layer.on('mouseout', () => { layer.closePopup(); });
	};

	const boundsLayer = L.geoJSON(megapixelsGeoJson, {
		pane: `pane-0`,
		id: 0,
		style: {
			fillOpacity: 0,
			weight: 1,
			color: 'white',
		},
	});

	useEffect(
		() => {
			if (!map) return;

			map.createPane(`pane-0`);
			map.getPane(`pane-0`).style.zIndex = 401;
			map.createPane(`pane-1`);
			map.getPane(`pane-1`).style.zIndex = 498;
			map.createPane(`pane-2`);
			map.getPane(`pane-2`).style.zIndex = 499;
			map.fitBounds(boundsLayer.getBounds());
			map.setMaxBounds(boundsLayer.getBounds());
			map.zoomControl.setPosition('topleft');
			map.options.maxZoom = 14;
			map.options.minZoom = 6;
			map.options.zoomSnap = 0.25;
			map.options.zoomDelta = 0.25;
			map.options.wheelPxPerZoomLevel = 170;
		}, [map, megapixelsGeoJson]
	);
	const resetZoom = () => {
		map.fitBounds(boundsLayer.getBounds());
	};

	const downloadData = () => {
		const x = megapixelsGeoJson;
		const url = window.URL.createObjectURL(
			// new Blob([JSON.stringify(dataSourcesMockData), JSON.stringify(methodsMockData), JSON.stringify(reportsMockData)], { type: 'text/json' })
			new Blob([JSON.stringify(x)], { type: 'text/json' })
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			'data.txt'
		);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};

	const renderLegend = () => {
		let colors;
		switch (country.id) {
		case 'SEN': colors = nineColorPallete; break;
		case 'ZWE': colors = nineColorPallete; break;
		case 'SDN': colors = nineColorPallete; break;
		default: colors = twelveColorPallete; break;
		}
		const squares = [];
		let index = 0;
		while (index != colors.length - 1) {
			squares.push(<div className="square-size" style={{ background: colors[index] }} />);
			index += 3;
			if (index > colors.length - 1) {
				index -= (colors.length - 1);
			}
		}
		squares.push(<div className="square-size" style={{ background: colors[colors.length - 1] }} />);
		return squares;
	};
	return (
		<div className="map-component">
			<div className="warning">
				<i className="fa-regular fa-circle-info" />
				<p className="open-sans"><Translator text="Select a hotspot to view more details and compare with similar hotspots" /></p>
			</div>
			<MapContainer scrollWheelZoom ref={mapRef}>
				<TileLayer
					attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
					url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}"
				/>
				<div className="reset-zoom" role="button" tabIndex="0" aria-label="reset-zoom" onClick={resetZoom}>
					<i className="fa-duotone fa-house" />
				</div>
			</MapContainer>
			{!closeLegend
				? (
					<div className="map-legend">
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
							{/* <h2>Legend</h2> */}
							{/* <Button onClick={() => setCloseLegend(true)}>	<i className="fa-solid fa-xmark" /></Button> */}
							<div style={{ display: 'flex', cursor: 'pointer' }} role="button" tabIndex="0" aria-label="close" onClick={() => setCloseLegend(true)}>
								<i className="fa-solid fa-xmark" />
							</div>
						</div>
						<div className="square-presentation">
							<div className="square" />
							<p>20 km<sup>2</sup></p>
						</div>
						<div className="color-pallete-wrap">
							<p><Translator text="Limited Conflict" /> </p>
							<div>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									{renderLegend()}

								</div>
								<p style={{ marginTop: '10px' }}><Translator text="Different conditions of climate" /></p>
							</div>
							<p><Translator text="High Conflict" /> </p>
						</div>
						<div className="download-button">
							<Button label={<Translator text="Download Data" />} icon="fa-regular fa-down-to-bracket" onClick={downloadData} />
						</div>
					</div>
				)
				: (
					<div className="open-legend-btn" role="button" tabIndex="0" aria-label="info" onClick={() => setCloseLegend(false)}>
						<i className="fa-light fa-circle-info" />
					</div>

				)

			}
		</div>
	);
};
export default Map;
