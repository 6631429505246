/* eslint-disable react/jsx-no-useless-fragment */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { backgroundColors as defaultColorsKenya } from '../../../../../../../../../../../../utils/palleteKenya';
import { backgroundColors as defaultColorsSenegal } from '../../../../../../../../../../../../utils/palleteSenegal';
import { backgroundColors as defaultColorsZimbabwe } from '../../../../../../../../../../../../utils/palleteZimbabwe';
import { backgroundColors as defaultColorsUganda } from '../../../../../../../../../../../../utils/palleteUganda';
import { backgroundColors as defaultColorsNigeria } from '../../../../../../../../../../../../utils/palleteNigeria';
import { backgroundColors as defaultColorsGuatemala } from '../../../../../../../../../../../../utils/palleteGuatemala';
import { backgroundColors as defaultColorsMali } from '../../../../../../../../../../../../utils/palleteMali';
import {
	backgroundColors as defaultColorsPhilippines,
} from '../../../../../../../../../../../../utils/palletePhilipines';
import { backgroundColors as defaultColorsZambia } from '../../../../../../../../../../../../utils/palleteZambia';
import { backgroundColors as defaultColorsEthiopia } from '../../../../../../../../../../../../utils/palleteEthiopia';
import { backgroundColors as defaultColorsSudan } from '../../../../../../../../../../../../utils/palleteSudan';
import Translator from '../../../../../../../../../../../../components/Translator';
import './styles.css';

const Scatter = (props) => {
	const { data, id, xLabel, yLabel } = props;

	const country = useSelector((state) => state.country);

	const defaultColors = (filters) => {
		switch (country.id) {
		case 'KEN': return defaultColorsKenya(filters).backgroundColor;
		case 'SEN': return defaultColorsSenegal(filters).backgroundColor;
		case 'ZWE': return defaultColorsZimbabwe(filters).backgroundColor;
		case 'UGA': return defaultColorsUganda(filters).backgroundColor;
		case 'NGA': return defaultColorsNigeria(filters).backgroundColor;
		case 'GTM': return defaultColorsGuatemala(filters).backgroundColor;
		case 'MLI': return defaultColorsMali(filters).backgroundColor;
		case 'PHL': return defaultColorsPhilippines(filters).backgroundColor;
		case 'ZMB': return defaultColorsZambia(filters).backgroundColor;
		case 'ETH': return defaultColorsEthiopia(filters).backgroundColor;
		case 'SDN': return defaultColorsSudan(filters).backgroundColor;
		default: return defaultColorsKenya(filters).backgroundColor;
		}
	};

	useEffect(() => {
		const root = am5.Root.new(id);

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: true,
			panY: true,
			wheelY: 'zoomXY',
			pinchZoomX: true,
			pinchZoomY: true,
			layout: root.verticalLayout,
		}));

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererX.new(root, {}),
			tooltip: am5.Tooltip.new(root, {}),
		}));

		xAxis.children.moveValue(am5.Label.new(root, {
			// text: xLabel,
			x: am5.p50,
			centerX: am5.p50,
		}), xAxis.children.length - 1);

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererY.new(root, {
				inversed: false,
			}),
			tooltip: am5.Tooltip.new(root, {}),
		}));

		yAxis.children.moveValue(am5.Label.new(root, {
			rotation: -90,
			// text: yLabel,
			y: am5.p50,
			centerX: am5.p50,
		}), 0);

		// Create series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
		data.map((item) => {
			const series = chart.series.push(am5xy.LineSeries.new(root, {
				calculateAggregates: true,
				xAxis,
				yAxis,
				valueYField: 'y',
				valueXField: 'x',
				fill: defaultColors(item.category),
				legendLabelText: item.category,
				// legendRangeLabelText: "Series: {name}"
				seriesTooltipTarget: 'bullet',
				// tooltip: am5.Tooltip.new(root, {
				// 	pointerOrientation: 'horizontal',
				// 	labelText: '[bold]{title}[/]\nLife expectancy: {valueY.formatNumber(\'#.0\')}\nGDP: {valueX.formatNumber(\'#,###.\')}\nPopulation: {value.formatNumber(\'#,###.\')}',
				// }),
			}));

			series.strokes.template.set('visible', false);

			// Add bullet
			// https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
			const circleTemplate = am5.Template.new({});

			series.bullets.push(() => {
				const bulletCircle = am5.Circle.new(root, {
					radius: 5,
					fill: series.get('fill'),
					fillOpacity: 0.8,
				}, circleTemplate);
				return am5.Bullet.new(root, {
					sprite: bulletCircle,
				});
			});

			// Add heat rule
			// https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
			series.set('heatRules', [{
				target: circleTemplate,
				min: 3,
				max: 60,
				dataField: 'value',
				key: 'radius',
			}]);

			// Set data
			// https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Setting_data
			series.data.setAll(item.data);

			// chart.set('cursor', am5xy.XYCursor.new(root, {
			// 	xAxis,
			// 	yAxis,
			// 	snapToSeries: [series],
			// }));

			series.appear(1000);
		});

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/

		// Add scrollbars
		// https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
		chart.set('scrollbarX', am5.Scrollbar.new(root, {
			orientation: 'horizontal',
		}));

		chart.set('scrollbarY', am5.Scrollbar.new(root, {
			orientation: 'vertical',
		}));

		const legend = chart.children.push(am5.Legend.new(root, {
			width: 200,
			paddingLeft: 15,
			height: am5.percent(100),
		}));

		// When legend item container is hovered, dim all the series except the hovered one
		legend.itemContainers.template.events.on('pointerover', (e) => {
			const itemContainer = e.target;

			// As series list is data of a legend, dataContext is series
			const series = itemContainer.dataItem.dataContext;

			chart.series.each((chartSeries) => {
				if (chartSeries != series) {
					chartSeries.strokes.template.setAll({
						strokeOpacity: 0.15,
						stroke: am5.color(0x000000),
					});
				} else {
					chartSeries.strokes.template.setAll({
						strokeWidth: 3,
					});
				}
			});
		});

		// When legend item container is unhovered, make all series as they are
		legend.itemContainers.template.events.on('pointerout', (e) => {
			const itemContainer = e.target;
			const series = itemContainer.dataItem.dataContext;

			chart.series.each((chartSeries) => {
				chartSeries.strokes.template.setAll({
					strokeOpacity: 1,
					strokeWidth: 1,
					stroke: chartSeries.get('fill'),
				});
			});
		});

		legend.itemContainers.template.set('width', am5.p100);
		legend.valueLabels.template.setAll({
			width: am5.p100,
			textAlign: 'right',
		});

		// It's is important to set legend data after all the events are set on template, otherwise events won't be copied
		legend.data.setAll(chart.series.values);

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);

		return () => root.dispose();
	}, [data]);

	return (
		<div id={id} style={{ width: '100%', height: '1000px' }}>
			<p className="rotate-xlabel-cimate"><Translator text={xLabel} /></p>
			<p className="rotate-ylabel-cimate"><Translator text={yLabel} /></p>
		</div>
	);
};
export default Scatter;
