/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Graph, MockGraph } from './components';
// import networkAnalysis from '../../../../../../../../assets/images/CountryInformationPage/How/Dialog/networkAnalysis.png';
import networkAnalysis from '../../../../../../../../assets/images/CountryInformationPage/How/na_big.jpg';
import CountryService from '../../../../../../../../services/httpService/countryService';
import LearnMoreDialogContent from '../../../../../SideBar/components/LearnMoreDialogContent';
import dataSource from './dataSource.json';
import './styles.css';
import Translator from '../../../../../../../../components/Translator';

const NetworkModelContent = () => {
	const country = useSelector((state) => state.country);

	const [toggleContent, setToggleContent] = useState(false);
	const [graphData, setGraphData] = useState(null);
	const [displayDialog, setDisplayDialog] = useState(false);

	useEffect(
		() => {
			CountryService.getGraphData(country.id)
				.then((res) => {
					setGraphData(res.graph_data);
				});
		}, []
	);

	const renderContent = () => {
		if (toggleContent) {
			// if (graphData) {
			// return (
			// 	<Graph na={graphData} />
			// );
			// }
			if (graphData) {
				return (
					<Graph data={graphData} />
				// 	<MockGraph data={graphData} />
				);
			}
			return null;
		}

		const getReportsByCountry = () => {
			if (window.location.href.includes('Kenya')) {
				return dataSource.kenyaReports;
			}
			if (window.location.href.includes('Mali')) {
				return dataSource.maliReports;
			}
			if (window.location.href.includes('Nigeria')) {
				return dataSource.nigeriaReports;
			}
			if (window.location.href.includes('Senegal')) {
				return dataSource.senegalReports;
			}
			if (window.location.href.includes('Sudan')) {
				return dataSource.sudanReports;
			}
			if (window.location.href.includes('Uganda')) {
				return dataSource.ugandaReports;
			}
			if (window.location.href.includes('Zimbabwe')) {
				return dataSource.zimbabweReports;
			}
		};

		return (
			<>
				<img src={networkAnalysis} width="100%" />
				<h3><Translator text="Network Analysis" /></h3>
				<Button className="open-tool-button" label={<Translator text="Open Tool" />} onClick={() => setToggleContent(true)} />
				<div className="text-container">
					<p className="open-sans">
						<Translator text="A range of tools for network analysis are used to identify the drivers behind the dynamic
						relationships that exist within the Climate-Security Nexus, enabling a global view of the
						underlying structure of the climate, conflict, and socio-economic system. Analyzing causal
						and critical path linkages is part of the application of graph theory, which focuses on
						describing the relationships between people, events and objects. Link analysis offers further
						insight into the principle linkages between climate security drivers that are not otherwise
						visible from looking at information considered in isolation and out of context. These links
						are quantifiable from which a determination can be made of which factors are likely the most
						crucial in determining the relative strength and responsiveness of the network elements that
						make up the Climate-Security Nexus in a given location. The most important drivers identified
						through this process are then used for the geographical mapping of likely conflict hotspots,
						as well as determining appropriate mitigation measures."
						/>
					</p>
				</div>
				<div className="learn-more-wrap">
					<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
					<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
						<LearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} dataReports={getReportsByCountry()} />
					</Dialog>
				</div>
			</>
		);
	};

	return (
		<div className="dialog-content">
			{renderContent()}
		</div>
	);
};

export default NetworkModelContent;
