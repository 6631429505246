const L = {
	English: {
		MenuBarOptionsHome: 'Home',
		MenuBarOptionsAbout: 'About',
		// MenuBarOptionsAbout: 'Index',
		MenuBarOptionsResources: 'Resources',
		MenuBarAboutSubMenuPlatform: 'The Platform',
		MenuBarAboutSubMenuTeam: 'The Team',
		MenuBarAboutSubMenuApproach: 'The Index',

		FooterPrivacy: 'Privacy',
		FooterTerms: 'Terms',

		LandingPageHowQuestionBubbleText: 'HOW does climate worsen the root causes of conflict?',
		LandingPageWhereQuestionBubbleText: 'where are the most vulnerable areas to climate induced insecurities and risks?',
		LandingPageWhoQuestionBubbleText: 'WHO are the vulnerable groups to climate and security risks that should be targeted?',
		LandingPageWhatQuestionBubbleText: 'WHAT needs to be done to break the cycle between climate and conflict?',
		LandingPageTitle: 'Climate Security Observatory',
		LandingPageSubtitle: 'ACTIONABLE EVIDENCE for a climate security sensitive agenda',
		LandingPageSwiperFirstSlideText: 'The Climate Security Observatory is a decision support tool helping stakeholders working in the field of climate, peace and security to understand and respond to climate-related security risks.',
		LandingPageSwiperSecondSlideTextA: 'WHY IS IT IMPORTANT?',
		LandingPageSwiperSecondSlideTextB: 'Researchers and policy makers agree that climate variability might indirectly affect conflict and peace. The assumption is that climate variability amplifies existing risks and insecurities, which then can increase grievances and tensions and ultimately affect peace and security in a specific context area.',
		LandingPageSwiperThirdSlideTextListIntro: 'The Climate Security Nexus describes the complex relationships and interactions across 5 different systems:',
		LandingPageSwiperThirdSlideTextListItemA: 'Climate and Environmental system',
		LandingPageSwiperThirdSlideTextListItemB: 'Food and agricultural system',
		LandingPageSwiperThirdSlideTextListItemC: 'Economic system',
		LandingPageSwiperThirdSlideTextListItemD: 'Socio-economic system',
		LandingPageSwiperThirdSlideTextListItemE: 'Political and institutional system',
		LandingPageSwiperFourthSlideText: 'Climate acting as a threat multiplier is a widely recognized concept, but still there is a lack of robust, localized and contextualized and policy-relevant evidence on the climate security-nexus. The CSO aims to contribute filling this gap.',

		HomePageDropdownHeading: 'Country',
		HomePageDropdownPlaceholder: 'Please Select',
		HomePageClimateSecurityDialoguesCardTitle: 'Climate Security Dialogues',
		HomePageClimateSecurityDialoguesCardText: 'Interactive dashboard to monitor climate security debates in digital spaces.',
		HomePageClimateSecurityDialoguesButtonLabel: 'Read More',
		HomePageClimateSecurityDialogButtonLabel: 'Open Tool',
		HomePageClimateSecurityDialogInfoTextA: 'The Climate Security Dialogues dashboard is an interactive tool that collects and analyses online data to display trends about climate security debates in digital spaces. It aims to monitor conversations about climate security to identify the most prevalent and/or relevant topics, actors, places, and events related to these discussions.',
		HomePageClimateSecurityDialogInfoTextB: 'Currently, the tool features insights from a social media pilot module with historical data from Twitter (currently X). Drawing on innovative research approaches that leverage social media platforms as proxies for wider public discourse and engagement, and considering the historical significance of Twitter as an important digital forum for information exchanges, social media analysis is based on climate security content generated on the platform between January 2014 and May 2023 (when access to the Twitter API for research purposes was terminated).',
		HomePageClimateSecurityDialogInfoTextC: 'The tool collected public posts that contained hashtags related to climate security using a snowball scraping technique. Analysis available includes an overview of volume, content, actors, and sentiment; insights related to post engagement; and analysis focused on hashtags as topics aggregators.',
		HomePageClimateSecurityDialogInfoTextD: 'A news media module is under development.',
		HomePageTwitterCardTitle: '#ClimateSecurity Stream',
		HomePageTwitterCardSubtitle: 'Live stream of tweets tagging #ClimateSecurity',

	},
	Greek: {
		MenuBarOptionsHome: 'Σπίτι',
		MenuBarOptionsAbout: 'Σχετικά',
		MenuBarOptionsResources: 'Πηγές',
		MenuBarOptionsContact: 'Επικοινωνία',

		MenuBarAboutSubMenuPlatform: 'Σχετικά με την πλατφόρμα',
		MenuBarAboutSubMenuHow: 'Πως να χρησιμοποιήσετε',
		MenuBarAboutSubMenuMethodology: 'Μεθοδολογία',
		MenuBarAboutSubMenuPartners: 'Συενργάτες',
	},
};

export default L;
