const Actions = {
	SetLanguage: 'SetLanguage',
	SetCurrentPage: 'SetCurrentPage',
	SetDarkMode: 'SetDarkMode',
	SetAboutPage: 'SetAboutPage',
	SetCountry: 'SetCountry',
	SetTab: 'SetTab',
	SetWhoSelectedFeature: 'SetWhoSelectedFeature',
	SetWhereSubPageSelection: 'SetWhereSubPageSelection',
	SetWhereFilterSelections: 'SetWhereFilterSelections',
	SetWhereSelectedFeature: 'SetWhereSelectedFeature',
	SetContextSubPageSelection: 'SetContextSubPageSelection',
	SetContextFilterSelections: 'SetContextFilterSelections',
	SetMegapixelsGeoJson: 'SetMegapixelsGeoJson',
	SetToggled: 'SetToggled',
	SetShowMenus: 'SetShowMenus',
	SetLivestockSelected: 'SetLivestockSelected',
	SetGeneralMappingSelected: 'SetGeneralMappingSelected',
	SetRouteClass: 'SetRouteClass',
	SetWhereSwitchValues: 'SetWhereSwitchValues',
	SetConflictLayersClass: 'SetConflictLayersClass',
	SetShowAcledMap: 'SetShowAcledMap',
	SetMinDate: 'SetMinDate',
	SetMaxDate: 'SetMaxDate',
	SetTileLayersClass: 'SetTileLayersClass',
	SetLivestockDataNdvi: 'SetLivestockDataNdvi',
	SetLivestockDataRainfall: 'livestockDataRainfall',
	SetTileLayersRainfallClass: 'SetTileLayersRainfallClass',
	SetSliderValue: 'SetSliderValue',
};

export default Actions;
