import React, { useEffect } from 'react';

const ComingSoon = (props) => {
	const { comingSoonContent } = props;

	return (
		<div style={{ color: '#FDC82F', padding: '40px', display: 'flex', justifyContent: 'center', width: '100%' }}>
			<h2>{comingSoonContent}</h2>
		</div>
	);
};

export default ComingSoon;
