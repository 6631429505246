const pallete = [
	'#c0e6bc',
	'#41b6c4',
	'#2a66ac',
	'#81ceb9',
	'#3391bc',
	'#253494',
];

export default pallete;
