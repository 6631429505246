import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import Translator from '../../../../components/Translator';

const MethodsDialogContent = (props) => {
	const { data } = props;

	const rowGroupHeaderTemplate = (d) => {
		const renderQuestion = () => {
			switch (d['Lead question']) {
			case 'How': return <p><span className="category"><Translator text="How" /></span> <Translator text="does climate worsen the root causes of conflict?" /></p>;
			case 'Where': return <p><span className="category"><Translator text="Where" /></span> <Translator text="are the most vulnerable areas to climate induced insecurities and risks?" /></p>;
			case 'Who': return <p><span className="category"><Translator text="Who" /></span> <Translator text="are the vulnerable groups to climate and security risks that should be targeted?" /></p>;
			case 'What': return <p><span className="category"><Translator text="What" /></span> <Translator text="needs to be done to break the vicious cycle between climate and conflict?" /></p>;
			default: return null;
			}
		};
		return (
			<div className="row-group-header">
				{renderQuestion()}
			</div>
		);
	};

	const urlTemplate = (url) => {
		return (
			(url.url
				? (
					<>
						<a href={url.url} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-regular fa-file-pdf" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a><br /><br />
					</>
				) : null
			)
		);
	};

	const titleTemplate = (item) => {
		return (
			<p><Translator text={item.Title} /></p>
		);
	};

	return (
		<div className="resources-dialog-content">
			<p className="title"><Translator text="Methods" /></p>
			<DataTable value={data} responsiveLayout="scroll" stripedRows rowGroupMode="subheader" groupRowsBy="Lead question" rowGroupHeaderTemplate={rowGroupHeaderTemplate}>
				<Column body={titleTemplate} header={<Translator text="Title" />} />
				<Column field="Year" header={<Translator text="Year" />} />
				<Column field="url" header={<Translator text="Url" />} body={urlTemplate} />
			</DataTable>
		</div>
	);
};

export default MethodsDialogContent;
