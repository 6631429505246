import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Actions from '../../reducer/actions';
import { Settings, Buttons, LangSelector } from './components';
import CsoLogo from '../../assets/images/MenuBar/CSOLogo.svg';
import './styles.css';

const MenuBar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const currentPage = useSelector((state) => state.currentPage);
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	const [left, setLeft] = useState(null);
	const [bottom, setBottom] = useState(null);
	const [width, setWidth] = useState(0);
	const [languageValue, setLanguageValue] = useState('English');

	// useEffect(
	// 	() => {
	// 		// setLanguage(L[languageValue]);
	// 		setCurrentPage('');
	// 	}, [languageValue]
	// );

	useEffect(
		() => {
			setTimeout(
				() => {
					if (document.getElementById('countryProfiles')) {
						switch (currentPage) {
						case 'CountryProfiles':
							moveBottomBar('countryProfiles', false);
							break;
						case 'GlobalTools':
							moveBottomBar('globalTools', false);
							break;
						case 'About':
							moveBottomBar('about', false);
							break;
						case 'Platform':
							moveBottomBar('about', false);
							break;
						case 'Approach':
							moveBottomBar('about', false);
							break;
						case 'Team':
							moveBottomBar('about', false);
							break;
						case 'Resources':
							moveBottomBar('resources', false);
							break;
						case 'Contact':
							moveBottomBar('contact', false);
							break;
						default:
							moveBottomBar('countryProfiles', true);
							break;
						}
					}
				}, 100
			);
		}, [currentPage]
	);

	const moveBottomBar = (id, flag) => {
		const element = document.getElementById(id);
		const rect = element.getBoundingClientRect();
		setLeft(rect.left);
		setBottom(rect.bottom);
		if (flag) {
			setWidth(0);
		} else {
			setWidth(rect.right - rect.left);
		}
	};

	// if (showRegistrationForm) {
	// 	return <PopUp />;
	// }
	return (
		<div className="menu" id="mode">
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<img
				src={CsoLogo}
				alt="logo"
				onClick={() => {
					setCurrentPage('LandingPage');
					navigate('/LandingPage');
				}}
			/>
			<Buttons left={left} width={width} bottom={bottom} />
			<div className="divider" />
			<div className="settings" tabIndex="0" role="button" aria-label="settings">
				<i className="fa-duotone fa-gear" />
				<div className="options" id="mode">
					<LangSelector
						value={languageValue}
						options={['English']}
						onChange={(e) => {
							setLanguageValue(e.value);
							document.getElementById('mode').blur();
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default MenuBar;
