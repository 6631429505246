/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../Card';
import {
	NetworkModelContent,
	EconometricModeling,
	ConceptualModeling,
	SummaryDiagram,
} from './components';
import VoicesFromTheField from '../VoicesFromTheField';
// import conceptualModeling from '../../../../../../assets/images/CountryInformationPage/How/conceptualModeling.png';
import voicesImage from '../../../../../../assets/images/CountryInformationPage/How/voices.png';
import conceptualModeling from '../../../../../../assets/images/CountryInformationPage/How/cm.png';
import networkAnalysis from '../../../../../../assets/images/CountryInformationPage/How/networkAnalysis.png';
import econometricModeling from '../../../../../../assets/images/CountryInformationPage/How/econometricModeling.png';
import voicesFromTheField from '../../../../../../assets/images/CountryInformationPage/How/voicesFromTheField.png';
import noImage from '../../../../../../assets/images/CountryInformationPage/How/no_image.png';
import summaryKenya from '../../../../../../assets/images/CountryInformationPage/How/KEN_GRAPH.png';
import imgSummaryKenya from '../../../../../../assets/images/CountryInformationPage/How/Summary_KEN.jpg';
import summarySenegal from '../../../../../../assets/images/CountryInformationPage/How/SEN_GRAPH.png';
import summaryGuatemala from '../../../../../../assets/images/CountryInformationPage/How/GTM_GRAPH.png';
import summaryZambia from '../../../../../../assets/images/CountryInformationPage/How/CP_zambia_Graph.png';
import Guatemala from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Guatemala.jpg';
import Kenya from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Kenya.jpg';
import Senegal from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Senegal.jpg';
import './styles.css';
import Actions from '../../../../../../reducer/actions';
import Translator from '../../../../../../components/Translator';
import useCustomTranslate from '../../../../../../utils/translateFunction';
import ComingSoon from '../../../../../../components/ComingSoon';

const How = () => {
	const country = useSelector((state) => state.country);
	const dispatch = useDispatch();
	const toggled = useSelector((state) => state.toggled);
	const setToggled = (payload) => dispatch({ type: Actions.SetToggled, payload });
	const [displayCard, setDisplayCard] = useState('');

	const renderContent = () => {
		switch (displayCard) {
		case 'Climate security pathways': return <ConceptualModeling />;
		case 'Underlying structures of the climate security nexus': return <NetworkModelContent />;
		case 'Food insecurity within the climate security nexus': return <EconometricModeling />;
		case 'Community voices': return <VoicesFromTheField textProperty="howText" />;
		case 'Integrated summary': return <SummaryDiagram />;
		default: return (
			<>
			</>
		);
		}
	};
	const showSummaryCard = () => {
		if (window.location.href.includes('Kenya')) {
			return (
				<Card image={summaryKenya} setDisplayCard={setDisplayCard} title="Integrated summary" subtitle={<Translator text="exploring mixed-method approach" />} text={<Translator text="Exploring relationships between drivers of the Climate Security Nexus identified through 4 qualitative and quantitative analyses." />}>
					<SummaryDiagram />
				</Card>
			);
		}
		if (window.location.href.includes('Philippines')) {
			return (
				<Card image={noImage} setDisplayCard={setDisplayCard} title="Integrated summary" subtitle={<Translator text="exploring mixed-method approach" />} text={<Translator text="Exploring relationships between drivers of the Climate Security Nexus identified through 4 qualitative and quantitative analyses." />}>
					<SummaryDiagram />
				</Card>
			);
		}
		if (window.location.href.includes('Senegal')) {
			return (
				<Card image={summarySenegal} setDisplayCard={setDisplayCard} title="Integrated summary" subtitle={<Translator text="exploring mixed-method approach" />} text={<Translator text="Exploring relationships between drivers of the Climate Security Nexus identified through 4 qualitative and quantitative analyses." />}>
					<SummaryDiagram />
				</Card>
			);
		}
		if (window.location.href.includes('Guatemala')) {
			return (
				<Card image={summaryGuatemala} setDisplayCard={setDisplayCard} title="Integrated summary" subtitle={<Translator text="exploring mixed-method approach" />} text={<Translator text="Exploring relationships between drivers of the Climate Security Nexus identified through 4 qualitative and quantitative analyses." />}>
					<SummaryDiagram />
				</Card>
			);
		}

		if (window.location.href.includes('Zambia')) {
			return (
				<Card image={summaryZambia} setDisplayCard={setDisplayCard} title="Integrated summary" subtitle={<Translator text="exploring mixed-method approach" />} text={<Translator text="Exploring relationships between drivers of the Climate Security Nexus identified through 4 qualitative and quantitative analyses." />}>
					<SummaryDiagram />
				</Card>
			);
		}
	};

	const renderVoicesFromTheField = () => {
		switch (country.name) {
		case 'Kenya': return true;
		case 'Senegal': return true;
		case 'Guatemala': return true;
		case 'Zambia': return true;
		default: return false;
		}
	};

	const assignVoicesImage = () => {
		switch (country.name) {
		case 'Kenya': return Kenya;
		case 'Senegal': return Senegal;
		case 'Guatemala': return Guatemala;
		default: return false;
		}
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{ country.id === 'BFA' || country.id === 'NER' ? (

				<ComingSoon comingSoonContent={'Coming Soon!'} />

			) : (

				<div className="how">

					{displayCard === '' ? (
						<div className="how-info">
							<div className="how-cards p-grid p-justify-center">
								{showSummaryCard()}
								{renderVoicesFromTheField()
									? 	(
										<Card image={voicesImage} setDisplayCard={setDisplayCard} title="Community voices" subtitle={<Translator text="hearing from those affected" />} text={<Translator text="Engaging community members as citizen scientists and experts to understand the various vulnerabilities that affect them and to propose needed changes for the better." />}>
											<VoicesFromTheField />
										</Card>
									)
									: null}
								<Card image={conceptualModeling} setDisplayCard={setDisplayCard} title="Climate security pathways" subtitle={<Translator text="A conceptual perspective" />} text={<Translator text="Identifying climate-related drivers of conflict by constructing an evidence-based model of critical pathways, relationships and system vulnerabilities." />}>
									<ConceptualModeling />
								</Card>
								<Card image={networkAnalysis} setDisplayCard={setDisplayCard} title="Underlying structures of the climate security nexus" subtitle={<Translator text="untangling complex relationships" />} text={<Translator text="Using graph theory and other tools for network analysis to identify and quantify the critical drivers behind the climate-conflict nexus." />}>
									<NetworkModelContent />
								</Card>
								<Card image={econometricModeling} setDisplayCard={setDisplayCard} title="Food insecurity within the climate security nexus" subtitle={<Translator text="quantifying causal mechanisms" />} text={<Translator text="Determining the mechanisms driving conflict risk within climate-vulnerable food, land and water systems through the application of statistical analysis and modeling." />}>
									<EconometricModeling />
								</Card>
							</div>
						</div>

					)
						: (
							<div className="how-content">
								<div className="go-back-btn">
									<Button
										onClick={() => {
											setToggled(true);
											setDisplayCard('');
										}}
										style={{ padding: '10px' }}
									><Translator text="Go Back" />
									</Button>
								</div>
								{renderContent()}
							</div>
						)

					}

				</div>

			) }

		</>

	);
};

export default How;
