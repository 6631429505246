/* eslint-disable consistent-return */
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import CheckBoxes from '../CheckBoxes';
import Comments from '../Comments';
import './styles.css';
import Translator from '../../../../../Translator';

const BuildingCapacity = (props) => {
	const { userInput, setUserInput } = props;
	const data = [
		{ submechanism: '6.1 Increase livelihood climate coping capacity', category: '6. Building capacity and resilience', id: '6.1', tooltipId: '1' },
		// { submechanism: '6.2 Increase livelihood climate adaptation capacity', category: '6. Building capacity and resilience' },
		{ submechanism: '6.2a Increase adaptation capacity of social systems', category: '6. Building capacity and resilience', id: '6.2a', tooltipId: '2a' },
		{ submechanism: '6.2b Increase adaptation capacity of production systems', category: '6. Building capacity and resilience', id: '6.2b', tooltipId: '2b' },
		{ submechanism: '6.3 Increase livelihood climate transformative capacity', category: '6. Building capacity and resilience', id: '6.3', tooltipId: '3' },
	];

	const infoTemplate = (rowData) => {
		// const id = rowData.submechanism.slice(2, 3);
		// console.log(rowData.submechanism);
		const description = () => {
			switch (rowData.tooltipId) {
			case '1': return 'Secure food production: provision of necessary inputs, irrigation sources, climate information \n' +
					'Diversify income and livelihood: spread farm operations, mixed-systems approach, analyze market value chains to address bottlenecks and identify opportunities for added value \n' +
					'Restore degraded infrastructures: sustain/introduce irrigation systems, mechanization technologies';
			// case '2': return 'Introduce intercommunal infrastructures: develop shared collecting/ storing/ processing/ transporting facilities for produce\n' +
			// 		'Facilitate access to intercommunal resources: extend fallow areas/pastures';
			case '2a': return 'Strengthen social capital (networks and connections) and financial capital to expand capacities to grow production systems \n' +
					'(e.g.: financial services, credits), increase land-tenure security, address instrumental needs of communities';
			case '2b': return 'Facilitate the adoption of adapted crops/ cultivars & animal types/ breeds, improve crop residue management, integrated nutrient management, provision of post-harvest  \n' +
				' storage and water harvesting structures, mixed production systems, increase access to collection, refrigeration, processing and transportation infrastructures';
			case '3': return 'Bolster equitable and efficient delivery of public services: monitor funds allocation, increase availability of extension services\n' +
					'Increase government revenues from natural resource management: increase available resources for the provision of public goods and services, foster foreign investment';
			default: return '';
			}
		};
		return (
			<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'center' }}>
				<Tooltip target={`.info-icon-${rowData.tooltipId}`} content={description()} position="left" />
				<i className={`info-icon-${rowData.tooltipId} fa-light fa-circle-info info-icon`} />
			</div>
		);
	};

	const notesTemplate = (rowData) => {
		return (
			<Comments rowData={rowData} setUserInput={setUserInput} userInput={userInput} />
		);
	};

	const presentTemplate = (rowData) => {
		return (
			<CheckBoxes rowData={rowData} setUserInput={setUserInput} userInput={userInput} />
		);
	};

	const rowClassName = (rowData) => {
		return { 'p-highlight': (rowData.id === '6.2a' || rowData.id === '6.2b') };
	};

	const submechanismTemplate = (submData) => {
		return (
			<p><Translator text={submData.submechanism} /></p>
		);
	};
	
	return (
		<div className="step">
			<div className="header">
				<p className="sub-title">
					<Translator text="Step 2 - Assess to which Climate-Peace Mechanisms  your proposed intervention is contributing to" />
				</p>
				<p className="open-sans">
					<Translator text="This step assesses a set of climate-peace mechanisms and indicators." />
					<br /><br />
					<Translator text="For each of mechanism and examples of practices for that mechanism (indicators), indicate if the listed criteria are fulfilled (=1), somehow fulfilled (=0.5) or not fulfilled (=0) and add a note describing how your program may fulfil the category." />
				</p>
			</div>
			<p className="open-sans"><Translator text="6. Building capacity and resilience" /></p>
			<DataTable
				value={data}
				responsiveLayout="scroll"
				// stripedRows
				rowClassName={rowClassName}
			>
				<Column field="submechanism" body={submechanismTemplate} header={<Translator text="Submechanism" />} />
				<Column field="info" header={<Translator text="Info" />} body={infoTemplate} />
				<Column field="notes" header={<Translator text="Notes" />} body={notesTemplate} />
				<Column field="present" header={<Translator text="Present" />} body={presentTemplate} />
			</DataTable>
		</div>
	);
};

export default BuildingCapacity;
