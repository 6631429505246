import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Scatter } from './components';
import CountryService from '../../../../../../../../../../services/httpService/countryService';
import './styles.css';
import Translator from '../../../../../../../../../../components/Translator';

const Climate = () => {
	const whereFiltersSelections = useSelector((state) => state.whereFiltersSelections);

	const country = useSelector((state) => state.country);

	const [data, setData] = useState(null);
	const [chartData, setChartData] = useState(null);

	useEffect(
		() => {
			CountryService.getScatterGraphData(country.id)
				.then((res) => {
					setData(res.graph_info);
				});
		}, [country]
	);

	useEffect(
		() => {
			if (data) {
				const temp = data.map((it) => {
					const categoryData = it.data.map((item) => {
						const x = item.find((variable) => variable.label === whereFiltersSelections.xAxis.label);
						const y = item.find((variable) => variable.label === whereFiltersSelections.yAxis.label);
						if (x && y) {
							return { x: x.value, y: y.value };
						}
						return { x: [], y: [] };
					});
					return { category: it.category, data: categoryData };
				});
				setChartData(temp);
			}
		}, [data, whereFiltersSelections]
	);

	return (
		<div className="climate">
			<p><Translator text="Scatter showing Daily rainfall variability versus Number of days with ratio of actual to potential evaporation / transpiration below 0.5" /></p>
			{chartData ? <Scatter data={chartData} id={2} xLabel={whereFiltersSelections.xAxis.label} yLabel={whereFiltersSelections.yAxis.label} /> : null}
		</div>
	);
};

export default Climate;
