/* eslint-disable no-unused-expressions,consistent-return */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import { sortArrayOfObjectsByPropertyValue } from '../../../../../../utils/functions';
import './styles.css';
import Translator from '../../../../../Translator';
import CountryService from '../../../../../../services/httpService/countryService';

const CountryAndRegion = (props) => {
	const { userInput, setUserInput, csstData } = props;

	const country = useSelector((state) => state.country);

	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedRegion, setSelectedRegion] = useState(userInput.region);
	const [selectedMunicipality, setSelectedMunicipality] = useState(userInput.municipality);
	const [municipalityOptions, setMunicipalityOptions] = useState(null);
	const [fullCountries, setFullCountries] = useState([]);

	const countries = [
		{ id: 'KEN', name: 'Kenya', iso2: 'KE' },
		{ id: 'SEN', name: 'Senegal' },
		{ id: 'ZWE', name: 'Zimbabwe' },
		{ id: 'UGA', name: 'Uganda' },
		{ id: 'NGA', name: 'Nigeria', iso2: 'NG' },
		{ id: 'GTM', name: 'Guatemala', iso2: 'GT' },
		{ id: 'MLI', name: 'Mali', iso2: 'ML' },
		{ id: 'PHL', name: 'Philippines', iso2: 'PH' },
		{ id: 'ZMB', name: 'Zambia', iso2: 'ZM' },
		{ id: 'ETH', name: 'Ethiopia', iso2: 'ET' },
		{ id: 'SDN', name: 'Sudan', iso2: 'SD' },
	];

	useEffect(
		() => {
			CountryService.getListOfCountries()
				.then((res) => {
					setFullCountries(res);
					console.log(fullCountries);
				});
		}, []
	);

	useEffect(
		() => {
			const newInput = { ...userInput };
			newInput.country = selectedCountry;
			newInput.region = '';
			newInput.municipality = '';
			setMunicipalityOptions(null);
			setUserInput(newInput);
		}, [selectedCountry]
	);

	useEffect(
		() => {
			const newInput = { ...userInput };
			newInput.region = selectedRegion;
			newInput.municipality = '';
			setUserInput(newInput);
			if (userInput.country) {
				if (userInput.country.id === 'GTM' || userInput.country.id === 'NER') {
					if (selectedRegion) {
						setMunicipalityOptions(selectedRegion.municipalities);
					}
				} else {
					setMunicipalityOptions(null);
				}
			}
		}, [selectedRegion]
	);
	//
	useEffect(
		() => {
			const newInput = { ...userInput };
			newInput.municipality = selectedMunicipality;
			setUserInput(newInput);
		}, [selectedMunicipality]
	);

	useEffect(
		() => {
			// console.log(selectedCountry);
			// if (userInput.country) {
			// 	setSelectedCountry(countries.find((item) => item.name === country.name));
			// 	console.log(selectedCountry);
			// } else {
			// 	setSelectedCountry(userInput.country);
			// }

			if (!userInput.country) {
				setSelectedCountry(userInput.country);
				// setSelectedCountry(fullCountries.find((item) => item.name === country.name));
			} else {
				setSelectedCountry(userInput.country);
			}
		}, []
	);

	const changeClass = () => {
		if (csstData) {
			return csstData.regions ? 'open-sans enable-text' : 'disable-text';
		}
	};

	const disableDropdown = () => {
		if (csstData) {
			return !csstData.regions;
		}
	};

	const itemTemplate = (item) => {
		if (item) {
			return (
				<Translator text={item.name} />
			);
		}
		return (
			<p><Translator text="Please select" /></p>
		);
	};

	const regionTemplate = (item) => {
		if (item) {
			if (userInput.country) {
				if (userInput.country.id === 'GTM' || userInput.country.id === 'NER') {
					return (
						<Translator text={item.region} />
					);
				}
			}
			return (
				<Translator text={item.name} />
			);
		}
		return (
			<p><Translator text="Please select" /></p>
		);
	};

	const regionOptions = () => {
		return userInput?.country?.id === 'GTM' || userInput?.country?.id === 'NER' ? sortArrayOfObjectsByPropertyValue(csstData?.regions, 'region') : sortArrayOfObjectsByPropertyValue(csstData?.regions, 'name');
	};

	const displayRegionsLabels = () => {
		return userInput?.country?.id === 'GTM' || userInput?.country?.id === 'NER' ? 'region' : 'name';
	};

	return (
		<div className="step">
			<div className="header">
				<p className="sub-title">
					<Translator text="Step 1. Define the context and  “ideal” set of Climate-Peace Mechanisms for your implementation area" />
				</p>
				<p className="open-sans">
					<Translator text="This step characterises the context of the location where your program is expected to be implemented." />
					<br /><br />
					<Translator text="Select the region to obtain broad context-specific drivers of conflict and insecurity retrieved from JRC INFORM." />
				</p>
			</div>
			<div className="dropdown-item">
				<p className="open-sans"><Translator text="Country my program is located *" /></p>
				<Dropdown
					className="dropdown-country"
					value={selectedCountry}
					options={sortArrayOfObjectsByPropertyValue(fullCountries, 'name')}
					optionLabel="name"
					onChange={(e) => setSelectedCountry(e.value)}
					placeholder="Please Select"
					itemTemplate={itemTemplate}
					valueTemplate={itemTemplate}
					filter
				/>
			</div>

			<div className="dropdown-item">
				<p className={changeClass()}><Translator text="Region my program is located *" /></p>
				<Dropdown
					className="dropdown-region"
					value={selectedRegion}
					options={regionOptions()}
					onChange={(e) => setSelectedRegion(e.value)}
					optionLabel={displayRegionsLabels()}
					placeholder="Please Select"
					itemTemplate={regionTemplate}
					valueTemplate={regionTemplate}
					disabled={disableDropdown()}
				/>
			</div>
			{
				municipalityOptions
					? (
						<div className="dropdown-item">
							<p className="open-sans"><Translator text="Municipality my program is located *" /></p>
							<Dropdown
								className="dropdown-region"
								value={selectedMunicipality}
								options={sortArrayOfObjectsByPropertyValue(municipalityOptions, 'name')}
								onChange={(e) => setSelectedMunicipality(e.value)}
								optionLabel="name"
								placeholder="Please Select"
								itemTemplate={itemTemplate}
								valueTemplate={itemTemplate}
							/>
						</div>
					)
					: null
			}
		</div>
	);
};

export default CountryAndRegion;
