import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useSelector } from 'react-redux';
import SummaryDownloadButton from '../SummaryDownloadButton';
import Translator from '../../../../../../components/Translator';
import ComingSoon from '../../../../../../components/ComingSoon';

const How = () => {
	const [displayDialog, setDisplayDialog] = useState(false);
	const country = useSelector((state) => state.country);
	return (
		<div className="tab how">
			{ country.id === 'BFA' || country.id === 'NER' ? (

				<ComingSoon comingSoonContent={''} />

			)
				: (
					<>
						<p className="title">
							<span><Translator text="HOW" /></span> <Translator text="does climate worsen the root causes of conflict?" />
						</p>
						<p className="open-sans tab-header">
							<Translator text="Understanding the relationship between climate and security is critical to conflict mitigation,
				and a necessary first step in the design of appropriate interventions. To make sense of the
				often complex web of linkages, a mixed-method approach is employed to examine the connections
				from several different perspectives. With a special focus on highly vulnerable water, land and
				food systems, both qualitative and quantitative methodologies are used to illuminate and make
				sense of the causal relationships, cross-referencing the results to form a more comprehensive
				picture of the complexity of the Climate-Security Nexus."
							/>
						</p>
						<Button label={<Translator text="Tutorial" />} onClick={() => setDisplayDialog(true)} />
						<Dialog header={<Translator text="CSO Tutorial" />} visible={displayDialog} style={{ width: '90vw', height: '90vh' }} onHide={() => setDisplayDialog(false)}>
							<iframe
								src="https://player.vimeo.com/video/828236579?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								frameBorder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowFullScreen
								style={{ width: '100%', height: '100%' }}
								title="Context_Kenya_Maximum_Temperature"
							/>
						</Dialog>
						<SummaryDownloadButton />
					</>
				)}

		</div>
	);
};

export default How;
