/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TabPanel, TabView } from 'primereact/tabview';
import { Ripple } from 'primereact/ripple';
import { Card } from 'primereact/card';
import { Context, How, What, Where, Who } from './components';
import Actions from '../../../../reducer/actions';
import { howImage, whereImage, whoImage, whatImage, contextImageKenya,
	contextImageEthiopia, contextImageNigeria, contextImageGuatemala, contextImageMali,
	contextImagePhilippines, contextImageSenegal, contextImageSudan, contextImageUganda, contextImageZambia, contextImageZimbabwe, contextImageBurkinaFaso, contextImageNiger } from '../../../../assets/images/images';
import './styles.css';
import Translator from '../../../../components/Translator';

const CountryInformationPage = () => {
	const dispatch = useDispatch();

	const country = useSelector((state) => state.country);

	const tab = useSelector((state) => state.tab);
	const setTab = (payload) => dispatch({ type: Actions.SetTab, payload });

	const toggled = useSelector((state) => state.toggled);
	const setToggled = (payload) => dispatch({ type: Actions.SetToggled, payload });

	const [activeIndex, setActiveIndex] = useState(0);
	// const [toggled, setToggled] = useState(true);
	useEffect(
		() => {
			// changeTabColor(tab, 'black');
			// setTab('Context');
			changeTabColor(tab, 'black');
			if (country.id === 'NER' || country.id === 'BFA') {
				setTab('Where');
				changeTabColor('Where', '#FDC82F');
			} else {
				setTab('Context');
				changeTabColor('Context', '#FDC82F');
			}
			return () => setToggled(true);
		}, [country]
	);

	useEffect(() => {
		const menu = document.getElementById('side-menu');
		const tabmenu = document.getElementsByClassName('p-tabview-nav');
		const navbar = document.getElementsByClassName('p-tabview-ink-bar');
		if (menu) {
			if (!toggled) {
				menu.style.width = '82px';
				menu.style.minWidth = '82px';
				menu.style.padding = '10px';
			} else {
				menu.style.width = '400px';
				menu.style.minWidth = '400px';
				menu.style.background = '#F7F7F7';
			}
		}
		if (tabmenu[0]) {
			if (!toggled) {
				tabmenu[0].style.flexDirection = 'column';
			} else {
				tabmenu[0].style.flexDirection = 'row';
			}
		}
		if (navbar[0]) {
			if (!toggled) {
				navbar[0].style.display = 'none';
				setActiveIndex((prev) => -1);
			} else {
				navbar[0].style.display = 'unset';
				setTimeout(
					() => {
						switch (tab) {
						case 'Context': setActiveIndex(0); break;
						case 'How': setActiveIndex(1); break;
						case 'Where': setActiveIndex(2); break;
						case 'Who': setActiveIndex(3); break;
						case 'What': setActiveIndex(4); break;
						default: setActiveIndex(0); break;
						}
					}, 500
				);
			}
		}
	}, [toggled, tab]);

	const changeCountryImage = () => {
		if (window.location.href.includes('Kenya')) {
			return contextImageKenya;
		}
		if (window.location.href.includes('Ethiopia')) {
			return contextImageEthiopia;
		}
		if (window.location.href.includes('Nigeria')) {
			return contextImageNigeria;
		}
		if (window.location.href.includes('Guatemala')) {
			return contextImageGuatemala;
		}
		if (window.location.href.includes('Mali')) {
			return contextImageMali;
		}
		if (window.location.href.includes('Philippines')) {
			return contextImagePhilippines;
		}
		if (window.location.href.includes('Senegal')) {
			return contextImageSenegal;
		}
		if (window.location.href.includes('Sudan')) {
			return contextImageSudan;
		}
		if (window.location.href.includes('Uganda')) {
			return contextImageUganda;
		}
		if (window.location.href.includes('Zambia')) {
			return contextImageZambia;
		}
		if (window.location.href.includes('Zimbabwe')) {
			return contextImageZimbabwe;
		}
		if (window.location.href.includes('Niger')) {
			return contextImageNiger;
		}
		if (window.location.href.includes('Burkina-Faso')) {
			return contextImageBurkinaFaso;
		}
	};

	const changeTabColor = (target, color) => {
		const selectedtabImage = document.getElementById(target);
		if (selectedtabImage) {
			selectedtabImage.fill = color;
			let paths = selectedtabImage.getElementsByTagName('path');
			paths = [...paths];
			paths.map((item) => {
				// eslint-disable-next-line no-param-reassign
				item.style.fill = color;
			});
		}
	};

	const tabHeaderTemplate = (options, image, text) => (
		<div className="tab-header" role="button" tabIndex="0" onClick={options.onClick}>
			<div
				className="p-ripple"
				id="about"
			>
				{image}
				<p style={{ color: 'black' }}>{text}</p>
				<Ripple />
			</div>
		</div>
	);
	return (
		<div className="expanded-menu" id="side-menu" style={toggled ? { alignItems: 'flex-end' } : { alignItems: 'center' }}>
			<div className="header" style={toggled ? { width: '100%' } : {}}>
				<p style={toggled ? { display: 'flex' } : { display: 'none' }}>{country.name}</p>
				<Button id="toggle" icon={toggled ? 'fa-solid fa-chevrons-left' : 'fa-solid fa-chevrons-right'} onClick={() => setToggled(!toggled)} />
			</div>
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => {
					setActiveIndex(e.index);
					changeTabColor(tab, 'black');
					switch (e.index) {
					case 0: setTab('Context'); changeTabColor('Context', '#FDC82F'); break;
					case 1: setTab('How'); changeTabColor('How', '#FDC82F'); break;
					case 2: setTab('Where'); changeTabColor('Where', '#FDC82F'); break;
					case 3: setTab('Who'); changeTabColor('Who', '#FDC82F'); break;
					case 4: setTab('What'); changeTabColor('What', '#FDC82F'); break;
					default: setTab('Context'); changeTabColor('Context', '#FDC82F'); break;
					}
				}}
			>
				<TabPanel header="Context" headerTemplate={(options) => tabHeaderTemplate(options, changeCountryImage(), <Translator text="Context" />)}>
					<div className="tab-content" style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1', height: '0', overflow: 'hidden' }}>
						<Context />
					</div>
				</TabPanel>
				<TabPanel header="How" headerTemplate={(options) => tabHeaderTemplate(options, howImage, <Translator text="How" />)}>
					<div className="tab-content" style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1', height: '0', overflow: 'hidden' }}>
						<How />
					</div>
				</TabPanel>
				<TabPanel header="Where" headerTemplate={(options) => tabHeaderTemplate(options, whereImage, <Translator text="Where" />)}>
					<div className="tab-content" style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1', height: '0', overflow: 'hidden' }}>
						<Where />
					</div>
				</TabPanel>
				<TabPanel header="Who" headerTemplate={(options) => tabHeaderTemplate(options, whoImage, <Translator text="Who" />)}>
					<div className="tab-content" style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1', height: '0', overflow: 'hidden' }}>
						<Who />
					</div>
				</TabPanel>
				<TabPanel header="What" headerTemplate={(options) => tabHeaderTemplate(options, whatImage, <Translator text="What" />)}>
					<div className="tab-content" style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1', height: '0', overflow: 'hidden' }}>
						<What />
					</div>
				</TabPanel>
			</TabView>
		</div>
	);
};
export default CountryInformationPage;
