import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'primereact/tooltip';
import { Slider } from 'primereact/slider';
import Actions from '../../../../../../../../reducer/actions';
import CountryService from '../../../../../../../../services/httpService/countryService';
import Translator from '../../../../../../../../components/Translator';

const GlobalMetrics = () => {
	const dispatch = useDispatch();
	
	const contextFilterSelections = useSelector((state) => state.contextFilterSelections);
	const setContextFilterSelections = (payload) => dispatch({ type: Actions.SetContextFilterSelections, payload });

	const [variable, setVariable] = useState(contextFilterSelections.globalVariable || {});
	const [variableOptions, setVariableOptions] = useState(null);
	const [startYear, setStartYear] = useState(contextFilterSelections.globalDuration[0]);
	const [endYear, setEndYear] = useState(contextFilterSelections.globalDuration[1]);
	const [durationOptions, setDurationOptions] = useState([]);
	const [duration, setDuration] = useState(contextFilterSelections.climateDuration);

	// const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const country = useSelector((state) => state.country);

	useEffect(
		() => {
			CountryService.getIndexesGraphData(country.id)
				.then((res) => {
					setVariableOptions(res.data_info);
				});
		}, [country]
	);

	useEffect(
		() => {
			const temp = { ...contextFilterSelections };
			temp.globalVariable = { ...variable };
			if (variable.data) {
				const newDurationOptions = variable.data.map((item) => {
					const date = new Date(item.year);
					const year = date.getYear() + 1900;
					return year;
				});
				setDurationOptions(newDurationOptions);
				setStartYear(newDurationOptions[0]);
				setEndYear(newDurationOptions[newDurationOptions.length - 1]);
			}
			setContextFilterSelections(temp);
		}, [variable]
	);

	useEffect(
		() => {
			const temp = { ...contextFilterSelections };
			temp.globalDuration = [startYear, endYear];
			setContextFilterSelections(temp);
		}, [startYear, endYear]
	);

	// const calculateDate = (index) => {
	// 	const year = Math.floor(2001 + duration[index] / 12);
	// 	const month = months[duration[index] % 12];
	// 	return `${month} ${year}`;
	// };
	//
	const itemTemplate = (item) => {
		if (item) {
			return (
				<Translator text={item.name} />
			);
		}
		return (
			<p><Translator text="Please select" /></p>
		);
	};
	return (
		<div className="filters">
			{
				variableOptions
					? (
						<div className="dropdown">
							<p className="open-sans"><Translator text="Variable" /></p>
							<Dropdown
								value={variable}
								options={variableOptions}
								onChange={(e) => setVariable(e.value)}
								optionLabel="name"
								placeholder={<Translator text="Please select" />}
								itemTemplate={itemTemplate}
								valueTemplate={itemTemplate}
							/>
						</div>
					)
					: null
			}
			{/* <div className="slider"> */}
			{/*	<p className="open-sans slider-title">During</p> */}
			{/*	<div className="slider-container" style={{ background: `linear-gradient(90deg, #73a98f 55%, #d8c092 55%)` }}> */}
			{/*		<Tooltip target=".p-slider-handle-start" content={calculateDate(0)} position="top" event="both" /> */}
			{/*		<Tooltip target=".p-slider-handle-end" content={calculateDate(1)} position="top" event="both" /> */}
			{/*		<Slider value={duration} onChange={(e) => setDuration(e.value)} range min={2} max={350} /> */}
			{/*	</div> */}
			{/* </div> */}
			{/* <div className="labels"> */}
			{/*	<p className="open-sans">March 2001</p> */}
			{/*	<p className="open-sans">March 2030</p> */}
			{/* </div> */}
			<div className="dropdown multiple">
				<p className="open-sans"><Translator text="Duration" /></p>
				<div className="during">
					<Dropdown value={startYear} options={durationOptions} onChange={(e) => setStartYear(e.value)} />
					<Dropdown value={endYear} options={durationOptions} onChange={(e) => setEndYear(e.value)} />
				</div>
			</div>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<p
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setVariable('');
					setStartYear('');
					setEndYear('');
				}}
			><Translator text="Reset all filters" />
			</p>
		</div>
	);
};

export default GlobalMetrics;
