import React from 'react';
import './styles.css';
import Translator from '../../../../../../../../../../components/Translator';

const NoClusterSelected = () => {
	return (
		<div className="no-cluster">
			<p className="title"><Translator text="Cluster Definition" /></p>
			{/* <p className="open-sans"> */}
			{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut */}
			{/*	labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco */}
			{/*	laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in */}
			{/*	voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat */}
			{/*	non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
			{/* </p> */}
			<div className="warning">
				<i className="fa-regular fa-circle-info" />
				<p className="open-sans"><Translator text="Select Variables to view some data" /></p>
			</div>
		</div>
	);
};

export default NoClusterSelected;
