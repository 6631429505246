/* eslint-disable no-param-reassign,jsx-a11y/no-static-element-interactions,consistent-return */
import * as echarts from 'echarts';
import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { graphic } from 'echarts';
import './styles.css';
import ChangeNAText from './components/ChangeNAText';
import Translator from '../../../../../../../../../../components/Translator';

const MockGraph = (props) => {
	const { data } = props;
	const [selectedNodes, setSelectedNodes] = useState([]);
	const [checked, setChecked] = useState(true);
	const [calculateGraph, setCalculateGraph] = useState(0);
	const [myChart, setMyChart] = useState(null);
	const [zoom, setZoom] = useState(0);
	const [selectedCategories, setSelectedCategories] = useState({});

	// eslint-disable-next-line consistent-return
	const displayDefaultVariables = () => {
		const categories = [];
		data.nodes.map((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});
		const categorizedData = categories.map((category) => {
			const temp = [];
			data.nodes.map((item, index) => {
				if (item.category === category) {
					temp.push({ ...item, key: index, children: [], label: item.name });
				}
			});
			return { category, nodes: temp };
		});
		if (!selectedNodes) return null;
		const preset = [];
		categorizedData.map((item) => {
			const nodes = item.nodes.map((node) => {
				preset.push({ category: node.category, dominant: node.dominant, id: node.id, key: node.key, label: node.label, name: node.name });
			});
		});
		setSelectedNodes([...preset]);
		setCalculateGraph((prev) => prev + 1);
	};

	useEffect(
		() => {
			displayDefaultVariables();
		}, []
	);

	useEffect(() => {
		const chartDom = document.getElementById('chart-container');
		checked ? setZoom(3) : setZoom(1);
		setMyChart(echarts.init(chartDom));
	}, [calculateGraph, checked]);

	useEffect(
		() => {
			if (zoom) {
				const option = {
					series: {
						zoom,
					},
				};
				myChart.setOption(option);
			}
		}, [zoom]
	);
	useEffect(() => {
		// const myChart = echarts.init(chartDom);
		if (!myChart) return;

		myChart.showLoading();
		myChart.hideLoading();

		if (!selectedNodes.length) return;

		let graphNodes = selectedNodes.map((item) => {
			return { ...item };
		});
		graphNodes = graphNodes.map((node) => {
			const index = data.categories.findIndex((item) => item.name === node.category);
			const newNode = node;
			newNode.category = index;
			return newNode;
		});
		const graph = JSON.parse(JSON.stringify(data));
		const forceNodes = [];
		graphNodes.map((node) => {
			let size = 10;
			let value = 0;
			let forceNodeSize = 1;
			const links = data.links.filter((link) => link.target === node.id || link.source === node.id);
			links.map((item) => {
				const temp = graphNodes.find((graphnode) => graphnode.id === item.target);
				const temp2 = graphNodes.find((graphnode) => graphnode.id === item.source);
				if (temp && temp2) {
					size += 2;
					if (forceNodeSize < 10) {
						forceNodeSize += 1;
					}
					value += 1;
				}
			});

			node.symbolSize = size;
			node.value = value;
			node.label = {
				show: false,
			};
			const forceNode = { ...node };
			forceNode.symbolSize = forceNodeSize;
			forceNode.value = value;
			forceNode.label = {
				show: false,
			};
			forceNodes.push(forceNode);
		});
		graph.nodes = graphNodes;

		const graphLinks = [];
		data.links.map((link) => {
			if (graphNodes.find((item) => item.id === link.source) && graphNodes.find((item) => item.id === link.target)) {
				graphLinks.push(link);
			}
		});
		graphLinks.map((link) => {
			const sourceNode = data.nodes.find((item) => item.id === link.source);
			const sourceColor = data.categories.find((item) => item.name === sourceNode.category)?.color;
			const targetNode = data.nodes.find((item) => item.id === link.target);
			const targetColor = data.categories.find((item) => item.name === targetNode.category)?.color;
			link.lineStyle = { ...link.lineStyle,
				color: new graphic.LinearGradient(0, 0, 1, 1, [
					{
						offset: 1,
						color: sourceColor || '#000000',
					},
					{
						offset: 0,
						color: targetColor || '#000000',
					},
				]),
				type: 'linear',
				curveness: 0.2,
				width: Math.round(Math.abs(link.value) * 10) + 1,
			};
			link.tooltip = {
				formatter: `"${sourceNode.name}" - "${targetNode.name}" correlation: ${link.value}`,
				textStyle: {
					fontFamily: 'Montserrat',
					fontWeight: '400',
				},
			};
		});

		const option = {
			title: {
				// text: 'Network Analysis',
				// subtext: 'Default layout',
				top: 'bottom',
				left: 'right',
			},
			color: data.categories.map((category) => category.color),
			tooltip: {},
			legend: [
				{
					data: graph.categories.map((a, index) => {
						return a.name;
					}),
				},
			],
			animationDuration: 1500,
			animationEasingUpdate: 'quinticInOut',
			series: [
				{
					name: 'Network Analysis',
					type: 'graph',
					layout: checked ? 'force' : 'circular',
					data: checked ? forceNodes : graph.nodes,
					links: graphLinks,
					categories: graph.categories,
					draggable: true,
					// scrollable: false,
					roam: 'move',
					zoom: checked ? 3 : 1,
					force: {
						repulsion: 100,
						gravity: 0.1,
						friction: 0.1,
						layoutAnimation: true,
					},
					label: {
						show: false,
						fontSize: 12,
					},
					labelLayout: {
						hideOverlap: true,
					},
					emphasis: {
						focus: 'adjacency',
					},
					lineStyle: {
						width: 5,
						color: new graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0,
								color: 'source',
							},
							{
								offset: 1,
								color: 'source',
							}]),
						type: 'linear',
					},
				},
			],
		};
		myChart.setOption(option);
		// myChart.on('highlight', (params) => {
		// 	console.log(params);
		// });
		// eslint-disable-next-line consistent-return
		return () => myChart.dispose();
	}, [myChart, calculateGraph, checked]);

	const leftContents = (
		<>

		</>
	);

	const rightContents = (
		<Button label="Calculate Graph" onClick={() => setCalculateGraph((prev) => prev + 1)} />
	);

	// const panelHeader = (
	// 	<div style={{ padding: '0.5rem 1rem', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
	// 		<h3 style={{ fontSize: '20px', fontWeight: '500' }}>Choose Variables</h3>
	// 	</div>
	// );

	const template = (options) => {
		const className = `${options.className} p-jc-between`;
		return (
			<div className={className} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
				{renderOptions()}
				<div className={className} style={{ gap: '8px' }}>
					<p>{checked ? <Translator text="Force" /> : <Translator text="Circular" /> }</p>
					<InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
				</div>
			</div>
		);
	};

	const renderOptions = () => {
		const categories = [];
		data.nodes.map((item) => {
			const temp = categories.find((category) => category === item.category);
			if (!temp) {
				categories.push(item.category);
			}
		});
		const categorizedData = categories.map((category) => {
			const temp = [];
			data.nodes.map((item, index) => {
				if (item.category === category) {
					temp.push({ ...item, key: index, children: [], label: item.name });
				}
			});
			return { category, nodes: temp };
		});
		if (!selectedNodes) return null;

		const options = categorizedData.map((item) => {
			const nodes = item.nodes.map((node) => {
				return { category: node.category, dominant: node.dominant, id: node.id, key: node.key, label: node.label, name: node.name };
			});
			return { category: item.category, nodes };
		});

		const groupedItemTemplate = (option) => {
			const onChange = (val) => {
				const newValue = { ...selectedCategories };
				newValue[option.category] = val;
				if (val) {
					const categoryNodes = [];
					data.nodes.map((node, index) => {
						if (node.category === option.category) {
							categoryNodes.push({ category: node.category, dominant: node.dominant, id: node.id, key: index, label: node.name, name: node.name });
						}
					});
					setSelectedNodes([...selectedNodes, ...categoryNodes]);
				} else {
					let newSelectedNodes = [...selectedNodes];
					newSelectedNodes = newSelectedNodes.filter((node) => node.category !== option.category);
					setSelectedNodes(newSelectedNodes);
				}
				setSelectedCategories(newValue);
			};
			return (
				<div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
					<Checkbox inputId="binary" checked={selectedCategories[option.category]} onChange={(e) => onChange(e.checked)} />
					<p><strong><Translator text={option.category} /></strong></p>
				</div>
			);
		};

		const itemTemplate = (item) => {
			if (item) {
				return (
					<Translator text={item.name} />
				);
			}
			return (
				<p><Translator text="Select Variables" /></p>
			);
		};

		return (
			<div className="text-dropdown-container">
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
					<h3 style={{ fontSize: '20px', fontWeight: '500' }}><Translator text="Choose Variables" /></h3>
					<MultiSelect
						value={selectedNodes}
						options={options}
						onChange={(e) => setSelectedNodes(e.value)}
						optionLabel="name"
						optionGroupLabel="category"
						optionGroupChildren="nodes"
						optionGroupTemplate={groupedItemTemplate}
						placeholder="Select Variables"
						itemTemplate={itemTemplate}
						maxSelectedLabels={2}
					/>
				</div>
				<Button label={<Translator text="Calculate Graph" />} onClick={() => setCalculateGraph((prev) => prev + 1)} />
			</div>

		);
	};

	return (
		<div className="na-graph">
			{/* <Panel toggleable> */}

			{/* </Panel> */}
			{/* {renderOptions()} */}
			{/* <Toolbar left={leftContents} right={rightContents} /> */}
			<Panel header="Variables Correlation" className="na-chart p-mt-6" headerTemplate={template}>
				<div className="zoom-buttons">
					<div className="zoom plus" onClick={() => setZoom(zoom + 1)}>
						<i className="fa-solid fa-plus" />
					</div>
					<div
						className="zoom minus"
						onClick={() => {
							if (zoom > 1) {
								setZoom(zoom - 1);
							}
						}}
					>
						<i className="fa-solid fa-minus" />
					</div>
				</div>
				<ChangeNAText />
				<div id="chart-container" style={{ width: '66%', height: '700px' }} />
			</Panel>
		</div>
	);
};
export default MockGraph;
