import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import Translator from '../../../../components/Translator';

const DataSourcesDialogContent = (props) => {
	const { data } = props;
	// const data = [
	// 	{ indicator: 'Drought', source: 'CHIRPS', variables: 'Var 1, Var 2, Var 3', range: '1990 - 2020', source_frequency: 'Daily', update_frequency: 'Montly' },
	// 	{ indicator: 'Violence', source: 'ACLED', variables: 'Var 1, Var 2, Var 3', range: '1990 - 2020', source_frequency: 'Event', update_frequency: 'Weekly' },
	// ];

	const urlTemplate = (url) => {
		return (
			(url.source
				? (
					<>
						<a href={url.source} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a><br /><br />
						{url.source_b
							? (
								<a href={url.source_b} target="_blank" rel="noreferrer" aria-label="link">
									<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
								</a>
							) : null }
					</>
				) : null
			)
		);
	};

	const descriptionTemplate = (descrData) => {
		return (
			<>
				<p><Translator text={descrData.description} /></p>
				{descrData.desc_source
					? (
						<a href={descrData.desc_source} target="_blank" rel="noreferrer" aria-label="link">
							<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
						</a>
					) : null}
			</>
		);
	};

	const nameTemplate = (item) => {
		return (
			<p><Translator text={item.variableName} /></p>
		);
	};

	const unitTemplate = (item) => {
		return (
			<p><Translator text={item.type} /></p>
		);
	};

	const spatialTemplate = (item) => {
		return (
			<p><Translator text={item.spatial_resolution} /></p>
		);
	};

	const aggregationTemplate = (item) => {
		return (
			<p><Translator text={item.temporal_aggregation} /></p>
		);
	};

	return (
		<div className="resources-dialog-content">
			<p className="title"><Translator text="Data Sources" /></p>

			<DataTable value={data} responsiveLayout="scroll" stripedRows>
				<Column body={nameTemplate} header={<Translator text="Variable Name" />} />
				<Column field="description" header={<Translator text="Description" />} body={descriptionTemplate} />
				<Column body={unitTemplate} header={<Translator text="Type/Unit" />} />
				<Column body={spatialTemplate} header={<Translator text="Spatial Resolution/Aggregation" />} />
				<Column body={aggregationTemplate} header={<Translator text="Temporal Aggregation" />} />
				<Column field="source" header={<Translator text="Source" />} body={urlTemplate} />
			</DataTable>
		</div>
	);
};

export default DataSourcesDialogContent;
