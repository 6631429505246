/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../../../reducer/actions';
import './styles.css';
import Translator from '../../../../../../components/Translator';

const Card = (props) => {
	const { image, title, subtitle, text, setDisplayCard, children, isWhatToolCard } = props;
	const dispatch = useDispatch();
	const toggled = useSelector((state) => state.toggled);
	const setToggled = (payload) => dispatch({ type: Actions.SetToggled, payload });

	const displayReadMoreButton = () => {
		if (title === 'Coming Soon') {
			return null;
		}
		if (isWhatToolCard) {
			return (
				<Button className="open-tool-button" label={<Translator text="Open Tool" />} onClick={() => setDisplayCard(title)} />
			);
		}
		return (
			<Button
				label={<Translator text="READ MORE" />}
				onClick={() => {
					setToggled(false);
					setDisplayCard(title);
				}}
			/>
		);
	};
	return (
		<div className={!isWhatToolCard ? 'content-card p-card' : 'content-card p-card tool-what-card'}>
			<div className="image-text-container">
				{image ? <img src={image} alt="image" /> : null}
				<h3><Translator text={title} />{subtitle ? <h5>{subtitle} </h5> : null}</h3>
				{text ? <p className="open-sans">{text}</p> : null}
			</div>
			{displayReadMoreButton()}
		</div>
	);
};

export default Card;
