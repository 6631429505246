/* eslint-disable react/jsx-no-useless-fragment,no-shadow,no-plusplus */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';

const PrevalenceChart = (props) => {
	const { data } = props;

	useEffect(() => {
		const root = am5.Root.new('prevalence');

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// root.dateFormatter.setAll({
		// 	dateFormat: 'yyyy',
		// 	dateFields: ['valueX'],
		// });

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			layout: root.verticalLayout,
			focusable: true,
			panX: true,
			panY: true,
			wheelX: 'panX',
			wheelY: 'zoomX',
			pinchZoomX: true,
		}));

		const easing = am5.ease.linear;

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
			maxDeviation: 0.1,
			groupData: false,
			baseInterval: {
				timeUnit: 'month',
				count: 1,
			},
			renderer: am5xy.AxisRendererX.new(root, {

			}),
			tooltip: am5.Tooltip.new(root, {}),
		}));

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			maxDeviation: 0.2,
			renderer: am5xy.AxisRendererY.new(root, {}),
		}));

		data.map((item) => {
			const series = chart.series.push(am5xy.LineSeries.new(root, {
				name: item.variable,
				minBulletDistance: 10,
				connect: false,
				xAxis,
				yAxis,
				valueYField: 'value',
				valueXField: 'date',
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: 'horizontal',
					labelText: '{valueY}',
				}),
			}));

			series.fills.template.setAll({
				fillOpacity: 0.2,
				visible: true,
			});

			series.strokes.template.setAll({
				strokeWidth: 2,
			});

			// Set up data processor to parse string dates
			// https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
			series.data.processor = am5.DataProcessor.new(root, {
				dateFormat: 'yyyy-MM',
				dateFields: ['date'],
			});

			series.data.setAll(item.data);

			series.bullets.push(() => {
				const circle = am5.Circle.new(root, {
					radius: 4,
					fill: root.interfaceColors.get('background'),
					stroke: series.get('fill'),
					strokeWidth: 2,
				});

				return am5.Bullet.new(root, {
					sprite: circle,
				});
			});
		});
		// Add series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			xAxis,
			behavior: 'none',
		}));
		cursor.lineY.set('visible', false);

		// add scrollbar
		chart.set('scrollbarX', am5.Scrollbar.new(root, {
			orientation: 'horizontal',
		}));

		const legend = chart.children.push(am5.Legend.new(root, {
			centerX: am5.percent(50),
			x: am5.percent(50),
			layout: am5.GridLayout.new(root, {
				// maxColumns: 2,
				fixedWidthGrid: true,
			}),
		}));

		// When legend item container is hovered, dim all the series except the hovered one
		legend.itemContainers.template.events.on('pointerover', (e) => {
			const itemContainer = e.target;

			// As series list is data of a legend, dataContext is series
			const series = itemContainer.dataItem.dataContext;

			chart.series.each((chartSeries) => {
				if (chartSeries != series) {
					chartSeries.strokes.template.setAll({
						strokeOpacity: 0.15,
						stroke: am5.color(0x000000),
					});
				} else {
					chartSeries.strokes.template.setAll({
						strokeWidth: 3,
					});
				}
			});
		});

		// When legend item container is unhovered, make all series as they are
		legend.itemContainers.template.events.on('pointerout', (e) => {
			const itemContainer = e.target;
			const series = itemContainer.dataItem.dataContext;

			chart.series.each((chartSeries) => {
				chartSeries.strokes.template.setAll({
					strokeOpacity: 1,
					strokeWidth: 1,
					stroke: chartSeries.get('fill'),
				});
			});
		});

		legend.data.setAll(chart.series.values);

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);

		// Generates random data, quite different range

		return () => root.dispose();
	}, [data]);

	return (
		<div id="prevalence" style={{ width: '66%', height: '500px' }} />
	);
};
export default PrevalenceChart;
