/* eslint-disable class-methods-use-this */
import { http } from '../index';

class CountryService {
	getGraphData = async (country) => {
		const result = await http.get(`/api/graph/${country}/data`);
		return result.data;
	};

	getMegapixelsOfCountry = async (country) => {
		const result = await http.get(`/api/${country}/MP`);
		return result.data;
	};

	getWhereBarChartData = async (country) => {
		const result = await http.get(`/api/graph/${country}/data/sa`);
		return result.data;
	};

	getWhereLineChartData = async (country) => {
		const result = await http.get(`/api/graph/${country}/line`);
		return result.data;
	};

	getGaugeChartData = async (country) => {
		const result = await http.get(`/api/${country}/gauge`);
		return result.data;
	};

	getBoxPlotData = async (country) => {
		const result = await http.get(`/api/${country}/boxplot`);
		return result.data;
	};

	getScatterGraphData = async (country) => {
		const result = await http.get(`/api/${country}/scatter`);
		return result.data;
	};

	getIndexesGraphData = async (country) => {
		const result = await http.get(`/api/${country}/indices`);
		return result.data;
	};

	getSentimentPrevalenceData = async (country) => {
		const result = await http.get(`/api/${country}/sma`);
		return result.data;
	};

	getAcledChartData = async (country) => {
		const result = await http.get(`/api/${country}/acled`);
		return result.data;
	};

	getClusterDefinitionBinnedData = async (country) => {
		const result = await http.get(`/api/graph/${country}/cluster`);
		return result.data;
	};

	getAdminLvl0 = async (country) => {
		const result = await http.get(`/api/${country}/gadm0`);
		return result.data;
	};

	getNationalAverages = async (country) => {
		const result = await http.get(`/api/${country}/natavg`);
		return result.data;
	};

	getScoringFunctionData = async (country) => {
		const result = await http.get(`/api/${country}/csst/retrieve`);
		return result.data;
	};

	getListOfCountries = async () => {
		const result = await http.get(`/api/csst/countries`);
		return result.data;
	};

	getLivestockTranshumanceRoutes = async (country) => {
		const result = await http.get(`/api/livestock/${country}`);
		return result.data;
	};

	getLivestockAcled = async (type) => {
		const result = await http.get(`/api/livestock/geojson/retrieve/${type}`);
		return result.data;
	};

	getLivestockTiles = async (type) => {
		const result = await http.get(`api/terracotta/retrieve/${type}`);
		return result.data;
	};

	getPolicyCoherenceData = async (country) => {
		const result = await http.get(`/api/pcatk/get/${country}`);
		return result.data;
	};

	getPdfUrl = async (themes, organisation, project, email, html, save) => {
		const result = await http.post(`/api/htmltopdf`, {
			themes,
			organisation,
			project,
			email,
			html,
			save,
		});
		return result.data;
	};

	getLivestockGraphData = async (coordinate) => {
		const result = await http.get(`/api/livestockmodule/${coordinate}`);
		return result.data;
	};
}

export default new CountryService();
