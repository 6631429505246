import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Introduction, Steps, Results } from './components';
import CountryService from '../../services/httpService/countryService';
import './styles.css';
import Translator from '../Translator';

const Tool3 = () => {
	const country = useSelector((state) => state.country);

	const [subPage, setSubPage] = useState('introduction');
	const [userInput, setUserInput] = useState({});
	const [csstData, setCsstData] = useState([]);

	useEffect(
		() => {
			const newUserInput = { ...userInput };
			newUserInput.country = country;
			setUserInput(newUserInput);
		}, [country]
	);

	// useEffect(() => {
	// 	console.log(userInput);
	// }, [userInput]);

	useEffect(
		() => {
			if (userInput.country) {
				CountryService.getScoringFunctionData(userInput.country.id)
					.then((res) => {
						setCsstData(res.data_info);
					});
			}
		}, [userInput.country]
	);

	const renderSubPage = () => {
		switch (subPage) {
		case 'introduction': return <Introduction setSubPage={setSubPage} />;
		case 'steps': return <Steps csstData={csstData} setSubPage={setSubPage} userInput={userInput} setUserInput={setUserInput} />;
		case 'results': return <Results csstData={csstData} userInput={userInput} />;
		default: return <Introduction />;
		}
	};

	return (
		<div className="tool">
			<p className="title" style={{ fontWeight: 'bold' }}>
				<Translator text="Climate Security Sensitivity Tool (CSST)" />
			</p>
			{renderSubPage()}
		</div>
	);
};

export default Tool3;
