/* eslint-disable react/jsx-no-useless-fragment */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

const FlowerChart = (props) => {
	const { data, csstData, setFlowerChartSrc, flowerChartId, width } = props;
	useEffect(() => {
		const root = am5.Root.new(flowerChartId);

		console.log(csstData);
		console.log(data);

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/radar-chart/
		const chart = root.container.children.push(
			am5radar.RadarChart.new(root, {
				layout: root.verticalLayout,
				panX: false,
				panY: false,
				wheelX: 'panX',
				wheelY: 'zoomX',
				height: 480,
			})
		);

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
		const cursor = chart.set('cursor', am5radar.RadarCursor.new(root, {
			behavior: 'zoomX',
		}));

		cursor.lineY.set('visible', false);

		// Create axes and their renderers
		// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
		const xRenderer = am5radar.AxisRendererCircular.new(root, {
			cellStartLocation: 0.2,
			cellEndLocation: 0.8,
		});

		// xRenderer.labels.template.setAll({
		// 	radius: 5,
		// });
		//

		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				maxDeviation: 0,
				categoryField: 'category',
				renderer: xRenderer,
				tooltip: am5.Tooltip.new(root, {}),
			})
		);

		xAxis.get('renderer').labels.template.setAll({
			fontSize: 16,
			textType: 'circular',
			radius: 20,
		});

		xAxis.data.setAll(data);

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5radar.AxisRendererRadial.new(root, {}),
				max: 1,
			})
		);

		// Create series
		// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
		// eslint-disable-next-line no-plusplus
		const series = chart.series.push(
			am5radar.RadarColumnSeries.new(root, {
				name: `Climate-Peace Mechanisms scores for the proposed climate action intervention`,
				xAxis,
				yAxis,
				valueYField: `value`,
				categoryXField: 'category',
			})
		);

		series.columns.template.setAll({
			tooltipText: '{valueY}',
			width: am5.percent(100),
		});

		series.data.setAll(data);

		const series2 = chart.series.push(
			am5radar.RadarColumnSeries.new(root, {
				name: `Ideal Climate-Peace Mechanisms scores`,
				xAxis,
				yAxis,
				valueYField: `value`,
				categoryXField: 'category',
			})
		);

		series2.columns.template.setAll({
			tooltipText: '{valueY}',
			width: am5.percent(100),
		});

		//
		// if (csstData.regions) {
		// 	const temp = csstData.inferred_data.map((item) => {
		// 		return { ...item, category: item.category.slice(2) };
		// 	});
		// 	console.log(temp);
		// 	series2.data.setAll(temp);
		// }

		if (csstData.name) {
			const temp = csstData.inferred_data.map((item) => {
				return { ...item, category: item.category.slice(2) };
			});
			console.log(temp);
			series2.data.setAll(temp);
		}

		// const legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {
		// 	width: 200,
		// 	paddingLeft: 15,
		// 	height: am5.percent(100),
		// }));

		const exporting = am5plugins_exporting.Exporting.new(root, {
		});

		exporting.export('png').then((imgData) => {
			setFlowerChartSrc(imgData);
		});

		const legend = chart.children.push(am5.Legend.new(root, {
			nameField: 'categoryX',
			centerX: am5.percent(0),
			height: '100px',
			// x: am5.percent(50),
			layout: root.verticalLayout,
			// y: am5.percent(0),
		}));
		// legend.data.push(series2);
		legend.data.setAll(chart.series.values);
		// legend.labels.template.set('forceHidden', true);

		series2.appear(1000);

		series.appear(1000);

		// Add scrollbars
		chart.set('scrollbarX', am5.Scrollbar.new(root, { orientation: 'horizontal', exportable: false }));
		chart.set('scrollbarY', am5.Scrollbar.new(root, { orientation: 'vertical', exportable: false }));

		// Animate chart
		// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
		chart.appear(1000, 100);

		return () => root.dispose();
	}, [data, csstData]);

	return (
		<div className="chart-container">
			{/* <p>{label}</p> */}
			<div id={flowerChartId} style={{ width, height: '550px' }} />
		</div>
	);
};
export default FlowerChart;
