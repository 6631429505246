/* eslint-disable consistent-return */
import React from 'react';
import './styles.css';
import Translator from '../../../../../../../../../../../../components/Translator';

const ChangeNAText = () => {
	const countryPerCountry = () => {
		if (window.location.href.includes('Ethiopia')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="The number of conflict events are associated with undernutrition factors such as children underweight and wasting prevalence as well as inequality (Sanitation facilities)." /></p>

					<p><Translator text="The country has experienced droughts, floods, and other extreme weather events, leading to crop failures and food insecurity. This is evident in the network where climate is closely related to socio-economic risks through various channels; such as the strong connection between drought (Climate water deficit, frequency of 5-day dry spells during rainy seasons) and agricultural production (Net primary production) which is then tightly connected to stunting prevalence and education inequality (difference of years of education between males and females)." /></p>

					<p><Translator text="This has contributed to the fragility of the country, as communities struggle to cope with the effects of climate change. In turn, the fragility of Ethiopia has been linked to conflict and migration, as communities are forced to compete for scarce resources in the land and water sector. The ongoing conflicts in the Tigray region and the displacement of millions of people within Ethiopia and across its borders are examples of this. Net migration sits in a nexus of climate (frequency of 5-day dry spells during rainy seasons), inequality (accessibility to healthcare services, years of education males, population density), resource scarcity (deforestation), and the prevalence of stunting and undernutrition." /></p>

					<p>	<Translator text="Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Sanitation facilities" /></li>
						<li><Translator text="Underweight prevalence (under 5 years)" /></li>
						<li><Translator text="Wasting prevalence (under 5 years)" /></li>
						<li><Translator text="Population density (Sen&apos;s, slope)" /></li>
						<li><Translator text="Population density (average)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Kenya')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="In Kenya, conflict indicators are closely associated with inequality variables, indicating that inequality is one of the primary pathways to instability. The total number of conflict events is connected to inequality factors (years of education for males, wealth index, population density, healthcare accessibility) and climate variables, such as maximum temperature and actual evapotranspiration." /></p>

					<p><Translator text="Agriculture, encompassing crops and livestock, is a crucial sector in Kenya&apos;s economy. However, it is highly vulnerable to climate change due to increasing temperatures, shifting rainfall patterns, and extreme weather events. The agricultural production node (net primary production) is situated at the heart of the climate cluster and is negatively correlated to many climate extremes, such as drought (number of days with a ratio of actual to potential evapotranspiration below 0.5) and heat stress (climate water deficit, heat stress on cattle, maximum temperature). This highlights the strong connection between climate extremes and the agricultural sector." /></p>

					<p><Translator text="Another extreme climate indicator (frequency of 5-day dry spells within rainy seasons) has a strong connection to migration and education inequalities (difference in years of education between males and females). This suggests that a significant climate event, when combined with particular socio-economic conditions, could be a critical factor in migration or instability in Kenya." /></p>

					<p><Translator text="Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="ears of education male" /></li>
						<li><Translator text="Absolute wealth index" /></li>
						<li><Translator text="Population density" /></li>
						<li><Translator text="Accessibility to healthcare services at 2019" /></li>
						<li><Translator text="Stunting prevalence (under 5 years)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Nigeria')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="In Nigeria, agricultural productivity (net primary production) is closely associated with various climate indicators, such as drought (climate water deficit) and heat stress (maximum temperature). This correlation suggests that climate extremes negatively affect agricultural production, which is closely related to socio-economic resilience. Specifically, undernutrition indicators (stunting prevalence, wasting prevalence) are embedded within climate and inequality variable." /></p>

					<p><Translator text="The country faces prevalent farmer-herder conflicts, with clashes arising between sedentary farmers and nomadic herders over resources like land, water, and grazing areas.The network highlights these challenges, showing the livestock Shannon diversity index as the primary socio-economic risk variable linked to conflict. This index is situated at the nexus of climate factors (actual evapotranspiration, climate water deficit, maximum temperature), inequality (difference in years of education between males and females, healthcare accessibility), and natural resources (deforestation). The conflicts often stem from competition for resources, historical grievances, and ethnic and religious tensions." /></p>

					<p><Translator text="Furthermore, deforestation, inequality, and low agricultural sector productivity collectively contribute to the emergence of conflict in Nigeria. The intricate interplay among these factors calls for a comprehensive and multi-dimensional approach to address the country&apos;s challenges in achieving sustainable agricultural productivity and social development." /></p>

					<p><Translator text="	Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Livestock Shannon diversity index" /></li>
						<li><Translator text="Difference of years of education (male - female)" /></li>
						<li><Translator text="Absolute wealth index" /></li>
						<li><Translator text="Stunting prevalence (under 5 years)" /></li>
						<li><Translator text="Years of education female" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Uganda')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="In Uganda, conflict is exacerbated by inequality factors, such as years of education for males and the difference in years of education. Migration is also positively correlated with conflict fatalities in particular, indicating the greater the conflict and associated fatalities, the greater for the propensity to migrate. Both net migration and inequality are also connected to climate extremes, which in turn is strongly linked to socio-economic risks through various channels." /></p>

					<p><Translator text="Drought, represented by the climate water deficit, has a strong positive correlation to undernutrition, as evidenced by wasting prevalence. Moreover, other climate drivers, like actual evapotranspiration, are linked to undernutrition factors such as stunting prevalence. These connections imply that livelihoods in Uganda heavily rely on subsistence agriculture, which is vulnerable to climate shocks." /></p>

					<p><Translator text="Additional evidence can be found in the connections between net primary production and agricultural area, both of which are driven by precipitation. Uganda&apos;s agricultural system predominantly relies on rainfed farming, making fluctuations in rainy seasons significantly impactful on food production and the country&apos;s overall food security." /></p>

					<p>	<Translator text="Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Estimated Net Migration" /></li>
						<li><Translator text="Years of education male" /></li>
						<li><Translator text="Difference of years of education (male - female)" /></li>
						<li><Translator text="Population density" /></li>
						<li><Translator text="Stunting prevalence (under 5 years)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Senegal')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="Conflict is the only thematic group which is quite tightly clustered as an individual group in this network, with only a few connections to productivity (livestock shannon diversity index) and inequality variables. However, conflict severity, measured by fatalities, is positioned further away from the main conflict cluster and exhibits stronger and more numerous connections to socio-economic risks and climate hazards." /></p>

					<p><Translator text="The network as a whole is highly interconnected, with few distinct clustered groups. This dense network indicates influential connections between various variables and a strong overlap between climate and socio-economic groups. This can be interpreted as low socio-economic resilience to climate shocks and a high potential for increased fragility, such as forced migration and conflict. Migration, in particular, has a negative correlation with inequality (population density, difference of years of education) and agricultural productivity (agricultural area)." /></p>

					<p><Translator text="Deforestation in Senegal sits at the intersection of climate, inequality, and agricultural productivity, illustrating the interconnected nature of these factors." /></p>

					<p>	<Translator text="Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Years of education male" /></li>
						<li><Translator text="Livestock Shannon diversity index" /></li>
						<li><Translator text="Piped water (percentage)" /></li>
						<li><Translator text="Years of education female" /></li>
						<li><Translator text="Difference of years of education (male - female)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Guatemala')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="Guatemalas network features a high level of interconnectivity, with numerous interspersed nodes and a few clustered groups. This illustrates the complexity of addressing the intertwined issues of climate risks and socio-economic vulnerabilities, as they both fuel and exacerbate each other. Conflict is linked to inequality, through indicators such as female education levels and healthcare accessibility, as well as agricultural indicators like the livestock Shannon diversity index." /></p>

					<p><Translator text="A negative correlation is observed between the years of education for both females and males and undernutrition indicators, such as stunting and wasting prevalence, indicating that higher education levels are associated with lower undernutrition rates. These factors are strongly correlated with heat and drought events, demonstrating the threats posed by climate change and erratic weather conditions to Guatemala&apos;s most vulnerable populations." /></p>

					<p><Translator text="Vulnerable groups, such as small-scale farmers and Indigenous communities, often experience the harshest impacts of climate extremes due to their limited resources and support systems. The network highlights these connections, with heat stress driving net primary production, which is closely related to resource exploitation (deforestation), socio-economic inequality (years of education female and male), and undernutrition (underweight prevalence). Additionally, agricultural areas are influenced by water availability, as evidenced by the climate water deficit indicator." /></p>

					<p><Translator text="	Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Years of education female" /></li>
						<li><Translator text="Accessibility to healthcare services at 2019" /></li>
						<li><Translator text="Livestock Shannon diversity index" /></li>
						<li><Translator text="Years of education male" /></li>
						<li><Translator text="Stunting prevalence (under 5 years)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Sudan')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="In Darfur, armed conflict and rapid population growth in the past few decades have added to the region&apos;s environmental and social challenges.Within the network, there is a positive correlation between conflicts and factors associated with inequality, such as disparities in levels of education (difference of years of education). We also see a correlation between between conflicts and access to healthcare services, as well as extreme climate conditions (days where the maximum temperature exceeds 30 degrees Celsius)." /></p>

					<p><Translator text="It is important to note that climate is also significantly linked to many inequality pathways in the network. Communities in Sudan are therefore highly vulnerable to the effects of climate change. These are expected to be particularly acute for Sudan as rising temperatures, unpredictable rainfall, increasingly severe droughts and even scarcer water resources take their toll on the country." /></p>

					<p><Translator text="The node representing migration is nested within the climate cluster and strongly associated with various climate drivers, such as extreme drought (Climate water deficit, actual evapotranspiration), heat stress, and flooding (number of days with waterlogging). This strong correlation indicates that migration in Sudan is heavily driven by climate, and it may also be a result of or a cause of conflict in the region. This is evident from the direct link between migration and the total number of conflicts in the network, where a greater number of conflicts is associated with increased migration." /></p>

					<p><Translator text="	Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Difference of years of education (male - female)" /></li>
						<li><Translator text="Accessibility to healthcare services at 2019" /></li>
						<li><Translator text="Estimated Net Migration" /></li>
						<li><Translator text="Years of education female" /></li>
						<li><Translator text="Wasting prevalence (under 5 years)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Zimbabwe')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="In Zimbabwe, there is a positive correlation between conflict and factors related to inequality, such as population density and access to sanitation facilities. This implies a link between inequality and social unrest." /></p>

					<p><Translator text="Water access and availability are emphasized through strong direct connections between migration, precipitation extremes, and inequality indicators, including piped water and sanitation facilities. Natural disasters, coupled with ongoing political and economic instability, prompt individuals to leave Zimbabwe in search of economic independence and improved living conditions." /></p>

					<p><Translator text="Successive droughts and climate change contribute to Zimbabwe&apos;s current food crisis. The network illustrates this by showing a close association between drought (climate water deficit) and undernutrition variables (stunting prevalence, wasting prevalence). The climate and undernutrition clusters are positioned tightly adjacent to each other within the network." /></p>

					<p>	<Translator text="Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Sanitation facilities" /></li>
						<li><Translator text="opulation density " /></li>
						<li><Translator text="Absolute wealth index" /></li>
						<li><Translator text="Relative wealth index" /></li>
						<li><Translator text="Piped water (percentage)" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Zambia')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="Zambia serves as an origin, transit, and destination for migrant populations. The strong connections between migration and several clusters within the network highlight the complexity of migration dynamics in the country,migration is also the primary socio-economic risk variable linked to conflict. Furthermore, migration is connected to climate (maximum temperature multi-annual coefficient of variation), inequality (population density, healthcare accessibility), and agriculture (livestock diversity index)." /></p>

					<p><Translator text="Although Zambia maintains relative stability compared to its neighbors, conflict and instability persist and exhibit numerous social linkages, such as the negative correlation of conflict to healthcare accessibility." /></p>

					<p><Translator text="Deforestation in Zambia occurs at a significant rate, with strong connections to climate factors and agricultural expansion. The network demonstrates these connections through close associations between deforestation and heat stress (heat stress on cattle, maximum temperature), flooding (number of days with waterlogging), and agriculture (net primary production, agricultural area)." /></p>

					<p><Translator text="	Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Estimated Net Migration" /></li>
						<li><Translator text="Accessibility to healthcare services at 2019" /></li>
						<li><Translator text="Years of education female" /></li>
						<li><Translator text="Stunting prevalence (under 5 years)" /></li>
						<li><Translator text="Population density" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Mali')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="In Mali, agricultural productivity (net primary production), livestock productivity (Livestock shannon diveristy index) and migration are three key socio-economic factors strongly related to conflict. This highlights the core of the farmer-herder conflict in Mali. The network shows heat stress (Heat stress on cattle, maximum temperature) is a fundamental climate variable with several strong connections to other clusters. A strong association exists between heat stress on cattle and agricultural indicators (agricultural area, livestock Shannon diversity), as well as inequality and undernutrition variables (stunting and wasting prevalence)." /></p>

					<p><Translator text="These correlations suggest that climate exacerbates resource competition, which is further intensified by weak governance systems and limited economic opportunities. Migration sits in the nexus of several clusters and is strongly connected to agricultural productivity, climate extremes and farmer-herder conflict." /></p>

					<p><Translator text="	Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Net primary production" /></li>
						<li><Translator text="Estimated Net Migration" /></li>
						<li><Translator text="Accessibility to healthcare services at 2019" /></li>
						<li><Translator text="Population density" /></li>
						<li><Translator text="Livestock Shannon diversity index" /></li>
					</ol>
				</div>
			);
		}
		if (window.location.href.includes('Philippines')) {
			return (
				<div className="country-na-text-container">
					<p><Translator text="Persistent conflict over the past several decades has exacerbated inequality and food security in various regions of the Philippines.This can be seen in the network through the interconnectivity between the conflict cluster and other factors such as migration, inequality, and agricultural productivity." /></p>

					<p><Translator text="The Philippines, an archipelagic nation with over 7,600 islands is particularly susceptible to climate change and natural disasters due to its unique geography. This vulnerability is evident within the agricultural sector, where key indicators such as net primary production and agricultural area are closely linked to the climate cluster. The network reveals that the agricultural sector (net primary production) isnegatively impacted by both drought (climate water deficit) and heat stress (heat stress on cattle, maximum temperature multi-annual 90th percentile)." /></p>

					<p><Translator text="Extreme climate events have a significant effect on vulnerable populations, many of whom rely on agriculture as their main source of income. This is illustrated by the strong connections between net migration and agricultural indicators. Additionally, the close ties between net migration and various socio-economic and undernutrition factors indicate the considerable challenges faced by displaced populations." /></p>

					<p><Translator text="	Most important socio-economic variables connected to (any) conflict variable:" /></p>
					<ol>
						<li><Translator text="Estimated Net Migration" /></li>
						<li><Translator text="Population density" /></li>
						<li><Translator text="Accessibility to healthcare services at 2019" /></li>
						<li><Translator text="Agricultural area" /></li>
						<li><Translator text="Wasting prevalence (under 5 years)" /></li>
					</ol>
				</div>
			);
		}
	};

	return countryPerCountry();
};

export default ChangeNAText;
