import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoVariableSelectedMetrics from '../NoVariableSelectedMetrics';
import { LineChart } from './components';
import './styles.css';
import Translator from '../../../../../../../../components/Translator';

const GlobalMetrics = () => {
	const contextFilterSelections = useSelector((state) => state.contextFilterSelections);

	const country = useSelector((state) => state.country);

	const [chartData, setChartData] = useState(null);
	const [showRank, setShowRank] = useState(true);

	useEffect(
		() => {
			if (contextFilterSelections.globalVariable?.name) {
				const newData = contextFilterSelections.globalVariable.data.filter((item) => {
					const temp = new Date(item.year);
					if (contextFilterSelections.globalDuration[0] && contextFilterSelections.globalDuration[1]) {
						const year = temp.getYear() + 1900;
						if (year >= contextFilterSelections.globalDuration[0] && year <= contextFilterSelections.globalDuration[1]) {
							return true;
						}
					}
					return false;
				});
				const temp = {
					name: contextFilterSelections.globalVariable.name,
					data: newData.map((item) => {
						if (item.score === 0 && item.rank === 0) {
							return { ...item, score: null, rank: null };
						} if (item.score === 0) {
							return { ...item, score: null };
						} if (item.rank === 0) {
							return { ...item, rank: null };
						}
						return item;
					}),
					// data: newData,
				};
				let flag = true;
				temp.data.map((item) => {
					if (item.rank) {
						flag = false;
					}
				});
				setShowRank(flag);
				setChartData(temp);
			}
		}, [contextFilterSelections]
	);

	if (!contextFilterSelections.globalVariable?.name) {
		return <NoVariableSelectedMetrics title={<Translator text="Global Metrics" />} />;
	}

	return (
		<div className="subpage global-metrics">
			<p className="title"><Translator text="Global Metrics" /></p>
			<div className="header">
				<p className="sub-title"><Translator text={contextFilterSelections.globalVariable?.name} /></p>
				<p className="sub-description"><Translator text={contextFilterSelections.globalVariable?.description} /></p>
				{/* <p> */}
				{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt */}
				{/*	ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco */}
				{/*	laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit */}
				{/*	in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat */}
				{/*	cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
				{/* </p> */}
			</div>
			{
				chartData
					? <LineChart data={chartData} showRank={showRank} />
					: null
			}
		</div>
	);
};

export default GlobalMetrics;
