/* eslint-disable consistent-return,react/no-unstable-nested-components */
import React from 'react';
import './styles.css';
import summaries from '../../summaries';
import Translator from '../../../../../../../../components/Translator';
import useCustomTranslate from '../../../../../../../../utils/translateFunction';

const NoVariableSelectedClimate = (props) => {
	const { title } = props;

	const renderContent = () => {
		if (window.location.href.includes('Kenya')) {
			return (
				summaries.kenya.climateText
			);
		}
		if (window.location.href.includes('Senegal')) {
			return (
				summaries.senegal.climateText
			);
		}
		if (window.location.href.includes('Guatemala')) {
			return (
				summaries.guatemala.climateText
			);
		}
		if (window.location.href.includes('Philippines')) {
			return (
				summaries.philippines.climateText
			);
		}
		if (window.location.href.includes('Zambia')) {
			return (
				summaries.zambia.climateText
			);
		}
		if (window.location.href.includes('Zimbabwe')) {
			return (
				summaries.zimbabwe.climateText
			);
		}
		if (window.location.href.includes('Sudan')) {
			return (
				summaries.sudan.climateText
			);
		}
		if (window.location.href.includes('Nigeria')) {
			return (
				summaries.nigeria.climateText
			);
		}
		if (window.location.href.includes('Mali')) {
			return (
				summaries.mali.climateText
			);
		}
		if (window.location.href.includes('Ethiopia')) {
			return (
				summaries.ethiopia.climateText
			);
		}
	};

	return (
		<div className="no-variable">
			<p className="title">{title}</p>
			{renderContent()}
			<div className="warning">
				<i className="fa-regular fa-circle-info" />
				<p className="open-sans"><Translator text={'Select Variables to view some data'} /></p>
			</div>
		</div>
	);
};

export default NoVariableSelectedClimate;
