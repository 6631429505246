import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Actions from '../../reducer/actions';
import './styles.css';

const NotFound = () => {
	const dispatch = useDispatch();
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });
	setShowMenus(false);
	useEffect(
		() => {
			// window.location.stop();
			setShowMenus(false);
		}, []
	);

	return (

		<div className="container">
			<div className="content">
				<h1 className="main-heading">404</h1>
				{/* <p>The page you&apos;re looking for could not be found</p> */}
				<p style={{ fontWeight: 'bold', fontSize: '35px', paddingBottom: '10px' }}>Ooops! You weren&apos;t supposed to see this</p>
				<p>The page you&apos;re looking for no longer exists.</p>
				<p>Return to the  <Link to="/Home">home page</Link> and remember: you haven&apos;t seen anything.</p>
			</div>
		</div>
	);
};

export default NotFound;
