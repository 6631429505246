/* eslint-disable react/jsx-no-useless-fragment,no-shadow */
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2';

const BubbleChart = (props) => {
	const { chartData, countryId, max, variable } = props;

	useEffect(() => {
		let counties = null;
		const county_lookup = {};

		const root = am5.Root.new('bubble-chart');
		root.setThemes([am5themes_Animated.new(root)]);

		root.numberFormatter.set('numberFormat', '#.');

		const chart = root.container.children.push(am5map.MapChart.new(root, {
			height: 400,
		}));

		// const polygonSeries = chart.series.push(
		// 	am5map.MapPolygonSeries.new(root, {
		// 		geoJSON: am5geodata_worldLow,
		// 		exclude: ['AQ'],
		// 	})
		// );

		const polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
			calculateAggregates: true,
			valueField: 'value',
			fill: '#c7c7c7',
		}));

		function loadGeodata(country) {
			// Default map
			const defaultMap = 'usaLow';

			if (country === 'US') {
				chart.set('projection', am5map.geoAlbersUsa());
			} else {
				chart.set('projection', am5map.geoMercator());
			}

			// calculate which map to be used
			let currentMap = defaultMap;
			let title = '';
			if (am5geodata_data_countries2[country] !== undefined) {
				// eslint-disable-next-line prefer-destructuring
				currentMap = am5geodata_data_countries2[country].maps[0];

				// add country title
				if (am5geodata_data_countries2[country].country) {
					title = am5geodata_data_countries2[country].country;
				}
			}

			am5.net.load(`https://cdn.amcharts.com/lib/5/geodata/json/${currentMap}.json`, chart).then((result) => {
				const geodata = am5.JSONParser.parse(result.response);
				const formatedData = [];
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < geodata.features.length; i++) {
					formatedData.push({
						id: geodata.features[i].id,
						value: Math.round(Math.random() * 10000),
					});
				}
				counties = geodata;
				counties = counties.features.map((p) => p.properties);
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < counties.length; i++) {
					const d = counties[i];
					county_lookup[d.name] = d;
				}
				polygonSeries.set('geoJSON', geodata);
				polygonSeries.data.setAll(formatedData);
			});

			// chart.seriesContainer.children.push(am5.Label.new(root, {
			// 	x: 5,
			// 	y: 5,
			// 	text: title,
			// 	background: am5.RoundedRectangle.new(root, {
			// 		fill: am5.color(0xffffff),
			// 		fillOpacity: 0.2,
			// 	}),
			// }));
		}

		loadGeodata(countryId);

		const bubbleSeries = chart.series.push(
			am5map.MapPointSeries.new(root, {
				valueField: 'value',
				calculateAggregates: true,
				polygonIdField: 'id',
			})
		);

		const circleTemplate = am5.Template.new({});

		bubbleSeries.bullets.push((root, series, dataItem) => {
			const container = am5.Container.new(root, {});

			const circle = container.children.push(
				am5.Circle.new(root, {
					radius: 10,
					fillOpacity: 0.7,
					fill: '#FDC82F',
					cursorOverStyle: 'pointer',
					tooltipText: `{name}: [bold]{value}[/]`,
				}, circleTemplate)
			);

			return am5.Bullet.new(root, {
				sprite: container,
				dynamic: true,
			});
		});

		bubbleSeries.bullets.push((root, series, dataItem) => {
			return am5.Bullet.new(root, {
				sprite: am5.Label.new(root, {
					text: '{value}',
					fill: '#2d2d2d',
					populateText: true,
					centerX: am5.p50,
					centerY: am5.p50,
					textAlign: 'center',
				}),
				dynamic: true,
			});
		});

		// minValue and maxValue must be set for the animations to work
		bubbleSeries.set('heatRules', [
			{
				target: circleTemplate,
				dataField: 'value',
				key: 'radius',
				// min: 1,
				// max: 100,
				// minValue: 1,
				// maxValue: max,
				customFunction: (sprite, min, maximum, value) => {
					// if (value) {
					sprite.set('radius', (100 / max) * value);
					// } else {
					// 	sprite.set('radius', 1);
					// }
				},
			},
		]);

		const label = bubbleSeries.children.unshift(am5.Label.new(root, {
			text: `${chartData[0].year}`,
			fontSize: 25,
			fontWeight: '500',
			textAlign: 'center',
			position: 'relative',
			x: am5.percent(50),
			// centerX: am5.percent(50),
		}));

		let counter = 0;
		bubbleSeries.data.setAll(chartData[0].data.map((item, i) => ({ value: item.value, id: item.id, name: item.name })));
		setInterval(() => {
			label.set('text', `${chartData[counter].year}`);
			updateData(counter);
			counter += 1;
			if (counter === chartData.length) {
				counter = 0;
			}
		}, 2000);

		function updateData(index) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < bubbleSeries.dataItems.length; i++) {
				bubbleSeries.data.setIndex(i, { value: chartData[index].data[i].value, id: chartData[index].data[i].id, name: chartData[index].data[i].name });
			}
		}

		// series.appear(1000);
		chart.appear(1000, 100);

		return () => root.dispose();
	}, [chartData, max]);

	return (
		<div id="bubble-chart" style={{ width: '100%', height: '500px' }} />
	);
};
export default BubbleChart;
