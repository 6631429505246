import React from 'react';
import { useSelector } from 'react-redux';
import { MapComponent, ClusterDefinition } from './components';
import './styles.css';

const Where = () => {
	const whereSubpageSelection = useSelector((state) => state.whereSubpageSelection);

	const renderCorrectPage = () => {
		if (whereSubpageSelection === 'map') {
			return <MapComponent />;
		}
		if (whereSubpageSelection === 'definition') {
			return <ClusterDefinition />;
		}
		return null;
	};
	
	return (
		<div className="where">
			{renderCorrectPage()}
		</div>
	);
};

export default Where;
