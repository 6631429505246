/* eslint-disable consistent-return,no-dupe-else-if,import/no-cycle */
import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import RegistrationService from '../../services/httpService/registrationService';

import './styles.css';
import Translator from '../Translator';

const PopUp = (props) => {
	const { setShowRegistrationForm } = props;
	const toast = useRef(null);
	const [countrySelection, setCountrySelection] = useState([]);
	const [csoUsageSelection, setCsoUsageSelection] = useState(null);
	const [typeSelection, setTypeSelection] = useState(null);
	const [organisationName, setOrganisationName] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [value, setValue] = useState(null);
	const [registrationFormData, setRegistrationFormData] = useState({});
	const [disableButton, setDisableButton] = useState(true);

	const countryData = [
		{ country: 'Andorra' },
		{ country: 'Antigua and Barbuda' },
		{ country: 'American Samoa' },
		{ country: 'Afghanistan' },
		{ country: 'Albania' },
		{ country: 'Aruba' },
		{ country: 'The United Arab Emirates' },
		{ country: 'Armenia' },
		{ country: 'Anguilla' },
		{ country: 'Angola' },
		{ country: 'Austria' },
		{ country: 'Antarctica' },
		{ country: 'Barbados' },
		{ country: 'Bangladesh' },
		{ country: 'Azerbaijan' },
		{ country: 'Argentina' },
		{ country: 'Australia' },
		{ country: 'Bermuda' },
		{ country: 'Bolivia' },
		{ country: 'Bosnia and Herzegovina' },
		{ country: 'Bahrain' },
		{ country: 'Burkina Faso' },
		{ country: 'Belgium' },
		{ country: 'Belize' },
		{ country: 'Brazil' },
		{ country: 'Bahamas' },
		{ country: 'Bulgaria' },
		{ country: 'Burundi' },
		{ country: 'Benin' },
		{ country: 'Botswana' },
		{ country: 'Bhutan' },
		{ country: 'Canada' },
		{ country: 'Côte d\'Ivoire' },
		{ country: 'Cocos (Keeling) Islands' },
		{ country: 'The Democratic Republic of the Congo' },
		{ country: 'Central African Republic' },
		{ country: 'Belarus' },
		{ country: 'Denmark' },
		{ country: 'Switzerland' },
		{ country: 'Cook Islands' },
		{ country: 'Cameroon' },
		{ country: 'Chile' },
		{ country: 'Algeria' },
		{ country: 'Cuba' },
		{ country: 'Djibouti' },
		{ country: 'Costa Rica' },
		{ country: 'Christmas Island' },
		{ country: 'Dominican Republic' },
		{ country: 'Colombia' },
		{ country: 'Czechia' },
		{ country: 'Western Sahara' },
		{ country: 'Egypt' },
		{ country: 'Ecuador' },
		{ country: 'Estonia' },
		{ country: 'China' },
		{ country: 'Cabo Verde' },
		{ country: 'Cyprus' },
		{ country: 'Germany' },
		{ country: 'Gabon' },
		{ country: 'Eritrea' },
		{ country: 'Dominica' },
		{ country: 'Fiji' },
		{ country: 'Spain' },
		{ country: 'Georgia' },
		{ country: 'United Kingdom of Great Britain and Northern Ireland' },
		{ country: 'Ethiopia' },
		{ country: 'Faroe Islands' },
		{ country: 'France' },
		{ country: 'Grenada' },
		{ country: 'Finland' },
		{ country: 'Guinea' },
		{ country: 'Gibraltar' },
		{ country: 'Guinea-Bissau' },
		{ country: 'Guam' },
		{ country: 'Greece' },
		{ country: 'Greenland' },
		{ country: 'Gambia' },
		{ country: 'Ireland' },
		{ country: 'Ghana' },
		{ country: 'Equatorial Guinea' },
		{ country: 'Guatemala' },
		{ country: 'Guyana' },
		{ country: 'Hungary' },
		{ country: 'Indonesia' },
		{ country: 'Israel' },
		{ country: 'British Indian Ocean Territory' },
		{ country: 'Honduras' },
		{ country: 'Croatia' },
		{ country: 'The Islamic Republic of Iran' },
		{ country: 'Haiti' },
		{ country: 'Jersey' },
		{ country: 'Jordan' },
		{ country: 'Isle of Man' },
		{ country: 'Italy' },
		{ country: 'India' },
		{ country: 'Japan' },
		{ country: 'Iceland' },
		{ country: 'Jamaica' },
		{ country: 'Saint Lucia' },
		{ country: 'Liechtenstein' },
		{ country: 'Iraq' },
		{ country: 'Cambodia' },
		{ country: 'Kenya' },
		{ country: 'Cayman Islands' },
		{ country: 'Saint Kitts and Nevis' },
		{ country: 'Liberia' },
		{ country: 'Kazakhstan' },
		{ country: 'Kyrgyzstan' },
		{ country: 'Kiribati' },
		{ country: 'Lao People\'s Democratic Republic' },
		{ country: 'Kuwait' },
		{ country: 'Moldova' },
		{ country: 'Comoros' },
		{ country: 'Marshall Islands' },
		{ country: 'Madagascar' },
		{ country: 'Lebanon' },
		{ country: 'Lesotho' },
		{ country: 'Morocco' },
		{ country: 'Northern Mariana Islands' },
		{ country: 'Sri Lanka' },
		{ country: 'Monaco' },
		{ country: 'Montenegro' },
		{ country: 'Mali' },
		{ country: 'Mongolia' },
		{ country: 'Mauritania' },
		{ country: 'Montserrat' },
		{ country: 'Mauritius' },
		{ country: 'Mexico' },
		{ country: 'Malta' },
		{ country: 'Malawi' },
		{ country: 'Myanmar' },
		{ country: 'Luxembourg' },
		{ country: 'Lithuania' },
		{ country: 'Namibia' },
		{ country: 'Maldives' },
		{ country: 'Libya' },
		{ country: 'Norway' },
		{ country: 'Mozambique' },
		{ country: 'Niger' },
		{ country: 'Nicaragua' },
		{ country: 'Netherlands' },
		{ country: 'New Caledonia' },
		{ country: 'Malaysia' },
		{ country: 'Nigeria' },
		{ country: 'New Zealand' },
		{ country: 'Peru' },
		{ country: 'Nepal' },
		{ country: 'Oman' },
		{ country: 'Nauru' },
		{ country: 'Niue' },
		{ country: 'Panama' },
		{ country: 'Poland' },
		{ country: 'Pakistan' },
		{ country: 'Saint Pierre and Miquelon' },
		{ country: 'Palau' },
		{ country: 'Portugal' },
		{ country: 'French Polynesia' },
		{ country: 'Philippines' },
		{ country: 'Saudi Arabia' },
		{ country: 'Puerto Rico' },
		{ country: 'Papua New Guinea' },
		{ country: 'Romania' },
		{ country: 'Russian Federation' },
		{ country: 'Rwanda' },
		{ country: 'Paraguay' },
		{ country: 'Sweden' },
		{ country: 'Qatar' },
		{ country: 'Sierra Leone' },
		{ country: 'Suriname' },
		{ country: 'Solomon Islands' },
		{ country: 'Serbia' },
		{ country: 'Seychelles' },
		{ country: 'Singapore' },
		{ country: 'Slovenia' },
		{ country: 'San Marino' },
		{ country: 'The Republic of the Sudan' },
		{ country: 'Somalia' },
		{ country: 'Senegal' },
		{ country: 'Sao Tome and Principe' },
		{ country: 'Chad' },
		{ country: 'Saint Helena, Ascension and Tristan da Cunha' },
		{ country: 'Turks and Caicos Islands' },
		{ country: 'Tajikistan' },
		{ country: 'Turkmenistan' },
		{ country: 'Tokelau' },
		{ country: 'Togo' },
		{ country: 'Slovakia' },
		{ country: 'Thailand' },
		{ country: 'Eswatini' },
		{ country: 'El Salvador' },
		{ country: 'Uganda' },
		{ country: 'Ukraine' },
		{ country: 'United States of America' },
		{ country: 'Tuvalu' },
		{ country: 'Türkiye' },
		{ country: 'Tunisia' },
		{ country: 'Tonga' },
		{ country: 'Trinidad and Tobago' },
		{ country: 'Tanzania, United Republic' },
		{ country: 'Uruguay' },
		{ country: 'The Socialist Republic of Viet Nam' },
		{ country: 'Yemen' },
		{ country: 'Uzbekistan' },
		{ country: 'Wallis and Futuna' },
		{ country: 'Zimbabwe' },
		{ country: 'Venezuela' },
		{ country: 'Samoa' },
		{ country: 'Zambia' },
		{ country: 'Hong Kong' },
		{ country: 'British Virgin Islands' },
		{ country: 'Macao' },
		{ country: 'French Guiana' },
		{ country: 'Brunei Darussalam' },
		{ country: 'Mayotte' },
		{ country: 'South Africa' },
		{ country: 'Congo' },
		{ country: 'Bouvet Island' },
		{ country: 'Aland Islands' },
		{ country: 'The Democratic People\'s Republic of Korea' },
		{ country: 'Vanuatu' },
		{ country: 'Guadeloupe' },
		{ country: 'Holy See' },
		{ country: 'Heard Island and McDonald Islands' },
		{ country: 'Latvia' },
		{ country: 'North Macedonia' },
		{ country: 'Falkland Islands [Malvinas]' },
		{ country: 'French Southern Territories' },
		{ country: 'Martinique' },
		{ country: 'The Republic of Korea' },
		{ country: 'Saint Vincent and the Grenadines' },
		{ country: 'Guernsey' },
		{ country: 'Svalbard and Jan Mayen' },
		{ country: 'South Sudan' },
		{ country: 'Saint Martin (French part)' },
		{ country: 'Norfolk Island' },
		{ country: 'South Georgia and the South Sandwich Islands' },
		{ country: 'The Federated States of Micronesia' },
		{ country: 'Réunion' },
		{ country: 'Saint Barthélemy' },
		{ country: 'Timor-Leste' },
		{ country: 'United States Minor Outlying Islands' },
		{ country: 'Pitcairn' },
		{ country: 'Bonaire, Sint Eustatius and Saba' },
		{ country: 'Sint Maarten (Dutch part)' },
		{ country: 'Taiwan (Province of China)' },
		{ country: 'Syrian Arab Republic' },
		{ country: 'Palestine, State of' },
		{ country: 'Curaçao' },
		{ country: 'The Virgin Islands of the United States' },
	];

	const usageData = ['Climate conflict relationships', 'Identifying communities of interest', 'Research', 'Policy Setting / Changes'];

	const typeData = ['Academy', 'Government Agency', 'Research Group', 'Private Organisation'];

	const cookies = new Cookies();
	const cookiesSkipForm = new Cookies();
	const expirationDate = new Date();

	expirationDate.setDate(expirationDate.getDate() + 365);

	const expirationDateSkipForm = new Date();

	expirationDateSkipForm.setDate(expirationDateSkipForm.getDate() + 30);

	useEffect(() => {
		const usersData = {
			country: countrySelection.country,
			usage: csoUsageSelection,
			organization_type: typeSelection,
			organization_name: organisationName,
			email: userEmail,
		};

		setRegistrationFormData(usersData);
	}, [countrySelection, csoUsageSelection, typeSelection, organisationName, userEmail]);

	useEffect(() => {
		checkIfInputsAreFilled();
	}, [countrySelection, csoUsageSelection, typeSelection, organisationName, userEmail, value]);

	const checkIfInputsAreFilled = () => {
		const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (value) {
			if (!userEmail.match(isValidEmail)) {
				setDisableButton(true);
			} else if (userEmail.match(isValidEmail) && organisationName !== '' && typeSelection && csoUsageSelection && countrySelection) {
				setDisableButton(false);
			}
		} else if (!value) {
			if (organisationName !== '' && typeSelection && csoUsageSelection && countrySelection) {
				setDisableButton(false);
			}
		}
	};
	const sendUsersData = () => {
		checkIfInputsAreFilled();
		if (!disableButton) {
			RegistrationService.getRegistrationData(registrationFormData)
				.then(() => {
					cookies.set('registrationForm', true, { path: '/', expires: expirationDate });
					toast.current.show({ severity: 'success', summary: 'Thank you', detail: 'Your registration has been successfully completed!', life: 6000 });
					const closePopUp = setTimeout(() => {
						setShowRegistrationForm(false);
					}, 2000);
					return () => clearTimeout(closePopUp);
				})
				.catch(() => {
					toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong please try again!', life: 6000 });
				});
		} else {
			return null;
		}
	};

	const skipRegistrationFrom = () => {
		setShowRegistrationForm(false);
		cookiesSkipForm.set('showRegistrationForm', true, { path: '/', expires: expirationDateSkipForm });
	};
	
	return (
		<>
			<Toast ref={toast} />
			<div className="popup-container">
				<div className="popup-content">
					<div className="popup-header">
						<h2><Translator text="Enjoying the Platform?" /></h2>
						{/* <div tabIndex="0" role="button" aria-label="exit" onClick={() => setShowRegistrationForm(false)}><i className="fa-regular fa-xmark" /></div> */}
					</div>
					<p><Translator text="We would like to collect some information about your use of the platform so that we can improve it" /></p>
					<div className="warning">
						<i className="fa-regular fa-circle-info" />
						<p className="open-sans"><Translator text="Any data we collect is to improve the platform and understand its users needs. We will not share this information with third parties." /></p>
					</div>
					<div className="registration-form">
						<div className="inner-content">
							<p><Translator text="Which country do you operate in*" /></p>
							<Dropdown
								value={countrySelection}
								onChange={(e) => setCountrySelection(e.value)}
								options={countryData}
								optionLabel="country"
								placeholder="Please select relevant country"
								className="inputs w-full md:w-14rem"
								filter
								filterBy="country"
								// filterBy={countrySelection}

							/>
							<p><Translator text="What do you use the CSO for*" /></p>
							<MultiSelect
								value={csoUsageSelection}
								onChange={(e) => setCsoUsageSelection(e.value)}
								options={usageData}
								placeholder="Please select relevant themes"
								maxSelectedLabels={1}
								className="inputs w-full md:w-14rem"
							/>
							<p><Translator text="What is the type of your organisation*" /></p>
							<Dropdown
								value={typeSelection}
								onChange={(e) => setTypeSelection(e.value)}
								options={typeData}
								placeholder="Please select relevant organisation type"
								className="inputs w-full md:w-14rem"
							/>
							<InputText placeholder="Name of your organisation*" className="inputs w-full md:w-14rem" value={organisationName} onChange={(e) => setOrganisationName(e.target.value)} />
						</div>
						<div className="agreement-check">
							<p><Translator text="I agree to being contacted by and notified of changes made to CSO." /></p>
							<Checkbox onChange={(e) => setValue(e.checked)} checked={value} disabled={!!(organisationName === '' || !typeSelection || !csoUsageSelection || !countrySelection)} />
						</div>
						{
							value
								?	(
									<div className="email-content">
										<InputText placeholder="Email Address*" style={{ minWidth: '345px' }} className="inputs w-full md:w-14rem" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
									</div>
								)
								: null
						}
						<div className="popup-buttons">
							<Button label={<Translator text="Complete" />} onClick={() => sendUsersData()} disabled={!!disableButton} />
							<Button label={<Translator text="Skip" />} aria-label="skip" tabIndex="0" role="button" onClick={() => skipRegistrationFrom(false)} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default PopUp;
