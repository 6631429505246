import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import annaImage from '../../../../assets/images/Team/anna.jpg';
import haroldImage from '../../../../assets/images/Team/harold.jpg';
import ashleighImage from '../../../../assets/images/Team/ashleigh.jpg';
import biaImage from '../../../../assets/images/Team/bia.jpg';
import leoImage from '../../../../assets/images/Team/leo.jpg';
import andresImage from '../../../../assets/images/Team/andres.jpg';
import theresaImage from '../../../../assets/images/Team/theresa.jpg';
import peterImage from '../../../../assets/images/Team/peter.jpg';
import graziaImage from '../../../../assets/images/Team/grazia.jpg';
import giuliacaroliImage from '../../../../assets/images/Team/giuliacaroli.jpg';
import allessandroImage from '../../../../assets/images/Team/allessandro.jpg';
import bensonImage from '../../../../assets/images/Team/benson.jpg';
import ignacioImage from '../../../../assets/images/Team/ignacio.jpg';
import marinaImage from '../../../../assets/images/Team/marina.jpg';
import giulianoImage from '../../../../assets/images/Team/giuliano.jpg';
import onyImage from '../../../../assets/images/Team/ony.jpg';
import giosueImage from '../../../../assets/images/Team/giosue.jpg';
import carolinaImage from '../../../../assets/images/Team/carolina.jpg';
import adamImage from '../../../../assets/images/Team/adam.jpg';
import niklasImage from '../../../../assets/images/Team/niklas.jpg';
import cesareImage from '../../../../assets/images/Team/cesare.jpg';
import fransImage from '../../../../assets/images/Team/frans.jpg';
import giuliatucciImage from '../../../../assets/images/Team/giuliatucci.jpg';
import victorImage from '../../../../assets/images/Team/victor.jpg';
import panagiotisImage from '../../../../assets/images/Team/panagiotis.jpg';
import sotirisImage from '../../../../assets/images/Team/sotiris.jpg';
import nikosImage from '../../../../assets/images/Team/nikos.jpg';
import nadiaImage from '../../../../assets/images/Team/nadia.jpg';
import manolisImage from '../../../../assets/images/Team/manolis.jpg';
import joramImage from '../../../../assets/images/Team/joram.jpg';
import julianImage from '../../../../assets/images/Team/julian.jpg';
import matthewImage from '../../../../assets/images/Team/matthew.jpg';
import krisImage from '../../../../assets/images/Team/kris.jpg';
import alexImage from '../../../../assets/images/Team/alex.jpg';
import evanImage from '../../../../assets/images/Team/evan.jpg';
import ewautImage from '../../../../assets/images/Team/ewaut.jpg';
import sigiImage from '../../../../assets/images/Team/sigi.jpg';
import tanayaImage from '../../../../assets/images/Team/tanaya.jpg';
import taniaImage from '../../../../assets/images/Team/tania.jpg';
import tinaImage from '../../../../assets/images/Team/tina.jpg';
import { howImage, whatImage, whereImage, whoImage } from '../../../../assets/images/images';
import Actions from '../../../../reducer/actions';
import { Cards } from './components';
import './styles.css';
import Translator from '../../../../components/Translator';

const Team = () => {
	const dispatch = useDispatch();
	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });

	useEffect(
		() => {
			setCurrentPage('Team');
			setShowMenus(true);
		}, []
	);

	return (
		<div className="team-container">
			<div className="team-content">
				<h2><Translator text="Analysis" /></h2>
				<div className="cards-container">
					<Cards image={haroldImage} name="Harold" lastName="Achicanoy Estrella" title={<Translator text="Spatial Analysis" />} iconWhere={whereImage} iconTextWhere={<Translator text="Where" />} iconWho={whoImage} iconTextWho={<Translator text="Who" />} />
					<Cards image={ashleighImage} name="Ashleigh" lastName="Basel" title={<Translator text="Network Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={annaImage} name="Anna" lastName="Belli" title={<Translator text="Econometric Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={biaImage} name="Bia" lastName="Carneiro" title={<Translator text="Social Media Analysis" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={giuliacaroliImage} name="Giulia" lastName="Caroli" title={<Translator text="Expert workshops" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={allessandroImage} name="Alessandro" lastName="Craparo" title={<Translator text="Network Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={bensonImage} name="Benson" lastName="Kenduiywo" title={<Translator text="Spatial Analysis" />} iconWhere={whereImage} iconTextWhere={<Translator text="Where" />} iconWho={whoImage} iconTextWho={<Translator text="Who" />} />
					<Cards image={ignacioImage} name="Ignacio" lastName="Madurga-Lopez" title={<Translator text="Climate Security Pathway Analysis / Expert workshops" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={marinaImage} name="Marina" lastName="Mastrorillo" title={<Translator text="Econometric Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={leoImage} name="Leonardo" lastName="Medina" title={<Translator text="Social Learning Theory / Expert workshops" />} iconHow={howImage} iconTextHow={<Translator text="How" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={andresImage} name="Andres" lastName="Mendez" title={<Translator text="Spatial Analysis" />} iconWhere={whereImage} iconTextWhere={<Translator text="Where" />} iconWho={whoImage} iconTextWho={<Translator text="Who" />} />
					<Cards image={onyImage} name="Ony" lastName="Minoarivelo" title={<Translator text="Network Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={julianImage} name="Julian" lastName="Ramirez-Villegas" title={<Translator text="Spatial Analysis" />} iconHow={whereImage} iconTextHow={<Translator text="Where" />} />
					<Cards image={giulianoImage} name="Giuliano" lastName="Resce" title={<Translator text="Social Media Analysis" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={giosueImage} name="Giosuè" lastName="Ruscica" title={<Translator text="Social Media Analysis" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={carolinaImage} name="Carolina" lastName="Sarzana" title={<Translator text="Climate Security Sensitivity Tool" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={adamImage} name="Adam" lastName="Savelli" title={<Translator text="Expert workshops" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={niklasImage} name="Niklas" lastName="Sax" title={<Translator text="Climate Security Pathway Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={cesareImage} name="Cesare" lastName="Scartozzi" title={<Translator text="Policy Coherence Analysis" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={fransImage} name="Frans" lastName="Schapendonk" title={<Translator text="Policy Coherence Analysis" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={joramImage} name="Joram" lastName="Tarusarira" title={<Translator text="Climate Security Pathway Analysis/ Expert Workshops" />} iconHow={howImage} iconTextHow={<Translator text="How" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={giuliatucciImage} name="Giulia" lastName="Tucci" title={<Translator text="Social Media Analysis" />} iconWhat={whatImage} iconTextWhat={<Translator text="What" />} />
					<Cards image={victorImage} name="Victor" lastName="Villa" title={<Translator text="Econometric Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
				</div>
			</div>
			<div className="team-content management">
				<h2><Translator text="Management" /></h2>
				<div className="cards-container">
					<Cards image={peterImage} name="Peter" lastName="Laederach" title={<Translator text="Co-lead CGIAR Climate Security Research Focus" />} />
					<Cards image={theresaImage} name="Theresa" lastName="Liebig" title={<Translator text="CSO lead" />} />
					<Cards image={graziaImage} name="Grazia" lastName="Pacillo" title={<Translator text="Co-lead CGIAR Climate Security Research Focus" />} />
				</div>
			</div>
			<div className="team-content">
				<h2><Translator text="Platform Development" /></h2>
				<div className="cards-container">
					<Cards image={manolisImage} name="Manolis" lastName="Atsilmis" title={<Translator text="Software Engineer" />} />
					<Cards image={krisImage} name="Kris" lastName="Galoppin" title={<Translator text="UI Designer/Content Editor" />} />
					<Cards image={nadiaImage} name="Nadia" lastName="Gketi" title={<Translator text="UI/UX Engineer" />} />
					<Cards image={sotirisImage} name="Sotiris" lastName="Konstantinidis" title={<Translator text="Lead Engineer" />} />
					<Cards image={matthewImage} name="Matthew" lastName="Vanhoutte" title={<Translator text="UI Designer " />} />
					<Cards image={nikosImage} name="Nikos" lastName="Vasilopoulos" title={<Translator text="Web Engineer" />} />
					<Cards image={panagiotisImage} name="Panagiotis" lastName="Zervas" title={<Translator text="Project Manager" />} />
				</div>
			</div>
			<div className="team-content collaborators">
				<h2><Translator text="Collaborators" /></h2>
				<div className="cards-container">
					<Cards image={tanayaImage} name="Tanaya" lastName="Dutta Gupta" title={<Translator text="Climate Security Pathway Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={taniaImage} name="Tània" lastName="Ferré Garcia" title={<Translator text="Climate Security Pathway Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={ewautImage} name="Ewaut" lastName="Kissel" title={<Translator text="Programmer" />} />
					<Cards image={sigiImage} name="Sigmund" lastName="Kluckner" title={<Translator text="User-centric design" />} />
					<Cards image={tinaImage} name="Tina" lastName="Kurath" title={<Translator text="Climate Security Pathway Analysis" />} iconHow={howImage} iconTextHow={<Translator text="How" />} />
					<Cards image={alexImage} name="Alex" lastName="Orenstein" title={<Translator text="Spatial Analysis" />} iconWhere={whereImage} iconTextWhere={<Translator text="Where" />} iconWho={whoImage} iconTextWho={<Translator text="Who" />} />
					<Cards image={evanImage} name="Evan" lastName="Tan" title={<Translator text="Communication" />} />
				</div>
			</div>
		</div>
	);
};

export default Team;
