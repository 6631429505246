import React from 'react';

const x = (gaugeChart, flowerChart, table, location, themes, organizationAndProject, country, legend) => `<html>` +
	`<head>` +
	`    <link rel="preconnect" href="https://fonts.googleapis.com">` +
	`    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>` +
	`    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,600;0,700;1,500&display=swap" rel="stylesheet">` +
	`` +
	`` +
	`    <style>` +
	`body {` +
	`background-color: #fff;` +
	`font-family: "Montserrat", sans-serif;` +
	`display: flex;` +
	`flex-direction: column;` +
	`align-items: center;` +
	`width: 100%;` +
	`margin: 0 auto;` +
	`}` +
	`` +
	`.container {` +
	`display: flex;` +
	`flex-direction: column;` +
	`align-items: flex-start;` +
	`width: 80%;` +
	`margin-bottom: 40px;` +
	`}` +
	`` +
	`.main-heading {` +
	`color: #FDC82F;` +
	`font-size: 36px;` +
	`font-weight: bold;` +
	`margin: 5px 0;` +
	`}` +
	`` +
	`.org-name {` +
	`font-weight:600;` +
	`font-size: 32px;` +
	`margin: 5px 0;;` +
	`}` +
	`` +
	`.org-name span {` +
	`font-weight:400;` +
	`}` +
	`` +
	`.info{` +
	`display: flex;` +
	`flex-direction: column;` +
	`gap: 5px;` +
	`text-align: justify;` +
	`}` +
	`` +
	`.category {` +
	`display: flex;` +
	`flex-direction: row;` +
	`gap: 20px;` +
	`margin: 10px 0 30px;` +
	`}` +
	`` +
	`.category div{` +
	`padding: 6px 15px;` +
	`border: 1px solid #d5d5d5;` +
	`background: #d5d5d5;` +
	`border-radius: 20px;` +
	`}` +
	`` +
	`.map{` +
	`display: flex;` +
	`flex-direction: column;` +
	`align-items: center;` +
	`}` +
	`` +
	`.map h4{` +
	`font-weight:600;` +
	`font-size: 20px;` +
	`margin: 5px 0;` +
	`}` +
	`` +
	`.map h4 span {` +
	`font-weight:400;` +
	`}` +
	`` +
	`.steps{` +
	`margin-top: 20px;` +
	`display: flex;` +
	`flex-direction: column;` +
	`text-align: justify;` +
	`}` +
	`` +
	`.steps img {` +
	`align-self: center;` +
	`width: 60vw;` +
	`height: 400px;` +
	`object-fit: cover;` +
	`}` +
	`` +
	`.steps h3 {` +
	`font-weight:500;` +
	`font-size: 24px;` +
	`margin: 0;` +
	`}` +
	`` +
	`.steps h4 {` +
	`font-weight:500;` +
	`font-size: 20px;` +
	`text-align: center;` +
	`}` +
	`` +
	`th {` +
	`color: #427730;` +
	`}` +
	`` +
	`td, th {` +
	`border: 1px solid #f9f9f9;` +
	`text-align: left;` +
	`padding: 8px;` +
	`}` +
	`` +
	`tr:nth-child(even) {` +
	`background-color: #f0eeee;` +
	`}` +
	`` +
	`.summary {` +
	`display: flex;` +
	`flex-direction: column;` +
	`align-items: center;` +
	`}` +
	`` +
	`.summary h4{` +
	`font-weight:500;` +
	`font-size: 20px;` +
	`margin-top: 100px;` +
	`text-align: center;` +
	`}` +
	`` +
	`.summary .flower {` +
	`align-self: center;` +
	`width: 60vw;` +
	`height: 600px;` +
	`object-fit: cover;` +
	`}` +
	`.summary .legend {` +
	`align-self: center;` +
	`}` +
	`    </style>` +
	`    <meta charset="UTF-8">` +
	`</head>` +
	`<body>` +
	`` +
	`<div class="container">` +
	`    <h1 class="main-heading">Climate Security Sensitivity Tool (CSST)</h1>` +
	`    <p class="org-name">${organizationAndProject}</span></p>` +
	`    <div class="category">` +
	`    ${themes}` +
	`    </div>` +
	`    <div class="info">` +
	`        <div class="map">` +
	`            <h4>${location}</h4>` +
	`        </div>` +
	`        <p>This tool targets practitioners, decision makers and multilateral institutes interested in diagnostic research for peace responsive climate action in the context of rural` +
	`            development. The CSST is useful for stakeholders investing in, and designing an agricultural climate action program with the goal to prevent maladaptation and related` +
	`            unintended consequences, avoid conflict relapses and contribute to peacebuilding. It highlights important programming features to prioritize based on context-specific` +
	`            drivers of conflict and it indicates the suitability of a program in relation to such drivers.</p>` +
	`    </div>` +
	`` +
	`    <div class="steps">` +
	`        <h3>Contextual Drivers</h3>` +
	`        <p>Step 1 characterizes the context of the location where your program is expected to be implemented. Context-specific drivers of conflict and insecurity for your selected area are retrieved from the Joint Research Centre’s INFORM risk index.</p>` +
	`        <h4>Context Drivers for ${country}</h4>` +
	` 		 <img src="${gaugeChart}" />` +
	`    </div>` +
	`    <div class="steps">` +
	`        <h3>Climate - Peace Program Data</h3>` +
	`        <p>Step 2 assesses a set of climate-peace mechanisms and indicators. For each of mechanism and examples of practices for that mechanism (indicators), you indicated if the listed criteria are fulfilled (=1), somehow fulfilled (=0.5) or not fulfilled (=0). Below are the results of your scoring.</p>` +
	`        <h4>Form Data as Inputted</h4>` +
	`        <table>` +
	`            <tr>` +
	`                <th>Sub-mechanism</th>` +
	`                <th>Comment</th>` +
	`                <th>Score</th>` +
	`            </tr>${ 
		table 
	}        </table>` +
	`    </div>` +
	`    <div class="summary">` +
	`        <h4>Summary of Program / Project Data Against Contextual Drivers Marked with Areas of Higher Risk</h4>` +
	` 		 <img class="flower" src="${flowerChart}" />` +
	`        <img class="legend" src="${legend}" />` +
	`    </div>` +
	`` +
	`</div>` +
	`</body>` +
	`</html>`;

export default x;
