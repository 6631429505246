import React from 'react';
import { T } from '@tolgee/react';
import makeKey from '../../utils/makeKey';

const Translator = (props) => {
	const { text, params } = props;

	return (
		<T
			keyName={makeKey(text)}
			params={params}
			defaultValue={text}
		/>
	);
};

export default Translator;
