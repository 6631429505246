import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Translator from '../../../../components/Translator';

const PapersDialogContent = (props) => {
	const { data } = props;

	const urlTemplate = (lineData) => {
		return (
			<a href={lineData.url} target="_blank" rel="noopener noreferrer" aria-label="link">
				<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
			</a>
		);
	};

	const titleTemplate = (item) => {
		return (
			<p><Translator text={item.Title} /></p>
		);
	};

	const yearTemplate = (item) => {
		return (
			<p><Translator text={item.Year} /></p>
		);
	};

	return (
		<div className="resources-dialog-content">
			<p className="title" style={{ textTransform: 'Capitalize' }}><Translator text="Papers" /></p>
			<DataTable value={data} responsiveLayout="scroll" stripedRows>
				<Column body={titleTemplate} header={<Translator text="Title" />} />
				<Column body={yearTemplate} header={<Translator text="Year" />} />
				<Column field="url" body={urlTemplate} header={<Translator text="Url" />} />
			</DataTable>
		</div>
	);
};

export default PapersDialogContent;
