/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import Translator from '../../components/Translator';

const texts = {
	dataSources: {
		title: 'Data Sources',
		subtitle: <p><Translator text="10+ data sources" /> <br />
			<span style={{ color: '#FDC82F' }}><Translator text="spanning" /></span> <br />
			<Translator text="20+ years" /></p>,
		content: 'The Climate Security Observatory (CSO) aggregates, ' +
			'processes and analyses global data for climate, environmental,  ' +
			'peace and security and socioeconomic indicators. The combination of the analyses ' +
			'yield a comprehensive understanding of the current climate security landscape and how ' +
			'this can be improved in the future.',
		buttonLabel: 'Data Sources',
	},
	methods: {
		title: 'Methods',
		subtitle: <p><Translator text="4 key" /> <span style={{ color: '#FDC82F' }}><Translator text="questions" /></span> <br />
			<Translator text="answered by" /> <br /> <Translator text="6 rigorous" /> <br /> <span style={{ color: '#FDC82F' }}><Translator text="analyses" /></span>
		</p>,
		content: 'The CSO identifies complex relationships, extracts insights and provides recommendations ' +
			'through multiple and multidisciplinary analyses each exploring different facets of the Climate Security ' +
			'Nexus. Each analysis underpinned with rigorous and proven methods helps the CSO answer the How, What, Where ' +
			'and Who components of the Climate-Security Nexus.',
		buttonLabel: 'Methods',
	},
	countryProfiles: {
		title: 'Country Reports',
		subtitle: <p><Translator text="Access the latest" /><br />
			<Translator text="CSO" /> <span style={{ color: '#FDC82F' }}><Translator text="analyses" /></span><br />
			<Translator text="by" /> <span style={{ color: '#FDC82F' }}><Translator text="country" /></span></p>,
		content: <p><Translator text="Using its innovative methodologies, the CSO answers its four key questions spanning multiple countries. Each country-based report is part of a continuous series reporting on the findings of the CGIAR FOCUS Climate Security Observatory work. The research focuses on 4 questions:" />
			<ol>
				<li><Translator text="HOW does climate worsen the root causes of conflict?" /></li>
				<li><Translator text="WHERE are the most vulnerable areas to climate induced insecurities and risks?" /></li>
				<li><Translator text="WHO are the vulnerable groups to climate and security risks that should be targeted?" /></li>
				<li><Translator text="WHAT needs to be done to break the cycle between climate and conflict?" /></li>
			</ol>
		</p>,
		buttonLabel: 'Country Profiles',
	},
	papers: {
		title: 'Journal Articles',
		subtitle: <p><Translator text="Read our" /> <span style={{ color: '#FDC82F' }}> <Translator text="peer-reviewed" /> <br /><Translator text="papers" /></span> <Translator text="around climate" />
			<Translator text="security" /></p>,
		buttonLabel: 'Journal Articles',
	},
	briefs: {
		title: 'Policy Briefs',
		subtitle: <p><Translator text="Discover our broad range of" /> <br /><span style={{ color: '#FDC82F' }}><Translator text="policy briefs" /></span></p>,
		buttonLabel: 'Policy Briefs',
	},
	other: {
		title: 'Others',
		subtitle: <p><Translator text="Discover further" /> <br />
			<Translator text="publications such as" /> <span style={{ color: '#FDC82F' }}><Translator text="book" /> <br />
				<Translator text="chapters, working papers" /> </span><br />
			<Translator text="and other" /> <span style={{ color: '#FDC82F' }}><Translator text="reports" /></span></p>,
		buttonLabel: 'Others',
	},
};

export default texts;
