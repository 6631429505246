import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useSelector } from 'react-redux';
import { PrevalenceChart, SentimentChart } from './components';
import CountryService from '../../../../../../../../../../services/httpService/countryService';
import { sortArrayOfObjectsByPropertyValue } from '../../../../../../../../../../utils/functions';
import './styles.css';
import Translator from '../../../../../../../../../../components/Translator';

const SocialMediaAnalysis = () => {
	const country = useSelector((state) => state.country);

	const [selectedData, setSelectedData] = useState(null);
	const [displayedChart, setDisplayedChart] = useState('prevalence');
	const [lineChartData, setLineChartData] = useState(null);
	const [barChartData, setBarChartData] = useState(null);
	const [sentimentPrevalence, setSentimentPrevalence] = useState(null);

	useEffect(
		() => {
			CountryService.getSentimentPrevalenceData(country.id)
				.then((res) => {
					setSentimentPrevalence(res.data_info);
					let totalValues = res.data_info.prevalence.map((variable) => {
						let sum = 0;
						variable.data.map((item) => {
							sum += item.value;
						});
						return { variable: variable.variable, sum };
					});
					totalValues = sortArrayOfObjectsByPropertyValue(totalValues, 'sum');
					const prevalentValues = [];
					// eslint-disable-next-line no-plusplus
					for (let i = 1; i < 6; i++) {
						prevalentValues.push(totalValues[totalValues.length - i]);
					}
					console.log(prevalentValues);
					const initial = res.data_info.prevalence.filter((item) => {
						const temp = prevalentValues.find((prevalent) => prevalent.variable === item.variable);
						if (temp) {
							return true;
						}
						return false;
					});
					// const initial = res.data_info.prevalence.filter((item) => {
					// 	if (item.variable === 'Drought' || item.variable === 'Conflicts') {
					// 		return true;
					// 	}
					// 	return false;
					// });
					setSelectedData(initial);
				});
		}, []
	);

	useEffect(
		() => {
			if (selectedData) {
				setLineChartData(selectedData);
				const newBarChartData = { year: '2007-2023' };
				selectedData.map((item) => {
					const sentimentItem = sentimentPrevalence.sentiment.find((it) => it.variable === item.variable);
					newBarChartData[sentimentItem.variable] = sentimentItem.value;
				});
				setBarChartData([newBarChartData]);
			}
		}, [selectedData]
	);

	const renderCharts = () => {
		switch (displayedChart) {
		case 'prevalence': return (
			<div className="chart">
				<p className="open-sans">
					<Translator text="The graph displays the time trend for the presence of different variables in the public conversations of policy actors, suggesting not only which issues are in their radar, but also when they are of most interest. By default, the graph shows the time trend for the five most prevalent variables. Specific variables can be selected from the dropdown menu." />
					<br /><br />
					<Translator text="The temporal distribution enables mapping any peaks and dips to specific events affecting policy dialogues, such as shocks (climate, conflict, socioeconomic, political, etc) or policy responses to particular issues." />
				</p>
				{lineChartData ? <PrevalenceChart data={lineChartData} /> : null}
			</div>
		);
		case 'sentiment': return (
			<div className="chart">
				<p className="open-sans">
					<Translator text="The figure displays the monthly sentiment of tweets related to the thematic variables. The aggregated sentiment for the last six months, for the five most prevalent variables, is shown by default. Specific variables and previous periods can be selected in the graph." />
					<br /><br />
					<Translator text="Sentiment analysis reflects the tone with which policy actors frame various issues, and should be interpreted from an institutional communications lens, i.e., the messages policy makers wish to convey publicly. As such, Tweets tend to focus on actions and solutions, which may gear the results towards mostly positive sentiment. However, a negative sentiment can indicate issues for which risks and insecurities are greater, as well as challenges to which policy solutions have yet to advance." />
				</p>
				{lineChartData ? <SentimentChart data={barChartData} selectedData={selectedData} /> : null}
			</div>
		);
		default: return null;
		}
	};

	if (!sentimentPrevalence) return null;

	const itemTemplate = (item) => {
		if (item) {
			return (
				<Translator text={item.variable} />
			);
		}
		return (
			<p><Translator text="Please select" /></p>
		);
	};

	return (
		<div className="social-media-analysis-container">
			<h2><Translator text="Social Media Analysis" /></h2>
			<div className="info-dropdown-container">
				<p><Translator text="Using data from the Twitter social media platform, thousands of publicly available tweets from policy makers are used to investigate the salience of climate security narratives and priorities among policy actors at the national level." /></p>
			</div>
			<div className="charts">
				<div className="selections">
					<div className="buttons">
						<Button label={<Translator text="Prevalence" />} onClick={() => setDisplayedChart('prevalence')} style={displayedChart === 'prevalence' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
						<Button label={<Translator text="Sentiment" />} onClick={() => setDisplayedChart('sentiment')} style={displayedChart === 'sentiment' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
						<Tooltip target=".info-icon" content="The values are normalized on a scale from 0-1, so that prevalence is shown as a proportion of all frequency, on all topics, in the corpus of Tweets. This is done to deal with biases in absolute frequencies that are due to variations in Twitter activity by different accounts, ensuring comparability over time." style={{ maxWidth: '20%', minWidth: '100px' }} />
						<i className="info-icon fa-light fa-circle-info" style={{ color: '#FDC82F', fontSize: '20px', fontWeight: '400' }} />
					</div>
					<div className="variables">
						<h3><Translator text="Select Variables (max 10)" /></h3>
						<MultiSelect value={selectedData} options={sentimentPrevalence.prevalence} onChange={(e) => setSelectedData(e.value)} optionLabel="variable" maxSelectedLabels={3} selectionLimit={10} showSelectAll={false} itemTemplate={itemTemplate} valueTemplate={itemTemplate} />
					</div>
				</div>
				{renderCharts()}
			</div>
		</div>
	);
};

export default SocialMediaAnalysis;
