/* eslint-disable no-param-reassign,react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslate } from '@tolgee/react';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import Actions from '../../../../../../../../reducer/actions';
import { Map, Series, Gauge, BoxPlot, BarChart, LivestockMap } from './components';
import headerImage from '../../../../../../../../assets/images/CountryInformationPage/Where/Dialog/dialogHeader.svg';
import CountryService from '../../../../../../../../services/httpService/countryService';
import { backgroundColors as defaultColorsKenya } from '../../../../../../../../utils/palleteKenya';
import { backgroundColors as defaultColorsSenegal } from '../../../../../../../../utils/palleteSenegal';
import { backgroundColors as defaultColorsZimbabwe } from '../../../../../../../../utils/palleteZimbabwe';
import { backgroundColors as defaultColorsUganda } from '../../../../../../../../utils/palleteUganda';
import { backgroundColors as defaultColorsNigeria } from '../../../../../../../../utils/palleteNigeria';
import { backgroundColors as defaultColorsGuatemala } from '../../../../../../../../utils/palleteGuatemala';
import { backgroundColors as defaultColorsMali } from '../../../../../../../../utils/palleteMali';
import { backgroundColors as defaultColorsPhilippines } from '../../../../../../../../utils/palletePhilipines';
import { backgroundColors as defaultColorsZambia } from '../../../../../../../../utils/palleteZambia';
import { backgroundColors as defaultColorsEthiopia } from '../../../../../../../../utils/palleteEthiopia';
import { backgroundColors as defaultColorsSudan } from '../../../../../../../../utils/palleteSudan';
import { backgroundColors as defaultColorsBurkina } from '../../../../../../../../utils/palleteBurkinaFaso';
import { backgroundColors as defaultColorsNiger } from '../../../../../../../../utils/palleteNiger';
import variableDescriptions from './variableDescriptions';
import generalImage from '../../../../../../../../assets/images/CountryInformationPage/Where/general.svg';
import livestockImage from '../../../../../../../../assets/images/CountryInformationPage/Where/livestock.svg';
import generalBlackImage from '../../../../../../../../assets/images/CountryInformationPage/Where/generalBlack.svg';
import livestockBlackImage from '../../../../../../../../assets/images/CountryInformationPage/Where/livestockBlack.svg';
import './styles.css';
import Translator from '../../../../../../../../components/Translator';

const MapComponent = () => {
	const dispatch = useDispatch();
	const whereSelectedFeature = useSelector((state) => state.whereSelectedFeature);

	const megapixelsGeoJson = useSelector((state) => state.megapixelsGeoJson);

	const country = useSelector((state) => state.country);

	const setWhereSelectedFeature = (payload) => dispatch({ type: Actions.SetWhereSelectedFeature, payload });

	const livestockSelected = useSelector((state) => state.livestockSelected);
	const setLivestockSelected = (payload) => dispatch({ type: Actions.SetLivestockSelected, payload });

	const generalMappingSelected = useSelector((state) => state.generalMappingSelected);
	const setGeneralMappingSelected = (payload) => dispatch({ type: Actions.SetGeneralMappingSelected, payload });
	const setWhereSwitchValues = (payload) => dispatch({ type: Actions.SetWhereSwitchValues, payload });

	const livestockDataNdvi = useSelector((state) => state.livestockDataNdvi);
	const setLivestockDataNdvi = (payload) => dispatch({ type: Actions.SetLivestockDataNdvi, payload });

	const livestockDataRainfall = useSelector((state) => state.livestockDataRainfall);
	const setLivestockDataRainfall = (payload) => dispatch({ type: Actions.SetLivestockDataRainfall, payload });

	const [toggleDialog, setToggleDialog] = useState(false);
	const [dialogAdminTableData, setDialogAdminTableData] = useState([]);
	const [impactPathways, setImpactPathways] = useState([]);
	const [lineChartData, setLineChartData] = useState(null);
	const [gaugeChartData, setGaugeChartData] = useState([]);
	const [boxplotData, setBoxplotData] = useState([]);
	const [livestockGeoJson, setLivestockGeoJson] = useState(null);
	const [acledGeoJson, setAcledGeoJson] = useState(null);
	const [acledPastoralGeoJson, setAcledPastoralGeoJson] = useState(null);
	const [acledData, setAcledData] = useState(null);
	const [acledDataPastoral, setAcledDataPastoral] = useState(null);
	const [showGraphs, setShowGraphs] = useState(false);

	const defaultColors = (filters) => {
		switch (country.id) {
		case 'KEN': return defaultColorsKenya(filters);
		case 'SEN': return defaultColorsSenegal(filters);
		case 'ZWE': return defaultColorsZimbabwe(filters);
		case 'UGA': return defaultColorsUganda(filters);
		case 'NGA': return defaultColorsNigeria(filters);
		case 'GTM': return defaultColorsGuatemala(filters);
		case 'MLI': return defaultColorsMali(filters);
		case 'PHL': return defaultColorsPhilippines(filters);
		case 'ZMB': return defaultColorsZambia(filters);
		case 'ETH': return defaultColorsEthiopia(filters);
		case 'SDN': return defaultColorsSudan(filters);
		case 'BFA': return defaultColorsBurkina(filters);
		case 'NER': return defaultColorsNiger(filters);
		default: return defaultColorsKenya(filters);
		}
	};

	useEffect(
		() => {
			console.log(megapixelsGeoJson);
			CountryService.getWhereBarChartData(country.id)
				.then((res) => {
					console.log(res);
					setLineChartData(res.graph_info);
				});
			CountryService.getGaugeChartData(country.id)
				.then((res) => {
					console.log(res);
					setGaugeChartData(res.graph_info);
				});
			CountryService.getBoxPlotData(country.id)
				.then((res) => {
					console.log(res);
					setBoxplotData(res.graph_info);
				});
			CountryService.getLivestockTranshumanceRoutes(country.id)
				.then((res) => {
					console.log(res);
					setLivestockGeoJson(res.graph_info);
				});
			CountryService.getLivestockAcled('all')
				.then((res) => {
					console.log(res);
					setAcledData(res.data);
				});
			CountryService.getLivestockAcled('pastoral')
				.then((res) => {
					console.log(res);
					setAcledDataPastoral(res.data);
				});
			CountryService.getLivestockTiles('ndvi')
				.then((res) => {
					console.log(res);
					setLivestockDataNdvi(res.json_data.maps.map((item) => ({ ...item, category: 'ndvi' })).reverse());
				});
			CountryService.getLivestockTiles('rainfall')
				.then((res) => {
					console.log(res);
					setLivestockDataRainfall(res.json_data.maps.map((item) => ({ ...item, category: 'rainfall' })).reverse());
				});
			return () => setWhereSelectedFeature({});
		}, [country]
	);

	useEffect(
		() => {
			console.log(whereSelectedFeature.properties);
			if (whereSelectedFeature.id) {
				setDialogAdminTableData([{ admin1: whereSelectedFeature.properties.NAME_1?.replaceAll(';', ' • '), admin2: whereSelectedFeature.properties.NAME_2?.replaceAll(';', ' • '), admin3: whereSelectedFeature.properties.NAME_3?.replaceAll(';', ' • ') }]);
				setToggleDialog(true);
				setTimeout(() => {
					setShowGraphs(true);
				}, 100);
			}
		}, [whereSelectedFeature]
	);
	useEffect(
		() => {
			setGeneralMappingSelected(false);
			setLivestockSelected(false);
		}, []
	);

	useEffect(() => {
		if (acledData) {
			// console.log(acledData.features);
			const uniqueFeatures = [];
			const uniqueCoordinates = [];
			const temp = acledData.features.map((item) => {
				const code = `${item.geometry.coordinates[0]}${item.geometry.coordinates[1]}`;
				if (!uniqueCoordinates.find((it) => it === code)) {
					uniqueCoordinates.push(code);
					uniqueFeatures.push([item.geometry.coordinates[1], item.geometry.coordinates[0]]);
				}
				return {

					type: 'Feature',
					geometry: {
						coordinates: [item.geometry.coordinates[1], item.geometry.coordinates[0]],
						type: 'Point',
					},
					properties: item.properties,
				};
			});
			// console.log(uniqueCoordinates);
			const data = { features: temp };
			// setAcledGeoJson(uniqueFeatures);
			// setAcledGeoJson(acledData);
			setAcledGeoJson(data);
		}
	}, [acledData]);

	useEffect(() => {
		if (acledDataPastoral) {
			// console.log(acledData.features);
			const uniqueFeatures = [];
			const uniqueCoordinates = [];
			const temp = acledDataPastoral.features.map((item) => {
				const code = `${item.geometry.coordinates[0]}${item.geometry.coordinates[1]}`;
				if (!uniqueCoordinates.find((it) => it === code)) {
					uniqueCoordinates.push(code);
					uniqueFeatures.push([item.geometry.coordinates[1], item.geometry.coordinates[0]]);
				}
				return {

					type: 'Feature',
					geometry: {
						coordinates: [item.geometry.coordinates[1], item.geometry.coordinates[0]],
						type: 'Point',
					},
					properties: item.properties,
				};
			});
			// console.log(uniqueCoordinates);
			const data = { features: temp };
			// setAcledGeoJson(uniqueFeatures);
			// setAcledGeoJson(acledData);
			setAcledPastoralGeoJson(data);
		}
	}, [acledDataPastoral]);

	const headerTemplate = () => {
		return (
			<div className="megapixel-details-header">
				<img src={headerImage} />
				<p><Translator text="Mega Pixel Details" /></p>
			</div>
		);
	};

	const assignLineChartData = (category) => {
		switch (category) {
		case 'Limited conflict': return lineChartData?.limited_conflict;
		case 'Moderate conflict': return lineChartData?.moderate_conflict;
		case 'High conflict': return lineChartData?.high_conflict;
		default: return [];
		}
	};

	const renderLineCharts = () => {
		if (!lineChartData) return null;
		return assignLineChartData(whereSelectedFeature.properties.conflict_clust_label).map((item, index) => {
			let label = '';
			let description = '';
			switch (item.variable) {
			case 'EVENTS': label = 'Events'; description = variableDescriptions.EVENTS; break;
			case 'FATALITIES': label = 'Fatalities'; description = variableDescriptions.FATALITIES; break;
			case 'SUBTYPE_RICHNESS': label = 'Sub-event type richness'; description = variableDescriptions.SUBTYPE_RICHNESS; break;
			case 'ACTOR1_RICHNESS': label = 'Actor 1 type richness'; description = variableDescriptions.ACTOR1_RICHNESS; break;
			case 'ACTOR2_RICHNESS': label = 'Actor 2 type richness'; description = variableDescriptions.ACTOR2_RICHNESS; break;
			case 'TYPE_RICHNESS': label = 'Event type richness'; description = variableDescriptions.TYPE_RICHNESS; break;
			default: break;
			}
			return <BarChart data={item.data} label={label} category={whereSelectedFeature.properties.conflict_clust_label} id={`${index + 1}`} pixelData={whereSelectedFeature.properties[item.variable]} description={description} />;
		});
	};

	const assignConflictColor = () => {
		switch (whereSelectedFeature.properties.conflict_clust_label) {
		case 'Limited conflict': return '#5b9379';
		case 'Moderate conflict': return '#efc160';
		case 'High conflict': return '#d94c4a';
		default: return '#fff';
		}
	};

	const renderBoxplotCharts = () => {
		console.log(whereSelectedFeature.properties.clim_cluster_short_label);
		console.log(boxplotData);
		if (!boxplotData) return null;
		const boxPlotDataForVariable = boxplotData.find((item) => item.name === whereSelectedFeature.properties.clim_cluster_short_label);
		if (boxPlotDataForVariable) {
			if (boxPlotDataForVariable.data) {
				return boxPlotDataForVariable.data.map((item, index) => {
					const temp = whereSelectedFeature.properties[item.variable];
					if (temp) {
						return <BoxPlot data={[item.data]} label={item.label} category={item.label} id={`${index + 10}`} climateValue={whereSelectedFeature.properties.clim_cluster_short_label} description={<Translator text={item.description} />} pixelData={temp} />;
					}
					return null;
				});
			}
		}
		return null;
	};

	const assignClimateColor = () => {
		const temp = defaultColors(whereSelectedFeature.properties?.clim_cluster_short_label);
		return temp.backgroundColor;
	};

	const renderImpactPathways = () => {
		if (whereSelectedFeature.properties.ip_all_category) {
			const temp = whereSelectedFeature?.properties?.ip_all_category?.split(';');
			return temp.map((item) => {
				if (item.includes('Inequality') || item.includes('Resources scarcity') || item.includes('Migration') || item.includes('Low productivity') || item.includes('Undernutrition')) {
					return (
						<div className="bubble" style={{ backgroundColor: '#285e69' }}>
							<p><Translator text={item} /></p>
						</div>
					);
				} return null;
			});
		}
		return null;
	};

	const renderGaugeCharts = () => {
		if (!gaugeChartData) return null;
		return gaugeChartData.map((item, index) => {
			const temp = whereSelectedFeature.properties[item.variable];
			if (temp) {
				return <Gauge data={{ min: item.data.min, max: item.data.max, value: temp }} id={index + 20} category={item.label} description={<Translator text={item.description} />} />;
			}
			return null;
		});
	};

	const titleTemplateAdmin1 = (data) => {
		return (
			<p><Translator text={data.admin1} /></p>
		);
	};

	const titleTemplateAdmin2 = (data) => {
		return (
			<p><Translator text={data.admin2} /></p>
		);
	};

	const titleTemplateAdmin3 = (data) => {
		return (
			<p><Translator text={data.admin3} /></p>
		);
	};
	return (
		<div className="map-component">
			{
				(country.id === 'KEN') ? (
					<>
						<div className={livestockSelected || generalMappingSelected ? 'hide-general-livestock-container ' : 'display-general-livestock-container'}>
							<div className="general-mapping">
								<img src={generalImage} />
								<h3>General Hotspot Mapping</h3>
								<p>Explore general climate security hotspots</p>
								<div className="livestock-btn-container">
									<Button
										className="livestock-btn"
										onClick={() => {
											setGeneralMappingSelected(true);
											setWhereSwitchValues(false);
										}}
									>Explore general spatial mapping
									</Button>
									<i className="fa-regular fa-arrow-right" />
								</div>
							</div>
							<div className="livestock-mapping">
								<img src={livestockImage} />
								<h3 style={{ paddingTop: '10px' }}>Livestock Hotspot Mapping</h3>
								<p>Explore livestock-specific climate security hotspots</p>
								<div className="livestock-btn-container">
									<Button
										className="livestock-btn"
										onClick={() => {
											setLivestockSelected(true);
											setWhereSwitchValues(true);
										}
										}
									>Explore livestock spatial mapping
									</Button>
									<i className="fa-regular fa-arrow-right" />
								</div>
							</div>
						</div>
						<div className="warning-switch-container">
							<div className="warning">
								<i className="fa-regular fa-circle-info" />
								<p className="open-sans"><Translator text="Select a hotspot to view more details and compare with similar hotspots" /></p>
							</div>
							{/* <div> */}
							{/*	<div className="switch"> */}
							{/*		<img src={!livestockSelected ? generalImage : generalBlackImage} /> */}
							{/*		/!* <InputSwitch *!/ */}
							{/*		/!*	checked={livestockSelected} *!/ */}
							{/*		/!*	onChange={(e) => setLivestockSelected(e.value)} *!/ */}
							{/*		/!* /> *!/ */}
							{/*		<InputSwitch */}
							{/*			checked={switchValues} */}
							{/*			onChange={(e) => { */}
							{/*				setSwitchValues(e.value); */}
							{/*				if (e.value) { */}
							{/*					setLivestockSelected(true); */}
							{/*					setGeneralMappingSelected(false); */}
							{/*				} else { */}
							{/*					setGeneralMappingSelected(true); */}
							{/*					setLivestockSelected(false); */}
							{/*				} */}
							{/*			}} */}
							{/*		/> */}
							{/*		<img src={livestockSelected ? livestockImage : livestockBlackImage} /> */}
							{/*	</div> */}
							{/* </div> */}
						</div>

						{
							livestockSelected ? (
								<>
									{livestockGeoJson && acledGeoJson && acledPastoralGeoJson ? <LivestockMap livestockGeoJson={livestockGeoJson} acledGeoJson={acledGeoJson} acledPastoralGeoJson={acledPastoralGeoJson} livestockNdviGeoJson={livestockDataNdvi} livestockRainfallGeoJson={livestockDataRainfall} /> : null}
								</>

							) : (
								<>
									{megapixelsGeoJson ? <Map /> : null}
								</>

							)

						}

						{whereSelectedFeature.properties
							? (
								<Dialog
									className="megapixel-details"
									header={headerTemplate}
									visible={toggleDialog}
									style={{ width: '80vw' }}
									onHide={() => {
										setShowGraphs(false);
										setToggleDialog(false);
									}}
								>
									<div className="map-dialog">
										<DataTable value={dialogAdminTableData} responsiveLayout="scroll" stripedRows>
											<Column body={titleTemplateAdmin1} header={<Translator text="Admin 1" />} />
											<Column body={titleTemplateAdmin2} header={<Translator text="Admin 2" />} />
											<Column body={titleTemplateAdmin3} header={<Translator text="Admin 3" />} />
										</DataTable>
										<div className="sub-title">
											<p><Translator text="Conflict Data" /></p>
										</div>
										<div className="bubble" style={{ backgroundColor: assignConflictColor() }}>
											<p style={assignConflictColor() === '#F6E09F' ? { color: '#2d2d2d' } : {}}><Translator text={whereSelectedFeature.properties.conflict_clust_label} /></p>
										</div>
										<div className="line-charts">
											{showGraphs ? renderLineCharts() : null}

										</div>
										<div className="sub-title">
											<p><Translator text="Climate Data" /></p>
										</div>
										<div className="bubble" style={{ backgroundColor: assignClimateColor() }}>
											<p style={assignClimateColor() === '#f3d8a9' ? { color: '#2d2d2d' } : {}}><Translator text={whereSelectedFeature.properties.clim_cluster_short_label} /></p>
										</div>
										<div className="line-charts">
											{showGraphs ? renderBoxplotCharts() : null}
										</div>
										<div className="sub-title">
											<p><Translator text="Socioeconomic Categories" /></p>
										</div>
										<div className="impact-pathways">
											{renderImpactPathways()}
										</div>
										<div className="line-charts">
											{showGraphs ? renderGaugeCharts() : null}
										</div>
									</div>
								</Dialog>
							)
							: null
						}
					</>
				) : (
					<>
						<div className="warning">
							<i className="fa-regular fa-circle-info" />
							<p className="open-sans"><Translator text="Select a hotspot to view more details and compare with similar hotspots" /></p>
						</div>
						{megapixelsGeoJson ? <Map /> : null}

						{whereSelectedFeature.properties
							? (
								<Dialog
									className="megapixel-details"
									header={headerTemplate}
									visible={toggleDialog}
									style={{ width: '80vw' }}
									onHide={() => {
										setShowGraphs(false);
										setToggleDialog(false);
									}}
								>
									<div className="map-dialog">
										<DataTable value={dialogAdminTableData} responsiveLayout="scroll" stripedRows>
											<Column body={titleTemplateAdmin1} header={<Translator text="Admin 1" />} />
											<Column body={titleTemplateAdmin2} header={<Translator text="Admin 2" />} />
											<Column body={titleTemplateAdmin3} header={<Translator text="Admin 3" />} />
										</DataTable>
										<div className="sub-title">
											<p><Translator text="Conflict Data" /></p>
										</div>
										<div className="bubble" style={{ backgroundColor: assignConflictColor() }}>
											<p style={assignConflictColor() === '#F6E09F' ? { color: '#2d2d2d' } : {}}><Translator text={whereSelectedFeature.properties.conflict_clust_label} /></p>
										</div>
										<div className="line-charts">
											{showGraphs ? renderLineCharts() : null}

										</div>
										<div className="sub-title">
											<p><Translator text="Climate Data" /></p>
										</div>
										<div className="bubble" style={{ backgroundColor: assignClimateColor() }}>
											<p style={assignClimateColor() === '#f3d8a9' ? { color: '#2d2d2d' } : {}}><Translator text={whereSelectedFeature.properties.clim_cluster_short_label} /></p>
										</div>
										<div className="line-charts">
											{showGraphs ? renderBoxplotCharts() : null}
										</div>
										<div className="sub-title">
											<p><Translator text="Socioeconomic Categories" /></p>
										</div>
										<div className="impact-pathways">
											{renderImpactPathways()}
										</div>
										<div className="line-charts">
											{showGraphs ? renderGaugeCharts() : null}
										</div>
									</div>
								</Dialog>
							)
							: null
						}
					</>
				)
			}

		</div>
	);
};

export default MapComponent;
