import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const ReactPdf = (props) => {
	const { chartSrc, flowerChartSrc, userInput } = props;

	const data = [
		{ submechanism: '1.1 Create livelihoods and sustain existing ones', category: '1. Economic development' },
		{ submechanism: '1.2 Develop bi-communal spaces and infrastructures', category: '1. Economic development' },
		{ submechanism: '1.3 Foster the provision of public goods and services', category: '1. Economic development' },
		{ submechanism: '2.1 Enhance institutional capacities for good environmental governance', category: '2. Building institutions' },
		{ submechanism: '2.2 Facilitate legal pluralism and resource rights', category: '2. Building institutions' },
		{ submechanism: '2.3 Foster equitable distribution of resources and benefits', category: '2. Building institutions' },
		{ submechanism: '3.1 Involve both high and grass-root levels while minimizing transboundary contacts', category: '3. Building trust and cooperation' },
		{ submechanism: '3.2 Foster intercommunal trust and create shared identities', category: '3. Building trust and cooperation' },
		{ submechanism: '3.3 Enhance social cohesion and empower vulnerable groups', category: '3. Building trust and cooperation' },
		{ submechanism: '4.1 Restore degraded ecosystems', category: '4. Resource sustainability' },
		{ submechanism: '4.2 Foster adoption of practices for sustainable use of resources', category: '4. Resource sustainability' },
		{ submechanism: '4.3 Conserve ecosystems and common-pool resources', category: '4. Resource sustainability' },
		{ submechanism: '5.1 Raise awareness on environmental issues and environment-conflict linkages', category: '5. Enhancing knowledge' },
		{ submechanism: '5.2 Establish the recognition of diverse ontologies in climate adaptation and natural resource management', category: '5. Enhancing knowledge' },
		// { submechanism: '5.3 Foster recognition of alternative knowledges and worldviews', category: '5. Enhancing knowledge' },
		{ submechanism: '6.1 Increase livelihood climate coping capacity', category: '6. Building capacity and resilience' },
		{ submechanism: '6.2 Increase livelihood climate adaptation capacity', category: '6. Building capacity and resilience' },
		{ submechanism: '6.3 Increase livelihood climate transformative capacity', category: '6. Building capacity and resilience' },
	];

	const displayRows = () => {
		return data.map((item, index) => {
			return (
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', backgroundColor: index % 2 === 0 ? 'white' : '#F7F7F7', padding: '4px', alignItems: 'center', justifyContent: 'center' }}>
					<p style={{ width: '50%', fontSize: '6px', fontWeight: '600' }}>{item.submechanism}</p>
					<p style={{ width: '25%', fontSize: '6px', fontWeight: '600' }}>{userInput[`${item.submechanism.split(' ')[0]}comment`]}</p>
					<p style={{ width: '25%', fontSize: '6px', fontWeight: '600' }}>{userInput[item.submechanism.split(' ')[0]]}</p>
				</div>
			);
		});
	};

	if (!chartSrc) return null;

	return (
		<Document>
			<Page
				size="A4"
				style={{
					flexDirection: 'row',
					backgroundColor: '#E4E4E4',
				}}
			>
				<View style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%', padding: '10px', textAlign: 'left', alignItems: 'center', justifyContent: 'center' }}>
					<Text style={{ color: '#FDC82F', fontSize: '12px', fontWeight: '800' }}>Climate Security Sensitivity Tool (CSST)</Text>
					<View className="header" style={{ width: '100%' }}>
						<Text style={{ fontSize: '10px', fontWeight: '800', width: '100%' }}>{userInput.country?.name} - {userInput.region?.name ? userInput.region?.name : userInput.region?.region}{userInput.municipality ? ` - ${userInput.municipality?.name}` : ''}</Text>
					</View>
					<Text style={{ fontSize: '10px', fontWeight: '600', width: '100%' }}>Contextual Drivers</Text>
					<Text style={{ fontSize: '6px' }}>Step 1 characterizes the context of the location where your program is expected to be implemented. Context-specific drivers of conflict and insecurity for your selected area are retrieved from the Joint Research Centre’s INFORM risk index.</Text>
					<Text style={{ fontSize: '10px', fontWeight: '800', width: '100%' }}>Context Drivers for Kenya</Text>
					{chartSrc ? <Image src={chartSrc} style={{ width: '200px' }} /> : null}
				</View>
			</Page>
			<Page
				size="A4"
				style={{
					flexDirection: 'row',
					backgroundColor: '#E4E4E4',
				}}
			>
				<View style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%', padding: '10px', textAlign: 'left', alignItems: 'center', justifyContent: 'center' }}>
					<Text style={{ fontSize: '10px', fontWeight: '600', width: '100%' }}>Climate - Peace Program Data</Text>
					<Text style={{ fontSize: '6px' }}>Step 2 assesses a set of climate-peace mechanisms and indicators. For each of mechanism and examples of practices for that mechanism (indicators), you indicated if the listed criteria are fulfilled (=1), somehow fulfilled (=0.5) or not fulfilled (=0). Below are the results of your scoring.</Text>
					<Text style={{ fontSize: '10px', fontWeight: '600', width: '100%' }}>Form Data as Inputted</Text>
					<View style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0px 0 0 0' }}>
						<View style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px #00000010 solid' }}>
							<Text style={{ width: '50%', fontSize: '6px', fontWeight: '600', color: '#427730' }}>Sub-mechanism</Text>
							<Text style={{ width: '25%', fontSize: '6px', fontWeight: '600', color: '#427730' }}>Comment</Text>
							<Text style={{ width: '25%', fontSize: '6px', fontWeight: '600', color: '#427730' }}>Score</Text>
						</View>
						{displayRows()}
					</View>
					<p style={{ fontSize: '10px', fontWeight: '600' }}>Summary of Program / Project Data Against Contextual Drivers Marked with Areas of Higher Risk</p>
					{flowerChartSrc ? <Image src={flowerChartSrc} style={{ width: '200px' }} /> : null}
				</View>
			</Page>
		</Document>
	);
};

export default ReactPdf;
