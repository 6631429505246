import React, { useEffect, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import {
	financeImage,
	multiLevelImage,
	policyImageGuatemala, programmaticImage, researchImage,
} from '../../../../../../../../../../../../assets/images/CountryInformationPage/What/policyProgramatic/img';
import './styles.css';

const GuatemalaContent = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [tab, setTab] = useState(0);

	useEffect(
		() => {
			changeTabColor(tab, 'black');
			setTab('multi-policy');
			changeTabColor('multi-policy', '#FDC82F');
		}, []
	);

	const headerTemplate = (options, image, header) => (
		<div role="button" tabIndex="0" onClick={options.onClick} className="tabs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
			{image}
			<p>{header}</p>
		</div>
	);

	const changeTabColor = (target, color) => {
		const selectedtabImage = document.getElementById(target);
		if (selectedtabImage) {
			selectedtabImage.fill = color;
			let paths = selectedtabImage.getElementsByTagName('path');
			paths = [...paths];
			paths.map((item) => {
				// eslint-disable-next-line no-param-reassign
				item.style.fill = color;
			});
		}
	};

	return (
		<TabView
			activeIndex={activeIndex}
			onTabChange={(e) => {
				setActiveIndex(e.index);
				changeTabColor(tab, 'black');
				switch (e.index) {
				case 0: setTab('multi-policy'); changeTabColor('multi-policy', '#FDC82F'); break;
				// case 1: setTab('policy'); changeTabColor('policy', '#FDC82F'); break;
				case 1: setTab('programmatic'); changeTabColor('programmatic', '#FDC82F'); break;
				case 2: setTab('research'); changeTabColor('research', '#FDC82F'); break;
				default: setTab('finance'); changeTabColor('finance', '#FDC82F'); break;
				}
			}
			}
		>
			<TabPanel header="multi-policy" headerTemplate={(options) => headerTemplate(options, policyImageGuatemala, 'Multi-level goveranance & Policy frameworks')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							Given that governance frameworks for climate change and peace have traditionally evolved independently due to inadequate cross-sectoral collaboration, a significant degree of institutional learning is required to effectively integrate climate security as a topic of concern in Guatemala´s policies and governance systems. To modify current practices for climate adaptation and peacebuilding towards integrating a climate security sensitive approach, governance efforts must adopt conscious strategies to develop multi-actor agreements and shared perceptions of climate, peace and security linkages that span across policy sectors and political-administrative levels.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Map existing legal and policy frameworks with relevance to climate and security and assess their coherence in terms of climate security. </li>
							<li>Conduct a gap analysis for the integration of climate security into the policy framework of relevant sectors, including climate change, peacebuilding, rural and urban development, human mobility, among others. risks and impacts, such as unequal access to natural resources or insecure land tenure. </li>
							<li>Identify existing multi-stakeholder platforms at national and sub-national levels that may serve as a base towards integrating a climate security focus on both climate action and peacebuilding strategies. </li>
							<li>Conduct a needs assessment of the designated multi-stakeholder platforms to identify actions required for increasing their capacity to include a climate security perspective. </li>
							<li>Develop a multi-level governance strategy for the participating platforms that ensure the effective participation and recognition of department- and community-level priorities for action at the intersection of climate, peace and security.</li>
							<li>Co-develop an agenda and clear terms of reference for the community of practice that indicate coordinating mandates, priority areas of action and mechanisms for collaboration and building stakeholder capacity.</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			{/* <TabPanel header="multi" headerTemplate={(options) => headerTemplate(options, policyImage, 'Policy frameworks')}> */}
			{/*	<div className="panel-container"> */}
			{/*		<div> */}
			{/*			<h3>Description</h3> */}
			{/*			<p className="m-0"> */}
			{/*				Given that governance frameworks for climate change and peace have traditionally evolved independently due to inadequate cross-sectoral collaboration, a significant degree of institutional learning is required to effectively integrate climate security as a topic of concern in Guatemala´s policies and governance systems. To modify current practices for climate adaptation and peacebuilding towards integrating a climate security sensitive approach, governance efforts must adopt conscious strategies to develop multi-actor agreements and shared perceptions of climate, peace and security linkages that span across policy sectors and political-administrative levels. */}
			{/*			</p> */}
			{/*		</div> */}
			{/*		<div> */}
			{/*			<h3>Key recommendations</h3> */}
			{/*			<ul> */}
			{/*				<li>Map existing legal and policy frameworks with relevance to climate and security and assess their coherence in terms of climate security. </li> */}
			{/*				<li>Conduct a gap analysis for the integration of climate security into the policy framework of relevant sectors, including climate change, peacebuilding, rural and urban development, human mobility, among others. risks and impacts, such as unequal access to natural resources or insecure land tenure. </li> */}
			{/*				<li>Identify existing multi-stakeholder platforms at national and sub-national levels that may serve as a base towards integrating a climate security focus on both climate action and peacebuilding strategies. </li> */}
			{/*				<li>Conduct a needs assessment of the designated multi-stakeholder platforms to identify actions required for increasing their capacity to include a climate security perspective. </li> */}
			{/*				<li>Develop a multi-level governance strategy for the participating platforms that ensure the effective participation and recognition of department- and community-level priorities for action at the intersection of climate, peace and security.</li> */}
			{/*				<li>Co-develop an agenda and clear terms of reference for the community of practice that indicate coordinating mandates, priority areas of action and mechanisms for collaboration and building stakeholder capacity.</li> */}
			{/*			</ul> */}
			{/*		</div> */}

			{/*	</div> */}
			{/* </TabPanel> */}
			<TabPanel header="programmatic" headerTemplate={(options) => headerTemplate(options, programmaticImage, 'Programmatic planning')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							There is a need to design climate adaptation programmes and initiatives that proactively contribute to sustaining peace. Similarly, peace and security actors should undertake programme planning with a climate perspective. Such efforts should crucially be responsive to specific local contexts and needs. Integrating climate and security risk analyses into the design of resilience projects with peace co-benefits across Guatemala therefore demands significant engagement and coordination across sectors and scales of governance, along with increasing capacity assessments where needed.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Map existing climate action and peacebuilding programmes throughout Guatemala that may be relevant to addressing climate security risks.</li>
							<li>Conduct evaluation processes of selected projects at the climate, peace and security intersection. </li>
							<li>Strengthen peacebuilding and climate actors’ capacity to conduct conflict assessments and develop project proposals that integrate a climate perspective and vulnerability assessments that account for conflict risks respectively. </li>
							<li>Build upon a better understanding of community-level risk coping and conflict management strategies towards developing climate security action plans. </li>
							<li>Channel international cooperation and public funding towards conflict-sensitive climate adaptation.</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="research" headerTemplate={(options) => headerTemplate(options, researchImage, 'Research and evidence gaps')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							Workshop participants emphasized the importance of improving and expanding current empirical research on the links between climate change, peace and security in Guatemala.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>Understand climate-related security risks beyond a homogenous view of conflict. </li>
							<li>Developing further evidence on the indirect linkages between climate, peace and security by expanding on the role of migration and displacement, political drivers of conflict, the role of extractive natural resource use, or loss of livelihoods. </li>
							<li>Focus research on the intermediate effects of different forms of human mobility and unequal access to natural resources use over climate-related security risks.</li>
							<li>Strengthen research on gender and climate security through intersectional approaches. </li>
							<li>Building upon community knowledge to conceptualize climate, peace and security linkages. </li>
							<li>Distinguish between regional scopes for climate, peace and security linkages. It is necessary to develop a more nuanced understanding of how climate interacts with instability at the level of each locality and department of Guatemala. </li>
							<li>Expand climate modeling capabilities to gain a better understanding of future risks by linking current dynamics of climate security with future hazards, while emphasizing the uncertainties inherent in modeling work, as well as the complex and non-linear interactions that are essential to decision-making processes.</li>
							<li>Foster research focusing on developing good practice recommendations for climate, peace and security programming within climate adaptation efforts.</li>
						</ul>
					</div>

				</div>
			</TabPanel>
			<TabPanel header="finance" headerTemplate={(options) => headerTemplate(options, financeImage, 'Finance')}>
				<div className="panel-container">
					<div>
						<h3>Description</h3>
						<p className="m-0">
							Even though climate adaptation action has traditionally avoided conflict-affected regions due to their high-risk profile and security concerns, there is an opportunity to link investment initiatives with climate security hotspots. Building on the workshop’s outputs, investment planning procedures are needed to co-design climate security investments together with local communities and multiple stakeholders that align incentives across the humanitarian-development-peace nexus.
						</p>
					</div>
					<div>
						<h3>Key recommendations</h3>
						<ul>
							<li>In violence-affected areas, climate-smart agricultural investments can help mitigate the drivers of violence as related to scarce natural resources and insecure livelihoods. </li>
							<li>Rather than create new and adjacent organizing structures, climate security practitioners should leverage pre-existing networks and multi-stakeholder platforms to support the development, implementation, and scaling of financial interventions. </li>
							<li>Enhance the grant writing and fundraising skills of organizations working at the intersection of climate, peace and security. </li>
							<li>Climate security should feature more prominently in climate action strategies and plans.</li>
						</ul>
					</div>

				</div>
			</TabPanel>
		</TabView>
	);
};

export default GuatemalaContent;
