import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabPanel, TabView } from 'primereact/tabview';
import { Ripple } from 'primereact/ripple';
import {
	howImageVoices,
	whatImageVoices,
} from '../../../../../../../../../../assets/images/images';
import Translator from '../../../../../../../../../../components/Translator';

const DataDisplay = (props) => {
	const { data, textProperty } = props;

	const [headerStyle, setHeaderStyle] = useState(false);
	const [tab, setTab] = useState(textProperty === 'whatText' ? 'What-Voices' : 'How-Voices');
	const [activeIndex, setActiveIndex] = useState(textProperty === 'whatText' ? 1 : 0);

	const renderData = (property) => {
		return data[property]?.map((item) => {
			return <p className="open-sans">{item}</p>;
		});
	};

	const accordionHeaderTemplate = (text) => {
		return (
			<div className="header">
				<p><Translator text={text} /></p>
			</div>
		);
	};

	const changeTabColor = (target, color) => {
		const selectedtabImage = document.getElementById(target);
		if (selectedtabImage) {
			selectedtabImage.fill = color;
			let paths = selectedtabImage.getElementsByTagName('path');
			paths = [...paths];
			paths.map((item) => {
				// eslint-disable-next-line no-param-reassign
				item.style.fill = color;
			});
		}
	};

	const tabHeaderTemplate = (options, image, text) => (
		<div className="tab-header" role="button" tabIndex="0" onClick={options.onClick}>
			<div
				className="p-ripple"
				id="about"
			>
				{image}
				<p style={{ color: 'black' }}>{text}</p>
				<Ripple />
			</div>
		</div>
	);

	const renderAccordionTabs = () => {
		return data.howData.map((item) => {
			return (
				<AccordionTab header={accordionHeaderTemplate(item.pathwayTitle)}>
					<div className="text">
						<p className="open-sans"><Translator text={item.text} /></p>
					</div>
				</AccordionTab>
			);
		});
	};

	if (data) {
		return (
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => {
					setActiveIndex(e.index);
					changeTabColor(tab, 'black');
					switch (e.index) {
					case 0: setTab('How-Voices'); changeTabColor('How-Voices', '#FDC82F'); break;
					case 1: setTab('What-Voices'); changeTabColor('What-Voices', '#FDC82F'); break;
					default: setTab('How'); changeTabColor('How-Voices', '#FDC82F'); break;
					}
				}}
			>
				<TabPanel header="How" headerTemplate={(options) => tabHeaderTemplate(options, howImageVoices, <Translator text="How" />)}>
					<div className="accordion-section">
						<p style={{ fontWeight: '600' }}><Translator text="Pathways" /></p>
						<Accordion activeIndex={0} onTabOpen={() => setHeaderStyle(true)} onTabClose={() => setHeaderStyle(false)}>
							{renderAccordionTabs()}
						</Accordion>
					</div>
				</TabPanel>
				<TabPanel header="What" headerTemplate={(options) => tabHeaderTemplate(options, whatImageVoices, <Translator text="What" />)}>
					<div className="accordion-section">
						<p style={{ fontWeight: '600' }}><Translator text="Solutions to pathways" /></p>
						<div className="text">
							{renderData('whatText')}
						</div>
					</div>
				</TabPanel>
			</TabView>
		);
	}
	return null;
};

export default DataDisplay;
