/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { TRANSITION } from 'leaflet/src/dom/DomUtil';
import Translator from '../../../../../../components/Translator';

const summaries = {
	kenya: {
		// eslint-disable-next-line react/jsx-no-undef
		climateText: <p><Translator text="Kenya has diverse climatic conditions, ranging from the hot and dry north and northeast to a hot and humid coastal region to the
			cooler and more temperate central highlands"
		/><sup><a href="https://www.worldbank.org/en/country/kenya/overview#1" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Rainfall varies widely across the country and is characterized by two distinct rainy seasons.
			Variations in rainfall are associated with extreme rainfall events and floods, as well as prolonged droughts that have become more frequent in
			recent decades"
		/><sup><a href="https://www.worldbank.org/en/country/kenya/overview#1" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Extreme flooding remains a major cause of land degradation and soil erosion. Kenya is projected to experience an increase in long-term
			average temperatures under all emissions scenarios (1.7°C by 2050 and 3.5°C by the end of the century)"
		/><sup><a href="https://www.worldbank.org/en/country/kenya/overview#1" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://reliefweb.int/sites/reliefweb.int/files/resources/Kenya_2.pdf" target="_blank" rel="noreferrer">2</a></sup>. </p>,

		conflictText: <p><Translator text="Kenya has enjoyed prolonged civil peace, albeit punctuated with brief periods of violence. In this
			ethnically and culturally diverse context, violence has usually been found to be associated with the occurrence of elections,
			especially during the polls that took place in 1992, 1997, and 2007"
		/><sup><a href="https://journals.sagepub.com/doi/10.1177/0022343311425842" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Effects of electoral violence in 2007 led to long-term population displacement, domestic supply chain disruptions, and food insecurity" /><sup><a href="https://fews.net/sites/default/files/documents/reports/Kenya_2008_01_final.pdf" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="A reformed political economic governance system was introduced with the passage of a new constitution in 2010, and an ambitious decentralization
			process initiated aimed to transfer autonomy and decision-making power to county governments at subnational level"
		/><sup><a href="http://publication.aercafricalibrary.org/bitstream/handle/123456789/2022/FW-001.pdf?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://www.worldbank.org/en/country/kenya/overview" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="Devolution remains a key factor
			for Kenya, with implications for shaping local decisions and adaptive capacity of vulnerable communities in the face of worsening climatic shocks and stresses."
		/></p>,
		globalMetricsText: <p><Translator text="Despite being one of the fastest growing and largest economies in Sub-Saharan Africa, Kenya continues to face challenges
			related to poverty, inequality, weak investments, and corruption, as well as internal and external shocks"
		/><sup><a href="https://www.worldbank.org/en/country/kenya/overview#1" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Economic disruptions due to the
			COVID-19 pandemic, along with pressure on health care infrastructure, have exacerbated these challenges. With a major portion of the population
			living in poverty, and a widening gap between rich and poor, more than two-thirds of families in Kenya experience vulnerabilities due to food insecurity
			and poor nutrition and health outcomes"
		/><sup><a href="https://www.usaid.gov/kenya/economic-growth-and-trade" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Further, as a net wheat, fuel, and fertilizer importer, Kenya’s economy remains vulnerable to global price shocks,
			such as that from the Russia-Ukraine war"
		/><sup><a href="https://www.worldbank.org/en/country/kenya/overview" target="_blank" rel="noreferrer">1</a></sup>. </p>,
	},
	senegal: {
		climateText: <p><Translator text="Senegal is part of the Sahel, one of the most climate-vulnerable regions in the world. It constitutes of a mainly arid and drought-prone territory
			characterised by poor soil quality and adverse weather conditions as well as considerable exposure to climate change and variability. Senegal suffers from
			both slow and rapid onset events such as droughts, floods, coastal erosion and sea-level rise"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2018-10/wb_gfdrr_climate_change_country_profile_for_SEN.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="There will be an increase in average temperatures between
			1.1 and 1.8°C by 2030 and, while there is an agreement that heavy rainfall events will increment, there is no consensus on whether annual rainfall will
			increase or decrease"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2018-10/wb_gfdrr_climate_change_country_profile_for_SEN.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/128758/1.4%20CSO%20-%20Senegal%20Shared%20Vision%20Report_FRENCH.pdf?sequence=5" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="As a consequence, extreme weather events such as droughts and floods may increase in frequency" /><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2018-10/wb_gfdrr_climate_change_country_profile_for_SEN.pdf" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Sea levels are likely to continue
			rising by 1.4 mm per year, with some projections estimating a rise of up to one meter by 2100"
		/><sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/128758/1.4%20CSO%20-%20Senegal%20Shared%20Vision%20Report_FRENCH.pdf?sequence=5" target="_blank" rel="noreferrer">2</a></sup>.</p>,
		conflictText: <p><Translator text="Senegal is considered one of the most stable countries in Africa. Nevertheless, the south of the country is the site of Africa&apos;s
			longest running armed conflict which, despite having evolved into a low-intensity war since the early 2000s, still presents a source of instability.
			Since 1982, the conflict in Casamance has pitted the Movement of Democratic Forces of Casamance (MFDC), which fights for the independence of the region,
			against the Senegalese government. The war has resulted in 5,000 deaths and thousands of displaced people and has been increasingly intertwined with criminal
			activities"
		/><sup><a href="https://www.ieee.es/Galerias/fichero/BoletinesIEEE3/2021/BoletinIEEE23.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://link.springer.com/chapter/10.1007/978-3-319-90206-7_10" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="The progress towards the west in neighbouring Mali of jihadist groups that are potentially seeking to enter Senegalese territory has raised concerns
			over the threat of radicalisation spread in the communities along the border"
		/><sup><a href="https://www.climatelinks.org/sites/default/files/asset/document/2017_Climate%20Change%20Risk%20Profile_Philippines.pdf" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://www.washingtonpost.com/news/worldviews/wp/2018/07/03/islamist-terrorist-groups-are-turning-their-attention-to-west-africa/" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="Since the mid-2000s, drug trafficking has become an increasingly salient,
			stoking concerns that this illegal activity would enable the financing of terrorist groups"
		/><sup><a href="https://www.researchgate.net/publication/311620384_Border_Security_and_Drug_Trafficking_in_Senegal_AIRCOP_and_global_security_assemblages" target="_blank" rel="noreferrer">5</a></sup>,<sup><a href="https://globalinitiative.net/wp-content/uploads/2022/07/GB-W-Africa-Corridor.July22.REV-web.pdf" target="_blank" rel="noreferrer">6</a></sup>. </p>,

		globalMetricsText: <p><Translator text="The Senegalese economy is strongly reliant on natural resources and highly vulnerable to climate variability and
			extreme weather events"
		/><sup><a href="https://oec.world/en/profile/country/sen" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2019-06/CSA%20_Profile_Zambia.pdf." target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Agriculture, livestock and fisheries employ more than 70% of the workforce and contribute to 13.6% of the GDP but
			they are also increasingly threatened by the climate"
		/><sup><a href="https://www4.unfccc.int/sites/submissions/INDC/Published%20Documents/Senegal/1/CPDN%20-%20S%C3%A9n%C3%A9gal.pdf" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://unfccc.int/cop3/fccc/natcom/natc/sennc1.pdf" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="Fishery is one of Senegal&apos;s main industries but has suffered an acute crisis, witnessing
			a large reduction in fish stocks"
		/><sup><a href="http://pubs.iclarm.net/resource_centre/Fish%20and%20Food%20Security%20in%20Africa%20Bene%20&%20Heck%20FFA.pdf" target="_blank" rel="noreferrer">5</a></sup>.<Translator text="Despite having made notable development progress, Senegal still suffers from different socioeconomic vulnerabilities.
			There is a 43% and 58% poverty rate in urban and rural areas, respectively. Food insecurity rates show that a total of 8.6% of urban and 15.1% of the rural
			households are food insecure"
		/><sup><a href="https://documents.wfp.org/stellent/groups/public/documents/ena/wfp244422.pdf" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="Poverty and food insecurity rates illustrate strong rural-urban inequalities present in the country, which tend to spur migration
			towards the main urban centres"
		/><sup><a href="https://www.afristat.org/wp-content/uploads/2023/03/ERI-ESI-Senegal-Rapport-Final.pdf" target="_blank" rel="noreferrer">7</a></sup>. </p>,
	},
	guatemala: {
		climateText: <p><Translator text="Guatemala is considered a primary hotspot for climate change due to its high vulnerability and low readiness.
			The average annual temperature as well as the maximum and minimum daily temperatures have increased by 0.8ºC and 0.6 ºC respectively and
			rainfall patterns have significantly been altered, indicating an increase of 122mm in the average annual rainfall as well as more intense
			and less distributed rainy days"
		/><sup><a href="https://sgccc.org.gt/congreso/wp-content/uploads/2021/08/Tercera-Comunicacion-Nacional-de-Cambio-Climatico-de-Guatemala.pdf" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="By 2050, temperatures are expected to increase between 2°C and 4°C" /><sup><a href="https://sgccc.org.gt/congreso/wp-content/uploads/2021/08/Tercera-Comunicacion-Nacional-de-Cambio-Climatico-de-Guatemala.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Climate projections expect a decrease
			in precipitations of up to 50 per cent in the semi-arid region of the country"
		/><sup><a href="https://insivumeh.gob.gt/wp-content/uploads/2021/02/Variabilidad_y_cambio_climatico.pdf" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Extreme events, such as tropical cyclones, are predicted to be
			more frequent across the country"
		/><sup><a href="https://insivumeh.gob.gt/wp-content/uploads/2021/02/Variabilidad_y_cambio_climatico.pdf" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Due to rising sea levels, predicted to increase by 9 to 13 cm by 2050, causing flooding and erosion, municipalities
			located in the coastal-marine areas will have a “high” to “very high” vulnerability to climate change"
		/><sup><a href="https://sgccc.org.gt/congreso/wp-content/uploads/2021/08/Tercera-Comunicacion-Nacional-de-Cambio-Climatico-de-Guatemala.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.climatelinks.org/sites/default/files/asset/document/2017_USAID%20ATLAS_Climate%20Change%20Risk%20Profile_Guatemala.pdf" target="_blank" rel="noreferrer">3</a></sup>. </p>,

		conflictText: <p><Translator text="Guatemala&apos;s recent history has been marked by military coups, a civil war, authoritarian repression, and ongoing instability.
			The country faces high levels of violence, particularly related to gang activity, organized crime, and drug trafficking"
		/><sup><a href="http://afghandata.org:8080/xmlui/bitstream/handle/123456789/22273/azu_acku_pamphlet_ge320_a33_n488_2016_w.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="The civil war,
			which took place during the Cold War, resulted in the deaths and disappearances of approximately 200,000 people, with indigenous Mayan communities
			being disproportionately affected"
		/><sup><a href="https://ucdp.uu.se/country/90" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Despite a peace agreement, Guatemala continues to experience violence from both state and non-state
			actors"
		/> <sup><a href="https://www.sup.org/books/title/?id=10027" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://journals.sagepub.com/doi/abs/10.1177/0094582X16650179" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="It has one of the highest homicide and femicide rates globally, leading to UNICEF considering
			it one of the most dangerous countries for children"
		/><sup><a href="https://www.unicef.org/media/66916/file/Hidden-in-plain-sight.pdf" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="Socio-environmental conflicts have also emerged due to new economic activities, such as sugar cane cultivation and mining, resulting in tensions between
			local communities and extractive industries"
		/><sup><a href="http://biblio3.url.edu.gt/Libros/2012/confli-UsoTierra1.pdf" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="These conflicts often stem from historical inequalities and limited resource access" /><sup><a href="https://www.rimisp.org/wp-content/uploads/2021/07/TED-Informe-Guatemala-FINAL.pdf" target="_blank" rel="noreferrer">7</a></sup>. </p>,

		globalMetricsText: <p><Translator text="Guatemala is a lower middle-income country heavily reliant on agriculture, particularly the coffee sector,
			which generates numerous jobs"
		/><sup><a href="https://data.worldbank.org/indicator/SL.AGR.EMPL.ZS?locations=GT" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.mdpi.com/2076-0760/8/12/323" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="However, the agriculture industry is vulnerable to climate impacts due to its dependence on rainfed production
			and its presence in disaster-prone areas"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2018-10/wb_gfdrr_climate_change_country_profile_for_GTM.pdf" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Poverty and inequality rates in Guatemala are persistently high, with indigenous communities and women
			facing significant disadvantages"
		/> <sup><a href="https://docs.wfp.org/api/documents/WFP-0000019629/download/" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://www.frontiersin.org/articles/10.3389/fsufs.2021.664253/full" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="The country also grapples with high rates of malnutrition and food insecurity, with chronic malnutrition affecting a
			large portion of the population, especially children"
		/><sup><a href="https://reliefweb.int/report/el-salvador/understanding-adverse-drivers-and-implications-migration-el-salvador-guatemala-and-honduras-comprehensive-analysis-building-report-complex-motivations-and-costs-central-american-migration" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="Guatemala&apos;s reliance on international food markets makes it susceptible to market shocks.
			The government&apos;s lack of funding for food security and healthcare, coupled with weak governance and corruption, hampers the state&apos;s ability to
			address crises and implement climate adaptation and mitigation measures"
		/><sup><a href="https://www.sciencedirect.com/science/article/pii/S2212096320300383?via%3Dihub" target="_blank" rel="noreferrer">7</a></sup>,<sup><a href="http://afghandata.org:8080/xmlui/bitstream/handle/123456789/22273/azu_acku_pamphlet_ge320_a33_n488_2016_w.pdf" target="_blank" rel="noreferrer">8</a></sup>. </p>,
	},
	philippines: {
		climateText: <p><Translator text="The Philippines, composed of 7,107 islands, is highly vulnerable to climate change and has limited resources for adaptation.
			It faces ecological threats and is exposed to natural hazards and climate change impacts"
		/> <sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/113512/Luzon_CRP.pdf?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-08/15852-WB_Philippines%20Country%20Profile-WEB.pdf" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="The country has a humid equatorial climate with
			high temperatures, heavy rainfall influenced by monsoons, and periodic droughts and heatwaves linked to El Niño Southern Oscillation"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-08/15852-WB_Philippines%20Country%20Profile-WEB.pdf" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="It is particularly
			prone to tropical cyclones, flooding, and landslides. Rainfall patterns vary, with some areas experiencing declines and others seeing increases"
		/><sup><a href="https://icsc.ngo/wp-content/uploads/2019/07/PAGASA_Observed_Climate_Trends_Projected_Climate_Change_PH_2018.pdf" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="By 2050,
			temperature increases of 0.9 to 1.9 degrees Celsius"
		/><sup><a href="https://www.eria.org/ERIA-DP-2013-15.pdf" target="_blank" rel="noreferrer">3</a></sup><Translator text="are projected, along with changes in rainfall patterns" /><sup><a href="https://icsc.ngo/wp-content/uploads/2019/07/PAGASA_Observed_Climate_Trends_Projected_Climate_Change_PH_2018.pdf" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="Climate change is expected to reduce tropical
			cyclone frequency, intensify seasonal variations in rainfall and cyclone intensity, raise sea levels by 20 cm, and worsen storm surge hazards and floods,
			particularly in coastal areas"
		/><sup><a href="https://icsc.ngo/wp-content/uploads/2019/07/PAGASA_Observed_Climate_Trends_Projected_Climate_Change_PH_2018.pdf" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://ovcre.uplb.edu.ph/journals-uplb/index.php/JESAM/article/view/484/461" target="_blank" rel="noreferrer">5</a></sup>.</p>,
		conflictText: <p><Translator text="Since gaining independence in 1946, the Philippines has faced a long history of violent subnational and local conflicts, particularly
			in Mindanao and the southern region. These conflicts involve communist insurgency groups, Muslim separatist groups, and military coup attempts"
		/> <sup><a href="https://www.tandfonline.com/doi/full/10.1080/13547860.2019.1572057" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://ucdp.uu.se/country/840" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="The root cause of the persistent conflict in Mindanao is competition over land, fueled by ethnoreligious divisions" /><sup><a href="https://www.crs.org/our-work-overseas/research-publications/local-solutions-land-conflict-mindanao" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="State-based violence, including
			extrajudicial killings linked to the War on Drugs, has increased"
		/><sup><a href="https://ucdp.uu.se/country/840" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="However, the government has initiated a peace process in the southern region, resulting
			in negotiations with the Moro Islamic Liberation Front (MILF) and the creation of the Bangsamoro Autonomous Region in Muslim Mindanao (BARMM) in 2019"
		/> <sup><a href="https://asiafoundation.org/publication/state-conflict-violence-asia/" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://www.crisisgroup.org/asia/south-east-asia/philippines/addressing-islamist-militancy-southern-philippines" target="_blank" rel="noreferrer">5</a></sup>.<Translator text="While militancy has diminished in some areas, episodic violence persists in certain municipalities" /><sup><a href="https://www.crisisgroup.org/asia/south-east-asia/philippines/addressing-islamist-militancy-southern-philippines" target="_blank" rel="noreferrer">5</a></sup>. </p>,

		globalMetricsText: <p><Translator text="Natural resources play a crucial role in the Philippines&apos;s economy, with agriculture, forestry,
			and fishing providing employment for a significant portion of the population"
		/> <sup><a href="https://cgspace.cgiar.org/handle/10568/82572" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://data.worldbank.org/indicator/NV.AGR.TOTL.ZS?locations=PH" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="However, challenges such as fragmented agriculture,
			lack of infrastructure, and institutional barriers have hindered the sector&apos;s development"
		/><sup><a href="https://cgspace.cgiar.org/handle/10568/82572" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="The country&apos;s unequal land tenure
			system and dependence on coastal areas further impact livelihoods"
		/> <sup><a href="http://usaidlandtenure.net/philippines" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-08/15852-WB_Philippines%20Country%20Profile-WEB.pdf" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="The fishing industry is vital for employment and diet" /><sup><a href="https://www.iucn.nl/en/story/more-fish-in-the-philippines/" target="_blank" rel="noreferrer">5</a></sup>.<Translator text="The Philippines
			faces vulnerability to climate change and natural hazards, which impacts food insecurity"
		/><sup><a href="https://docs.wfp.org/api/documents/WFP-0000133650/download/?_ga=2.262535308.1452654544.1675154767-1750196881.1667213291" target="_blank" rel="noreferrer">6</a></sup>.<Translator text="Poverty rates, food insecurity and child malnutrition
			remain significant challenges"
		/><sup><a href="https://data.worldbank.org/indicator/SN.ITK.MSFI.ZS?locations=PH" target="_blank" rel="noreferrer">7</a></sup>,<sup><a href="https://www.ipcinfo.org/ipc-country-analysis/details-map/en/c/1044577/" target="_blank" rel="noreferrer">8</a></sup>,<sup><a href="https://www.fao.org/documents/card/en/c/cc0639en" target="_blank" rel="noreferrer">9</a></sup>.<Translator text="Despite being a net food importer, the country has increased food exports, making it vulnerable to external
			supply chain disruptions."
		/> </p>,
	},
	zambia: {
		climateText: <p><Translator text="Zambia&apos;s climate is predominantly sub-tropical, characterized by a hot and dry, a wet rainy, and cool dry seasons" /><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf" target="_blank" rel="noreferrer">1</a></sup>.
			<Translator text="The country is divided into three agro-ecological regions, each with different precipitation patterns and vulnerability levels" /><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="The southern
			and western regions are dry and prone to droughts, while the central and eastern regions are more favorable for agriculture. The northern region
			experiences erratic rains and floods"
			/><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Climate variability in Zambia is influenced by the El Niño-Southern Oscillation (ENSO), with El Niño leading
			to dry spells and La Niña increasing wet spells"
			/><sup><a href="https://www.researchgate.net/publication/250221756_Interannual_variability_in_dry_and_wet_spell_characteristics_over_Zambia" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Historical data shows a long-term increase in mean temperature, hot days, and hot nights, along with
			a decrease in average rainfall"
			/><sup><a href="https://pdf.usaid.gov/pdf_docs/pa00msqc.pdf" target="_blank" rel="noreferrer">3</a></sup>. <Translator text="Future climate change projections indicate a temperature increase of 1.5-3°C by 2050, with increased rainfall variability,
			longer dry phases, and more intense extreme precipitation events"
			/><sup><a href="https://www.semanticscholar.org/paper/Near-term-climate-change-in-Zambia-Gannon-Kandy/7b7f49888f518af01a12c0b2e68ae05d90c52dbe" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="This will amplify the occurrence of floods and droughts in the country" /><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf" target="_blank" rel="noreferrer">1</a></sup>, <sup><a href="https://pdf.usaid.gov/pdf_docs/pa00msqc.pdf" target="_blank" rel="noreferrer">3</a></sup>, <sup><a href="https://www.semanticscholar.org/paper/Near-term-climate-change-in-Zambia-Gannon-Kandy/7b7f49888f518af01a12c0b2e68ae05d90c52dbe" target="_blank" rel="noreferrer">4</a></sup>. </p>,

		conflictText: <p><Translator text="Zambia is politically relatively stable, having avoided armed conflict since gaining independence" /><sup><a href="https://www.academia.edu/66396003/From_inclusive_governance_to_peace_exploring_African_governance_systems" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="It is considered a
			&apos;&apos;Zone of Peace&apos;&apos; in Southern Africa"
		/><sup><a href="http://uu.diva-portal.org/smash/record.jsf?pid=diva2%3A1150379&dswid=-9091" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="The post-independence government focused on national unity rather than ethnic identity,
			although ethnic and tribal identities still exist in the political sphere"
		/> <sup><a href="https://jgu.edu.in/jsgp/a-comparison-of-factors-behind-electoral-violence-for-the-states-of-maharashtra-kerala/" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://www.tandfonline.com/doi/abs/10.1080/21582041.2017.1385831" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="The secessionist movement of the Lozi Kingdom in Western Zambia has
			caused tensions"
		/><sup><a href="https://link.springer.com/chapter/10.1007/978-3-319-90206-7_11" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="The country has experienced episodes of political turmoil, including attempted military coups and occasional riots with xenophobic
			tendencies"
		/><sup><a href="https://www.academia.edu/66396003/From_inclusive_governance_to_peace_exploring_African_governance_systems" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.bbc.com/news/world-africa-36092917" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Discontent with former President Edward Lungu, economic inequality, and declining copper prices have contributed to social unrest" /><sup><a href="https://acleddata.com/2016/09/09/zambia-september-2016-update/" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="Lungu&apos;s
			administration increased repression, leading to human rights concerns"
		/><sup><a href="https://www.amnesty.org/en/documents/afr63/4057/2021/en/" target="_blank" rel="noreferrer">7</a></sup>. <Translator text="In 2021, opposition leader Hakainde Hichilema won the election, bringing a change in
			government"
		/><sup><a href="https://edition.cnn.com/2021/08/16/africa/zambia-election-president-hichilema/index.html." target="_blank" rel="noreferrer">8</a></sup>.<Translator text="Despite potential challenges, Zambia remains relatively stable and peaceful compared to its neighbours" /><sup><a href="https://www.academia.edu/66396003/From_inclusive_governance_to_peace_exploring_African_governance_systems" target="_blank" rel="noreferrer">1</a></sup>.</p>,

		globalMetricsText: <p><Translator text="Zambia, a landlocked country heavily dependent on natural resources, is vulnerable to climate change" /><sup><a href="https://sa-tied.wider.unu.edu/sites/default/files/pdf/SA-TIED-WP-137.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="It experienced significant
			economic growth between 2004 and 2014, driven by the mining sector, particularly copper. However, economic progress stagnated as copper prices declined,
			highlighting the country&apos;s reliance on mining"
		/><sup><a href="https://sa-tied.wider.unu.edu/sites/default/files/pdf/SA-TIED-WP-137.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://data.worldbank.org/indicator/NY.GDP.MKTP.CD?locations=ZM" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Efforts are being made to diversify the economy into agriculture, energy, transportation, construction,
			and tourism"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf." target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Agriculture plays a crucial role, employing 50% of the population and contributing 3.4% to the GDP" /> <sup><a href="https://data.worldbank.org/indicator/SL.UEM.TOTL.ZS?locations=ZM." target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://www.fao.org/3/cb8716en/cb8716en.pdf" target="_blank" rel="noreferrer">5</a></sup>.<Translator text="Hydroelectric power is the primary
			source of energy, but increasing droughts pose challenges to the energy sector"
		/><sup><a href="https://sa-tied.wider.unu.edu/sites/default/files/pdf/SA-TIED-WP-137.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Despite Zambia&apos;s transition to a lower middle-income category, socioeconomic
			vulnerabilities persist, with only a small urban minority benefiting from economic growth"
		/><sup><a href="https://sa-tied.wider.unu.edu/sites/default/files/pdf/SA-TIED-WP-137.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.oecd.org/dac/financing-sustainable-development/development-finance-topics/Zambia-Transition-Finance-country-pilot.pdf." target="_blank" rel="noreferrer">2</a></sup>.<Translator text="The majority of the population relies on low-productivity
			rainfed subsistence farming, facing inadequate resources, farming practices, and limited access to agro-meteorological information"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf." target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/83484/CSA%20_Profile_Zambia.pdf?sequence=4&isAllowed=y." target="_blank" rel="noreferrer">6</a></sup>.<Translator text="High poverty rates,
			unemployment, food insecurity, and a growing population further compound the challenges faced by the country"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/Third%20National%20Communication%20-%20Zambia.pdf." target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://reliefweb.int/report/zambia/zambia-ipc-acute-food-insecurity-analysis-july-2022-march-2023-published-august-30-2022" target="_blank" rel="noreferrer">7</a></sup>,<sup><a href="https://www.bbc.com/news/world-africa-58408951." target="_blank" rel="noreferrer">8</a></sup>. </p>,
	},
	zimbabwe: {
		climateText: <p><Translator text="Zimbabwe, a landlocked country in southern Africa, has a semi-arid region and a subtropical climate" /><sup><a href="https://iopscience.iop.org/article/10.1088/1755-1307/6/41/412045" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Rainfall is highly variable,
			occurring from mid-November to mid-March, while the dry season lasts from mid-May to mid-November"
		/><sup><a href="http://climatechange.org.zw/" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Temperatures differ across regions, with cooler
			climates in the east and north and warmer temperatures up to 40ºC in the south and northeast"
		/> <sup><a href="https://www.kas.de/c/document_library/get_file?uuid=6dfce726-fdd1-4f7b-72e7-e6c1ca9c9a95&groupId=252038" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://www.climatelinks.org/sites/default/files/asset/document/2017_Cadmus_Climate-Risk-Profile_Haiti.pdf%0Ahttps:/www.climatelinks.org/sites/default/files/asset/document/2017_USAID" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="The country is highly vulnerable to climate change
			and is exposed to tropical cyclones, floods, droughts, and dry spells, with extreme droughts primarily affecting southwestern provinces"
		/><sup><a href="http://climatechange.org.zw/" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://www.kas.de/c/document_library/get_file?uuid=6dfce726-fdd1-4f7b-72e7-e6c1ca9c9a95&groupId=252038" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://www.mdpi.com/2071-1050/12/3/752" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="Over the past
			century, evidence indicates a delayed onset and early end to the rainy season and a temperature increase of 0.9ºC"
		/> <sup><a href="https://link.springer.com/article/10.1023/B:GEJO.0000003613.15101.d9" target="_blank" rel="noreferrer">6</a></sup>,<sup><a href="https://unfccc.int/sites/default/files/resource/Zimbabwe%20Biennial%20Update%20Report%201.pdf" target="_blank" rel="noreferrer">7</a></sup>. <Translator text="Future projections suggest rising
			temperatures, reduced annual precipitation, more frequent and intense extreme precipitation events, and increased occurrences of droughts and dry spells"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/Zimbabwe%204th%20National%20Communication%20to%20the%20UNFCCC.pdf" target="_blank" rel="noreferrer">8</a></sup>.<Translator text="Provinces such as Matabeleland South, Masvingo, and Manicaland are expected to be most affected by declining precipitation" /><sup><a href="https://unfccc.int/sites/default/files/resource/Zimbabwe%204th%20National%20Communication%20to%20the%20UNFCCC.pdf" target="_blank" rel="noreferrer">8</a></sup>. </p>,

		conflictText: <p> <Translator text="Zimbabwe gained independence from the UK in 1980 following the Rhodesian Bush War. The country experienced periods of
			stability but also faced economic hardships and political turmoil"
		/><sup><a href="https://ucdp.uu.se/country/552" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="It experienced ecades of authoritarian rule characterized by corruption,
			weak governance and tensions among the elites"
		/><sup><a href="http://cpi.transparency.org/cpi2013/results/" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://engagedscholarship.csuohio.edu/cgi/viewcontent.cgi?referer=&httpsredir=1&article=1024&context=tdr" target="_blank" rel="noreferrer">3</a></sup>. <Translator text="The Gukurahundi Massacres in the 1980s resulted in the deaths of thousands of civilians as the
			government suppressed rebels"
		/><sup><a href="http://archive.kubatana.net/docs/hr/ccjp_lrf_breaking_silence_9904.pdf" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="The Movement for Democratic Change (MDC) emerged as the main opposition force in the early 2000s, leading to clashes
			with the ruling ZANU-PF"
		/><sup><a href="http://www.diva-portal.org/smash/record.jsf?pid=diva2%3A469267&dswid=-9396" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="State-sponsored violence has been concentrated in opposition strongholds" /><sup><a href="http://www.diva-portal.org/smash/record.jsf?pid=diva2%3A469267&dswid=-9396" target="_blank" rel="noreferrer">5</a></sup>.<Translator text="Machete gang violence has increased in gold mining
			areas, driven by conflicts over control and accusations of government patronage"
		/><sup><a href="https://www.tandfonline.com/doi/full/10.1080/03056244.2019.1622210" target="_blank" rel="noreferrer">6</a></sup>.<Translator text="Machete-wielding gangs have emerged as a response to elite control of
			mineral resources. The government has been accused of encroaching on artisanal miners and mobilizing machete gangs against rivals"
		/><sup><a href="https://www.crisisgroup.org/africa/southern-africa/zimbabwe/294-all-glitters-not-gold-turmoil-zimbabwes-mining-sector" target="_blank" rel="noreferrer">7</a></sup>. </p>,

		globalMetricsText: <p><Translator text="Natural resources, particularly minerals like diamonds, gold, platinum, and chrome, play a significant role in Zimbabwe&apos;s economy,
			accounting for a substantial portion of export earnings and GDP"
		/><sup><a href="https://www.lse.ac.uk/business/consulting/reports/sia-in-support-of-the-negotiations-with-esa5" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="The majority of the population resides in rural areas, highlighting the importance of
			the agricultural sector"
		/><sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/97083/CSA%20_Profile_Zimbabwe_12012018_1330.pdf?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Agriculture, forestry, and fishing are climate-sensitive sectors that contribute to the livelihoods of rural households and
			employment for a significant portion of the population. However, the sector is vulnerable to higher temperatures and rainfall variability, with the reliance
			on rainfed crops posing a risk"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-05/14956-WB_Zimbabwe%20Country%20Profile-WEB%20%281%29.pdf" target="_blank" rel="noreferrer">3</a></sup>. <Translator text="Zimbabwe has faced a decline in living standards, with economic challenges, unemployment, poverty, hyperinflation, and
			recurrent droughts impacting the population"
		/> <sup><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&ved=2ahUKEwjy5OzI197-AhWTWcAKHfqDAtAQFnoECAsQAQ&url=https%3A%2F%2Fwww.africaportal.org%2Fdocuments%2F18475%2Fzimbabwe_conflict_insight_final_13.09.2018.pdf&usg=AOvVaw3PPHRtsiMNpIiUGFXNPO6O" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://www.ifad.org/documents/38714170/42164624/climate_analysis_zimbabwe.pdf/31fb5ab0-7a57-5978-4b82-51ddfd99ba71?t=1606831141000" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="Widespread poverty and food insecurity prevail, with high levels of malnutrition and a significant proportion
			of the population resorting to food-based coping strategies"
		/><sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/97083/CSA%20_Profile_Zimbabwe_12012018_1330.pdf?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Environmental degradation, inadequate support systems further challenge Zimbabwe&apos;s agricultural
			sector, leading to increased dependence on food imports and vulnerability to climate change impacts"
		/> <sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/97083/CSA%20_Profile_Zimbabwe_12012018_1330.pdf?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://apps.worldagroforestry.org/downloads/Publications/PDFS/B14549.pdf" target="_blank" rel="noreferrer">6</a></sup>,<sup><a href="https://www.fao.org/3/cb9997en/cb9997en.pdf" target="_blank" rel="noreferrer">7</a></sup>. </p>,
	},
	ethiopia: {
		climateText: <p><Translator text="Ethiopia has a diverse climate and topography, ranging from semi-arid desert conditions to high rainfall equatorial rainforests" /><sup><a href="https://unfccc.int/sites/default/files/resource/ethnc2.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="It experiences
			three rainy seasons, with the June-September period accounting for a significant portion of annual rainfall"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/ethnc2.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Average temperatures have been increasing since
			1960, particularly from July to September, with a rise of 1°C and an increase in the number of hot days"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-05/15463A-WB_Ethiopia%20Country%20Profile-WEB.pdf" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="There has been a decrease in spring and summer rainfall
			in south and central Ethiopia, along with an increase in heavy rainfall events"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/ethnc2.pdf" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="The country faces risks of extreme climate events like droughts and floods" /><sup><a href="https://unfccc.int/sites/default/files/resource/ethnc2.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Ongoing La Niña-induced droughts and consecutive failed rain seasons have affected millions of people, especially in the southern and south-eastern regions" /><sup><a href="https://ethiopia.iom.int/sites/g/files/tmzbdl996/files/documents/Drought%20Response%20Plan%202023.pdf" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Future projections suggest temperature increases between 1.5°C and 3°C by 2050, while rainfall predictions remain uncertain, with potential declines and regional
			variations"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/ethnc2.pdf" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Southern regions are expected to face significant reductions in rainfall." /> </p>,

		conflictText: <p><Translator text="Ethiopia has a complex political and conflict history influenced by ethnic dynamics and challenging climatic conditions. Drought-induced famines
			in the past led to rebellions, political turmoil, and changes of government"
		/><sup><a href="https://www.tandfonline.com/doi/abs/10.3763/ehaz.2002.0413" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Ethiopia&apos;s diverse population consists of over 70 ethnic groups, many
			with separatist sentiments and interethnic conflicts"
		/><sup><a href="https://ucdp.uu.se/country/530" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Opposition groups united to overthrow the Derg regime after a period of intra-state conflicts" /><sup><a href="https://www.cambridge.org/core/journals/journal-of-modern-african-studies/article/abs/opposition-politics-and-ethnicity-in-ethiopia-we-will-all-go-down-together/C26E58A22ADD7E8960D6082F5A92DBB6" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Among others the Ogaden War and Eritrea&apos;s struggle for independence" /> <sup><a href="https://apps.dtic.mil/sti/pdfs/ADA326941.pdf" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="http://csis-website-prod.s3.amazonaws.com/s3fs-public/publication/190115_Eritrea_IndependenceMovements.pdf" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="The fall of the Derg in 1991 led to the rule of the EPRDF, characterized
			by a commitment to ethnic-based federalism"
		/><sup><a href="https://ucdp.uu.se/country/530" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://www.refworld.org/pdfid/4aa4c0c82.pdf" target="_blank" rel="noreferrer">6</a></sup>.<Translator text="The effectiveness of ethnic federalism remains a topic of debate" /><sup><a href="https://issafrica.org/research/monographs/drivers-of-ethnic-conflict-in-contemporary-ethiopia" target="_blank" rel="noreferrer">7</a></sup>. <Translator text="Competing ethno-nationalism and ethnic
			mobilization have weakened state structures, leading to increased ethnic-based violence"
		/><sup><a href="https://issafrica.org/research/monographs/drivers-of-ethnic-conflict-in-contemporary-ethiopia" target="_blank" rel="noreferrer">7</a></sup>,<sup><a href="https://www.crisisgroup.org/africa/horn-africa/ethiopia/269-managing-ethiopias-unsettled-transition" target="_blank" rel="noreferrer">8</a></sup>.<Translator text="Simmering conflict have sparked again after political liberalization
			under Abiy Ahmed Ali"
		/><sup><a href="https://issafrica.org/research/monographs/drivers-of-ethnic-conflict-in-contemporary-ethiopia" target="_blank" rel="noreferrer">7</a></sup>. <Translator text="Turning to a civil war mainly in Tigray region until a peace deal was signed in 2022 to facilitate humanitarian aid and protect civilians" /><sup><a href="https://www.crisisgroup.org/africa/horn-africa/ethiopia/turning-pretoria-deal-lasting-peace-ethiopia" target="_blank" rel="noreferrer">9</a></sup>. </p>,

		globalMetricsText: <p><Translator text="Ethiopia, the oldest state in sub-Saharan Africa, heavily relies on agriculture for its economy, employment, and export earnings" /><sup><a href="https://cgspace.cgiar.org/handle/10568/92491" target="_blank" rel="noreferrer">1</a></sup>.
			<Translator text="However, climate change poses a significant threat to the agricultural sector, projected to reduce the country&apos;s GDP by up to 10 percent by 2045" /><sup><a href="https://unfccc.int/sites/default/files/resource/ethnc2.pdf" target="_blank" rel="noreferrer">2</a></sup>.
			<Translator text="With agriculture predicted to decline by 6-32.5 percent by 2050, Ethiopia may become dependent on food aid" /><sup><a href="https://www.mdpi.com/2225-1154/9/6/96" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Population growth exacerbates challenges,
			including declining soil fertility and limited resources, while a land tenure system characterized by state ownership leads to tenure insecurity and land
			fragmentation"
			/><sup><a href="https://cgspace.cgiar.org/handle/10568/92491" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://www.fao.org/faostat/en/#country/238" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="Poverty and food insecurity persist, with a high prevalence of undernourishment among
			children"
			/><sup><a href="https://www.fao.org/faostat/en/#country/238" target="_blank" rel="noreferrer">5</a></sup>,<sup><a href="https://www.fao.org/documents/card/en/c/cb3255en" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="Limited access to food and inadequate infrastructure further compound these challenges" /><sup><a href="https://cgspace.cgiar.org/handle/10568/92491" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="In 2022, prolonged droughts, conflict, and cholera
			outbreaks worsened the humanitarian situation, leaving millions in need and millions internally displaced"
			/><sup><a href="https://reliefweb.int/report/ethiopia/unicef-ethiopia-humanitarian-situation-report-no-10-october-2022" target="_blank" rel="noreferrer">7</a></sup>.</p>,
	},
	sudan: {
		climateText: <p><Translator text="Sudan&apos;s geography is characterized by arid and semi-arid ecosystems, with the northern part dominated by deserts and the south
			experiencing low rainfall savannah and montane vegetation"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/Sudan-2NC-Final.pdf" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="The country&apos;s climate varies, with the main rainy season occurring from May to October" /><sup><a href="https://unfccc.int/sites/default/files/resource/Sudan-2NC-Final.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="Over the years, Sudan has witnessed a rise in temperature and changes in rainfall patterns" /><sup><a href="https://unfccc.int/sites/default/files/resource/Sudan-2NC-Final.pdf" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="It is considered one of the most climate-vulnerable countries,
			with increased frequency of extreme events such as droughts and floods"
		/><sup><a href="https://pdf.usaid.gov/pdf_docs/pa00mtz6.pdf" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Historical droughts have been recorded before, but the recurrence since 1987 is
			unprecedented1. Temperatures are projected to increase, leading to intensified droughts, while rainfall is expected to slightly increase with
			higher variability"
		/><sup><a href="https://unfccc.int/sites/default/files/resource/Sudan-2NC-Final.pdf" target="_blank" rel="noreferrer">1</a></sup>, <sup><a href="https://pdf.usaid.gov/pdf_docs/pa00mtz6.pdf" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Desertification and rising sea levels are also concerns for the country&apos;s future" /><sup><a href="https://unfccc.int/sites/default/files/resource/Sudan-2NC-Final.pdf" target="_blank" rel="noreferrer">1</a></sup>. </p>,

		conflictText: <p><Translator text="Sudan is a diverse country with ongoing conflicts rooted in tribal divisions and resource competition" /><sup><a href="https://scholarship.law.upenn.edu/cgi/viewcontent.cgi?article=2037&context=jil" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Since its independence in 1956,
			it has faced political instability and civil wars, particularly between the government in Khartoum and marginalized regions like Darfur, Blue Nile State,
			and Kordofan"
		/><sup><a href="https://www.cfr.org/blog/roots-sudans-upheaval" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="The North-South civil war resulted in the secession of South Sudan in 2011 after the signing of the Comprehensive Peace Agreement" /><sup><a href="https://scholarship.law.upenn.edu/cgi/viewcontent.cgi?article=2037&context=jil" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="The Darfur
			conflict has been a major source of violence, with the third rebellion from 2003 to 2010 being the largest civil war"
		/><sup><a href="https://scholarship.law.upenn.edu/cgi/viewcontent.cgi?article=2037&context=jil" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Climate change and desertification
			have exacerbated resource pressures, but contextual drivers such as marginalization, historical conflict, and governance also contribute
			to the Darfur conflict"
		/> <sup><a href="https://books.google.de/books?id=hnggygAACAAJ" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://pubmed.ncbi.nlm.nih.gov/22069801/" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="In recent years, protests and political changes, including the overthrow of Omar al-Bashir in 2019, have characterized Sudan&apos;s
			political landscape, with ongoing tensions and power struggles between the military and civilian factions"
		/> <sup><a href="https://www.crisisgroup.org/africa/horn-africa/sudan/b143-improving-prospects-peaceful-transition-sudan" target="_blank" rel="noreferrer">5</a></sup>,<sup><a href="https://www.crisisgroup.org/africa/horn-africa/sudan/breakthrough-sudans-impasse" target="_blank" rel="noreferrer">6</a></sup>.<Translator text="Intercommunal violence and political impasse
			continue to pose challenges for the country"
		/> <sup><a href="https://www.aljazeera.com/news/2022/7/19/whats-behind-tribal-violence-in-sudans-blue-nile-state-explainer" target="_blank" rel="noreferrer">7</a></sup>.  </p>,
		globalMetricsText: <p><Translator text="Sudan is grappling with high levels of poverty, economic instability, and food insecurity. Nearly half of the population lives in poverty,
			and the secession of South Sudan has had a detrimental impact on the country&apos;s economy "
		/><sup><a href="https://databankfiles.worldbank.org/public/ddpext_download/poverty/33EF03BB-9722-4AE2-ABC7-AA2972D68AFE/Global_POVEQ_SDN.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.statista.com/statistics/727142/gross-domestic-product-gdp-per-capita-in-sudan/" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Climate change poses a significant threat to the agricultural
			sector, with unpredictable rainfall patterns leading to crop losses and jeopardizing food security"
		/><sup><a href="https://www.unep.org/resources/report/sudan-first-state-environment-outlook-report-2020" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Productivity in agriculture has declined, exacerbating the
			challenges faced by the country"
		/><sup><a href="https://www.fao.org/documents/card/en/c/cb9122en" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="Sudan also grapples with soaring inflation, the highest in the world, further worsening the economic situation" /><sup><a href="https://data.worldbank.org/indicator/FP.CPI.TOTL.ZG?locations=SD" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="The combination
			of conflict, economic crises, and poor harvests has resulted in acute food insecurity, affecting a significant portion of the population"
		/><sup><a href="https://fews.net/east-africa/sudan/food-security-outlook-update/april-2022" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="The Ukraine-Russia War
			has worsened the food crisis, impacting Sudan&apos;s wheat imports"
		/><sup><a href="https://fews.net/east-africa/sudan/food-security-outlook-update/april-2022" target="_blank" rel="noreferrer">6</a></sup>.<Translator text="Rising fuel and agricultural prices have fueled protests and strikes, exacerbating
			socio-economic conditions"
		/><sup><a href="https://www.thenewhumanitarian.org/analysis/2022/04/15/post-coup-sudan-protest-hunger-humanitarian-needs" target="_blank" rel="noreferrer">7</a></sup>.  </p>,
	},
	nigeria: {
		climateText: <p><Translator text="Nigeria has three distinct climatic zones: a tropical climate in the south, a tropical savannah climate in the central regions, and a
			Sahelian hot and semi-arid climate in the north"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-07/15918-WB_Nigeria%20Country%20Profile-WEB.pdf" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="Temperature has been increasing over the past five decades, with a rise of 0.2°C per decade on average" /><sup><a href="https://www4.unfccc.int/sites/SubmissionsStaging/NationalReports/Documents/187563_Nigeria-NC3-1-TNC%20NIGERIA%20-%2018-04-2020%20-%20FINAL.pdf" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Projections indicate a further increase of 1.95°C to 2.31°C by 2050, with higher increases in central and northern areas" /><sup><a href="https://www4.unfccc.int/sites/SubmissionsStaging/NationalReports/Documents/187563_Nigeria-NC3-1-TNC%20NIGERIA%20-%2018-04-2020%20-%20FINAL.pdf" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Rainfall variability is expected
			to rise, along with more extreme weather events"
		/><sup><a href="https://www4.unfccc.int/sites/SubmissionsStaging/NationalReports/Documents/314059286_Nigeria-BUR2-1-NIGERIA%20BUR%202%20-%20Second%20Biennial%20Update%20Report%20(BUR2).pdf" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Northern Nigeria may experience more flooding and droughts, while eastern and central regions could become
			more arid"
		/><sup><a href="https://climateknowledgeportal.worldbank.org/sites/default/files/2021-07/15918-WB_Nigeria%20Country%20Profile-WEB.pdf" target="_blank" rel="noreferrer">1</a></sup>, <sup><a href="https://www4.unfccc.int/sites/SubmissionsStaging/NationalReports/Documents/187563_Nigeria-NC3-1-TNC%20NIGERIA%20-%2018-04-2020%20-%20FINAL.pdf" target="_blank" rel="noreferrer">2</a></sup>. <Translator text="Southern parts may face heavier rainfall and increased flood risks, compounded by rising sea levels in the Niger Delta region" /><sup><a href="https://www.hindawi.com/journals/amete/2017/8576150/" target="_blank" rel="noreferrer">4</a></sup>.</p>,

		conflictText: <p><Translator text="Nigeria has a history of ethnopolitical divisions between its Muslim-majority north and Christian-majority south" /><sup><a href="https://ucdp.uu.se/country/475" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="The country has faced
			conflicts such as the secessionist conflict of Biafra, Boko Haram insurgency in the northeast, and social unrest in the Niger Delta region"
		/> <sup><a href="https://www.sciencedirect.com/science/article/abs/pii/S0143622811001470" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://adelphi.de/en/publications/climate-fragility-profile-lake-chad-basin" target="_blank" rel="noreferrer">3</a></sup>,<sup><a href="https://cpr.unu.edu/research/projects/climate-security.html#_" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="Intercommunal
			conflicts between farmers and herders in central Nigeria have also increased, driven by resource scarcity and changes in migration patterns"
		/> <sup><a href="https://www.oecd-ilibrary.org/development/pastoralist-violence-in-north-and-west-africa_63dff519-en" target="_blank" rel="noreferrer">5</a></sup>,<sup><a href="https://www.researchgate.net/publication/320199002_Nigeria_Climate_War_Migratory_Adaptation_and_Farmer-Herder_Conflicts" target="_blank" rel="noreferrer">6</a></sup>.<Translator text="Organized crime,
			including piracy, trafficking, and armed robbery, is another challenge"
		/> <sup><a href="https://www.unodc.org/res/piracy/index_html/UNODC_GMCP_Pirates_of_the_Niger_Delta_between_brown_and_blue_waters.pdf" target="_blank" rel="noreferrer">7</a></sup>,<sup><a href="https://thenextier.com/wp-content/plugins/download-attachments/includes/download.php?id=6160" target="_blank" rel="noreferrer">8</a></sup>.<Translator text="These conflicts and criminal activities pose climate and fragility risks in three key
			areas: the north, the middle belt, and the Niger Delta"
		/><sup><a href="https://www.strausscenter.org/wp-content/uploads/Country-Brief-Fragility-and-Climate-Risks-in-Nigeria-2019.pdf" target="_blank" rel="noreferrer">9</a></sup>.<Translator text="Nigeria is significantly affected by violent incidents and fatalities compared to other countries
			in North and West Africa."
		/> </p>,
		globalMetricsText: <p><Translator text="Nigeria, as one of Africa&apos;s most populous countries, faces significant development challenges despite its
			abundant natural resources"
		/> <sup><a href="https://www.imf.org/external/pubs/ft/wp/2003/wp03139.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.researchgate.net/publication/250612848_The_Resource_Curse_in_Nigeria_A_Story_of_Oil_and_Corruption" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="The country struggles with poor governance, including nepotism and corruption, leading to economic stagnation
			and the so-called &apos;&apos;resource curse.&apos;&apos;"
		/> <sup><a href="https://www.imf.org/external/pubs/ft/wp/2003/wp03139.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www.researchgate.net/publication/250612848_The_Resource_Curse_in_Nigeria_A_Story_of_Oil_and_Corruption" target="_blank" rel="noreferrer">2</a></sup><Translator text="High poverty levels, unemployment, and inadequate infrastructure contribute to
			the country&apos;s development hurdles"
		/><sup><a href="http://ttps//www.africaportal.org/documents/22527/WA-37.pdf" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="The agricultural sector, although significant, suffers from low productivity due to factors such as poor
			soil fertility, limited access to inputs and markets, adverse climatic conditions, and inefficient land management"
		/> <sup><a href="https://csis-website-prod.s3.amazonaws.com/s3fs-public/publication/170317_Downie_AgricultureSectorNigeria_Web.pdf" target="_blank" rel="noreferrer">4</a></sup>,<sup><a href="https://www.fao.org/documents/card/en/c/cb5856en" target="_blank" rel="noreferrer">5</a></sup>,<sup><a href="https://www4.unfccc.int/sites/SubmissionsStaging/NationalReports/Documents/187563_Nigeria-NC3-1-TNC%20NIGERIA%20-%2018-04-2020%20-%20FINAL.pdf" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="Nigeria&apos;s agriculture
			is highly vulnerable to climate variability and extremes, exacerbating food insecurity and hindering progress towards food security"
		/><sup><a href="https://www.imf.org/external/pubs/ft/wp/2003/wp03139.pdf" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://www4.unfccc.int/sites/SubmissionsStaging/NationalReports/Documents/187563_Nigeria-NC3-1-TNC%20NIGERIA%20-%2018-04-2020%20-%20FINAL.pdf" target="_blank" rel="noreferrer">6</a></sup>. <Translator text="The conflict-affected
			northeastern states experience acute food insecurity, with millions of people affected"
		/><sup><a href="https://www.fao.org/3/cb6846en/cb6846en.pdf" target="_blank" rel="noreferrer">7</a></sup>. <Translator text="Overall, Nigeria&apos;s economic potential remains largely untapped,
			and addressing issues related to governance, poverty, and agricultural productivity is crucial for sustainable development."
		/> </p>,
	},
	mali: {
		climateText: <p><Translator text="Mali is characterized by three distinct climatic zones: the arid north, the semiarid Sahelian center, and the Sudanian south" /><sup><a href="https://unfccc.int/documents/181335" target="_blank" rel="noreferrer">1</a></sup>.<Translator text="The country is
			located in the Sahel, one of the most climate-vulnerable regions globally"
		/><sup><a href="https://www.researchgate.net/publication/264859757_Identifying_hot_spots_of_security_vulnerability_associated_with_climate_change_in_Africa" target="_blank" rel="noreferrer">2</a></sup>.<Translator text="Over the past decades, there has been an overall increase in annual temperatures
			and a decline in rainfall, although there has been partial recovery since the 1990s"
		/><sup><a href="https://www.usaid.gov/sites/default/files/documents/1866/DCHA_FFP_Mali_CRP_WITHOUT_adaptation_responses_10082019.pdf" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="Climatic projections indicate a further increase in temperatures by 2050,
			with more significant effects in the northern regions, and a decline in annual precipitation from south to north"
		/><sup><a href="https://unfccc.int/documents/181335" target="_blank" rel="noreferrer">1</a></sup>. <Translator text="As a result, Mali is expected to face increased
			incidence of droughts and floods, with accelerated desertification and more frequent Harmattan dust storms in the north and heavier rainfall and flooding in the
			south"
		/><sup><a href="https://unfccc.int/documents/181335" target="_blank" rel="noreferrer">1</a></sup>, <sup><a href="https://www.researchgate.net/publication/264859757_Identifying_hot_spots_of_security_vulnerability_associated_with_climate_change_in_Africa" target="_blank" rel="noreferrer">2</a></sup>, <sup><a href="https://www.usaid.gov/sites/default/files/documents/1866/DCHA_FFP_Mali_CRP_WITHOUT_adaptation_responses_10082019.pdf" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="These climate changes pose significant challenges to the country&apos;s ecosystems and agriculture." /> </p>,

		conflictText: <p><Translator text="Mali has a history of large-scale and widespread conflict, involving state-based, intrastate, and communal violence" /><sup><a href="https://ucdp.uu.se/country/432" target="_blank" rel="noreferrer">1</a></sup>.
			<Translator text="The country&apos;s high degree of ethnic diversity, combined with the legacy of French colonial rule and post-colonial governance,
			has contributed to historical tensions between the North and South and regional disparities"
			/><sup><a href="https://www.clingendael.org/sites/default/files/pdfs/The_roots_of_Malis_conflict.pdf" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://nupi.brage.unit.no/nupi-xmlui/bitstream/handle/11250/2468085/NUPI_rapport_Mali_Ba_B%C3%B8%C3%A5s.pdf?sequence=2" target="_blank" rel="noreferrer">3</a></sup>. <Translator text="Grievances, particularly among the Tuareg
			population in Northern Mali, have led to multiple insurrections, with the most recent occurring in 2012"
			/><sup><a href="https://nupi.brage.unit.no/nupi-xmlui/bitstream/handle/11250/2468085/NUPI_rapport_Mali_Ba_B%C3%B8%C3%A5s.pdf?sequence=2" target="_blank" rel="noreferrer">3</a></sup>.<Translator text="This uprising evolved into a multidimensional
			security crisis, involving various armed actors"
			/><sup><a href="http://www.diva-portal.org/smash/get/diva2:271599/FULLTEXT01.pdf;producing" target="_blank" rel="noreferrer">4</a></sup>. <Translator text="The situation has been further complicated by recent coup d&apos;états in 2020 and 2021,
			which have heightened political tensions and prolonged the occupation of armed groups in the northern and central parts of the country"
			/><sup><a href="https://icg-prod.s3.amazonaws.com/304-transition-au-mali.pdf" target="_blank" rel="noreferrer">5</a></sup>. <Translator text="The root causes
			of the conflicts include socio-economic exclusion, marginalization, weak state presence in peripheral areas, resource exploitation, and ethnic friction"
			/><sup><a href="https://www.sipri.org/publications/2021/sipripolicy-papers/climate-related-security-risks-and-peacebuilding-mali" target="_blank" rel="noreferrer">6</a></sup>.
			<Translator text="Corruption, weak governance, and centralized rule continue to hamper state institutions despite efforts to establish a multi-party system" /><sup><a href="https://www.interpeace.org/wp-content/uploads/2015/03/2015_03_02_Mali_Autoportrait_FR.pdf" target="_blank" rel="noreferrer">7</a></sup>. </p>,
		
		globalMetricsText: <p><Translator text="Mali&apos;s economy heavily relies on natural resources, particularly the agricultural, livestock, and fishery sectors" /><sup><a href="https://unfccc.int/documents/181335" target="_blank" rel="noreferrer">1</a></sup>.
			<Translator text="These sectors employ a significant portion of the workforce, contribute 40% to the GDP, and account for a large share of exports. However, they are
			highly vulnerable to climate variability and extreme weather events due to their dependence on rainfed agriculture and subsistence smallholder farming"
			/><sup><a href="https://unfccc.int/documents/181335" target="_blank" rel="noreferrer">1</a></sup>.
			<Translator text="Challenges such as underdeveloped markets, lack of infrastructure, financial opportunities, and insecurity further hinder agricultural productivity
			and the country&apos;s ability to meet food requirements"
			/><sup><a href="https://nupi.brage.unit.no/nupi-xmlui/bitstream/handle/11250/2468085/NUPI_rapport_Mali_Ba_B%C3%B8%C3%A5s.pdf?sequence=2" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://cgspace.cgiar.org/bitstream/handle/10568/111457/MALI%20CSA%20Profile_feb21.pdf" target="_blank" rel="noreferrer">3</a></sup>. <Translator text="The gold sector, characterized by unregulated extraction activities, is also a significant
			export product but contributes to environmental degradation and is sometimes linked to armed groups and criminal networks"
			/><sup><a href="https://unfccc.int/documents/181335" target="_blank" rel="noreferrer">1</a></sup>,<sup><a href="https://mneguidelines.oecd.org/Assessment-of-the-supply-chains-of-gold-produced-in-Burkina-Faso-Mali-Niger.pdf" target="_blank" rel="noreferrer">4</a></sup>.<Translator text="The ongoing armed conflict
			has further exacerbated vulnerabilities, leading to widespread poverty, rural-urban inequality, unemployment, and food insecurity"
			/><sup><a href="https://nupi.brage.unit.no/nupi-xmlui/bitstream/handle/11250/2468085/NUPI_rapport_Mali_Ba_B%C3%B8%C3%A5s.pdf?sequence=2" target="_blank" rel="noreferrer">2</a></sup>,<sup><a href="https://www.afdb.org/en/documents/mali-country-strategy-paper-2015-2019-completion-report-combined-2019-country-portfolio-performance-review" target="_blank" rel="noreferrer">5</a></sup>.<Translator text="Mali&apos;s rapidly
			growing population is projected to put additional pressure on natural resources, declining per capita water availability"
			/><sup><a href="https://climate-diplomacy.org/climate-security-expert-network" target="_blank" rel="noreferrer">6</a></sup>,<sup><a href="https://www.pik-potsdam.de/en/institute/departments/climate-resilience/projects/project-pages/agrica/climate-risk-profile_mali_en" target="_blank" rel="noreferrer">7</a></sup>.  </p>,
	},

};

export default summaries;
