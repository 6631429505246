import React from 'react';

const x = [
	{
		type: 'title',
		text: '1. Introduction',
		params: '',
	},
	{
		type: '',
		params: {
			br: () => { return <br />; },
		},
		text: 'Welcome to the Climate Security Observatory (CSO) platform (the “Site”) (www.cso.cgiar.org). ' +
			'The Site is an online platform that aggregates and processes global data for climate, environmental, ' +
			'peace and security indicators, applying qualitative and quantitative methods to understand the How, ' +
			'What, Where and Who components of the climate-security nexus at different national and subnational scales. ' +
			'<br></br><br></br>' +
			'The CGIAR FOCUS Climate Security maintains and administers the Site, which abides by the policies and terms of the CGIAR (https://www.cgiar.org/) and it is owned by the CGIAR System Organization (“Owner”).' +
			'<br></br><br></br>' +
			'In accessing and using the Site, including its subdomains, associated websites, mobile applications and extranets, as applicable, each a “Site” and collectively the “Sites”, you (the “User”) agree to comply with and be bound by these terms and conditions of use (“Terms of Use”) and with the Privacy Policy , unless specific terms apply to a Site or Sites. By accessing or using data, methods, tools, any other type of information, or services provided in or through the Site or Sites, you are deemed to accept and be bound by the Terms of Use, as well as any applicable international, national or local laws and regulations. If you do not agree with any of these Terms of Use, you may browse the Site or Sites yet you are prohibited from accessing or using data, methods, tools, any other type of information, or services provided in or through this Site or Sites.' +
			'<br></br><br></br>' +
			'The CGIAR FOCUS Climate Security and the Owner hereby reserve the right to modify these Terms of Use at its discretion at any time, such modifications being effective when posted.  You as User are responsible for checking these Terms of Use periodically to see whether they have changed.' +
			'<br></br><br></br>' +
			'By using the Site, you as User agree to be bound by the then current version of the Terms of Use, and to have accepted the Privacy Policy  applicable to the Site.',
	},
	{
		type: 'title',
		text: '2. Copyright',
		params: '',
	},
	{
		type: '',
		text: 'Unless otherwise explicitly stated, the following copyright notice applies to the Site (or Sites):' +
			'<br></br><br></br>' +
			'Copyright ©2023 CGIAR System Organization' +
			'<br></br><br></br>' +
			'Unless otherwise specified, the content, organization, graphics, design, audio, animation, video, compilation, magnetic translation, digital conversion, infrastructure, design and services on the Site (or Sites), as well as their selection and arrangement, are protected by copyright and any other applicable property rights, including intellectual property rights. The Owner holds the title to the Site (or Sites) unless otherwise specified.' +
			'<br></br><br></br>' +
			'Note that except as otherwise explicitly stated, you as User do not acquire ownership rights to any content, document or other materials viewed, consulted or accessed through the Site.',
		params: {
			br: () => { return <br />; },
		},
	},
	{
		type: 'title',
		text: '3. Access and Use of Content',
		params: '',
	},
	{
		type: '',
		text: 'Access and use of this Site or Sites and its content is provided in accordance with the CGIAR Principles on the Management of Intellectual Assets and the CGIAR Open and FAIR Data Assets Policy.' +
			'<br></br><br></br>' +
			'In addition, CGIAR System Organization is an International Aid Transparency Initiative (IATI) publisher (XM-DAC-47015) and reserves the right to publish any Materials (see below) to the IATI Registry (directly or via third party).' +
			'<br></br><br></br>' +
			'The content or materials accessed through the Site, include but are not limited to raw data, annotated or otherwise analyzed data, databases, information, reports, videos, images, figures, diagrams, maps, presentations, written documents such as briefs, reports, memoranda, discussion papers, articles (including peer-reviewed articles), books, book chapters, capacity building materials, websites, methods, tools, software, and services (referred all as “Materials”), which can be generated, created or developed either a.  by the CGIAR FOCUS Climate Security (“CSO Materials”); b. by CGIAR staff or personnel working at or for any of the Action Areas, Initiatives, Platforms or groups, including staff of or personnel associated with CGIAR Centers; or by CGIAR Funders (“Contributed Materials”); or c. belonging to or originated by third parties, including partners or collaborators, made available through the Site by uploading of such Material, or through links, or by citation or aggregation (“Third Party Materials”).',
		params: {
			br: () => { return <br />; },
		},
	},
	{
		type: 'sub-title',
		text: 'a. CSO Materials',
		params: '',
	},
	{
		type: '',
		text: 'Unless otherwise explicitly stated, CSO Materials, except for software and images (photos, or videos, or audio files of people), are available under the Creative Commons Attribution-NonCommercial License 4.0 International (CC-BY-NC 4.0), that allows you to download, copy, reproduce, modify, remix, build upon, disseminate, publish without seeking permission from the Owner as long as it is for non-commercial purposes, you give credit to the authors or to the owner as provided in the respective citation, and the copyright notice and this license remain with any of the CSO Materials, even if they become part of derived works. Full terms of the license are available here.',
		params: '',
	},
	{
		type: '',
		text: 'If as a User of CSO Materials you wish to deviate from these licensing conditions, contact the CGIAR FOCUS Climate Security to seek prior authorization. Nothing in these Terms obliges the CGIAR FOCUS Climate Security or the Owner to grant such prior authorization.',
		params: '',
	},
	{
		type: 'sub-title',
		text: 'b. Contributed Materials',
		params: '',
	},
	{
		type: '',
		text: 'If you decide to contribute Materials to the CSO Site, you must have the right to make such a contribution. You must be the owner of, or have the control over, or have created, generated or developed such Contributed Materials. As the owner of Contributed Materials, you or the organization you represent (e.g. a specific CGIAR Center, a CGIAR funding organization), as applicable, shall retain copyright on the Contributed Materials.',
		params: '',
	},
	{
		type: '',
		text: 'If Contributed Materials are owned by a third party, you must make sure that you have the rights to grant to the Owner the license hereby specified, and allow the CGIAR FOCUS Climate Security to use the hereby specified license. As a contributor you agree to grant the Owner, its successors and assignees, a free-of-charge access, royalty-free, perpetual, worldwide, non-exclusive, and sublicensable license to use, copy, reproduce, modify, adapt, publish, translate, perform, create derivative works, disseminate or otherwise make available to the public your Contribution in the Site (or Sites), for the full term of any rights that may exist in your Contribution. If you decide to share your results with the CSO Site, they will be available under the Creative Commons Attribution-NonCommercial License 4.0 International (CC-BY-NC 4.0).',
		params: '',
	},
	{
		type: '',
		text: 'Notice that as User you are completely free to share or not Materials with the Owner or the CGIAR FOCUS Climate Security through the Site. If you decide to share or contribute Materials, you may be prompted to provide information about the name of the organization you come from, represent or employs you, the name of the project or program under which such Materials are generated, and your email address. Some of such information may be deemed Personal Data and in this case, it would be governed by the Privacy policy  of the Site.',
		params: '',
	},
	{
		type: '',
		text: 'Contributors must warrant to the best of their knowledge that the Contributed Materials do not infringe the rights of any third party, including copyrights, trademarks or any other intellectual property rights, tangible property rights, privacy protection, prior informed consent, farmers’ rights, rights on traditional knowledge, and/or biodiversity-related access and benefit-sharing rights. In case of doubt, as a contributor, you shall abstain from providing Contributed Materials to the Site or if already contributed, withdraw such Contributed Materials.',
		params: '',
	},
	{
		type: '',
		text: 'You also represent and warrant that the uploaded content has been verified in accordance with applicable quality standards and internal checks, including scientific accuracy at your organization or through a quality external review. As a User and contributor, you agree not to upload, post, transmit or otherwise make available any content that is unlawful, racially motivated, harmful, hateful, harassing, obscene, invasive of another’s privacy, or that infringes any patent, trademark or other proprietary rights of any party.',
		params: '',
	},
	{
		type: '',
		text: 'If you make available on the Site any content of an experimental nature or pending quality and accuracy verification, this must be expressly stated by you with a disclaimer. You are solely responsible for the licensing of any content shared through, tagged within, displayed in and uploaded to the Site, comprising but not limited to copyright compliance, dissemination, visibility and usability of the content. You are responsible for the correct attribution of the content shared through, tagged within, displayed in and uploaded to the Site and of the accuracy of associated metadata. The User assumes full responsibility for any omission and incorrect or partial information provided while sharing through, tagging within, displaying in and uploading any content to the Site, intentionally or unintentionally.',
		params: '',
	},
	{
		type: '',
		text: 'The Owner or the CGIAR FOCUS Climate Security may, but is not obligated to, review your Contributions. The Owner or the CGIAR FOCUS Climate Security may, in its sole discretion, remove your Contributed Material if it establishes that violates these Terms of Use.',
		params: '',
	},
	{
		type: '',
		text: 'If you delete or withdraw a Contributed Material, neither the Owner nor the CGIAR FOCUS Climate Security warrant that it will be able to or will take responsibility for removing deleted contributions from the Site, including but not limited to those outside of its reasonable control or those to whom you or others may have forwarded your Contributed Material.',
		params: '',
	},
	{
		type: '',
		text: 'Acceptance of a Contribution Material does not constitute an endorsement or sponsorship of the Contribution by the Owner or by the CGIAR FOCUS Climate Security. The contributor shall defend, indemnify, and hold the Owner (including the CGIAR FOCUS Climate Security) harmless from any resulting claims, damages or costs (including reasonable attorney fees) in case of false or misleading information or negligence.',
		params: '',
	},
	{
		type: 'sub-title',
		text: 'c. Third Party Materials',
		params: '',
	},
	{
		type: '',
		text: 'Third Party Materials are governed by the terms applicable and contained in third party websites or as explicitly stated by such third parties. Neither the Owner nor the CGIAR FOCUS Climate Security are responsible for or have the control over such Third Party Materials. The Owner and the CGIAR FOCUS Climate Security make no warranties or representations about the content, information, products, or services offered by third parties. The Owner and the CGIAR FOCUS Climate Security provide access to such Third Party Materials for information purposes and convenience only. The Owner and the CGIAR FOCUS Climate Security will make best efforts but does not ensure revision of Third Party Material. Display of Third Party Materials does not constitute endorsement or sponsorship by the Owner or by the CGIAR FOCUS Climate Security of any Third Party Materials. Use of any such Third Party Material is at the User’s own risk.',
		params: '',
	},
	{
		type: '',
		text: 'If as a Third Party and as a User, you decided to freely contribute Materials to the Site, the terms and conditions as stated under “Contributed Materials” will apply to you and the contributed Materials.',
		params: '',
	},
	{
		type: 'title',
		text: '4. Images and audio visuals',
		params: '',
	},
	{
		type: '',
		text: 'The CSO Site uses images (photos) and audio visuals that have been created or generated by CGIAR, including staff working at or for the CGIAR Centers, through its diverse programs, projects and events, and also images or videos available at platforms such as Vimeo.',
		params: '',
	},
	{
		type: '',
		text: 'Images and audio visuals used may have their own applicable license. The CGIAR FOCUS Climate Security either mentions, lists or provides a link to the ownership and use terms applicable to such images and audio visuals. Users must access and use such images and audio visuals in accordance to the terms stipulated in the applicable license.',
		params: '',
	},
	{
		type: '',
		text: 'All photos, videos or audios, particularly where people could be identified, are for non-profit activities including education, research, and related personal activities subject to proper attribution and compliance of the applicable terms. Please contact us  if you have any questions.',
		params: '',
	},
	{
		type: 'title',
		text: '5. Accuracy of the Materials',
		params: '',
	},
	{
		type: '',
		text: 'Materials appearing on the Site could include technical, typographical, or photographic errors. While the CGIAR FOCUS Climate Security may make efforts to work on the quality and completeness of the Materials, it does not warrant that any of such Materials on the Site are accurate, complete or current. The CGIAR FOCUS Climate Security may make changes to any Materials contained on the Site at any time without notice. Nevertheless, the CGIAR FOCUS Climate Security does not make any commitment to update the Materials. Users and contributors of the Site are completely responsible for the accuracy of the content uploaded by them.',
		params: '',
	},
	{
		type: '',
		text: 'Users can make use of third parties services and contents provided by and embedded in the Site after reading and accepting the associated and applicable Terms of Use and Privacy Policy. The Owner is not responsible for the correct functioning and availability of those third party services or tools, and declines any direct and indirect liability in this regard.',
		params: '',
	},
	{
		type: 'title',
		text: '6. User’s Responsibilities',
		params: '',
	},
	{
		type: '',
		text: 'The CGIAR FOCUS Climate Security might decide that a User may need to register and/or create a User account to access or use certain sections of the Site, Materials or other Site features. In such a case, the User will represent and warrant that (i) the User is of legal age or possess legal parental or guardian consent and fully able and competent to abide by and comply with these Terms of Use; and (ii) if the User represents a legal entity, the User has authority to bind such legal entity to these Terms of Use. The User will be responsible for keeping the password and other relevant login information confidential and shall inform the Owner or the CGIAR FOCUS Climate Security if at any point the User considers that her or his password or login information have been compromised and/or is being misused by someone else and shall take such action as is required and/or is requested by us to prevent such misuse. The User is responsible for all activities conducted under her or his User account.',
		params: '',
	},
	{
		type: '',
		text: 'By accessing and using this Site and all its content, you as User agree not to use them for any unlawful, illegal, fraudulent or harmful purpose and activity or in any way that would violate the Owner’s and third-party rights, including but not limited to intellectual property rights.',
		params: '',
	},
	{
		type: '',
		text: 'Any action that causes, or may cause, damage to the Site or disability of the performance, availability or accessibility of the Site and its content is prohibited.',
		params: '',
	},
	{
		type: 'title',
		text: '7. Use of Logo',
		params: '',
	},
	{
		type: '',
		text: 'The Owner logo, formed by its brandmark, the icon or symbol and the tagline, is owned by the CGIAR System Organization. Likewise, the CGIAR System Organization owns the brandmark, the icon or symbol and the tagline, as applicable, of the CGIAR Initiatives and the Climate Security Observatory.  In particular, the CSO icon or symbol and the tagline was designed and first expressed in its fixed form in [insert month and year ]. The CSO logo and the other Owner’s logo identify the Site as well as activities carried out at or by the platform. All the current logos and any variations that might be introduced to them from time to time are protected by copyright, with all rights reserved:',
		params: '',
	},
	{
		type: '',
		text: 'Copyright ©2023 CGIAR System Organization',
		params: '',
	},
	{
		type: '',
		text: 'You or any of your agents, employees and/or sub-contractors as Users of the Site, or you or any of your agents, employees and/or sub-contractors as users generating content for or at the Site, or any third party are not authorized to copy, reprint, record, photograph, depict, publish, distribute, incorporate, or use the CSO logo or any of the Owner’s logos, in any form or way, without the prior written authorization from the Owner.',
		params: '',
	},
	{
		type: 'title',
		text: '8. Disclaimer',
		params: '',
	},
	{
		type: '',
		text: 'All the Materials on the Site are provided on an &apos;AS IS&apos; basis. Neither the Owner nor the CGIAR FOCUS Climate Security makes warranties, expressed or implied, including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, non-infringement of intellectual property rights or any other violation of rights.',
		params: '',
	},
	{
		type: '',
		text: 'While the CGIAR FOCUS Climate Security and the Owner abide by the' +
			' highest scientific and technical standards, the Owner and the CGIAR FOCUS Climate Security do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the data, information, tools, content or any other Materials on its Site or Sites, or otherwise relating to such Materials, or on any other Sites, sites or websites linked to this Site.',
		params: '',
	},
	{
		type: '',
		text: 'References to geographical or other territories and groupings are based on [insert the sources of the maps and territories names used at CSO ], terminology used by other relevant international organizations or other sources deemed relevant by the CGIAR FOCUS Climate Security. The use of such references and terminology used on this Site, as well as, in the materials contained therein or linked to, do not constitute or imply an expression of opinion by the Owner or the CGIAR FOCUS Climate Security concerning the status of any State or territory, or its frontiers or sovereignty. The colours, boundaries and names shown and the designations used on maps available from or through the Site are not intended to imply any judgment, official endorsement, or acceptance by the Owner or by the CGIAR FOCUS Climate Security as to the legal status or frontier of any territory.',
		params: '',
	},
	{
		type: '',
		text: 'While it is our goal to minimize disruption caused by technical errors, we cannot guarantee that our service will not be interrupted or otherwise affected. The Owner and the CGIAR FOCUS Climate Security accept no responsibility with regard to such problems incurred as a result of using this Site or any linked external sites.',
		params: '',
	},
	{
		type: '',
		text: 'The CGIAR FOCUS Climate Security may change material on the Site without prior notice.',
		params: '',
	},
	{
		type: 'title',
		text: '9. Liabilities and Indemnification',
		params: '',
	},
	{
		type: '',
		text: 'Except as prohibited by law, in no event shall the Owner, nor any of their agents, employees, suppliers and/or sub-contractors be liable to you or any other party for any claim, loss, demand or damages whatsoever (whether such claims, loss, demands or damages were foreseeable, known or otherwise, and including, without limitation, special, indirect, punitive or consequential damages or damages for any loss of data or profit, or due to business interruption) arising out of or in connection with the access, use of or reliance on the Site or information, content or materials created included on the Site by you or any of your agents, employees and/or sub-contractors. You as User are solely responsible for your submission of material to the Site, the use of the Site and for any claims relating to its content. You agree to indemnify, defend and hold the Owners and our partners, attorneys, staff and affiliates harmless from any liability, loss, claim and expense, including reasonable attorney’s fees, related to your violation of these Terms of Use.',
		params: '',
	},
	{
		type: 'title',
		text: '10. Governing law and Dispute Resolution',
		params: '',
	},
	{
		type: '',
		text: 'These Terms of Use are governed by and construed in accordance with the General Principles of Law, to the exclusion of any single national system of law.',
		params: '',
	},
	{
		type: '',
		text: 'If a dispute arises between the Owner and you out of or in connection with these Terms of Use and the Privacy Policy , including any question regarding their existence, validity, interpretation, implementation or termination, you and the Owner will try to settle such dispute amicably through negotiation.',
		params: '',
	},
	{
		type: '',
		text: 'If any dispute cannot be settled amicably within 60 (sixty) days, either party shall be entitled at that point to refer the dispute to be resolved by arbitration in accordance with the UNCITRAL Arbitration Rules in effect at the time of the dispute. There will be one arbitrator appointed by the parties by mutual consent, or failing this, by the Secretary-General of the Permanent Court of Arbitration. The place of arbitration will be Paris, France and the language to be used in the arbitration proceedings will be English. Unless otherwise expressly provided in these Terms of Use, the arbitral tribunal will have no authority to award (i) punitive damages, or (ii) damages for consequential or indirect losses. The arbitral award shall be final and binding on both parties.',
		params: '',
	},
];

export default x;
