/* eslint-disable react/jsx-no-useless-fragment,no-shadow,no-plusplus */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';
import Translator from '../../../../../../../../../../../../components/Translator';

const LineChart = (props) => {
	const { data, id, label } = props;

	useEffect(() => {
		const root = am5.Root.new(id);

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([am5themes_Animated.new(root)]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				wheelX: 'panX',
				wheelY: 'zoomX',
				paddingLeft: 0,
				layout: root.verticalLayout,
			})
		);

		// Add scrollbar
		// https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
		chart.set(
			'scrollbarX',
			am5.Scrollbar.new(root, {
				orientation: 'horizontal',
			})
		);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xRenderer = am5xy.AxisRendererX.new(root, {
			minorGridEnabled: true,
			minGridDistance: 60,
		});
		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: 'season',
				renderer: xRenderer,
			})
		);
		xRenderer.grid.template.setAll({
			location: 1,
		});

		xRenderer.labels.template.setAll({
			rotation: -90,
			centerY: am5.p50,
			centerX: am5.p100,
			paddingRight: 15,
		});

		xAxis.data.setAll(data);

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				min: 0,
				extraMax: 0.1,
				renderer: am5xy.AxisRendererY.new(root, {
					strokeOpacity: 0.1,
				}),
			})
		);

		const yAxis2 = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererY.new(root, {
				opposite: true,
			}),
			min: 0,
			maxPrecision: 0,
		}));

		// Add series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/

		const series1 = chart.series.push(
			am5xy.ColumnSeries.new(root, {
				name: label,
				xAxis,
				yAxis,
				valueYField: id,
				categoryXField: 'season',
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: 'horizontal',
					labelText: `${label} in {categoryX}: {valueY} {info}`,
				}),
			})
		);

		series1.columns.template.setAll({
			tooltipY: am5.percent(10),
			templateField: 'columnSettings',
		});

		series1.data.setAll(data);

		const series2 = chart.series.push(
			am5xy.LineSeries.new(root, {
				name: 'Conflict',
				xAxis,
				yAxis: yAxis2,
				valueYField: 'conflict',
				categoryXField: 'season',
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: 'horizontal',
					labelText: 'Conflicts: {valueY} {info}',
				}),
			})
		);

		series2.strokes.template.setAll({
			strokeWidth: 3,
			templateField: 'strokeSettings',
		});

		series2.set('fill', am5.color('#ff0000'));
		series2.set('stroke', am5.color('#ff0000'));

		const filteredData = data.filter((item) => item.conflict !== 0);

		series2.data.setAll(filteredData);

		series2.bullets.push(() => {
			return am5.Bullet.new(root, {
				sprite: am5.Circle.new(root, {
					strokeWidth: 3,
					stroke: series2.get('stroke'),
					radius: 5,
					fill: root.interfaceColors.get('background'),
				}),
			});
		});

		chart.set('cursor', am5xy.XYCursor.new(root, {}));

		// Add legend
		// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
		const legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.p50,
				x: am5.p50,
			})
		);
		legend.data.setAll(chart.series.values);

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);
		series1.appear();

		return () => root.dispose();
	}, [data]);

	return (
		<div className="chart-container">
			<div id={id} style={{ width: '100%', height: '600px' }} />
			<p className="rotate-label"><Translator text="Number of conflict events" /></p>
		</div>
	);
};
export default LineChart;
