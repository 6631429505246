/* eslint-disable consistent-return */
// eslint-disable-next-line import/prefer-default-export
import pallete from './twelveColorPallete';

export 	const defaultColors = (filters) => {
	if (filters.climate === 'High-Moderate precipitation' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[0], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High-Moderate precipitation' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[1], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High-Moderate precipitation' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[2], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Low precipitation' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[3], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Low precipitation' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[4], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Low precipitation' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[5], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High precipitation' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[6], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High precipitation' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[7], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High precipitation' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[8], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Moderate-Low precipitation' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[9], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Moderate-Low precipitation' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[10], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Moderate-Low precipitation' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[11], fillOpacity: 1, weight: 1 };
	}
	return { fillOpacity: 0, opacity: 0 };
};

export 	const backgroundColors = (variable) => {
	if (variable === 'High-Moderate precipitation' || variable === 'Limited conflict') {
		return { backgroundColor: pallete[0] };
	}
	if (variable === 'Low precipitation' || variable === 'Moderate conflict') {
		return { backgroundColor: pallete[7] };
	}
	if (variable === 'Moderate-Low precipitation' || variable === 'High conflict') {
		return { backgroundColor: pallete[11] };
	}
	if (variable === 'High precipitation') {
		return { backgroundColor: pallete[5] };
	}
	return { backgroundColor: pallete[0] };
};

export const pal = pallete;
