import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Svg } from '../../../../../../assets/images/Approach/cso_approach_on_load_with_urls.svg';
import { Approach, Playback } from './components';
import './styles.css';

const SvgPlayer = () => {
	// states for the player
	const [paused, setPaused] = useState(false);
	const [seek, setSeek] = useState(0);
	const [duration, setDuration] = useState(0);

	// ref to access the DOM to use the svgator player
	const svgRef = useRef();

	// Play pause functionality
	const play_pause = (e) => {
		setPaused(e.value);
        
		if (paused === false) {
			svgRef.current.svgatorPlayer.pause();
		} else if (paused === true) {
			svgRef.current.svgatorPlayer.play();
		}
	};

	const seek_update = (e) => {
		// set the seek value
		setSeek(e.value);

		// set the svg seek location
		svgRef.current.svgatorPlayer.seek(e.value);
	};

	// calculate the current percentage of duration
	const get_percent = (offset) => {
		return (offset / duration) * 100;
	};

	// function to update seekbar based on keyframe event
	const seek_event = (offset) => {
		const seekValue = get_percent(offset);
		setSeek(seekValue);
	};

	useEffect(() => {
		// attaching the keyframe to get offset everyframe to update the seek bar
		svgRef.current.svgatorPlayer.on('keyframe', seek_event);

		// setting duration for future use
		setDuration(svgRef.current.svgatorPlayer.duration);
	});    

	return (
		<div className="approach-container">
			<div className="approach">
				<Approach ref={svgRef} />
			</div>
			<div>
				<Playback paused={paused} onPlayClick={play_pause} seek={seek} seekUpdate={seek_update} />
			</div>
		</div>
	);
};

export default SvgPlayer;
