/* eslint-disable class-methods-use-this */
import { http } from '../index';

class RegistrationService {
	getRegistrationData = async (registrationData) => {
		const result = await http.post(`/api/register`, registrationData);
		return result.data;
	};
}

export default new RegistrationService();
