/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { TRANSITION } from 'leaflet/src/dom/DomUtil';
import Translator from '../../../../../../../../../../../components/Translator';

const texts = {
	kenya: {
		// eslint-disable-next-line react/jsx-no-undef
		policyFrameworks: <p><Translator text="Awareness of the conditions and circumstances that may heighten the risk of climate-related security risks emerging in Kenya are generally reflected in Kenyan policies and strategic planning frameworks, yet specific integrated programmatic frameworks for ensuring operational synergies between climate adaptation and peacebuilding generally remain absent. More exploratory work should be undertaken in order to identify concrete opportunities for where adaptation targets and activities can contribute to conflict prevention, transformation, and peacebuilding in specific geographic areas.  " /> </p>,

		multilevelGovernance: <p><Translator text="A blueprint for more integrated cross-sectoral and multi-scalar policymaking and programme design does however exist in the form of Kenya's Ending Drought Emergencies Common Programme Framework (2015). This multi-pillared initiative binds together multiple actors from across sectors and scales into a unified planning and objective setting, implementation, and monitoring and evaluation framework. The Common Programme Framework instrument allows for multiple actors' complimentary capacities to be realised by combining shorter-term emergency responses to drought with longer-term  efforts to reduce vulnerabilities and build resilience across multiple pillars, therefore producing an aggregate benefit for target populations. This kind of policy instrument is particularly well-suited for managing complex issues that span multiple sectors and time horizons (such as the climate, peace, and security nexus), and should be upscaled and replicated across different thematic areas. " /></p>,
	},
	zambia: {
		policyFrameworks: <p><Translator text="In Zambian policy discussions, there’s widespread recognition of climate change risks to human security, but less awareness of how these risks can escalate into broader insecurity and conflict. Effective coordination mechanisms among sectors crucial for addressing climate-related security risks are notably limited. Innovative planning tools are needed to promote strategic alignment for a climateresilient peace. Key sectors like social welfare and refugee management can better integrate climate change considerations. Encouraging the inclusion of climate change actors in existing coordinating mechanisms, such as the Social Protection Sectoral Advisory Group, is crucial. Integrating climate projections into established resettlement schemes, like the 2015 National Resettlement Policy, can enhance responsiveness to evolving climate trends." /> </p>,

		multilevelGovernance: <p><Translator text="Existing planning instruments can better incorporate climate action into provincial and district planning, while also integrating considerations for the climate-peace-security nexus. Zambia’s National Adaptation Plan (NAP) urges the integration of sub-national climate action into the Integrated Development Planning template, yet the potential for incorporating the nexus remains unexplored. National guidelines for Integrated Development Plans should be updated to include conflict-sensitive and peace-positive climate action." /></p>,
	},

};

export default texts;
