/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useSelector } from 'react-redux';
import voicesFromTheField from '../../../../../../assets/images/CountryInformationPage/How/Dialog/voicesFromTheField.png';
import { ModelContent } from './components';
import LearnMoreDialogContent from '../../../SideBar/components/LearnMoreDialogContent';
import dataSource from './dataSource.json';
import './styles.css';
import Kenya from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Kenya.jpg';
import voicesImage from '../../../../../../assets/images/CountryInformationPage/How/voices.jpg';
import Senegal from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Senegal.jpg';
import Guatemala from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Guatemala.jpg';
import Translator from '../../../../../../components/Translator';
import useCustomTranslate from '../../../../../../utils/translateFunction';

const VoicesFromTheField = (props) => {
	const { textProperty } = props;

	const country = useSelector((state) => state.country);

	const [toggleContent, setToggleContent] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);

	const assignVoicesImage = () => {
		switch (country.name) {
		case 'Kenya': return Kenya;
		case 'Senegal': return Senegal;
		case 'Guatemala': return Guatemala;
		// case 'Zambia': return Zambia;
		default: return false;
		}
	};

	const renderContent = () => {
		if (toggleContent) {
			return <ModelContent textProperty={textProperty} />;
		}
		return (
			<>
				<img className="voices-image" src={voicesImage} />
				<p style={{ fontSize: '36px', fontWeight: '600', color: '#FDC82F' }}>{textProperty === 'whatText' ? <Translator text="Foster Inclusive Participatory Research" /> : <Translator text="Community Voices" /> }</p>
				<Button className="open-tool-button" label={<Translator text="Open Model" />} onClick={() => setToggleContent(true)} />
				<div className="text-container">
					<p className="open-sans">
						{
							textProperty === 'whatText'
								? <Translator text="Climate-related security risks and environmental peacebuilding adaptation strategies need to be assessed by those that are affected. In line with a participatory action research approach, community members are repositioned as citizen scientists and experts in understanding the different vulnerabilities that afflict them and in proposing required changes for the better. The process is also meant to identify and develop resilience building solutions based on collective action that contribute towards sustainable peacebuilding. " />
								: <Translator text="Climate-related security risks and environmental peace-building adaptation strategies need to be assessed by those that are affected. A participatory action research approach is used to develop pathways to climate security that better relate to people's everyday experiences of the mutually reinforcing risks posed by climate variability and human insecurity, and the multi-directional linkages between these factors. Community members will be engaged as citizen scientists and experts to understand the various vulnerabilities that affect them and to propose needed changes for the better. " />
						}
					</p>
				</div>
				<div className="learn-more-wrap">
					<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
					<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
						<LearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} />
					</Dialog>
				</div>
			</>
		);
	};

	return (
		<div className="dialog-content">
			{renderContent()}
		</div>
	);
};

export default VoicesFromTheField;
