import React, { useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import { Slider } from 'primereact/slider';
import './styles.css';

const Playback = ({ paused, onPlayClick, seek, seekUpdate }) => {
	return (
		<div className="controls">
			<ToggleButton
				onLabel="Play"
				offLabel="Pause"
				onIcon="pi pi-play"
				offIcon="pi pi-pause"
				checked={paused}
				onChange={onPlayClick}
				className="w-9rem"
			/>
			<Slider value={seek} className="w-30rem" onChange={seekUpdate} />
		</div>
	);
};

export default Playback;
