/* eslint-disable react/jsx-no-useless-fragment */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import React, { useEffect } from 'react';

const SolidGauge = (props) => {
	const { data, setChartSrc, solidChartId, width } = props;

	useEffect(() => {
		const root = am5.Root.new(solidChartId);
		console.log(data);
		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/radar-chart/
		const chart = root.container.children.push(am5radar.RadarChart.new(root, {
			layout: root.verticalLayout,
			panX: false,
			panY: false,
			wheelX: 'panX',
			wheelY: 'zoomX',
			innerRadius: am5.percent(20),
			startAngle: -90,
			endAngle: 180,
		}));

		const dataWithColors = data.map((item, index) => {
			// eslint-disable-next-line no-param-reassign
			item.columnSettings = {
				fill: chart.get('colors').getIndex(index),
			};

			return item;
		});

		// Create axes and their renderers
		// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
		const xRenderer = am5radar.AxisRendererCircular.new(root, {
			// minGridDistance: 50
		});

		xRenderer.labels.template.setAll({
			radius: 10,
		});

		xRenderer.grid.template.setAll({
			forceHidden: true,
		});

		const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
			renderer: xRenderer,
			min: 0,
			max: 10,
			strictMinMax: true,
			numberFormat: '#\'',
			tooltip: am5.Tooltip.new(root, {}),
		}));

		const yRenderer = am5radar.AxisRendererRadial.new(root, {
			minGridDistance: 20,
		});

		yRenderer.labels.template.setAll({
			centerX: am5.p100,
			fontWeight: '500',
			fontSize: 18,
			templateField: 'columnSettings',
		});

		yRenderer.grid.template.setAll({
			forceHidden: true,
		});

		const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'category',
			renderer: yRenderer,
		}));

		yAxis.data.setAll(dataWithColors);

		// Create series
		// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
		const series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
			xAxis,
			yAxis,
			clustered: false,
			valueXField: 'full',
			categoryYField: 'category',
			fill: root.interfaceColors.get('alternativeBackground'),
		}));

		series1.columns.template.setAll({
			width: am5.p100,
			fillOpacity: 0.08,
			strokeOpacity: 0,
			cornerRadius: 20,
		});

		series1.data.setAll(dataWithColors);

		const series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
			xAxis,
			yAxis,
			clustered: false,
			valueXField: 'value',
			categoryYField: 'category',
		}));

		series2.columns.template.setAll({
			width: am5.p100,
			strokeOpacity: 0,
			tooltipText: '{category}: {valueX}',
			cornerRadius: 20,
			templateField: 'columnSettings',
		});

		series2.data.setAll(dataWithColors);

		const exporting = am5plugins_exporting.Exporting.new(root, {
		});

		exporting.export('png').then((imgData) => {
			// document.getElementById("myImage").src = imgData;
			setChartSrc(imgData);
		});

		const legend = chart.children.push(am5.Legend.new(root, {
			nameField: 'categoryY',
			centerX: am5.percent(50),
			x: am5.percent(50),
			// fillField: '{templateField.fill}',
			// y: am5.percent(0),
		}));

		legend.data.setAll(series2.dataItems);

		// Animate chart and series in
		// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
		series1.appear(1000);
		series2.appear(1000);
		chart.appear(1000, 100);

		return () => root.dispose();
	}, [data]);

	return (
		<div className="chart-container">
			{/* <p>{label}</p> */}
			<div id={solidChartId} style={{ width, height: '500px' }} />
		</div>
	);
};
export default SolidGauge;
