/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { SocialMediaAnalysis } from './components';
import Card from '../../../Card';
import socialMediaImage from '../../../../../../../../assets/images/CountryInformationPage/What/social_big.jpg';
import LearnMoreDialogContent from '../../../../../SideBar/components/LearnMoreDialogContent';
import dataSource from './dataSource.json';
import './styles.css';
import PolicyProgrammatic from './components/PolicyProgrammatic';
import Translator from '../../../../../../../../components/Translator';
import CountryService from '../../../../../../../../services/httpService/countryService';
import Toolkit from './components/Toolkit'; 

const SocialMedia = (props) => {
	const country = useSelector((state) => state.country);
	const { displayBackButton, setDisplayBackButton } = props;
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayCard, setDisplayCard] = useState('');
	const [isWhatToolCard, setIsWhatToolCard] = useState(true);
	const [policyData, setPolicyData] = useState([]);

	useEffect(() => {
		CountryService.getPolicyCoherenceData(country.id)
			.then((res) => {
				if (res.data) {
					console.log(res.data);
					setPolicyData(res.data);
				}
			});
	}, [country]);

	useEffect(() => {
		let subTopicsList;
		if (policyData.documents) {
			policyData.documents.map((element) => {
				subTopicsList = element.variables.map((item) => item.child);
				// eslint-disable-next-line no-param-reassign
				element.subTopic = subTopicsList;
			});
		}
	}, [policyData]);
	
	const renderContent = () => {
		switch (displayCard) {
		case 'Climate Security Policy Explorer': return <Toolkit policyCoherenceDocuments={policyData.documents} policyCoherenceTopics={policyData.topics} policyCoherenceSubTopics={policyData.sub_topics} policyCoherenceTreeData={policyData.all_variables} />;
		case 'Social Media Analysis': return <SocialMediaAnalysis />;
		case 'Policy and Programmatic Recommendations': return <PolicyProgrammatic />;
		default: return (
			<>
			</>
		);
		}
	};

	const getCountry = () => {
		// eslint-disable-next-line no-shadow
		let country;
		if (window.location.href.includes('Guatemala')) {
			country = ' Guatemala ';
		}
		if (window.location.href.includes('Kenya')) {
			country = ' Kenya ';
		}
		if (window.location.href.includes('Senegal')) {
			country = ' Senegal ';
		}
		if (window.location.href.includes('Zambia')) {
			country = ' Zambia ';
		}
		if (window.location.href.includes('Philippines')) {
			country = ' Philippines ';
		}
		if (window.location.href.includes('Uganda')) {
			country = ' Uganda ';
		}
		if (window.location.href.includes('Ethiopia')) {
			country = ' Ethiopia ';
		}
		if (window.location.href.includes('Nigeria')) {
			country = ' Nigeria ';
		}
		if (window.location.href.includes('Sudan')) {
			country = ' Sudan ';
		}
		if (window.location.href.includes('Zimbabwe')) {
			country = ' Zimbabwe ';
		}
		if (window.location.href.includes('Mali')) {
			country = ' Mali ';
		}
		const text = 'Breaking down policy silos between climate and security is crucial. Over 40 experts and practitioners working across the humanitarian, development and peace sectors in' + `${country}` + 'came together to co-develop policy and programmatic recommendations towards integrating climate security considerations in climate action strategies.';

		return text;
	};

	const getReportsByCountry = () => {
		if (window.location.href.includes('Kenya')) {
			return dataSource.kenyaReports;
		}
		if (window.location.href.includes('Mali')) {
			return dataSource.maliReports;
		}
		if (window.location.href.includes('Nigeria')) {
			return dataSource.nigeriaReports;
		}
		if (window.location.href.includes('Senegal')) {
			return dataSource.senegalReports;
		}
		if (window.location.href.includes('Sudan')) {
			return dataSource.sudanReports;
		}
		if (window.location.href.includes('Uganda')) {
			return dataSource.ugandaReports;
		}
		if (window.location.href.includes('Zimbabwe')) {
			return dataSource.zimbabweReports;
		}
		if (window.location.href.includes('Guatemala')) {
			return dataSource.guatemalaReports;
		}
	};

	return (

		<div className="dialog-content">
			{displayCard === ''
				? (
					<>
						<img style={{ width: '100%' }} src={socialMediaImage} />
						<h2><Translator text="Identify and Address Policy Gaps" /></h2>
						<p><Translator text="Concrete actions from multiple policy sectors and governance levels are needed to develop an integrated approach for managing climate-related security risks. Building peace and conflict responsiveness into climate policies requires a multi-sectoral, integrated strategy in which several policy domains cooperate in a coherent way. Programs for climate adaptation and mitigation must be are aware of other sectors to guarantee that they are effective, but also that they do no harm." /></p>
						<div className="what-cards p-grid p-justify-center">
							<Card isWhatToolCard={setIsWhatToolCard} setDisplayCard={setDisplayCard} title="Social Media Analysis" text={<Translator text="Understanding the policy landscape to identify gaps is crucial. We use Social Media Analysis to explore how climate, conflict, and socio-economic risks and insecurities are represented in the public narratives of policy makers. This provides a starting point for the development of evidence-based advocacy and engagement strategies to ensure that climate change responses are climate security-sensitive." />}>
								<SocialMediaAnalysis />
							</Card>
							<Card isWhatToolCard={setIsWhatToolCard} setDisplayCard={setDisplayCard} title="Climate Security Policy Explorer" text={<Translator text="This climate security policy explorer assesses policy and strategic outcomes from a range of sectors to assess coherence and sensitivity to climate security. The goal is to assess the linkages between different policy areas and levels; analyze how well a policy or strategy recognizes the issue of climate security and the extent to which it demonstrates awareness of climate-related security risks; and assess how concrete the policy or strategy is in terms of implementing processes, instruments, and specific programmatic activities to address climate-related security risks in a coherent manner." />}>
								<Toolkit />
							</Card>
							<Card isWhatToolCard={setIsWhatToolCard} setDisplayCard={setDisplayCard} title="Policy and Programmatic Recommendations" text={<Translator text={getCountry()} />}>
								<PolicyProgrammatic />
							</Card>
						</div>
						<div className="learn-more-wrap">
							<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
							<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
								<LearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} dataReports={getReportsByCountry()} />
							</Dialog>
						</div>

					</>
				)
				: (
					<div className="how-content">
						{displayBackButton === false
							? (
								<div className="go-back-btn">
									<Button onClick={() => setDisplayCard('')} style={{ padding: '10px' }}>Go Back</Button>
								</div>
							)
							: null}

						{renderContent()}
					</div>
				)

			}
		</div>
	);
};

export default SocialMedia;
