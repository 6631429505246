const pallete = [
	'#cafcb4',
	'#9ed798',
	'#1d8641',
	'#d5efcf',
	'#55b567',
	'#00441b',
];

export default pallete;
