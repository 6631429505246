import React from 'react';
import './styles.css';
import { Button } from 'primereact/button';
import Translator from '../../../Translator';

const Introduction = (props) => {
	const { setSubPage } = props;

	return (
		<div className="sub-page introduction">
			<div className="header">
				<p className="sub-title"><Translator text="Run your assessment" /></p>
				<p>
					<Translator text="This process will help you assess your climate action programs and their implementation are appropriate for the context in which they are carried out and recommending solutions to improve their appropriateness." />

					<Translator text="Your results can be downloaded at the end and you can choose to help us build up an intervention database by submitting your results to the platform." />
				</p>
			</div>
			<Button label={<Translator text="Start" />} onClick={() => setSubPage('steps')} />
		</div>
	);
};

export default Introduction;
