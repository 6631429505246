import React, { useState } from 'react';
import { Button } from 'primereact/button';
import './styles.css';
import { Dialog } from 'primereact/dialog';
import { useSelector } from 'react-redux';
import SummaryDownloadButton from '../SummaryDownloadButton';
import Translator from '../../../../../../components/Translator';
import ComingSoon from '../../../../../../components/ComingSoon';

const What = () => {
	const [displayTutorial, setDisplayTutorial] = useState(false);

	const country = useSelector((state) => state.country);

	return (
		<div className="tab what">
			{ country.id === 'BFA' || country.id === 'NER' ? (

				<ComingSoon comingSoonContent={''} />

			)
				: (

					<>
						<p className="title">
							<span><Translator text="WHAT" /></span> <Translator text="needs to be done to break the vicious cycle between climate and conflict" />
						</p>
						<p className="open-sans tab-header">
							<Translator text="Both the analysis of the links between climate change, peace, and security and the solutions to these issues must be approached in a holistic and multidisciplinary manner. Given the complexity of the issues, there is no silver bullet fix. Entry points for solutions towards climate security-sensitive decision making and programming should be explored from multilevel perspectives." />
						</p>
						<Button label={<Translator text="Tutorial" />} onClick={() => setDisplayTutorial(true)} />
						<Dialog header={<Translator text="CSO Tutorial" />} visible={displayTutorial} style={{ width: '90vw', height: '90vh' }} onHide={() => setDisplayTutorial(false)}>
							<iframe
								src="https://player.vimeo.com/video/828235961?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								frameBorder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowFullScreen
								style={{ width: '100%', height: '100%' }}
								title="Context_Kenya_Maximum_Temperature"
							/>
						</Dialog>
						<SummaryDownloadButton />
					</>
				)}

		</div>
	);
};

export default What;
