/* eslint-disable consistent-return */
// eslint-disable-next-line import/prefer-default-export
import pallete from './nineColorPallete';

export 	const defaultColors = (filters) => {
	if (filters.climate === 'Low precipation/High levels of drougth stress' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[0], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Low precipation/High levels of drougth stress' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[1], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Low precipation/High levels of drougth stress' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[2], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Moderate precipitation/ Moderate levels of drougth stress' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[3], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Moderate precipitation/ Moderate levels of drougth stress' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[4], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'Moderate precipitation/ Moderate levels of drougth stress' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[5], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High precipitation/Low levels of drougth stress' && filters.conflict === 'Limited conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[6], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High precipitation/Low levels of drougth stress' && filters.conflict === 'Moderate conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[7], fillOpacity: 1, weight: 1 };
	} if (filters.climate === 'High precipitation/Low levels of drougth stress' && filters.conflict === 'High conflict') {
		return { color: '#656565', opacity: 1, fillColor: pallete[8], fillOpacity: 1, weight: 1 };
	}
	return { fillOpacity: 0, opacity: 0 };
};

export 	const backgroundColors = (variable) => {
	if (variable === 'Low precipation/High levels of drougth stress' || variable === 'Limited conflict') {
		return { backgroundColor: pallete[0] };
	}
	if (variable === 'Moderate precipitation/ Moderate levels of drougth stress' || variable === 'Moderate conflict') {
		return { backgroundColor: pallete[4] };
	}
	if (variable === 'High precipitation/Low levels of drougth stress' || variable === 'High conflict') {
		return { backgroundColor: pallete[8] };
	}
	return { backgroundColor: pallete[0] };
};

export const pal = pallete;
