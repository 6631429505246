/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState } from 'react';
// import {BrowserRouter as Router,Switch,useLocation,Route} from "react-router-dom";
import { HashRouter as Router, Navigate, Route, Routes, Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Gleap from 'gleap';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'universal-cookie';
import DA from '@amcharts/amcharts5-geodata/lang/DA';
import { Dialog } from 'primereact/dialog';
import MenuBar from './components/MenuBar';
import Footer from './components/Footer';
import Loader from './components/Loader';
import { LandingPage, Home, CountryInformationPage, Resources, CountryProfiles, GlobalTools } from './pages';
import { Approach, Platform, Team } from './pages/About/components';
import PopUp from './components/PopUp';
import NotFound from './pages/NotFound';
import { http } from './services/httpService';
import L from './utils/localization';
import Actions from './reducer/actions';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import './App.css';

const App = () => {
	const dispatch = useDispatch();

	const setLanguage = (payload) => dispatch({ type: Actions.SetLanguage, payload });

	const showMenus = useSelector((state) => state.showMenus);

	const [toggleLoader, setToggleLoader] = useState(false);
	const [showPopUp, setShowPopUp] = useState(false);
	const initialized = useRef(true);
	let timer;
	let popup = null;
	const newCookie = new Cookies();

	const expirationDateSkipForm = new Date();
	expirationDateSkipForm.setDate(expirationDateSkipForm.getDate() + 30);
	useEffect(
		() => {
			if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
				Gleap.initialize('027ZlaJwQFLGyW7ztG0xdcXqrARsOswU');
			}
			setLanguage(L.English);

			http.interceptors.request.use(
				(config) => {
					clearTimeout(timer);
					setToggleLoader(true);
					return config;
				},
				(error) => {
					console.log(error);
					return Promise.reject(error);
				}
			);
			http.interceptors.response.use(
				(response) => {
					console.log(response);
					timer = setTimeout(() => {
						setToggleLoader(false);
					}, 100);
					return response;
				},
				(error) => {
					console.log(error);
					return Promise.reject(error);
				}
			);
		}, []
	);
	useEffect(() => {
		if (initialized.current) {
			const cookies = new Cookies();

			if (cookies.get('registrationForm')) {
				setShowPopUp(false);
			} else if (newCookie.get('showRegistrationForm')) {
				setShowPopUp(false);
			} else {
				const timerPopup = setTimeout(() => {
					setShowPopUp(true);
				}, 5 * 60 * 1000);
				return () => clearTimeout(timerPopup);
			}
		}
		return () => {
			initialized.current = false;
		};
	}, []);

	if (showPopUp) {
		popup = <PopUp setShowRegistrationForm={setShowPopUp} />;
	}
	const skipRegistrationFrom = () => {
		setShowPopUp(false);
		newCookie.set('showRegistrationForm', true, { path: '/', expires: expirationDateSkipForm });
	};

	return (
		<div className="app">
			{toggleLoader ? <Loader /> : null}
			<Router>
				{ showMenus ? <MenuBar /> : null }
				<Dialog visible={showPopUp} style={{ width: '60vw', height: '80vh' }} onHide={() => skipRegistrationFrom()}>
					{popup}
				</Dialog>
				<Routes>
					<Route exact path="/" element={<Navigate replace to="/LandingPage" />} />
					<Route path="/Home" element={<Home />} />
					<Route path="/CountryProfiles" element={<CountryProfiles />} />
					<Route path="/GlobalTools" element={<GlobalTools />} />
					<Route path="/LandingPage" element={<LandingPage />} />
					<Route path="/Information/*" element={<CountryInformationPage />} />
					<Route path="/Resources" element={<Resources />} />
					<Route path="/Approach" element={<Approach />} />
					<Route path="/Team" element={<Team />} />
					<Route path="/Platform" element={<Platform />} />
					<Route path="*" element={<NotFound />} key="notFound" />
				</Routes>
				<CookieConsent
					location="bottom"
					buttonText="I understand"
					cookieName="consentCookie"
					style={{ background: 'white', color: '#2d2d2d' }}
					buttonStyle={{ color: '#FDC82F', fontSize: '13px', backgroundColor: '#2d2d2d', padding: '12px', borderRadius: '4px', fontFamily: 'Montserrat' }}
					expires={150}
				>
					<p>This website uses cookies to enhance the user experience.</p>
				</CookieConsent>
				{ showMenus ? <Footer /> : null }
			</Router>
		</div>
	);
};
export default App;
