/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { MapOptions, ClusterDefinionOptions, LivestockOptions } from './components';
import LearnMoreDialogContent from '../LearnMoreDialogContent';
import Actions from '../../../../../../reducer/actions';
import data from './dataSource.json';
import './styles.css';
import SummaryDownloadButton from '../SummaryDownloadButton';
import Translator from '../../../../../../components/Translator';
import dataSource from '../../../Body/components/What/components/SocialMedia/dataSource.json';
import generalImage from '../../../../../../assets/images/CountryInformationPage/Where/general.svg';
import generalBlackImage from '../../../../../../assets/images/CountryInformationPage/Where/generalBlack.svg';
import livestockImage from '../../../../../../assets/images/CountryInformationPage/Where/livestock.svg';
import livestockBlackImage from '../../../../../../assets/images/CountryInformationPage/Where/livestockBlack.svg';

const Where = () => {
	const dispatch = useDispatch();

	const whereSubpageSelection = useSelector((state) => state.whereSubpageSelection);
	const setWhereSubPageSelection = (payload) => dispatch({ type: Actions.SetWhereSubPageSelection, payload });

	const country = useSelector((state) => state.country);

	const livestockSelected = useSelector((state) => state.livestockSelected);
	const generalMappingSelected = useSelector((state) => state.generalMappingSelected);
	const setLivestockSelected = (payload) => dispatch({ type: Actions.SetLivestockSelected, payload });
	const setGeneralMappingSelected = (payload) => dispatch({ type: Actions.SetGeneralMappingSelected, payload });

	const whereSwitchValues = useSelector((state) => state.whereSwitchValues);
	const setWhereSwitchValues = (payload) => dispatch({ type: Actions.SetWhereSwitchValues, payload });

	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayTutorial, setDisplayTutorial] = useState(false);
	const [switchValues, setSwitchValues] = useState(false);

	const getReportsByCountry = () => {
		if (window.location.href.includes('Kenya')) {
			return data.kenyaReports;
		}
		if (window.location.href.includes('Mali')) {
			return data.maliReports;
		}
		if (window.location.href.includes('Nigeria')) {
			return data.nigeriaReports;
		}
		if (window.location.href.includes('Senegal')) {
			return data.senegalReports;
		}
		if (window.location.href.includes('Uganda')) {
			return data.ugandaReports;
		}
		if (window.location.href.includes('Zimbabwe')) {
			return data.zimbabweReports;
		}
		if (window.location.href.includes('Sudan')) {
			return data.sudanReports;
		}
	};
	const showMapsFilter = () => {
		if (country.id === 'KEN') {
			return (
				<div className="tab where">
					{ !livestockSelected ? (
						<>
							{livestockSelected || generalMappingSelected ? (
								<div className="switch">
									<img src={!livestockSelected ? generalImage : generalBlackImage} />
									{/* <InputSwitch */}
									{/*	checked={livestockSelected} */}
									{/*	onChange={(e) => setLivestockSelected(e.value)} */}
									{/* /> */}
									<InputSwitch
										checked={whereSwitchValues}
										onChange={(e) => {
											setWhereSwitchValues(e.value);
											if (e.value) {
												setLivestockSelected(true);
												setGeneralMappingSelected(false);
											} else {
												setGeneralMappingSelected(true);
												setLivestockSelected(false);
											}
										}}
									/>
									<img src={livestockSelected ? livestockImage : livestockBlackImage} />
								</div>
							) : null
							}

							<p className="open-sans tab-header">
								<Translator text="A spatial hotspots analysis is used to map the climate-conflict nexus,
	and identify the geographic co-occurrence of specific combinations of conflict,
	climate conditions, and socio-economic vulnerabilities."
								/>
							</p>
							{generalMappingSelected ? (
								<>
									<div className="options">
										<Button label={<Translator text="Map" />} onClick={() => setWhereSubPageSelection('map')} style={whereSubpageSelection === 'map' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
										<Button label={<Translator text="Cluster Definition" />} onClick={() => setWhereSubPageSelection('definition')} style={whereSubpageSelection === 'definition' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
									</div>
									{whereSubpageSelection === 'map' ? <MapOptions /> : <ClusterDefinionOptions />}
								</>

							) : null
							}
						</>
					) : (
						<>
							<div className="switch">
								<img src={!livestockSelected ? generalImage : generalBlackImage} />
								{/* <InputSwitch */}
								{/*	checked={livestockSelected} */}
								{/*	onChange={(e) => setLivestockSelected(e.value)} */}
								{/* /> */}
								<InputSwitch
									checked={whereSwitchValues}
									disabled={!!((livestockSelected && generalMappingSelected))}
									onChange={(e) => {
										setWhereSwitchValues(e.value);
										if (e.value) {
											setLivestockSelected(true);
											setGeneralMappingSelected(false);
										} else {
											setGeneralMappingSelected(true);
											setLivestockSelected(false);
										}
									}}
								/>
								<img src={livestockSelected ? livestockImage : livestockBlackImage} />
							</div>
							<p className="open-sans tab-header">
								<Translator text="A spatial hotspots analysis is used to map livestock related conflicts, climate stressors, transhumance routes, water points, livestock markets, and pastoral Infrastructure as an entry point for analyzing potential trigger points for livestock-related conflicts and their spatiotemporal distribution." />
							</p>
							<LivestockOptions />
						</>
					)}
				</div>
			);
		}
		return (
			<>
				<p className="open-sans tab-header">
					<Translator text="A spatial hotspots analysis is used to map the climate-conflict nexus,
					and identify the geographic co-occurrence of specific combinations of conflict,
					climate conditions, and socio-economic vulnerabilities."
					/>
				</p>
				<div className="options">
					<Button label={<Translator text="Map" />} onClick={() => setWhereSubPageSelection('map')} style={whereSubpageSelection === 'map' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
					<Button label={<Translator text="Cluster Definition" />} onClick={() => setWhereSubPageSelection('definition')} style={whereSubpageSelection === 'definition' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
				</div> 
				{whereSubpageSelection === 'map' ? <MapOptions /> : <ClusterDefinionOptions />}

			</>
		);
	};

	return (
		<div className="tab where">
			<p className="title">
				<span><Translator text="WHERE" /></span> <Translator text="are the most vulnerable areas to climate induced insecurities and risks?" />
			</p>
			{showMapsFilter()}
			{/* { */}
			{/*	(country.id === 'KEN') ? ( */}
			{/*		*/}
			{/*		*/}
			{/*	) : null */}
			{/* } */}
			{/* { !livestockSelected ? ( */}
			{/*	<> */}
			{/*		<p className="open-sans tab-header"> */}
			{/*			<Translator text="A spatial hotspots analysis is used to map the climate-conflict nexus, */}
			{/*	and identify the geographic co-occurrence of specific combinations of conflict, */}
			{/*	climate conditions, and socio-economic vulnerabilities." */}
			{/*			/> */}
			{/*		</p> */}
			{/*		{generalMappingSelected ? ( */}
			{/*			<> */}
			{/*				<div className="options"> */}
			{/*					<Button label={<Translator text="Map" />} onClick={() => setWhereSubPageSelection('map')} style={whereSubpageSelection === 'map' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} /> */}
			{/*					<Button label={<Translator text="Cluster Definition" />} onClick={() => setWhereSubPageSelection('definition')} style={whereSubpageSelection === 'definition' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} /> */}
			{/*				</div> */}
			{/*				{whereSubpageSelection === 'map' ? <MapOptions /> : <ClusterDefinionOptions />} */}
			{/*			</> */}

			{/*		) : null */}
			{/*		} */}
			{/*		/!* <p className="open-sans tab-header"> *!/ */}
			{/*		/!*	<Translator text="A spatial hotspots analysis is used to map the climate-conflict nexus, *!/ */}
			{/*		/!* and identify the geographic co-occurrence of specific combinations of conflict, *!/ */}
			{/*		/!* climate conditions, and socio-economic vulnerabilities." *!/ */}
			{/*		/!*	/> *!/ */}
			{/*		/!* </p> *!/ */}

			{/*	</> */}
			{/* ) : ( */}
			{/*	<> */}
			{/*		<p className="open-sans tab-header"> */}
			{/*			<Translator text="A spatial hotspots analysis is used to map livestock related conflicts, climate stressors, transhumance routes, water points, livestock markets, and pastoral Infrastructure as an entry point for analyzing potential trigger points for livestock-related conflicts and their spatiotemporal distribution." /> */}
			{/*		</p> */}
			{/*		<LivestockOptions /> */}
			{/*	</> */}
			{/* )} */}
			{/* <p className="open-sans tab-header"> */}
			{/*	<Translator text="A spatial hotspots analysis is used to map the climate-conflict nexus, */}
			{/*	and identify the geographic co-occurrence of specific combinations of conflict, */}
			{/*	climate conditions, and socio-economic vulnerabilities." */}
			{/*	/> */}
			{/* </p> */}
			{/* <div className="options"> */}
			{/*	<Button label={<Translator text="Map" />} onClick={() => setWhereSubPageSelection('map')} style={whereSubpageSelection === 'map' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} /> */}
			{/*	<Button label={<Translator text="Cluster Definition" />} onClick={() => setWhereSubPageSelection('definition')} style={whereSubpageSelection === 'definition' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} /> */}
			{/* </div> */}
			{/* {whereSubpageSelection === 'map' ? <MapOptions /> : <ClusterDefinionOptions />} */}

			<div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
				<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
				<Button label={<Translator text="Tutorial" />} onClick={() => setDisplayTutorial(true)} />
			</div>
			<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
				<LearnMoreDialogContent dataSource={data.data} dataMethods={data.methods} dataReports={getReportsByCountry()} />
			</Dialog>
			<Dialog header={<Translator text="CSO Tutorial" />} visible={displayTutorial} style={{ width: '90vw', height: '90vh' }} onHide={() => setDisplayTutorial(false)}>
				<iframe
					src="https://player.vimeo.com/video/828235986?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					style={{ width: '100%', height: '100%' }}
					title="Context_Kenya_Maximum_Temperature"
				/>
			</Dialog>
			<SummaryDownloadButton />
		</div>
	);
};

export default Where;
