/* eslint-disable react/jsx-closing-tag-location,indent */
import React from 'react';
import Translator from '../../../../../../../../components/Translator';

const x = {
	EVENTS: <p className="open-sans">
		<Translator text="Total number of conflict events. More information on ACLED Event and Sub-Event Types can be found&nbsp;" />
		<a href="https://acleddata.com/acleddatanew/wp-content/uploads/2021/11/ACLED_Codebook_v1_January-2021.pdf" target="_blank" rel="noopener noreferrer" aria-label="see more">
			<Translator text="here" />
		</a>
	</p>,
	FATALITIES: <p className="open-sans">
		<Translator text="Total number of reported fatalities which occurred during conflict event. More information on ALCED fatalities can be found&nbsp;" />
		<a href="https://acleddata.com/acleddatanew/wp-content/uploads/dlm_uploads/2020/02/FAQs_-ACLED-Fatality-Methodology_2020.pdf" target="_blank" rel="noopener noreferrer" aria-label="see more">
			<Translator text="here" />
		</a>
	</p>,
	SUBTYPE_RICHNESS: <p className="open-sans">
		<Translator text="Total number of unique conflict sub-event types. More information on ACLED Event and Sub-Event Types can be found&nbsp;" />
		<a href="https://acleddata.com/acleddatanew/wp-content/uploads/2021/11/ACLED_Codebook_v1_January-2021.pdf" target="_blank" rel="noopener noreferrer" aria-label="see more">
			<Translator text="here" />
		</a>
	</p>,
	ACTOR1_RICHNESS: <p className="open-sans">
		<Translator text="The total number of unique actor 1 types involved in conflicts events. More information on ACLED Actors and
		Interactions can be found&nbsp;"
		/>
		<a href="https://www.acleddata.com/wp-content/uploads/dlm_uploads/2017/12/Actors_FINAL.pdf" target="_blank" rel="noopener noreferrer" aria-label="see more">
			<Translator text="here" />
		</a>
	</p>,
	ACTOR2_RICHNESS: <p className="open-sans"><Translator text="The total number of unique actor 2 types involved in conflicts events. More information on
		ACLED Actors and Interactions can be found&nbsp;"
	/>
		<a href="https://www.acleddata.com/wp-content/uploads/dlm_uploads/2017/12/Actors_FINAL.pdf" target="_blank" rel="noopener noreferrer" aria-label="see more">
			<Translator text="here" />
		</a>
	</p>,
	TYPE_RICHNESS: <p className="open-sans"><Translator text="Total number of unique conflict event types. More information on ACLED
		Event and Sub-Event Types can be found&nbsp;"
	/>
		<a href="https://acleddata.com/acleddatanew/wp-content/uploads/2021/11/ACLED_Codebook_v1_January-2021.pdf" target="_blank" rel="noopener noreferrer" aria-label="see more">
			<Translator text="here" />
		</a>
	</p>,
};

export default x;
