/* eslint-disable react/jsx-no-useless-fragment */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';
import Translator from '../../../../../../../../../../../../components/Translator';
import './styles.css';

const MultipleBars = (props) => {
	const { data, id, category, label } = props;
	console.log(data);

	useEffect(() => {
		const root = am5.Root.new(id);

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: true,
			// panY: true,
			wheelX: 'panX',
			// wheelY: 'zoomX',
			// layout: root.verticalLayout,
			panY: false,
			wheelY: 'zoomX',
			// layout: root.verticalLayout,
		}));

		// Add legend
		// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
		const legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.p50,
				x: am5.p50,
			})
		);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
		xRenderer.labels.template.setAll({
			centerY: am5.p50,
			centerX: am5.p100,
		});

		xRenderer.grid.template.setAll({
			location: 1,
		});

		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			maxDeviation: 0.2,
			categoryField: 'value',
			renderer: xRenderer,
			tooltip: am5.Tooltip.new(root, {}),
		}));

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			// maxDeviation: 0.3,
			renderer: am5xy.AxisRendererY.new(root, {
				// strokeOpacity: 0.1,
				centerX: am5.p50,
				x: am5.p50,
			}),
		}));

		yAxis.children.moveValue(am5.Label.new(root, {
			rotation: -90,
			// text: 'Frequency (number of megapixels)',
			y: am5.p50,
			centerX: am5.p50,
		}), 0);

		xAxis.data.setAll(data);

		xAxis.get('renderer').labels.template.setAll({
			textAlign: 'center',
		});

		const assignColor = () => {
			switch (category) {
			case 'Limited Conflict': return 'rgba(115,169,143,0.3)';
			case 'Moderate Conflict': return 'rgba(246,224,159,0.3)';
			case 'High Conflict': return 'rgba(230,151,138,0.30)';
			default: return '#fff';
			}
		};

		const assignStrokeColor = () => {
			switch (category) {
			case 'Limited Conflict': return 'rgba(115,169,143,1)';
			case 'Moderate Conflict': return 'rgba(246,224,159,1)';
			case 'High Conflict': return 'rgba(230,151,138,1)';
			default: return '#fff';
			}
		};
		
		const series = chart.series.push(am5xy.ColumnSeries.new(root, {
			name: category,
			xAxis,
			yAxis,
			valueYField: 'density',
			categoryXField: 'value',
			fill: assignColor(),
			stroke: assignStrokeColor(),
		}));

		series.columns.template.setAll({
			tooltipText: '{categoryX}: {valueY}',
			width: am5.percent(90),
			tooltipY: 0,
			strokeOpacity: 0,
		});

		series.data.setAll(data);

		series.appear();

		series.bullets.push(() => {
			return am5.Bullet.new(root, {
				locationY: 0,
				sprite: am5.Label.new(root, {
					text: '{valueY}',
					fill: root.interfaceColors.get('alternativeText'),
					centerY: 0,
					centerX: am5.p50,
					populateText: true,
				}),
			});
		});
		legend.data.push(series);

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/

		chart.appear(1000, 100);

		return () => root.dispose();
	}, [data]);

	return (
		<div className="chart-container">
			<div id={id} style={{ width: '33%', minWidth: '500px', height: '500px' }} />
			<p className="rotate-label-conflict"><Translator text="Frequency (number of megapixels)" /></p>
			<div className="info">
				<p><Translator text={label} /></p>
			</div>
		</div>
	);
};
export default MultipleBars;
