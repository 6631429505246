import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Translator from '../../../../components/Translator';

const BriefsDialogContent = (props) => {
	const { data } = props;

	const urlTemplate = (lineData) => {
		return (
			<a href={lineData.url} target="_blank" rel="noopener noreferrer" aria-label="link">
				<i className="fa-solid fa-link" style={{ fontSize: '25px', color: '#fdc82f' }} />
			</a>
		);
	};

	const titleTemplate = (item) => {
		return (
			<p><Translator text={item.Title} /></p>
		);
	};

	// const titleTemplate = (item) => {
	// 	return (
	// 		<p><Translator text={item.Title} /></p>
	// 	);
	// };

	return (
		<div className="resources-dialog-content">
			<p className="title"><Translator text="Others" /></p>
			<DataTable value={data} responsiveLayout="scroll" stripedRows>
				<Column body={titleTemplate} header="Title" />
				<Column field="Year" header="Year" />
				<Column field="url" body={urlTemplate} header="Url" />
			</DataTable>
		</div>
	);
};

export default BriefsDialogContent;
