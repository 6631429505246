/* eslint-disable react/jsx-no-useless-fragment,no-shadow,no-plusplus */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';

const LineChart = (props) => {
	const { data, showRank } = props;

	useEffect(() => {
		const root = am5.Root.new('multiple-lines');
		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				focusable: true,
				panX: true,
				panY: true,
				wheelX: 'panX',
				wheelY: 'zoomX',
				pinchZoomX: true,
			})
		);

		const easing = am5.ease.linear;
		chart.get('colors').set('step', 3);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xAxis = chart.xAxes.push(
			am5xy.DateAxis.new(root, {
				maxDeviation: 0.1,
				groupData: false,
				baseInterval: {
					timeUnit: 'year',
					count: 1,
				},
				// tooltipIntervalOffset: 0,
				renderer: am5xy.AxisRendererX.new(root, {
					minGridDistance: 20,
				}),
				tooltip: am5.Tooltip.new(root, {}),
			})
		);

		const xRenderer = xAxis.get('renderer');
		xRenderer.grid.template.setAll({
			location: 0.5,
			multiLocation: 0.5,
			visible: true,
		});

		function createAxisAndSeries(property) {
			const yRenderer = am5xy.AxisRendererY.new(root, {});
			const yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					maxDeviation: 1,
					renderer: yRenderer,
				})
			);

			if (chart.yAxes.indexOf(yAxis) > 0) {
				yAxis.set('syncWithAxis', chart.yAxes.getIndex(0));
			}

			// Add series
			// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
			const series = chart.series.push(
				am5xy.LineSeries.new(root, {
					xAxis,
					yAxis,
					valueYField: property,
					valueXField: 'year',
					tooltip: am5.Tooltip.new(root, {
						pointerOrientation: 'horizontal',
						labelText: '{valueY}',
					}),
				})
			);

			series.bullets.push((item) => am5.Bullet.new(item, {
				sprite: am5.Circle.new(item, {
					radius: 4,
					fill: series.get('fill'),
					stroke: root.interfaceColors.get('background'),
					strokeWidth: 2,
					tooltipText: '{valueY}',
					showTooltipOn: 'hover',
					tooltip: am5.Tooltip.new(root, {}),
				}),
			}));

			yAxis.children.push(am5.Label.new(root, {
				text: property,
				stroke: series.get('fill'),
				fill: series.get('fill'),
				textAlign: 'center',
				position: 'absolute',
				x: am5.p50,
				y: am5.p100,
				fontWeight: 'bold',
			}));

			// series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
			series.strokes.template.setAll({ strokeWidth: 1 });

			yRenderer.grid.template.set('strokeOpacity', 0.05);
			yRenderer.labels.template.set('fill', series.get('fill'));
			yRenderer.setAll({
				stroke: series.get('fill'),
				strokeOpacity: 1,
				opacity: 1,
			});

			// series.fills.template.setAll({
			// 	fillOpacity: 0.2,
			// 	visible: true,
			// });

			series.data.processor = am5.DataProcessor.new(root, {
				dateFormat: 'yyyy',
				dateFields: ['year'],
			});

			series.data.setAll(data.data);
		}

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			xAxis,
			behavior: 'none',
		}));
		cursor.lineY.set('visible', false);

		// add scrollbar
		chart.set('scrollbarX', am5.Scrollbar.new(root, {
			orientation: 'horizontal',
		}));

		if (!showRank) {
			createAxisAndSeries('rank');
		}
		createAxisAndSeries('score');

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);

		// Generates random data, quite different range

		return () => root.dispose();
	}, [data]);

	return (
		<div id="multiple-lines" style={{ width: '100%', height: '500px' }} />
	);
};
export default LineChart;
