import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import Actions from '../../reducer/actions';
import { GlobeCountries, Map, Question } from './components';
import './styles.css';
import Translator from '../../components/Translator';
import { how, what, where, who } from '../../assets/images/images';

const CountryProfiles = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [selectedCountry, setSelectedCountry] = useState(null);
	const [toggled, setToggled] = useState(true);

	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });

	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	const countries = ['Burkina-Faso', 'Kenya', 'Senegal', 'Niger', 'Nigeria', 'Uganda', 'Zimbabwe', 'Guatemala', 'Mali', 'Philippines', 'Zambia', 'Ethiopia', 'Sudan'];

	useEffect(
		() => {
			setCurrentPage('CountryProfiles');
			setShowMenus(true);
		}, []
	);
	useEffect(
		() => {
			if (selectedCountry) {
				navigate(`/Information/${selectedCountry}`);
			}
		}, [selectedCountry]
	);

	const itemTemplate = (item) => {
		return (
			<Translator text={item} />
		);
	};

	return (
		<div className="country-profiles-page">
			<div className="side-icons">
				<Question question="HOW" text="does climate worsen the root causes of conflict?" image={how} id="question-how" />
				<Question question="WHERE" text="are the most vulnerable areas to climate induced insecurities and risks?" image={where} id="question-where" />
				<Question question="WHO" text="are the vulnerable groups to climate and security risks that should be targeted?" image={who} id="question-who" />
				<Question question="WHAT" text="can be done to break the vicious cycle between climate and conflict" image={what} id="question-what" />

			</div>
			<div className="map-container">
				<GlobeCountries toggled={toggled} />
			</div>
			<div className="dropdown-container" style={toggled ? { opacity: '1', zIndex: '0', marginBottom: '20px' } : { opacity: '0', zIndex: '-1', marginBottom: '20px' }}>
				{/* <p className="open-sans"><Translator text={'Country'} /></p> */}
				<Dropdown
					className="dropdown-country"
					value={selectedCountry}
					options={countries.sort()}
					onChange={(e) => setSelectedCountry(e.value)}
					placeholder={<Translator text={'Please Select Country'} />}
					itemTemplate={itemTemplate}
				/>
			</div>
		</div>
	);
};
export default CountryProfiles;
