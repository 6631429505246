/* eslint-disable react/jsx-no-useless-fragment,no-shadow,no-plusplus */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect } from 'react';

const SentimentChart = (props) => {
	const { data, selectedData } = props;

	useEffect(() => {
		// console.log(data);
		const root = am5.Root.new('sentiment');

		root.setThemes([
			am5themes_Animated.new(root),
		]);

		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			wheelX: 'panX',
			wheelY: 'zoomX',
			layout: root.verticalLayout,
		}));

		// Add legend
		// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
		const legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.p50,
				x: am5.p50,
			})
		);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'year',
			renderer: am5xy.AxisRendererX.new(root, {
				cellStartLocation: 0.1,
				cellEndLocation: 0.9,
			}),
			tooltip: am5.Tooltip.new(root, {}),
		}));

		xAxis.data.setAll(data);

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererY.new(root, {}),
		}));

		// Add series
		// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
		function makeSeries(name, fieldName) {
			console.log(name, fieldName);
			const series = chart.series.push(am5xy.ColumnSeries.new(root, {
				name,
				xAxis,
				yAxis,
				valueYField: fieldName,
				categoryXField: 'year',
			}));

			series.columns.template.setAll({
				tooltipText: '{name}: {valueY}',
				width: am5.percent(90),
				tooltipY: 0,
			});

			series.data.setAll(data);

			// Make stuff animate on load
			// https://www.amcharts.com/docs/v5/concepts/animations/
			series.appear();

			series.bullets.push(() => am5.Bullet.new(root, {
				locationY: 0,
				sprite: am5.Label.new(root, {
					// text: '{valueY}',
					fill: root.interfaceColors.get('alternativeText'),
					centerY: 0,
					centerX: am5.p50,
					populateText: true,
				}),
			}));

			legend.data.push(series);
		}
		// makeSeries('year', 'year');
		selectedData.map((item) => {
			makeSeries(`${item.variable}`, `${item.variable}`);
		});

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);

		// Generates random data, quite different range

		return () => root.dispose();
	}, [data]);

	return (
		<div id="sentiment" style={{ width: '66%', height: '500px' }} />
	);
};
export default SentimentChart;
