import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import { useTranslate } from '@tolgee/react';
import Actions from '../../../../../../../../reducer/actions';
import Translator from '../../../../../../../../components/Translator';
import useCustomTranslate from '../../../../../../../../utils/translateFunction';
import translatePureFunction from '../../../../../../../../utils/translatePureFunction';

const Climate = () => {
	const dispatch = useDispatch();
	const { t } = useTranslate();

	const contextFilterSelections = useSelector((state) => state.contextFilterSelections);
	const setContextFilterSelections = (payload) => dispatch({ type: Actions.SetContextFilterSelections, payload });
	
	const [variable, setVariable] = useState(contextFilterSelections.climateVariable);
	const [duration, setDuration] = useState(contextFilterSelections.climateDuration);

	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	useEffect(
		() => {
			const temp = { ...contextFilterSelections };
			temp.climateVariable = variable;
			temp.climateDuration = [...duration];
			setContextFilterSelections(temp);
		}, [variable, duration]
	);

	const calculateDate = (index) => {
		const year = Math.floor(2001 + duration[index] / 12);
		const month = months[duration[index] % 12];
		return `${month} ${year}`;
	};

	const itemTemplate = (item) => {
		if (item) {
			return (
				<Translator text={item} />
			);
		}
		return (
			<p><Translator text="Please select" /></p>
		);
	};

	return (
		<div className="filters">
			<div className="dropdown">
				<p className="open-sans">Variable</p>
				<Dropdown
					value={variable}
					options={['Minimum temperature', 'Maximum temperature']}
					onChange={(e) => setVariable(e.value)}
					placeholder={translatePureFunction('Please select', t)}
					itemTemplate={itemTemplate}
					valueTemplate={itemTemplate}
				/>
			</div>
			{/* <div className="slider"> */}
			{/*	<p className="open-sans slider-title">During</p> */}
			{/*	<div className="slider-container" style={{ background: `linear-gradient(90deg, #73a98f 55%, #d8c092 55%)` }}> */}
			{/*		<Tooltip target=".p-slider-handle-start" content={calculateDate(0)} position="top" event="both" /> */}
			{/*		<Tooltip target=".p-slider-handle-end" content={calculateDate(1)} position="top" event="both" /> */}
			{/*		<Slider value={duration} onChange={(e) => setDuration(e.value)} range min={2} max={350} /> */}
			{/*	</div> */}
			{/*	<div className="labels"> */}
			{/*		<p className="open-sans">March 2001</p> */}
			{/*		<p className="open-sans">March 2030</p> */}
			{/*	</div> */}
			{/* </div> */}
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<p
				style={{ cursor: 'pointer' }}
				onClick={() => {
					setDuration([]);
					setVariable('');
				}}
			><Translator text="Reset all filters" />
			</p>
		</div>
	);
};

export default Climate;
