import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import SocialMedia from './components/SocialMedia';
import ClimateSecurity from './components/ClimateSecurity';
import ActorIntervention from './components/ActorIntervention';
import VoicesFromTheField from '../VoicesFromTheField';
import Card from '../Card';
import socialMediaImage from '../../../../../../assets/images/CountryInformationPage/What/socialMedia.png';
import climateSecurityImage from '../../../../../../assets/images/CountryInformationPage/What/climateSecurity.svg';
import actorInterventionImage from '../../../../../../assets/images/CountryInformationPage/What/actorIntervention.svg';
import voicesImage from '../../../../../../assets/images/CountryInformationPage/What/voices.png';
import fosterInclusive from '../../../../../../assets/images/CountryInformationPage/What/fosterInclusive.png';
import './styles.css';
import { ConceptualModeling, EconometricModeling, NetworkModelContent } from '../How/components';
import conceptualModeling from '../../../../../../assets/images/CountryInformationPage/How/conceptualModeling.png';
import networkAnalysis from '../../../../../../assets/images/CountryInformationPage/How/networkAnalysis.png';
import econometricModeling from '../../../../../../assets/images/CountryInformationPage/How/econometricModeling.png';
import voicesFromTheField from '../../../../../../assets/images/CountryInformationPage/How/voicesFromTheField.png';
import Actions from '../../../../../../reducer/actions';
import Kenya from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Kenya.jpg';
import Senegal from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Senegal.jpg';
import Guatemala from '../../../../../../assets/images/CountryInformationPage/VoicesFromTheField/Guatemala.jpg';
import Translator from '../../../../../../components/Translator';
import ComingSoon from '../../../../../../components/ComingSoon';

const What = () => {
	const country = useSelector((state) => state.country);
	const [displayBackButton, setDisplayBackButton] = useState(true);
	const [displayCard, setDisplayCard] = useState('');
	const dispatch = useDispatch();
	const toggled = useSelector((state) => state.toggled);
	const setToggled = (payload) => dispatch({ type: Actions.SetToggled, payload });

	const renderContent = () => {
		switch (displayCard) {
		case 'Identify and address policy gaps': return <SocialMedia />;
		case 'Design peace-responsive climate action programs': return <ClimateSecurity />;
		case 'Collaborate with Actors at the Intersection of Climate and Security': return <ActorIntervention />;
		case 'Foster inclusive participatory research': return <VoicesFromTheField textProperty="whatText" />;
		default: return (
			<>
			</>
		);
		}
	};

	const renderVoicesFromTheField = () => {
		switch (country.name) {
		case 'Kenya': return true;
		case 'Senegal': return true;
		case 'Guatemala': return true;
		case 'Zambia': return true;
		default: return false;
		}
	};

	const assignVoicesImage = () => {
		switch (country.name) {
		case 'Kenya': return Kenya;
		case 'Senegal': return Senegal;
		case 'Guatemala': return Guatemala;
		default: return false;
		}
	};
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{ country.id === 'BFA' || country.id === 'NER' ? (

				<ComingSoon comingSoonContent={'Coming Soon!'} />

			)
				: (

					<div className="what">
						{displayCard === ''
							? (
								<div className="what p-grid p-justify-center">
									<Card image={socialMediaImage} setDisplayCard={setDisplayCard} title="Identify and address policy gaps" text={<Translator text="A multi-sectoral, integrated strategy is needed to build peace and conflict responsiveness into climate security-related policies." />}>
										<SocialMedia displayBackButton={displayBackButton} setDisplayBackButton={setDisplayBackButton} />
									</Card>
									<Card image={climateSecurityImage} setDisplayCard={setDisplayCard} title="Design peace-responsive climate action programs" text={<Translator text="The Climate Security Sensitivity Scoring Tool - bridging the gap between peacekeeping and climate adaptation." />}>
										<ClimateSecurity />
									</Card>
									{renderVoicesFromTheField()
										? 	(
											<Card image={voicesImage} setDisplayCard={setDisplayCard} title="Foster inclusive participatory research" text={<Translator text="Participatory action research to identify resilience-building solutions through collective action." />}>
												<VoicesFromTheField />
											</Card>
										)
										: null}
									<Card image={actorInterventionImage} setDisplayCard={setDisplayCard} title="Collaborate with Actors at the Intersection of Climate and Security" text={<Translator text="Exploring how key players interact locally, nationally and internationally around the climate security nexus." />}>
										<ActorIntervention />
									</Card>
								</div>
							)
							:				(
								<div className="what-content">
									{displayBackButton
										? (
											<div className="go-back-btn">
												<Button
													onClick={() => {
														setToggled(true);
														setDisplayCard('');
													}}
													style={{ padding: '10px' }}
												><Translator text="Go Back" />
												</Button>
											</div>
										) : null}

									{renderContent()}
								</div>
							)

						}
					</div>
				)}

		</>

	);
};
export default What;
