import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Timeline } from 'react-twitter-widgets';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { Card } from 'primereact/card';
import Actions from '../../reducer/actions';
import integrated from '../../assets/images/GlobalTools/integrated.svg';
import csst from '../../assets/images/GlobalTools/csst.svg';
import csstDialog from '../../assets/images/GlobalTools/design_big.jpg';
import mapping from '../../assets/images/GlobalTools/mapping.svg';
import security from '../../assets/images/GlobalTools/security.svg';
import icsat from '../../assets/images/GlobalTools/icsat.png';
import mappingDialog from '../../assets/images/GlobalTools/mappingDialog.png';
// import { GlobeCountries, Map } from './components';
import CardDialoguesImage from '../../assets/images/Home/HomeCardDialoguesImg.svg';
import './styles.css';
import Translator from '../../components/Translator';
import networkAnalysis from '../../assets/images/CountryInformationPage/How/na_big.jpg';
import Tool3 from '../../components/Tool3';

const GlobalTools = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [selectedCountry, setSelectedCountry] = useState(null);
	const [toggled, setToggled] = useState(true);
	const [toolButtonDialog, setToolButtonDialog] = useState(false);
	const [displayBasic, setDisplayBasic] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayIcsat, setDisplayIcsat] = useState(false);
	const [displayMapping, setDisplayMapping] = useState(false);
	const [displayCsst, setDisplayCsst] = useState(false);
	const [toolButtonCsstDialog, setToolButtonCsstDialog] = useState(false);

	const language = useSelector((state) => state.language);
	const setShowMenus = (payload) => dispatch({ type: Actions.SetShowMenus, payload });

	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	const countries = ['Kenya', 'Senegal', 'Nigeria', 'Uganda', 'Zimbabwe', 'Guatemala', 'Mali', 'Philippines', 'Zambia', 'Ethiopia', 'Sudan'];

	useEffect(
		() => {
			setCurrentPage('GlobalTools');
			setShowMenus(true);
		}, []
	);
	useEffect(
		() => {
			if (selectedCountry) {
				navigate(`/Information/${selectedCountry}`);
			}
		}, [selectedCountry]
	);

	const headerDialoguesCardIntegrated = (
		<img alt="Card-image" src={integrated} />
	);

	const headerDialoguesCardCsst = (
		<img alt="Card-image" src={csst} />
	);

	const headerDialoguesCardMapping = (
		<img alt="Card-image" src={mapping} />
	);

	const headerDialoguesCardSecurity = (
		<img alt="Card-image" src={security} />
	);

	const footerDialoguesCard = (
		<Button
			label={<Translator text={language.HomePageClimateSecurityDialoguesButtonLabel} />}
			className="dialogues-card-read-more"
			onClick={() => {
				setDisplayBasic(true);
				setToolButtonDialog(false);
			}}
		/>
	);

	const footerDialoguesIntegratedCard = (
		<Button
			label={<Translator text={language.HomePageClimateSecurityDialoguesButtonLabel} />}
			className="dialogues-card-read-more"
			onClick={() => {
				setDisplayIcsat(true);
				setToolButtonDialog(false);
			}}
		/>
	);

	const footerDialoguesMappingCard = (
		<Button
			label={<Translator text={language.HomePageClimateSecurityDialoguesButtonLabel} />}
			className="dialogues-card-read-more"
			onClick={() => {
				setDisplayMapping(true);
			}}
		/>
	);

	const footerDialoguesCsstCard = (
		<Button
			label={<Translator text={language.HomePageClimateSecurityDialoguesButtonLabel} />}
			className="dialogues-card-read-more"
			onClick={() => {
				setDisplayCsst(true);
			}}
		/>
	);

	const changeStyles = () => {
		const menu = document.getElementById('side-menu');
		if (menu) {
			if (toggled) {
				menu.style.width = '0';
				menu.style.minWidth = '0';
				setToggled(false);
			} else {
				menu.style.width = '400px';
				menu.style.minWidth = '400px';
				menu.style.background = '#F7F7F7';
				setToggled(true);
			}
		}
	};

	const itemTemplate = (item) => {
		return (
			<Translator text={item} />
		);
	};
	// const renderMarkdownToHTML = (twitters) => {
	// 	const twitterHtml = { __html: { twitters } };
	// 	// eslint-disable-next-line react/no-danger
	// 	return <div dangerouslySetInnerHTML={twitterHtml} />;
	// };

	return (
		<div className="global-tools-page">
			<h1><Translator text="Climate Security Tools" /></h1>
			<div className="global-tools-content">
				<Card className="security-dialogues-card" title={<Translator text={'Climate Security Programming Dashboard for Climate Finance (CSPDxCF)'} />} footer={footerDialoguesIntegratedCard} header={headerDialoguesCardIntegrated} style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1' }}>
					<p className="m-0">{<Translator text={'Risk analyses and guidance to address project challenges and harness opportunities that foster peace.'} />}
					</p>
				</Card>
				<Card className="security-dialogues-card" title={<Translator text={'Climate Security Sensitivity Tool (CSST)'} />} footer={footerDialoguesCsstCard} header={headerDialoguesCardCsst} style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1' }}>
					<p className="m-0">{<Translator text={'Bridging the gap between peacekeeping and climate adaptation.'} />}
					</p>
				</Card>
				<Card className="security-dialogues-card" title={<Translator text={'Climate Security Actor Mapping'} />} footer={footerDialoguesMappingCard} header={headerDialoguesCardMapping} style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1' }}>
					<p className="m-0">{<Translator text={'Explores the global web of key actors and their thematic and geographical foci around the climate security nexus.'} />}
					</p>
				</Card>
				<Card className="security-dialogues-card" title={<Translator text={language.HomePageClimateSecurityDialoguesCardTitle} />} footer={footerDialoguesCard} header={headerDialoguesCardSecurity} style={toggled ? { opacity: '1', zIndex: '0' } : { opacity: '0', zIndex: '-1' }}>
					<p className="m-0 cl-dialogue-text">{<Translator text={'Interactive dashboard to monitor climate security debates in digital spaces'} />}
					</p>
				</Card>
				{/* <div className="button-container"> */}
				{/*	<Button label={<Translator text="Tutorial" />} onClick={() => setDisplayDialog(true)} /> */}
				{/* </div> */}
				{/* <Dialog header={<Translator text="CSO Tutorial" />} visible={displayDialog} style={{ width: '90vw', height: '90vh' }} onHide={() => setDisplayDialog(false)}> */}
				{/*	<iframe */}
				{/*		src="https://player.vimeo.com/video/828842392?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" */}
				{/*		frameBorder="0" */}
				{/*		allow="autoplay; fullscreen; picture-in-picture" */}
				{/*		allowFullScreen */}
				{/*		style={{ width: '100%', height: '100%' }} */}
				{/*		title="Context_Kenya_Maximum_Temperature" */}
				{/*	/> */}
				{/* </Dialog> */}
			</div>
			<Dialog className="dialog" visible={displayBasic} onHide={() => setDisplayBasic(false)}>
				<div className="dialog-tool-container">
					{ toolButtonDialog ? (
						<>
							<h3>{<Translator text={language.HomePageClimateSecurityDialoguesCardTitle} />}</h3>
							{/* <iframe src="https://app.powerbi.com/view?r=eyJrIjoiZTBjZjlmZjMtZDRiYS00ZDA3LWJkZWUtNWY2YzcyNGI1NzY5IiwidCI6ImM4YjRkMmVmLTgyYjctNDA5ZS1iZjYxLTA5NzE5MDBiMjliNiIsImMiOjl9" style={{ width: '100%', height: '100%', overflow: 'auto', border: 'none' }} /> */}
							<iframe src="https://app.powerbi.com/view?r=eyJrIjoiNDczNTE1OTEtNzJlNy00OWJlLTg1ZmMtMWQ3NWNmNjhhMzdjIiwidCI6IjZhZmEwZTAwLWZhMTQtNDBiNy04YTJlLTIyYTdmOGMzNTdkNSIsImMiOjh9" style={{ width: '100%', height: '100%', overflow: 'auto', border: 'none' }} />
						</>
					) : (
						<>
							<h3>{<Translator text={language.HomePageClimateSecurityDialoguesCardTitle} />}</h3>
							<Button label={<Translator text={language.HomePageClimateSecurityDialogButtonLabel} />} className="open-tool-button" role="button" tabIndex="0" onClick={() => setToolButtonDialog(true)} />
							{/* <Button className="open-tool-button" label={<Translator text="Open Model" />} /> */}
							<div className="dialog-info-container">
								<p><Translator text="The Climate Security Dialogues dashboard is an interactive tool that collects and analyses online data to display trends about climate security debates in digital spaces. It aims to monitor conversations about climate security to identify the most prevalent and/or relevant topics, actors, places, and events related to these discussions. Currently, the tool features insights from a social media pilot module with historical data from Twitter (currently X), and a News Media module, developed in collaboration with Media Cloud. " /></p>
								{/* <p>{<Translator text={language.HomePageClimateSecurityDialogInfoTextA} />}</p> */}
								{/* <p>{<Translator text={language.HomePageClimateSecurityDialogInfoTextB} />}</p> */}
								{/* <p>{<Translator text={language.HomePageClimateSecurityDialogInfoTextC} />}</p> */}
								{/* <p>{<Translator text={language.HomePageClimateSecurityDialogInfoTextD} />}</p> */}
							</div>
						</>
					)}
				</div>
			</Dialog>
			<Dialog className="dialog programming-toolkit" visible={displayIcsat} onHide={() => setDisplayIcsat(false)}>
				<div className="dialog-tool-container">
					<img src={icsat} width="100%" />
					<h3><Translator text="Climate Security Programming Dashboard for Climate Finance (CSPDxCF)" /></h3>
					{/* <h2><Translator text="Coming Soon!" /></h2> */}
					<a className="open-tool-button" style={{ marginBottom: '0' }} role="button" tabIndex="0" href="https://cspd.cso.cgiar.org/" target="_blank" rel="noreferrer">Open Tool</a>
					<p> <Translator text="The Climate Security Programming Dashboard for Climate Finance (CSPDxCF) is an all-in-one solution for preliminary conflict-sensitive assessments and tailored guidance, targeting projects funded by international financial institutions and climate funds." /></p>
					{/* <Button className="open-tool-button" label={<Translator text="Open Model" />} /> */}
					{/* <div className="text-container"> */}
					{/*	<p className="open-sans"> */}
					{/*		<Translator text="Coming Soon" /> */}
					{/*	</p> */}
					{/* </div> */}
				</div>
			</Dialog>
			<Dialog className="dialog actor-mapping" visible={displayMapping} onHide={() => setDisplayMapping(false)}>
				<div className="dialog-tool-container">
					<img src={mappingDialog} width="100%" />
					<h3><Translator text="Collaborate with Actors at the Intersection of Climate and Security" /></h3>
					<h2><Translator text="Coming Soon!" /></h2>
					{/* <Button className="open-tool-button" label={<Translator text="Open Model" />} onClick={() => setToolButtonDialog(true)} /> */}
					<div className="text-container">
						<p>
							<Translator text="Multidisciplinary and cross-sectoral collaboration is essential to addressing climate security-related risks and developing climate security-sensitive solutions. The actor and intervention mapping explores the web of implementation areas and thematic and geographical foci of key players around the climate security nexus from the local to international level." />
						</p>
					</div>
				</div>
			</Dialog>
			<Dialog className="dialog csst" visible={displayCsst} onHide={() => setDisplayCsst(false)}>
				<div className="dialog-tool-container dialog-content">
					{
						toolButtonCsstDialog ? (
							<Tool3 />
							// <h2 style={{ color: '#000' }}>Coming Soon!</h2>
						) : (
							<>
								<img src={csstDialog} width="100%" />
								<h3><Translator text="Design peace-responsive climate action programs" /></h3>
								<Button className="open-tool-button" label={<Translator text="Open Tool" />} onClick={() => setToolButtonCsstDialog(true)} />
								{/* <Button className="open-tool-button" label={<Translator text="Open Model" />} onClick={() => setToolButtonDialog(true)} /> */}
								<div className="text-container">
									<p>
										<Translator text="Peacekeeping and climate adaptation are rarely integrated in adaptation initiatives. The Climate Security Sensitivity Scoring Tool tries to close this gap by assessing whether climate action programs and their implementation are appropriate for the context in which they are carried out and recommending solutions to improve their appropriateness. It is aimed at practitioners, policymakers, and international organizations interested in diagnostic research for peace-responsive climate action in agricultural and rural development." />
									</p>
								</div>
							</>
						)
					}

				</div>
			</Dialog>
		</div>
	);
};
export default GlobalTools;
