/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useTranslate } from '@tolgee/react';
import { FlowerChart, SolidGauge, Pdf, ReactPdf } from './components';
import placeholder from './placeholder';
import CountryService from '../../../../services/httpService/countryService';
import legend from '../../../../assets/images/CountryInformationPage/What/Legend.png';
import './styles.css';
import translatePureFunction from '../../../../utils/translatePureFunction';
import Translator from '../../../Translator';

const Results = (props) => {
	const element = useRef();
	const { t } = useTranslate();
	const { userInput, csstData } = props;
	const [displayChart, setDisplayChart] = useState('Summary');
	const [flowerChartData, setFlowerChartData] = useState([]);
	const [gaugeChartData, setGaugeChartData] = useState([]);
	const [chartSrc, setChartSrc] = useState(null);
	const [flowerChartSrc, setFlowerChartSrc] = useState(null);
	const [invChartSrc, setInvChartSrc] = useState(null);
	const [invFlowerChartSrc, setInvFlowerChartSrc] = useState(null);
	const [downloadDialog, setDownloadDialog] = useState(false);
	const [checked, setChecked] = useState(false);
	const [themes, setThemes] = useState([]);
	const [organisation, setOrganisation] = useState('');
	const [project, setProject] = useState('');
	const [email, setEmail] = useState('');

	const categories = ['1. Economic development', '2. Building institutions', '3. Building trust and cooperation', '4. Resource sustainability', '5. Enhancing knowledge', '6. Building capacity and resilience'];

	useEffect(
		() => {
			console.log(userInput);
			console.log(csstData);
			const flowerData = categories.map((item, index) => {
				if (index + 1 === 6) {
					return {
						category: item.slice(2),
						value: ((userInput[`${index + 1}.1`] || 0) + (((userInput[`${index + 1}.2a`] || 0) + (userInput[`${index + 1}.2b`] || 0)) / 2) + (userInput[`${index + 1}.3`] || 0)) / 3,
					};
				}
				if (index + 1 === 5) {
					return {
						category: item.slice(2),
						value: ((userInput[`${index + 1}.1`] || 0) + (userInput[`${index + 1}.2`] || 0)) / 2,
					};
				}
				return {
					category: item.slice(2),
					value: ((userInput[`${index + 1}.1`] || 0) + (userInput[`${index + 1}.2`] || 0) + (userInput[`${index + 1}.3`] || 0)) / 3,
				};
			});
			if (userInput.country.id === 'GTM' || userInput.country.id === 'NER') {
				let temp = userInput.municipality;
				if (temp) {
					temp = temp.data.map((item) => {
						return { ...item, full: 10 };
					});
					setGaugeChartData(temp);
				}
			} else if (userInput.region === '') {
				let temp = csstData;
				if (temp) {
					temp = temp.data.map((item) => {
						return { ...item, full: 10 };
					});
					setGaugeChartData(temp);
				}
			} else {
				let temp = userInput.region;
				if (temp) {
					temp = temp.data.map((item) => {
						return { ...item, full: 10 };
					});
					setGaugeChartData(temp);
				}
			}
			setFlowerChartData(flowerData);
		}, [userInput]
	);

	const data = [
		{ submechanism: '1.1 Create livelihoods and sustain existing ones', category: '1. Economic development' },
		{ submechanism: '1.2 Develop bi-communal spaces and infrastructures', category: '1. Economic development' },
		{ submechanism: '1.3 Foster the provision of public goods and services', category: '1. Economic development' },
		{ submechanism: '2.1 Enhance institutional capacities for good environmental governance', category: '2. Building institutions' },
		{ submechanism: '2.2 Facilitate legal pluralism and resource rights', category: '2. Building institutions' },
		{ submechanism: '2.3 Foster equitable distribution of resources and benefits', category: '2. Building institutions' },
		{ submechanism: '3.1 Involve both high and grass-root levels while minimizing transboundary contacts', category: '3. Building trust and cooperation' },
		{ submechanism: '3.2 Foster intercommunal trust and create shared identities', category: '3. Building trust and cooperation' },
		{ submechanism: '3.3 Enhance social cohesion and empower vulnerable groups', category: '3. Building trust and cooperation' },
		{ submechanism: '4.1 Restore degraded ecosystems', category: '4. Resource sustainability' },
		{ submechanism: '4.2 Foster adoption of practices for sustainable use of resources', category: '4. Resource sustainability' },
		{ submechanism: '4.3 Conserve ecosystems and common-pool resources', category: '4. Resource sustainability' },
		{ submechanism: '5.1 Raise awareness on environmental issues and environment-conflict linkages', category: '5. Enhancing knowledge' },
		{ submechanism: '5.2 Establish the recognition of diverse ontologies in climate adaptation and natural resource management', category: '5. Enhancing knowledge' },
		// { submechanism: '5.3 Foster recognition of alternative knowledges and worldviews', category: '5. Enhancing knowledge' },
		{ submechanism: '6.1 Increase livelihood climate coping capacity', category: '6. Building capacity and resilience' },
		{ submechanism: '6.2 Increase livelihood climate adaptation capacity', category: '6. Building capacity and resilience' },
		{ submechanism: '6.3 Increase livelihood climate transformative capacity', category: '6. Building capacity and resilience' },
	];

	const displayRows = () => {
		let tableString = '';
		data.map((item, index) => {
			let complexComment = '';
			let score = false;
			if (item.submechanism.split(' ')[0] === '6.2') {
				score = (userInput['6.2a'] + userInput['6.2b']) / 2;
				// complexComment = `${userInput['6.2acomment']} ${userInput['6.2bcomment']}`;
				complexComment = `${userInput['6.2acomment'] ? userInput['6.2acomment'] : ''} ${userInput['6.2bcomment'] ? userInput['6.2bcomment'] : ''}`;
			}
			tableString = `${tableString} <tr>` +
				`                <td>${item.submechanism}</td>` +
				`                <td>${complexComment || (userInput[`${item.submechanism.split(' ')[0]}comment`] ? userInput[`${item.submechanism.split(' ')[0]}comment`] : ' ')}</td>` +
				`                <td>${score || userInput[item.submechanism.split(' ')[0]]}</td>` +
				`            </tr>`;
		});
		return tableString;
	};
	const header = () => {
		return (
			<p style={{ fontWeight: '600', fontSize: '36', color: '#FDC82F', padding: '0 12px' }}><Translator text="Download" /></p>
		);
	};
	const prepareDownload = () => {
		let location;
		if (userInput.country.id === 'GTM' || userInput.country.id === 'NER') {
			location = `${userInput.country.name} • ${userInput.municipality.name} • <span>${userInput.region.region}</span>`;
		} else if (!csstData.regions) {
			location = `${userInput.country.name}`;
		} else {
			location = `${userInput.country.name} • <span>${userInput.region.name}</span>`;
		}

		const organizationAndProject = `${organisation} • ${project}`;
		let selectedThemes = '';
		themes.map((item) => {
			selectedThemes = `${selectedThemes}<div>` +
				`            ${item}` +
				`        </div>`;
		});

		const country = userInput.country.name;

		console.log(selectedThemes);

		// CountryService.getPdfUrl(placeholder(chartSrc, flowerChartSrc, displayRows(), location, selectedThemes, organizationAndProject, country, legend))
		CountryService.getPdfUrl(themes, organisation, project, email, placeholder(chartSrc, flowerChartSrc, displayRows(), location, selectedThemes, organizationAndProject, country, legend), checked)
			.then((res) => {
				window.open(res, '_blank');
				setDownloadDialog(false);
			});
	};
	const itemTemplate = (item) => {
		if (item) {
			return (
				<Translator text={item} />
			);
		}
		return (
			<p><Translator text="Please select relevant themes" /></p>
		);
	};

	const csstDataInput = (input) => {
		if (input.country.id === 'GTM' || input.country.id === 'NER') {
			return input.municipality;
		} if (!input.region) {
			console.log(input.country);
			return csstData;
		}
		console.log(input.region);
		return input.region;
	};

	return (
		<div className="sub-page results">
			<p className="sub-title"><Translator text="Results" /></p>
			<div className="content">
				<div className="information">
					<p className="sub-title">
						<Translator text="Ideal Climate-Peace Mechanisms scores for the selected region vs. those for the proposed
						intervention"
						/>
					</p>
					<p className="open-sans">
						<Translator text="The results shown provide a quick overview of and graphical representation of a) prevalent
						drivers, b) mechanisms scores for your program and c) the extent to which context-specific
						drivers are responded by the program design&apos;s mechanism&apos;s scores."
						/>
					</p>
					<Button
						label={<Translator text="Save Results" />}
						onClick={() => {
							setDownloadDialog(true);
						}}
					/>
				</div>
				<div className="charts">
					<div className="buttons">
						<Button label={<Translator text="Summary" />} onClick={() => setDisplayChart('Summary')} style={displayChart === 'Summary' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
						<Button label={<Translator text="Contextual Drivers" />} onClick={() => setDisplayChart('Contextual Drivers')} style={displayChart === 'Contextual Drivers' ? { backgroundColor: '#FDC82F', color: 'black' } : { backgroundColor: 'black', color: '#FDC82F' }} />
					</div>

					<div className="chart">
						{
							(displayChart === 'Summary')
								? <FlowerChart width={'100%'} flowerChartId={'flowerChartId'} data={flowerChartData} csstData={csstDataInput(userInput)} setFlowerChartSrc={setFlowerChartSrc} />
								: <SolidGauge width={'100%'} solidChartId={'gaugeChartId'} data={gaugeChartData} setChartSrc={setChartSrc} />
						}

					</div>
					<div className="invisible-charts">
						<FlowerChart width={'60vw'} flowerChartId={'invisibleflowerChartId'} data={flowerChartData} csstData={userInput.country.id === 'GTM' || userInput.country.id === 'NER' ? userInput.municipality : userInput.region} setFlowerChartSrc={setFlowerChartSrc} />
						<SolidGauge width={'60vw'} solidChartId={'invisibleGaugeChartId'} data={gaugeChartData} setChartSrc={setChartSrc} />
					</div>
				</div>

			</div>
			<Dialog header={header} visible={downloadDialog} style={{ width: '80vw' }} onHide={() => setDownloadDialog(false)}>
				<div className="csst-download-dialog">
					<div className="warning">
						<i className="fa-regular fa-circle-info" />
						<p className="open-sans"><Translator text="Do you wish to provide your results to this platform to help build up
							an intervention database? If you agree, it means that the climate security sensitivity
							scoring results of you project/program will be showcased on the CSO."
						/>
						</p>
					</div>
					<div className="checkbox-container">
						<p className="open-sans"><Translator text="I agree to sharing my results with CGIAR" /></p>
						<Checkbox onChange={(e) => setChecked(e.checked)} checked={checked} />
					</div>
					<div className="fields">
						<p className="open-sans"><Translator text="What is the thematic area of the project / program*" /></p>
						<MultiSelect
							value={themes}
							options={['Agricultural', 'Input Supply', 'Pastoral', 'Production']}
							onChange={(e) => setThemes(e.value)}
							placeholder="Please select relevant themes"
							itemTemplate={itemTemplate}
							selectedItemTemplate={itemTemplate}
						/>
						<InputText value={organisation} onChange={(e) => setOrganisation(e.target.value)} placeholder={translatePureFunction('Name of your organisation*', t)} />
						<InputText value={project} onChange={(e) => setProject(e.target.value)} placeholder={translatePureFunction('Name of the project / program*', t)} />
						<InputText value={email} onChange={(e) => setEmail(e.target.value)} placeholder={translatePureFunction('Email Address*', t)} />
					</div>
					<Button label={<Translator text="Download" />} onClick={() => prepareDownload()} disabled={!(organisation && project && email && themes.length)} />
				</div>
			</Dialog>
		</div>
	);
};

export default Results;
