/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import climateSecurityImage from '../../../../../../../../assets/images/CountryInformationPage/What/design_big.jpg';
import Tool3 from '../../../../../../../../components/Tool3';
import './styles.css';
import LearnMoreDialogContent from '../../../../../SideBar/components/LearnMoreDialogContent';
import dataSource from './dataSource.json';
import Translator from '../../../../../../../../components/Translator';

const ClimateSecurity = () => {
	const [toolButton, setToolButton] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayTutorial, setDisplayTutorial] = useState(false);

	const getReportsByCountry = () => {
		if (window.location.href.includes('Kenya')) {
			return dataSource.kenyaReports;
		}
		return dataSource.dataReports;
	};

	return (
		<div className="dialog-content">
			{ toolButton ? (
				<Tool3 />
			)
				: (
					<>
						<img style={{ width: '100%' }} src={climateSecurityImage} />
						<h2 style={{ fontSize: '30px', color: '#FDC82F' }}>
							<Translator text="Design Peace-responsive Climate Action Programs" />
						</h2>
						<Button className="open-tool-button" label={<Translator text="Open Tool" />} onClick={() => setToolButton(true)} />
						<div className="text-container">
							<p>
								<Translator text="Peacekeeping and climate adaptation are rarely integrated in adaptation initiatives.
								The Climate Security Sensitivity Scoring Tool tries to close this gap by assessing
								whether climate action programs and their implementation are appropriate for the
								context in which they are carried out and recommending solutions to improve their
								appropriateness. It is aimed at practitioners, policymakers, and international
								organizations interested in diagnostic research for peace-responsive climate action
								in agricultural and rural development."
								/>
							</p>
						</div>
						<div className="learn-more-wrap">
							<div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
								<Button label={<Translator text="Learn More" />} onClick={() => setDisplayDialog(true)} />
								<Button label={<Translator text="Tutorial" />} onClick={() => setDisplayTutorial(true)} />
							</div>
							<Dialog className="learn-more" header={<Translator text="Learn More" />} visible={displayDialog} style={{ width: '80vw' }} onHide={() => setDisplayDialog(false)}>
								<LearnMoreDialogContent dataSource={dataSource.data} dataMethods={dataSource.methods} dataReports={getReportsByCountry()} />
							</Dialog>
							<Dialog header={<Translator text="CSO Tutorial" />} visible={displayTutorial} style={{ width: '90vw', height: '90vh' }} onHide={() => setDisplayTutorial(false)}>
								<iframe
									src="https://player.vimeo.com/video/828236547?autoplay=1&loop=1&h=f6d240b684&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
									frameBorder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowFullScreen
									style={{ width: '100%', height: '100%' }}
									title="Context_Kenya_Maximum_Temperature"
								/>
							</Dialog>
						</div>
					</>

				)}
		</div> 
	);
};

export default ClimateSecurity;
