import { useTranslate } from '@tolgee/react';
import makeKey from './makeKey';

const translatePureFunction = (defaultString, t, customKey) => {
	if (customKey) {
		return t(makeKey(customKey), defaultString);
	}
	return t(makeKey(defaultString), defaultString);
};

export default translatePureFunction;
