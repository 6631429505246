import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import Actions from '../../../../../../../../reducer/actions';
import Translator from '../../../../../../../../components/Translator';
import './styles.css';
import CountryService from '../../../../../../../../services/httpService/countryService';

const LivestockOptions = () => {
	const dispatch = useDispatch();
	const routeClass = useSelector((state) => state.routeClass);
	const setRouteClass = (payload) => dispatch({ type: Actions.SetRouteClass, payload });
	const conflictLayersClass = useSelector((state) => state.conflictLayersClass);
	const setConflictLayersClass = (payload) => dispatch({ type: Actions.SetConflictLayersClass, payload });
	const minDate = useSelector((state) => state.minDate);
	const maxDate = useSelector((state) => state.maxDate);
	const setMinDate = (payload) => dispatch({ type: Actions.SetMinDate, payload });
	const setMaxDate = (payload) => dispatch({ type: Actions.SetMaxDate, payload });
	const tileLayersClass = useSelector((state) => state.tileLayersClass);
	const setTileLayersClass = (payload) => dispatch({ type: Actions.SetTileLayersClass, payload });

	const tileLayersRainfallClass = useSelector((state) => state.tileLayersRainfallClass);
	const setTileLayersRainfallClass = (payload) => dispatch({ type: Actions.SetTileLayersRainfallClass, payload });

	const livestockDataNdvi = useSelector((state) => state.livestockDataNdvi);

	const livestockDataRainfall = useSelector((state) => state.livestockDataRainfall);

	const sliderValue = useSelector((state) => state.sliderValue);
	const setSliderValue = (payload) => dispatch({ type: Actions.SetSliderValue, payload });
	const [disableSlider, setDisableSlider] = useState(true);

	const routeOptions = [
		{ label: 'Major Functional', featureClass: 'Major functional' },
		{ label: 'Major Functional Seasonal', featureClass: 'Major functional seasonal' },
		{ label: 'Minor Functional', featureClass: 'Minor functional' },
		{ label: 'Minor Non-Functional', featureClass: 'Minor non-functional' },
		{ label: 'Major Non-Functional', featureClass: 'Major non-functional' },
		{ label: 'Other', featureClass: 'Other' },
	];

	const conflictOptions = [
		{ label: 'Pastoral-related events', featureClass: 'Pastoral' },
		{ label: 'All Events', featureClass: 'All Events' },
		{ label: 'No Events', featureClass: null },

	];
	//
	useEffect(() => {
		console.log(conflictLayersClass);
		if (conflictLayersClass.featureClass === null) {
			setSliderValue([]);
			setDisableSlider(true);
		} else {
			setDisableSlider(false);
		}
	}, [conflictLayersClass]);

	return (
		<div className="livestock-container">
			<div className="dropdown environmental-layers">
				<p className="open-sans"><Translator text="Transhumance Routes" /></p>
				<MultiSelect
					value={routeClass}
					// options={['Major Functional', 'Major Functional Seasonal', 'Minor Functional', 'Minor Non-Functional', 'Major Non-Functional', 'Other']}
					onChange={(e) => setRouteClass(e.value)}
					options={routeOptions}
					optionLabel="label"
				/>
			</div>
			<div className="dropdown environmental-layers">
				<p className="open-sans"><Translator text="Conflict Layers" /></p>
				<Dropdown
					value={conflictLayersClass}
					onChange={(e) => setConflictLayersClass(e.value)}
					options={conflictOptions}
					optionLabel="label"
				/>
			</div>

			<div className={!sliderValue[0] && !sliderValue[1] ? 'disable' : 'slider'}>
				<p className="open-sans"><Translator text="During" /></p>
				{/* <InputText value={value} onChange={(e) => setValue(e.target.value)} /> */}
				<p> {new Date(sliderValue[0]).toLocaleDateString('en-GB')} - {new Date(sliderValue[1]).toLocaleDateString('en-GB')}   </p>
				<Slider
					value={sliderValue}
					onChange={(e) => setSliderValue(e.value)}
					range
					min={minDate}
					max={maxDate}
					disabled={!sliderValue[0] && !sliderValue[1] || disableSlider}
				/>
			</div>

			<div className="dropdown environmental-layers tiles">
				<p className="open-sans"><Translator text="Vegetation Layers" /></p>
				<MultiSelect
					selectionLimit={1}
					value={tileLayersClass}
					onChange={(e) => setTileLayersClass(e.value)}
					options={livestockDataNdvi}
					optionLabel="name"
					filter
					className="tiles"
				/>
			</div>
			<div className="dropdown environmental-layers tiles">
				<p className="open-sans"><Translator text="Rainfall Layers" /></p>
				<MultiSelect
					selectionLimit={1}
					value={tileLayersRainfallClass}
					onChange={(e) => setTileLayersRainfallClass(e.value)}
					options={livestockDataRainfall}
					optionLabel="name"
					filter
					className="tiles"
				/>
			</div>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
			<p
				style={{ cursor: 'pointer', textAlign: 'center' }}
				onClick={() => {
					setConflictLayersClass({ label: '', featureClass: null });
					setTileLayersRainfallClass([]);
					setTileLayersClass([]);
					setRouteClass([]);
					setSliderValue([]);
				}}
			><Translator text="Reset all filters" />
			</p>
		</div>
	);
};
export default LivestockOptions;
