import React from 'react';

const multiLevelImage = (
	<svg id="multi" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4354_5456)">
			<path d="M6.73877 21.0747C6.73877 19.821 6.02644 18.6867 4.98534 18.2688C3.99904 17.8509 2.84836 18.2688 2.19082 19.1643C1.47849 20.1195 1.3689 21.4927 1.91685 22.5673C2.46479 23.6419 3.56068 24.1792 4.65658 23.9404C5.86205 23.7016 6.68397 22.4479 6.73877 21.0747Z" fill="#2D2D2D" />
			<path d="M4.93145 19.8209C5.04104 19.8806 4.87665 19.7612 4.93145 19.8209C5.04104 19.9403 5.15063 20.0001 5.20542 20.1195Z" fill="#2D2D2D" />
			<path d="M7.88977 26.6867C7.88977 25.5524 7.06785 24.6569 6.08155 24.5972C5.80758 24.5972 5.5336 24.5972 5.25963 24.5972H2.30073C1.64319 24.5972 0.985659 24.8957 0.602098 25.6121C0.328125 26.0897 0.328125 26.5673 0.328125 27.1046V30.5673C0.328125 30.9255 0.328125 31.3434 0.328125 31.7016C0.38292 32.3584 0.711687 32.8957 1.15004 33.2539C1.5336 33.4927 1.91717 33.5524 2.35552 33.5524C2.13634 33.3136 1.91717 33.0748 1.75278 32.836V35.5822V37.1942C1.75278 37.7912 1.80758 38.3285 2.19114 38.7464C2.68429 39.2837 3.28703 39.224 3.88977 39.224H4.71169C4.93086 39.224 5.15004 39.224 5.36922 39.1643C6.08155 38.9852 6.46511 38.2091 6.46511 37.433V36.0599V32.836C6.24593 33.0748 6.02675 33.3136 5.86237 33.5524C6.24593 33.5524 6.5747 33.4927 6.90347 33.3136C7.28703 33.1345 7.561 32.7763 7.72539 32.2987C7.88977 31.8807 7.88977 31.4628 7.88977 30.9852V27.6419V26.6867Z" fill="#2D2D2D" />
			<path d="M6.35547 26.0898C6.41026 26.1495 6.46506 26.1495 6.46506 26.2092Z" fill="#2D2D2D" />
			<path d="M17.2056 21.0747C17.2056 19.821 16.4932 18.6867 15.4521 18.2688C14.4658 17.8509 13.3152 18.2688 12.6576 19.1643C11.9453 20.1195 11.8357 21.4927 12.3836 22.5673C12.9316 23.6419 14.0275 24.1792 15.1234 23.9404C16.3289 23.7016 17.1508 22.4479 17.2056 21.0747Z" fill="#2D2D2D" />
			<path d="M15.2871 19.7612C15.3419 19.7612 15.3419 19.8209 15.3967 19.8209C15.5063 19.8806 15.3419 19.7612 15.4515 19.8806C15.5063 19.9403 15.6159 20.0597 15.6707 20.1194C15.7255 20.1791 15.7255 20.2388 15.7803 20.2985" fill="#2D2D2D" />
			<path d="M17.4784 26.5075C17.2592 25.4926 16.6565 24.6567 15.6702 24.6567C15.2866 24.6567 14.9579 24.6567 14.5743 24.6567C14.1907 24.6567 13.862 24.6567 13.4784 24.6567C12.4921 24.6567 11.8894 25.5523 11.6702 26.5075C11.2866 28.2985 10.9031 30.1493 10.5195 31.9403C10.4099 32.4776 10.3003 33.0149 10.5743 33.5523C10.7935 34.0896 11.2866 34.4478 11.725 34.6269C12.0537 34.7463 12.3277 34.6866 12.6565 34.6866C12.4373 34.4478 12.2181 34.209 12.0537 33.9702C12.1633 34.6866 12.2181 35.403 12.3277 36.1791C12.3825 36.597 12.4373 37.0149 12.4921 37.3732C12.4921 37.4329 12.4921 37.5523 12.4921 37.612C12.5469 38.1493 12.7113 38.5672 13.0948 38.8657C13.4784 39.2239 13.9716 39.1642 14.4647 39.1642C14.8483 39.1642 15.2866 39.2239 15.6154 39.1045C15.999 38.9851 16.3825 38.6866 16.5469 38.3284C16.6565 38.0896 16.7113 37.7911 16.7113 37.4926C16.7661 37.1941 16.7661 36.8358 16.8209 36.5373C16.9305 35.7015 17.04 34.806 17.0948 33.9702C16.8757 34.209 16.6565 34.4478 16.4921 34.6866C16.8209 34.6866 17.2044 34.6866 17.5332 34.5672C18.0811 34.3284 18.5743 33.8508 18.7387 33.1941C18.8483 32.7164 18.7387 32.2388 18.6291 31.7612C18.3551 30.3881 18.0811 29.0746 17.7524 27.7015C17.6428 27.2836 17.588 26.8657 17.4784 26.5075Z" fill="#2D2D2D" />
			<path d="M15.5605 26.03H15.6701H15.7249C15.8893 26.03 15.6153 25.9703 15.7797 26.03C15.7797 26.03 15.8345 26.03 15.8893 26.0897C15.7797 26.0897 15.8893 26.0897 15.9441 26.0897C15.9989 26.0897 15.9989 26.1494 16.0537 26.1494C15.9441 26.03 16.0537 26.1494 16.0537 26.2091" fill="#2D2D2D" />
			<path d="M27.6704 21.0747C27.6704 19.821 26.9581 18.6867 25.917 18.2688C24.9307 17.8509 23.78 18.2688 23.1225 19.1643C22.4101 20.1195 22.3005 21.4927 22.8485 22.5673C23.3964 23.6419 24.4923 24.1792 25.5882 23.9404C26.7937 23.7016 27.6156 22.4479 27.6704 21.0747Z" fill="#2D2D2D" />
			<path d="M26.3008 20.418C26.3008 20.418 26.3008 20.4777 26.3556 20.4777C26.3556 20.418 26.3556 20.418 26.3556 20.4777" fill="#2D2D2D" />
			<path d="M25.3145 19.582C25.424 19.582 25.4788 19.6417 25.5884 19.6417C25.7528 19.7014 25.4788 19.582 25.6432 19.6417C25.698 19.6417 25.7528 19.7014 25.8076 19.7611C25.8624 19.7611 25.8624 19.8208 25.9172 19.8208C26.0268 19.8805 25.8624 19.7611 25.972 19.8805C26.0268 19.9402 26.1364 20.0596 26.1912 20.1193" fill="#2D2D2D" />
			<path d="M23.8906 20.2986C23.8906 20.2389 23.9454 20.2389 23.9454 20.1792C24.055 20.0001 23.8906 20.2986 24.0002 20.1195C24.055 20.0598 24.1098 20.0001 24.1646 19.9404C24.2194 19.8807 24.2194 19.8807 24.2742 19.821C24.3838 19.7613 24.4386 19.7016 24.5482 19.6419C24.6577 19.5822 24.3838 19.7016 24.603 19.6419C24.6577 19.6419 24.6577 19.6419 24.7125 19.5822C24.7673 19.5822 24.8221 19.5822 24.8221 19.5225C24.8769 19.5225 24.9865 19.5225 24.8769 19.5225C24.9865 19.5225 25.0413 19.5225 25.1509 19.5225" fill="#2D2D2D" />
			<path d="M28.8213 26.6867C28.8213 25.5524 27.9994 24.6569 27.0131 24.5972C26.7391 24.5972 26.4651 24.5972 26.1912 24.5972H23.2871C22.6295 24.5972 21.972 24.8957 21.5884 25.6121C21.3145 26.0897 21.3145 26.5673 21.3145 27.1046V30.5673C21.3145 30.9255 21.3145 31.3434 21.3145 31.7016C21.3692 32.3584 21.698 32.8957 22.1364 33.2539C22.5199 33.4927 22.9035 33.5524 23.3418 33.5524C23.1227 33.3136 22.9035 33.0748 22.7391 32.836V35.5822V37.1942C22.7391 37.7912 22.7939 38.3285 23.1775 38.7464C23.6706 39.2837 24.2734 39.224 24.8761 39.224H25.698C25.9172 39.224 26.1364 39.224 26.3555 39.1643C27.0679 38.9852 27.4514 38.2091 27.4514 37.433V36.0599V32.836C27.2323 33.0748 27.0131 33.3136 26.8487 33.5524C27.2323 33.5524 27.561 33.4927 27.8898 33.3136C28.2734 33.1345 28.5473 32.7763 28.7117 32.2987C28.8761 31.8807 28.8761 31.4628 28.8761 30.9852V27.6419L28.8213 26.6867Z" fill="#2D2D2D" />
			<path d="M38.1372 21.0747C38.1372 19.821 37.4249 18.6867 36.3838 18.2688C35.3975 17.8509 34.2468 18.2688 33.5893 19.1643C32.8769 20.1195 32.7673 21.4927 33.3153 22.5673C33.8632 23.6419 34.9591 24.1792 36.055 23.9404C37.2605 23.7016 38.0824 22.4479 38.1372 21.0747Z" fill="#2D2D2D" />
			<path d="M36.7656 20.418C36.7656 20.418 36.7656 20.4777 36.8204 20.4777C36.8204 20.418 36.8204 20.418 36.8204 20.4777" fill="#2D2D2D" />
			<path d="M38.41 26.5075C38.1909 25.4926 37.5881 24.6567 36.6018 24.6567C36.2183 24.6567 35.8895 24.6567 35.5059 24.6567C35.1224 24.6567 34.7936 24.6567 34.41 24.6567C33.4237 24.6567 32.821 25.5523 32.6018 26.5075C32.2183 28.2985 31.8347 30.1493 31.4511 31.9403C31.3416 32.4776 31.232 33.0149 31.5059 33.5523C31.7251 34.0896 32.2183 34.4478 32.6566 34.6269C32.9854 34.7463 33.2594 34.6866 33.5881 34.6866C33.3689 34.4478 33.1498 34.209 32.9854 33.9702C33.095 34.6866 33.1498 35.403 33.2594 36.1791C33.3142 36.597 33.3689 37.0149 33.4237 37.3732C33.4237 37.4329 33.4237 37.5523 33.4237 37.612C33.4785 38.1493 33.6429 38.5672 34.0265 38.8657C34.41 39.2239 34.9032 39.1642 35.3963 39.1642C35.7799 39.1642 36.2183 39.2239 36.547 39.1045C36.9306 38.9851 37.3142 38.6866 37.4785 38.3284C37.5881 38.0896 37.6429 37.7911 37.6429 37.4926C37.6977 37.1941 37.6977 36.8358 37.7525 36.5373C37.8621 35.7015 37.9717 34.806 38.0265 33.9702C37.8073 34.209 37.5881 34.4478 37.4237 34.6866C37.7525 34.6866 38.1361 34.6866 38.4648 34.5672C39.0128 34.3284 39.5059 33.8508 39.6703 33.1941C39.7799 32.7164 39.6703 32.2388 39.5607 31.7612C39.2868 30.3881 39.0128 29.0746 38.684 27.7015C38.5744 27.2836 38.5196 26.8657 38.41 26.5075Z" fill="#2D2D2D" />
			<path d="M36.9316 26.1494L36.9864 26.2091C36.8768 26.0897 36.9864 26.2091 36.9864 26.2688" fill="#2D2D2D" />
			<path d="M36.4922 26.03H36.6018H36.6566C36.821 26.03 36.547 25.9703 36.7114 26.03C36.7114 26.03 36.7662 26.03 36.821 26.0897C36.7114 26.0897 36.821 26.0897 36.8758 26.0897" fill="#2D2D2D" />
			<path d="M8.05324 14.9254C8.05324 14.8657 8.05324 14.806 8.05324 14.7463C8.05324 14.6866 8.05324 14.6866 8.05324 14.7463C8.05324 14.6866 8.05324 14.6866 8.05324 14.6269C8.05324 14.5672 8.05324 14.5075 8.10804 14.4478C8.10804 14.3881 8.21763 14.209 8.10804 14.3284C8.16283 14.209 8.21763 14.0896 8.27242 14.0299C8.38201 13.8507 8.21763 14.0896 8.32722 13.9701C8.38201 13.9104 8.38201 13.8507 8.4368 13.8507C8.4916 13.791 8.4916 13.791 8.54639 13.7313C8.65598 13.6119 8.4916 13.791 8.60119 13.6716C8.65598 13.6119 8.71078 13.6119 8.76557 13.5522C8.76557 13.5522 8.92996 13.4328 8.87516 13.4925C8.82037 13.5522 8.98475 13.4328 8.98475 13.4328C9.03954 13.4328 9.09434 13.3731 9.14913 13.3731C9.25872 13.3134 9.03954 13.3731 9.20393 13.3731C9.25872 13.3731 9.31352 13.3731 9.36831 13.3731C9.42311 13.3731 9.4779 13.3731 9.53269 13.3731C9.69708 13.3731 9.42311 13.3134 9.58749 13.3731C9.69708 13.3731 9.80667 13.4328 9.91626 13.4925C9.80667 13.4328 9.97105 13.5522 9.97105 13.5522C10.0258 13.5522 10.0806 13.6119 10.1354 13.6716L10.1902 13.7313C10.1354 13.6716 10.1354 13.6716 10.1902 13.7313C10.245 13.791 10.2998 13.8507 10.3546 13.8507C10.4094 13.9104 10.4094 13.9104 10.4642 13.9701C10.5738 14.0896 10.4094 13.9104 10.519 14.0299C10.5738 14.1493 10.6286 14.209 10.6834 14.3284C10.6286 14.209 10.6834 14.3881 10.6834 14.3881C10.6834 14.4478 10.7382 14.5075 10.7382 14.5672C10.7382 14.6269 10.7382 14.6269 10.7382 14.6866C10.7382 14.6269 10.7382 14.6269 10.7382 14.6866C10.7382 14.7463 10.7382 14.8657 10.7382 14.9254C10.7382 15.2836 11.0121 15.6418 11.3409 15.6418C11.6697 15.6418 11.9437 15.3433 11.9437 14.9254C11.9437 13.3134 10.793 12 9.36831 12C7.94365 12 6.79297 13.3134 6.79297 14.9254C6.79297 15.2836 7.06694 15.6418 7.39571 15.6418C7.77927 15.6418 8.05324 15.3433 8.05324 14.9254Z" fill="#2D2D2D" />
			<path d="M18.52 14.9254C18.52 14.8657 18.52 14.806 18.52 14.7463C18.52 14.6866 18.52 14.6866 18.52 14.7463C18.52 14.6866 18.52 14.6866 18.52 14.6269C18.52 14.5672 18.52 14.5075 18.5748 14.4478C18.5748 14.3881 18.6844 14.209 18.5748 14.3284C18.6296 14.209 18.6844 14.0896 18.7392 14.0299C18.8488 13.8507 18.6844 14.0896 18.794 13.9701C18.8488 13.9104 18.8488 13.8507 18.9036 13.8507C18.9584 13.791 18.9584 13.791 19.0132 13.7313C19.1228 13.6119 18.9584 13.791 19.068 13.6716L19.2324 13.5522C19.2324 13.5522 19.3968 13.4328 19.342 13.4925C19.2872 13.5522 19.4515 13.4328 19.4515 13.4328C19.5063 13.4328 19.5611 13.3731 19.6159 13.3731C19.7255 13.3134 19.5063 13.3731 19.6707 13.3731C19.7255 13.3731 19.7803 13.3731 19.8351 13.3731C19.8899 13.3731 19.9447 13.3731 19.9995 13.3731C20.1639 13.3731 19.8899 13.3134 20.0543 13.3731C20.1639 13.3731 20.2735 13.4328 20.3831 13.4925C20.2735 13.4328 20.4378 13.5522 20.4378 13.5522C20.4926 13.5522 20.5474 13.6119 20.6022 13.6716L20.657 13.7313C20.6022 13.6716 20.6022 13.6716 20.657 13.7313C20.7118 13.791 20.7666 13.8507 20.8214 13.8507C20.8762 13.9104 20.8762 13.9104 20.931 13.9701C21.0406 14.0896 20.8762 13.9104 20.9858 14.0299C21.0406 14.1493 21.0954 14.209 21.1502 14.3284C21.0954 14.209 21.1502 14.3881 21.1502 14.3881C21.1502 14.4478 21.205 14.5075 21.205 14.5672C21.205 14.6269 21.205 14.6269 21.205 14.6866C21.205 14.6269 21.205 14.6269 21.205 14.6866C21.205 14.7463 21.205 14.8657 21.205 14.9254C21.205 15.2836 21.4789 15.6418 21.8077 15.6418C22.1365 15.6418 22.4105 15.3433 22.4105 14.9254C22.4105 13.3134 21.2598 12 19.8351 12C18.4105 12 17.2598 13.3134 17.2598 14.9254C17.2598 15.2836 17.5337 15.6418 17.8625 15.6418C18.2461 15.6418 18.52 15.3433 18.52 14.9254Z" fill="#2D2D2D" />
			<path d="M28.9849 14.9254C28.9849 14.8657 28.9849 14.806 28.9849 14.7463C28.9849 14.6866 28.9849 14.6866 28.9849 14.7463C28.9849 14.6866 28.9849 14.6866 28.9849 14.6269C28.9849 14.5672 28.9849 14.5075 29.0397 14.4478C29.0397 14.3881 29.1493 14.209 29.0397 14.3284C29.0945 14.209 29.1493 14.0896 29.2041 14.0299C29.3137 13.8507 29.1493 14.0896 29.2589 13.9701C29.3137 13.9104 29.3137 13.8507 29.3684 13.8507C29.4232 13.791 29.4232 13.791 29.478 13.7313C29.5876 13.6119 29.4232 13.791 29.5328 13.6716C29.5876 13.6119 29.6424 13.6119 29.6972 13.5522C29.6972 13.5522 29.8616 13.4328 29.8068 13.4925C29.752 13.5522 29.9164 13.4328 29.9164 13.4328C29.9712 13.4328 30.026 13.3731 30.0808 13.3731C30.1904 13.3134 29.9712 13.3731 30.1356 13.3731C30.1904 13.3731 30.2452 13.3731 30.3 13.3731C30.3547 13.3731 30.4095 13.3731 30.4643 13.3731C30.6287 13.3731 30.3547 13.3134 30.5191 13.3731C30.6287 13.3731 30.7383 13.4328 30.8479 13.4925C30.7383 13.4328 30.9027 13.5522 30.9027 13.5522C30.9575 13.5522 31.0123 13.6119 31.0671 13.6716L31.1219 13.7313C31.0671 13.6716 31.0671 13.6716 31.1219 13.7313C31.1767 13.791 31.2315 13.8507 31.2863 13.8507C31.341 13.9104 31.341 13.9104 31.3958 13.9701C31.5054 14.0896 31.341 13.9104 31.4506 14.0299C31.5054 14.1493 31.5602 14.209 31.615 14.3284C31.5602 14.209 31.615 14.3881 31.615 14.3881C31.615 14.4478 31.6698 14.5075 31.6698 14.5672C31.6698 14.6269 31.6698 14.6269 31.6698 14.6866C31.6698 14.6269 31.6698 14.6269 31.6698 14.6866C31.6698 14.7463 31.6698 14.8657 31.6698 14.9254C31.6698 15.2836 31.9438 15.6418 32.2726 15.6418C32.6013 15.6418 32.8753 15.3433 32.8753 14.9254C32.8753 13.3134 31.7246 12 30.3 12C28.8753 12 27.7246 13.3134 27.7246 14.9254C27.7246 15.2836 27.9986 15.6418 28.3274 15.6418C28.7109 15.6418 28.9849 15.3433 28.9849 14.9254Z" fill="#2D2D2D" />
			<path d="M13.315 8.95516C13.315 8.89546 13.315 8.83576 13.315 8.77605C13.315 8.71635 13.315 8.71635 13.315 8.77605C13.315 8.71635 13.315 8.71635 13.315 8.65665C13.315 8.59695 13.315 8.53725 13.3698 8.47755C13.3698 8.41784 13.4793 8.23874 13.3698 8.35814C13.4246 8.23874 13.4793 8.11934 13.5341 8.05964C13.6437 7.88053 13.4793 8.11934 13.5889 7.99993C13.6437 7.94023 13.6437 7.88053 13.6985 7.88053C13.7533 7.82083 13.7533 7.82083 13.8081 7.76113C13.9177 7.64173 13.7533 7.82083 13.8629 7.70143C13.9177 7.64173 13.9725 7.64173 14.0273 7.58202C14.0273 7.58202 14.1917 7.46262 14.1369 7.52232C14.0821 7.58202 14.2465 7.46262 14.2465 7.46262C14.3013 7.46262 14.3561 7.40292 14.4109 7.40292C14.5204 7.34322 14.3013 7.40292 14.4656 7.40292C14.5204 7.40292 14.5752 7.40292 14.63 7.40292C14.6848 7.40292 14.7396 7.40292 14.7944 7.40292C14.9588 7.40292 14.6848 7.34322 14.8492 7.40292C14.9588 7.40292 15.0684 7.46262 15.178 7.52232C15.0684 7.46262 15.2328 7.58202 15.2328 7.58202C15.2876 7.58202 15.3424 7.64173 15.3972 7.70143L15.4519 7.76113C15.3972 7.70143 15.3972 7.70143 15.4519 7.76113C15.5067 7.82083 15.5615 7.88053 15.6163 7.88053C15.6711 7.94023 15.6711 7.94023 15.7259 7.99993C15.8355 8.11934 15.6711 7.94023 15.7807 8.05964C15.8355 8.17904 15.8903 8.23874 15.9451 8.35814C15.8903 8.23874 15.9451 8.41785 15.9451 8.41785C15.9451 8.47755 15.9999 8.53725 15.9999 8.59695C15.9999 8.65665 15.9999 8.65665 15.9999 8.71635C15.9999 8.65665 15.9999 8.65665 15.9999 8.71635C15.9999 8.77605 15.9999 8.89546 15.9999 8.95516C15.9999 9.31337 16.2739 9.67158 16.6026 9.67158C16.9314 9.67158 17.2054 9.37307 17.2054 8.95516C17.2054 7.34322 16.0547 6.02979 14.63 6.02979C13.2054 6.02979 12.0547 7.34322 12.0547 8.95516C12.0547 9.31337 12.3287 9.67158 12.6574 9.67158C13.041 9.67158 13.315 9.37307 13.315 8.95516Z" fill="#2D2D2D" />
			<path d="M23.7798 8.95516C23.7798 8.89546 23.7798 8.83576 23.7798 8.77605C23.7798 8.71635 23.7798 8.71635 23.7798 8.77605C23.7798 8.71635 23.7798 8.71635 23.7798 8.65665C23.7798 8.59695 23.7798 8.53725 23.8346 8.47755C23.8346 8.41784 23.9442 8.23874 23.8346 8.35814C23.8894 8.23874 23.9442 8.11934 23.999 8.05964C24.1086 7.88053 23.9442 8.11934 24.0538 7.99993C24.1086 7.94023 24.1086 7.88053 24.1634 7.88053C24.2182 7.82083 24.2182 7.82083 24.273 7.76113C24.3825 7.64173 24.2182 7.82083 24.3278 7.70143C24.3825 7.64173 24.4373 7.64173 24.4921 7.58202C24.4921 7.58202 24.6565 7.46262 24.6017 7.52232C24.5469 7.58202 24.7113 7.46262 24.7113 7.46262C24.7661 7.46262 24.8209 7.40292 24.8757 7.40292C24.9853 7.34322 24.7661 7.40292 24.9305 7.40292C24.9853 7.40292 25.0401 7.40292 25.0949 7.40292C25.1497 7.40292 25.2045 7.40292 25.2593 7.40292C25.4236 7.40292 25.1497 7.34322 25.3141 7.40292C25.4236 7.40292 25.5332 7.46262 25.6428 7.52232C25.5332 7.46262 25.6976 7.58202 25.6976 7.58202C25.7524 7.58202 25.8072 7.64173 25.862 7.70143L25.9168 7.76113C25.862 7.70143 25.862 7.70143 25.9168 7.76113C25.9716 7.82083 26.0264 7.88053 26.0812 7.88053C26.136 7.94023 26.136 7.94023 26.1908 7.99993C26.3004 8.11934 26.136 7.94023 26.2456 8.05964C26.3004 8.17904 26.3551 8.23874 26.4099 8.35814C26.3551 8.23874 26.4099 8.41785 26.4099 8.41785C26.4099 8.47755 26.4647 8.53725 26.4647 8.59695C26.4647 8.65665 26.4647 8.65665 26.4647 8.71635C26.4647 8.65665 26.4647 8.65665 26.4647 8.71635C26.4647 8.77605 26.4647 8.89546 26.4647 8.95516C26.4647 9.31337 26.7387 9.67158 27.0675 9.67158C27.3962 9.67158 27.6702 9.37307 27.6702 8.95516C27.6702 7.34322 26.5195 6.02979 25.0949 6.02979C23.6702 6.02979 22.5195 7.34322 22.5195 8.95516C22.5195 9.31337 22.7935 9.67158 23.1223 9.67158C23.451 9.67158 23.7798 9.37307 23.7798 8.95516Z" fill="#2D2D2D" />
			<path d="M18.52 3.34334C18.52 3.28364 18.52 3.22394 18.52 3.16424C18.52 3.10454 18.52 3.10454 18.52 3.16424C18.52 3.10454 18.52 3.10454 18.52 3.04483C18.52 2.98513 18.52 2.92543 18.5748 2.86573C18.5748 2.80603 18.6844 2.62692 18.5748 2.74633C18.6296 2.62692 18.6844 2.50752 18.7392 2.44782C18.8488 2.26872 18.6844 2.50752 18.794 2.38812C18.8488 2.32842 18.8488 2.26872 18.9036 2.26872C18.9584 2.20901 18.9584 2.20901 19.0132 2.14931C19.1228 2.02991 18.9584 2.20901 19.068 2.08961L19.2324 1.97021C19.2324 1.97021 19.3968 1.8508 19.342 1.91051C19.2872 1.97021 19.4515 1.8508 19.4515 1.8508C19.5063 1.8508 19.5611 1.7911 19.6159 1.7911C19.7255 1.7314 19.5063 1.7911 19.6707 1.7911C19.7255 1.7911 19.7803 1.7911 19.8351 1.7911C19.8899 1.7911 19.9447 1.7911 19.9995 1.7911C20.1639 1.7911 19.8899 1.7314 20.0543 1.7911C20.1639 1.7911 20.2735 1.8508 20.3831 1.91051C20.2735 1.8508 20.4378 1.97021 20.4378 1.97021C20.4926 1.97021 20.5474 2.02991 20.6022 2.08961L20.657 2.14931C20.6022 2.08961 20.6022 2.08961 20.657 2.14931C20.7118 2.20901 20.7666 2.26872 20.8214 2.26872C20.8762 2.32842 20.8762 2.32842 20.931 2.38812C21.0406 2.50752 20.8762 2.32842 20.9858 2.44782C21.0406 2.56722 21.0954 2.62692 21.1502 2.74633C21.0954 2.62692 21.1502 2.80603 21.1502 2.80603C21.1502 2.86573 21.205 2.92543 21.205 2.98513C21.205 3.04483 21.205 3.04483 21.205 3.10454C21.205 3.04483 21.205 3.04483 21.205 3.10454C21.205 3.16424 21.205 3.28364 21.205 3.34334C21.205 3.70155 21.4789 4.05976 21.8077 4.05976C22.1365 4.05976 22.4105 3.76125 22.4105 3.34334C22.4105 1.7314 21.2598 0.417969 19.8351 0.417969C18.4105 0.417969 17.2598 1.7314 17.2598 3.34334C17.2598 3.70155 17.5337 4.05976 17.8625 4.05976C18.2461 4.00006 18.52 3.70155 18.52 3.34334Z" fill="#2D2D2D" />
		</g>
		<defs>
			<clipPath id="clip0_4354_5456">
				<rect width="40" height="40" fill="white" />
			</clipPath>
		</defs>
	</svg>

);

const policyImageGuatemala = (
	<svg id="multi-policy" width="140" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Clip-Path">
			<g id="Layer">
				<path id="Layer" className="s0" d="m14.6 48.4c0-2.8-1.4-5.3-3.5-6.2-2-0.9-4.3 0-5.7 2-1.4 2.1-1.6 5.1-0.5 7.4 1.1 2.4 3.3 3.6 5.5 3.1 2.5-0.6 4.1-3.3 4.2-6.3z" />
				<path id="Layer" className="s0" d="m11 45.6c0.2 0.1-0.1-0.1 0 0 0.2 0.3 0.4 0.4 0.5 0.7z" />
				<path id="Layer" className="s0" d="m17 60.7c0-2.5-1.7-4.5-3.7-4.6q-0.8 0-1.7 0h-5.9c-1.4 0-2.7 0.7-3.5 2.2-0.5 1.1-0.5 2.1-0.5 3.3v7.6c0 0.8 0 1.8 0 2.5 0.1 1.5 0.7 2.7 1.6 3.5 0.8 0.5 1.6 0.6 2.5 0.6-0.5-0.5-0.9-1-1.3-1.6v6.1 3.5c0 1.3 0.2 2.5 0.9 3.4 1 1.2 2.2 1.1 3.5 1.1h1.6c0.5 0 0.9 0 1.4-0.1 1.4-0.4 2.2-2.1 2.2-3.8v-3.1-7.1c-0.5 0.6-0.9 1.1-1.2 1.6 0.7 0 1.4-0.1 2.1-0.5 0.7-0.4 1.3-1.2 1.6-2.2 0.4-1 0.4-1.9 0.4-2.9v-7.4z" />
				<path id="Layer" className="s0" d="m13.8 59.4c0.2 0.1 0.3 0.1 0.3 0.3z" />
				<path id="Layer" className="s0" d="m35.8 48.4c0-2.8-1.5-5.3-3.6-6.2-2-0.9-4.3 0-5.6 2-1.5 2.1-1.7 5.1-0.6 7.4 1.1 2.4 3.4 3.6 5.6 3.1 2.4-0.6 4.1-3.3 4.2-6.3z" />
				<path id="Layer" className="s0" d="m31.9 45.5c0.1 0 0.1 0.1 0.2 0.1 0.2 0.1-0.1-0.1 0.1 0.1 0.1 0.2 0.4 0.4 0.5 0.6 0.1 0.1 0.1 0.2 0.2 0.4" />
				<path id="Layer" className="s0" d="m36.3 60.3c-0.4-2.2-1.6-4.1-3.6-4.1-0.8 0-1.5 0-2.2 0-0.8 0-1.5 0-2.3 0-1.9 0-3.2 2-3.6 4.1-0.8 4-1.6 8-2.3 12-0.3 1.2-0.5 2.3 0.1 3.5 0.4 1.2 1.4 2 2.3 2.4 0.7 0.2 1.2 0.1 1.9 0.1-0.5-0.5-0.9-1-1.2-1.6 0.2 1.6 0.3 3.2 0.5 4.9 0.1 0.9 0.2 1.8 0.4 2.6 0 0.2 0 0.4 0 0.5 0.1 1.2 0.4 2.1 1.2 2.8 0.7 0.8 1.7 0.7 2.7 0.7 0.8 0 1.7 0.1 2.4-0.2 0.7-0.2 1.5-0.9 1.9-1.7 0.2-0.5 0.3-1.2 0.3-1.8 0.1-0.7 0.1-1.5 0.2-2.1 0.2-1.9 0.4-3.8 0.6-5.7-0.5 0.6-0.9 1.1-1.3 1.6 0.7 0 1.5 0 2.1-0.3 1.2-0.5 2.1-1.5 2.5-3 0.2-1 0-2.1-0.2-3.1-0.6-3-1.1-5.9-1.8-9-0.2-0.9-0.3-1.8-0.6-2.6z" />
				<path id="Layer" className="s0" d="m32.5 59.3h0.2 0.1c0.3 0-0.2-0.2 0.1 0 0 0 0.1 0 0.2 0.1-0.2 0 0 0 0.1 0 0.1 0 0.1 0.1 0.3 0.1-0.3-0.2 0 0 0 0.2" />
				<path id="Layer" className="s0" d="m56.9 48.4c0-2.8-1.4-5.3-3.5-6.2-2-0.9-4.3 0-5.7 2-1.4 2.1-1.6 5.1-0.5 7.4 1.1 2.4 3.3 3.6 5.5 3.1 2.5-0.6 4.1-3.3 4.2-6.3z" />
				<path id="Layer" className="s0" d="m54.2 46.9c0 0 0 0.2 0.1 0.2q0-0.2 0 0" />
				<path id="Layer" className="s0" d="m52.2 45.1c0.2 0 0.3 0.1 0.5 0.1 0.4 0.1-0.2-0.1 0.1 0 0.1 0 0.3 0.1 0.4 0.3 0.1 0 0.1 0.1 0.2 0.1 0.2 0.1-0.1-0.1 0.1 0.1 0.1 0.2 0.3 0.4 0.4 0.6" />
				<path id="Layer" className="s0" d="m49.3 46.7c0-0.2 0.1-0.2 0.1-0.3 0.2-0.4-0.1 0.3 0.1-0.1q0.2-0.2 0.3-0.4c0.2-0.2 0.2-0.2 0.3-0.3 0.2-0.1 0.3-0.3 0.5-0.4 0.2-0.1-0.3 0.1 0.1 0 0.1 0 0.1 0 0.3-0.1 0.1 0 0.2 0 0.2-0.2 0.1 0 0.3 0 0.1 0 0.2 0 0.3 0 0.5 0" />
				<path id="Layer" className="s0" d="m59.3 60.7c0-2.5-1.7-4.5-3.7-4.6q-0.8 0-1.7 0h-5.8c-1.4 0-2.7 0.7-3.5 2.2-0.5 1.1-0.5 2.1-0.5 3.3v7.6c0 0.8 0 1.8 0 2.5 0.1 1.5 0.8 2.7 1.6 3.5 0.8 0.5 1.6 0.6 2.5 0.6-0.5-0.5-0.9-1-1.2-1.6v6.1 3.5c0 1.3 0.1 2.5 0.9 3.4 1 1.2 2.2 1.1 3.4 1.1h1.6c0.5 0 0.9 0 1.4-0.1 1.4-0.4 2.2-2.1 2.2-3.8v-3.1-7.1c-0.4 0.6-0.9 1.1-1.2 1.6 0.8 0 1.4-0.1 2.1-0.5 0.8-0.4 1.3-1.2 1.6-2.2 0.4-1 0.4-1.9 0.4-2.9v-7.4z" />
				<path id="Layer" className="s0" d="m78.1 48.4c0-2.8-1.4-5.3-3.6-6.2-1.9-0.9-4.3 0-5.6 2-1.4 2.1-1.7 5.1-0.6 7.4 1.2 2.4 3.4 3.6 5.6 3.1 2.4-0.6 4.1-3.3 4.2-6.3z" />
				<path id="Layer" className="s0" d="m75.3 46.9c0 0 0 0.2 0.1 0.2q0-0.2 0 0" />
				<path id="Layer" className="s0" d="m78.6 60.3c-0.4-2.2-1.6-4.1-3.6-4.1-0.8 0-1.4 0-2.2 0-0.8 0-1.5 0-2.2 0-2 0-3.3 2-3.7 4.1-0.8 4-1.5 8-2.3 12-0.2 1.2-0.5 2.3 0.1 3.5 0.4 1.2 1.4 2 2.3 2.4 0.7 0.2 1.2 0.1 1.9 0.1-0.4-0.5-0.9-1-1.2-1.6 0.2 1.6 0.3 3.2 0.5 4.9 0.1 0.9 0.3 1.8 0.4 2.6 0 0.2 0 0.4 0 0.5 0.1 1.2 0.4 2.1 1.2 2.8 0.8 0.8 1.8 0.7 2.8 0.7 0.7 0 1.6 0.1 2.3-0.2 0.8-0.2 1.5-0.9 1.9-1.7 0.2-0.5 0.3-1.2 0.3-1.8 0.1-0.7 0.1-1.5 0.2-2.1 0.2-1.9 0.5-3.8 0.6-5.7-0.5 0.6-0.9 1.1-1.2 1.6 0.6 0 1.4 0 2.1-0.3 1.1-0.5 2.1-1.5 2.4-3 0.2-1 0-2.1-0.2-3.1-0.6-3-1.1-5.9-1.8-9-0.2-0.9-0.3-1.8-0.6-2.6z" />
				<path id="Layer" className="s0" d="m75.7 59.5l0.1 0.2c-0.3-0.3 0 0 0 0.1" />
				<path id="Layer" className="s0" d="m74.8 59.3h0.2 0.1c0.3 0-0.2-0.2 0.1 0 0 0 0.1 0 0.2 0.1-0.2 0 0 0 0.1 0" />
				<path id="Layer" className="s0" d="m17.3 34.8q0-0.2 0-0.4 0-0.2 0 0c0-0.1 0-0.1 0-0.2 0-0.2 0-0.3 0.1-0.4 0-0.1 0.2-0.5 0-0.3 0.1-0.2 0.2-0.5 0.3-0.6 0.2-0.4-0.1 0.1 0.1-0.2 0.1-0.1 0.1-0.2 0.3-0.2 0.1-0.2 0.1-0.2 0.2-0.3 0.2-0.3-0.1 0.1 0.1-0.1 0.1-0.2 0.2-0.2 0.3-0.3 0 0 0.4-0.2 0.2-0.1-0.1 0.1 0.3-0.1 0.3-0.1 0.1 0 0.2-0.2 0.3-0.2 0.2-0.1-0.2 0 0.1 0q0.2 0 0.3 0 0.2 0 0.4 0c0.3 0-0.2-0.1 0.1 0 0.2 0 0.4 0.2 0.6 0.3-0.2-0.1 0.2 0.1 0.2 0.1 0.1 0 0.2 0.1 0.3 0.3l0.1 0.1q-0.2-0.2 0 0c0.1 0.1 0.2 0.3 0.3 0.3 0.1 0.1 0.1 0.1 0.3 0.2 0.2 0.3-0.2-0.1 0.1 0.2 0.1 0.2 0.2 0.4 0.3 0.6-0.1-0.2 0 0.2 0 0.2 0 0.1 0.1 0.2 0.1 0.3 0 0.2 0 0.2 0 0.3q0-0.2 0 0c0 0.1 0 0.4 0 0.5 0 0.8 0.6 1.6 1.2 1.6 0.7 0 1.2-0.6 1.2-1.6 0-3.5-2.3-6.4-5.2-6.4-2.8 0-5.2 2.9-5.2 6.4 0 0.8 0.6 1.6 1.3 1.6 0.7 0 1.3-0.6 1.3-1.6z" />
				<path id="Layer" className="s0" d="m38.4 34.8q0-0.2 0-0.4 0-0.2 0 0c0-0.1 0-0.1 0-0.2 0-0.2 0-0.3 0.2-0.4 0-0.1 0.2-0.5 0-0.3 0.1-0.2 0.2-0.5 0.3-0.6 0.2-0.4-0.1 0.1 0.1-0.2 0.1-0.1 0.1-0.2 0.2-0.2 0.1-0.2 0.1-0.2 0.2-0.3 0.3-0.3-0.1 0.1 0.1-0.1l0.4-0.3c0 0 0.3-0.2 0.2-0.1-0.1 0.1 0.2-0.1 0.2-0.1 0.1 0 0.2-0.2 0.4-0.2 0.2-0.1-0.3 0 0.1 0q0.1 0 0.3 0 0.2 0 0.3 0c0.4 0-0.2-0.1 0.1 0 0.3 0 0.5 0.2 0.7 0.3-0.2-0.1 0.1 0.1 0.1 0.1 0.1 0 0.2 0.1 0.3 0.3l0.2 0.1q-0.2-0.2 0 0c0.1 0.1 0.2 0.3 0.3 0.3 0.1 0.1 0.1 0.1 0.2 0.2 0.2 0.3-0.1-0.1 0.1 0.2 0.1 0.2 0.2 0.4 0.4 0.6-0.2-0.2 0 0.2 0 0.2 0 0.1 0.1 0.2 0.1 0.3 0 0.2 0 0.2 0 0.3q0-0.2 0 0c0 0.1 0 0.4 0 0.5 0 0.8 0.5 1.6 1.2 1.6 0.7 0 1.2-0.6 1.2-1.6 0-3.5-2.3-6.4-5.2-6.4-2.9 0-5.2 2.9-5.2 6.4 0 0.8 0.5 1.6 1.2 1.6 0.8 0 1.3-0.6 1.3-1.6z" />
				<path id="Layer" className="s0" d="m59.6 34.8q0-0.2 0-0.4 0-0.2 0 0c0-0.1 0-0.1 0-0.2 0-0.2 0-0.3 0.1-0.4 0-0.1 0.2-0.5 0-0.3 0.1-0.2 0.2-0.5 0.3-0.6 0.3-0.4-0.1 0.1 0.1-0.2 0.2-0.1 0.2-0.2 0.3-0.2 0.1-0.2 0.1-0.2 0.2-0.3 0.2-0.3-0.1 0.1 0.1-0.1 0.1-0.2 0.2-0.2 0.3-0.3 0 0 0.4-0.2 0.3-0.1-0.2 0.1 0.2-0.1 0.2-0.1 0.1 0 0.2-0.2 0.3-0.2 0.2-0.1-0.2 0 0.1 0q0.2 0 0.4 0 0.1 0 0.3 0c0.3 0-0.2-0.1 0.1 0 0.2 0 0.4 0.2 0.7 0.3-0.3-0.1 0.1 0.1 0.1 0.1 0.1 0 0.2 0.1 0.3 0.3l0.1 0.1q-0.2-0.2 0 0c0.1 0.1 0.2 0.3 0.3 0.3 0.2 0.1 0.2 0.1 0.3 0.2 0.2 0.3-0.1-0.1 0.1 0.2 0.1 0.2 0.2 0.4 0.3 0.6-0.1-0.2 0 0.2 0 0.2 0 0.1 0.1 0.2 0.1 0.3 0 0.2 0 0.2 0 0.3q0-0.2 0 0c0 0.1 0 0.4 0 0.5 0 0.8 0.6 1.6 1.2 1.6 0.7 0 1.3-0.6 1.3-1.6 0-3.5-2.4-6.4-5.2-6.4-2.9 0-5.3 2.9-5.3 6.4 0 0.8 0.6 1.6 1.3 1.6 0.7 0 1.3-0.6 1.3-1.6z" />
				<path id="Layer" className="s0" d="m27.9 21.7q0-0.2 0-0.4 0-0.2 0 0c0-0.1 0-0.1 0-0.3 0-0.1 0-0.2 0.1-0.3 0-0.2 0.2-0.6 0-0.3 0.1-0.3 0.2-0.5 0.4-0.7 0.2-0.4-0.2 0.2 0.1-0.1 0.1-0.1 0.1-0.3 0.2-0.3 0.1-0.1 0.1-0.1 0.2-0.2 0.2-0.3-0.1 0.1 0.1-0.2 0.1-0.1 0.2-0.1 0.4-0.2 0 0 0.3-0.3 0.2-0.2-0.1 0.2 0.2-0.1 0.2-0.1 0.1 0 0.2-0.1 0.3-0.1 0.3-0.1-0.2 0 0.1 0q0.2 0 0.4 0 0.1 0 0.3 0c0.3 0-0.2-0.1 0.1 0 0.2 0 0.5 0.1 0.7 0.2-0.2-0.1 0.1 0.2 0.1 0.2 0.1 0 0.2 0.1 0.3 0.2l0.1 0.2q-0.1-0.2 0 0c0.1 0.1 0.3 0.2 0.4 0.2 0.1 0.2 0.1 0.2 0.2 0.3 0.2 0.3-0.1-0.1 0.1 0.1 0.1 0.3 0.2 0.4 0.3 0.7-0.1-0.3 0 0.1 0 0.1 0 0.2 0.1 0.3 0.1 0.4 0 0.1 0 0.1 0 0.3q0-0.2 0 0c0 0.1 0 0.4 0 0.5 0 0.8 0.6 1.6 1.3 1.6 0.6 0 1.2-0.7 1.2-1.6 0-3.5-2.3-6.4-5.2-6.4-2.9 0-5.2 2.9-5.2 6.4 0 0.8 0.5 1.6 1.2 1.6 0.8 0 1.3-0.7 1.3-1.6z" />
				<path id="Layer" className="s0" d="m49.1 21.7q0-0.2 0-0.4 0-0.2 0 0c0-0.1 0-0.1 0-0.3 0-0.1 0-0.2 0.1-0.3 0-0.2 0.2-0.6 0-0.3 0.1-0.3 0.2-0.5 0.3-0.7 0.2-0.4-0.1 0.2 0.1-0.1 0.1-0.1 0.1-0.3 0.2-0.3 0.2-0.1 0.2-0.1 0.3-0.2 0.2-0.3-0.1 0.1 0.1-0.2 0.1-0.1 0.2-0.1 0.3-0.2 0 0 0.3-0.3 0.2-0.2-0.1 0.2 0.3-0.1 0.3-0.1 0.1 0 0.2-0.1 0.3-0.1 0.2-0.1-0.2 0 0.1 0q0.2 0 0.3 0 0.2 0 0.4 0c0.3 0-0.3-0.1 0.1 0 0.2 0 0.4 0.1 0.6 0.2-0.2-0.1 0.1 0.2 0.1 0.2 0.2 0 0.3 0.1 0.4 0.2l0.1 0.2q-0.2-0.2 0 0c0.1 0.1 0.2 0.2 0.3 0.2 0.1 0.2 0.1 0.2 0.2 0.3 0.3 0.3-0.1-0.1 0.2 0.1 0.1 0.3 0.2 0.4 0.3 0.7-0.1-0.3 0 0.1 0 0.1 0 0.2 0.1 0.3 0.1 0.4 0 0.1 0 0.1 0 0.3q0-0.2 0 0c0 0.1 0 0.4 0 0.5 0 0.8 0.6 1.6 1.2 1.6 0.7 0 1.2-0.7 1.2-1.6 0-3.5-2.3-6.4-5.2-6.4-2.8 0-5.2 2.9-5.2 6.4 0 0.8 0.6 1.6 1.2 1.6 0.7 0 1.4-0.7 1.4-1.6z" />
				<path id="Layer" className="s0" d="m38.4 9.4q0-0.2 0-0.4 0-0.2 0 0c0-0.2 0-0.2 0-0.3 0-0.1 0-0.3 0.2-0.4 0-0.1 0.2-0.5 0-0.3 0.1-0.2 0.2-0.5 0.3-0.6 0.2-0.4-0.1 0.1 0.1-0.1 0.1-0.2 0.1-0.3 0.2-0.3 0.1-0.1 0.1-0.1 0.2-0.3 0.3-0.2-0.1 0.2 0.1-0.1l0.4-0.3c0 0 0.3-0.2 0.2-0.1-0.1 0.1 0.2-0.1 0.2-0.1 0.1 0 0.2-0.2 0.4-0.2 0.2-0.1-0.3 0 0.1 0q0.1 0 0.3 0 0.2 0 0.3 0c0.4 0-0.2-0.1 0.1 0 0.3 0 0.5 0.2 0.7 0.3-0.2-0.1 0.1 0.1 0.1 0.1 0.1 0 0.2 0.2 0.3 0.3l0.2 0.1q-0.2-0.2 0 0c0.1 0.2 0.2 0.3 0.3 0.3 0.1 0.1 0.1 0.1 0.2 0.3 0.2 0.2-0.1-0.2 0.1 0.1 0.1 0.2 0.2 0.4 0.4 0.6-0.2-0.2 0 0.2 0 0.2 0 0.1 0.1 0.2 0.1 0.4 0 0.1 0 0.1 0 0.2q0-0.2 0 0c0 0.2 0 0.4 0 0.6 0 0.7 0.5 1.5 1.2 1.5 0.7 0 1.2-0.6 1.2-1.5 0-3.6-2.3-6.5-5.2-6.5-2.9 0-5.2 2.9-5.2 6.5 0 0.7 0.5 1.5 1.2 1.5 0.8-0.1 1.3-0.8 1.3-1.5z" />
			</g>
		</g>
		<path id="Layer" className="s0" d="m163.3 76.7v-6.2h-41.4v6.2c0 5.5 3.8 10.1 8.5 10.1h37.1c-0.3-0.4-0.6-0.8-1-1-2.1-2.4-3.2-5.7-3.2-9.1z" />
		<path id="Layer" fillRule="evenodd" className="s0" d="m148.4 33v-10.1l8.9-5.3 9 5.3v10.1c0 2.3-1.3 5.3-3 6.7l-6.1 4.8-5.9-4.8c-1.7-1.4-3-4.4-2.9-6.7zm4.2-1l2.5 3.1c0.3 0.2 0.5 0.4 0.8 0.4 0.2 0 0.6-0.2 0.8-0.4l5.1-6.2c0.4-0.5 0.4-1.3 0-1.8-0.5-0.6-1.1-0.6-1.6 0l-4.3 5.1-1.8-2.1c-0.4-0.6-1.1-0.6-1.5 0-0.5 0.5-0.5 1.4 0 1.9z" />
		<path id="Layer" className="s0" d="m190.9 7.1c-3.3 0-6.1 3.3-6.1 7.2v12.4h12.2v-12.4c0-3.9-2.7-7.1-6.1-7.2z" />
		<path id="Layer" fillRule="evenodd" className="s0" d="m139.3 7.2h45.9c-1.6 1.9-2.7 4.4-2.7 7.2v62.4c0.1 5.5-3.8 10.1-8.4 10.1-4.8-0.1-8.6-4.6-8.6-10.2v-7.4c0-0.7-0.4-1.4-1.1-1.4h-31.2l0.3-54.1c0-3.7 2.7-6.7 5.8-6.7zm35.1 53.3c0-0.6-0.4-1.3-1.1-1.3h-31c-0.6 0-1.1 0.5-1.1 1.3 0 0.7 0.4 1.3 1.1 1.3h31c0.7 0 1.2-0.6 1.1-1.3zm-28.6-38.6v10.9c0 3.3 1.7 7.1 4 8.9l6.7 5.4c0.2 0.1 0.4 0.3 0.6 0.3 0.2 0 0.5-0.2 0.7-0.3l6.6-5.4c2.4-1.8 4-5.6 4-8.9v-10.9c0.1-0.5-0.2-0.9-0.5-1.2l-10.2-5.9c-0.2-0.1-0.7-0.1-1 0l-10.2 5.9c-0.3 0.3-0.6 0.7-0.6 1.2zm28.6 31.5c0-0.6-0.4-1.3-1.1-1.3h-31c-0.6 0-1.1 0.5-1.1 1.3 0 0.7 0.4 1.3 1.1 1.3h31c0.7 0 1.2-0.6 1.1-1.3z" />
	</svg>

);

const policyImage = (
	<svg id="policy" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.3017 32.9554V30.1494H1.80859V32.9554C1.80859 35.4628 3.67161 37.5524 6.02777 37.5524H24.3839C24.2196 37.3733 24.0552 37.1942 23.8908 37.0748C22.8497 36.0002 22.3017 34.5076 22.3017 32.9554Z" fill="#2D2D2D" />
		<path d="M16.9858 11.761C17.205 11.5222 17.5337 11.5222 17.7529 11.761L18.6296 12.7162L20.7666 10.3879C20.9858 10.1491 21.3145 10.1491 21.5337 10.3879C21.7529 10.6267 21.7529 10.9849 21.5337 11.2237L19.0132 14.0297C18.9036 14.1491 18.7392 14.2088 18.6296 14.2088C18.4652 14.2088 18.3556 14.1491 18.246 14.0297L16.9858 12.6565C16.7666 12.4177 16.7666 11.9998 16.9858 11.761ZM16.3282 16.1192L19.2871 18.3282L22.3008 16.1192C23.1228 15.5222 23.7803 14.1491 23.7803 13.0744V8.47742L19.3419 6.08936L14.9036 8.47742V13.0744C14.8488 14.1491 15.5063 15.5222 16.3282 16.1192Z" fill="#2D2D2D" />
		<path d="M35.9453 1.31348C34.3015 1.31348 32.9316 2.80601 32.9316 4.59706V10.209H38.959V4.59706C38.959 2.80601 37.644 1.37318 35.9453 1.31348Z" fill="#2D2D2D" />
		<path d="M27.2341 22.9851H11.8916C11.5628 22.9851 11.3436 22.6866 11.3436 22.3881C11.3436 22.0299 11.6176 21.7911 11.8916 21.7911H27.2341C27.5628 21.7911 27.782 22.0896 27.782 22.3881C27.8368 22.6866 27.5628 22.9851 27.2341 22.9851ZM27.2341 26.209H11.8916C11.5628 26.209 11.3436 25.9105 11.3436 25.612C11.3436 25.2538 11.6176 25.015 11.8916 25.015H27.2341C27.5628 25.015 27.782 25.3135 27.782 25.612C27.8368 25.9105 27.5628 26.209 27.2341 26.209ZM13.6998 8.05974C13.6998 7.82094 13.8094 7.64183 13.9738 7.52243L19.0149 4.83586C19.1793 4.77616 19.3984 4.77616 19.508 4.83586L24.5491 7.52243C24.7135 7.64183 24.8779 7.82094 24.8231 8.05974V13.015C24.8231 14.5075 24.0012 16.2388 22.8505 17.0747L19.5628 19.5224C19.4532 19.5821 19.3436 19.6418 19.2341 19.6418C19.1245 19.6418 19.0149 19.5821 18.9053 19.5224L15.6176 17.0747C14.4669 16.2388 13.645 14.5075 13.645 13.015V8.05974H13.6998ZM10.4121 1.31348C8.87789 1.31348 7.56282 2.68661 7.56282 4.35825L7.39844 28.9553H22.8505C23.1793 28.9553 23.3984 29.2538 23.3984 29.612V32.9553C23.3984 35.5224 25.2614 37.5523 27.6176 37.612C29.919 37.612 31.8368 35.5224 31.782 33.015V4.65676C31.782 3.34333 32.3299 2.209 33.0971 1.37318H10.4121V1.31348Z" fill="#2D2D2D" />
	</svg>

);

const programmaticImage = (
	<svg id="programmatic" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M27.8899 21.612H20.4927V37.1344C20.4927 37.5523 20.1639 37.9105 19.8351 37.9105C19.4516 37.9105 19.1776 37.5523 19.1776 37.1344V21.612H10.7392C10.52 21.612 8.76662 21.4926 8.9858 21.4926V25.4926H11.6707C11.8899 25.4926 12.0543 25.612 12.2187 25.7911C13.1502 27.1642 10.794 23.4627 17.5885 34.3284C17.8077 34.6866 17.7529 35.1642 17.4242 35.403C17.0954 35.6418 16.7118 35.5821 16.4926 35.2239C16.4926 35.2239 10.0817 27.403 9.91731 27.403L7.67073 26.9851V20.1791C7.67073 19.8806 7.78032 19.6418 7.9995 19.5224C8.21868 19.403 9.69813 19.5821 9.91731 19.7015L9.36936 20.8955C9.47895 20.9553 15.6707 36.5373 15.6707 36.5373C15.8899 36.8955 15.7803 37.3732 15.5063 37.612C15.1776 37.8508 14.794 37.7911 14.5748 37.4329L10.1365 30.1493H1.91731H3.56114C2.46525 30.209 1.53375 29.1344 1.75292 27.9403C2.24607 24.7164 3.17758 19.0448 3.50635 17.1344C3.61594 16.597 3.83511 16.0597 4.21868 15.7015C5.58854 14.209 7.28717 14.5075 8.27347 15.6418L10.2461 17.015H14.794C15.7255 18.0896 23.8899 18.2687 24.5474 17.612V17.1344" fill="#2D2D2D" />
		<path d="M11.7246 21.6116H19.1767V37.134C19.1767 37.5519 19.5054 37.9101 19.8342 37.9101C20.2178 37.9101 20.4917 37.5519 20.4917 37.134V21.6116H29.9712C30.1904 21.6116 30.4095 21.5519 30.6287 21.4922V25.4922H27.9986C27.7794 25.4922 27.5602 25.6116 27.4506 25.7907C26.5191 27.1639 28.8753 23.4624 22.0808 34.328C21.8616 34.6862 21.9164 35.1639 22.2452 35.4027C22.5739 35.6415 22.9575 35.5818 23.1767 35.2236C23.1767 35.2236 28.2726 27.9997 28.4369 27.9997L31.9438 26.9847V20.1788C31.9438 19.8803 31.8342 19.6415 31.615 19.5221C31.3958 19.4027 31.1219 19.4027 30.9575 19.5221L30.3 19.9997C31.2315 21.4922 23.9986 36.537 23.9986 36.537C23.7794 36.8952 23.889 37.3728 24.163 37.6116C24.4917 37.8504 24.8753 37.7907 25.0945 37.4325L29.5328 30.1489H36.0534C36.0534 30.1489 36.4917 30.1489 36.9849 30.1489C37.6424 30.1489 38.1356 29.4922 38.026 28.8355L36.1082 16.8355C36.0534 16.6564 35.9438 16.4176 35.889 16.2385C34.4643 14.1489 32.4917 14.3877 31.4506 15.6415L29.478 17.0146H24.8753C23.9438 18.0892 15.7794 18.2683 15.1219 17.6116V17.134" fill="#2D2D2D" />
		<path d="M23.0141 15.1043L19.7812 10.7461C22.247 10.7461 24.2744 12.5968 24.4936 14.9849C24.5484 15.164 23.0141 15.1043 23.0141 15.1043Z" fill="#2D2D2D" />
		<path d="M27.3968 18.4476C27.3968 18.4476 12.2188 18.7461 12.2188 18.3282C12.2188 14.9252 16.4927 9.19385 19.8352 9.19385C23.0133 9.19385 27.1777 15.2237 27.3968 18.4476Z" fill="#2D2D2D" />
		<path d="M6.84822 6.44775C5.09479 6.44775 3.56055 8.1194 3.56055 10.0895C3.56055 12.0597 5.04 13.7313 6.84822 13.7313C8.65644 13.7313 10.1359 12.0597 10.1359 10.0895C10.1359 8.1194 8.65644 6.44775 6.84822 6.44775Z" fill="#2D2D2D" />
		<path d="M19.8345 0.716309C18.0811 0.716309 16.5469 2.38795 16.5469 4.3581C16.5469 6.32825 18.0263 7.99989 19.8345 7.99989C21.6428 7.99989 23.1222 6.32825 23.1222 4.3581C23.1222 2.38795 21.6428 0.716309 19.8345 0.716309Z" fill="#2D2D2D" />
		<path d="M33.3697 6.44775C31.6163 6.44775 30.082 8.1194 30.082 10.0895C30.082 12.0597 31.5615 13.7313 33.3697 13.7313C35.1779 13.7313 36.6574 12.0597 36.6574 10.0895C36.6574 8.1194 35.1779 6.44775 33.3697 6.44775Z" fill="#2D2D2D" />
	</svg>

);

const researchImage = (
	<svg id="research" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M33.4245 1.85056C32.5478 1.85056 31.8355 2.62668 31.8355 3.58191C31.8355 4.53713 32.5478 5.37295 33.4245 5.37295C34.3012 5.37295 35.0136 4.59683 35.0136 3.64161C35.0136 2.68638 34.3012 1.85056 33.4245 1.85056ZM23.7259 12.6565C22.7944 12.6565 22.0821 13.4327 22.0821 14.4476C22.0821 15.4625 22.7944 16.2386 23.7259 16.2386C24.6574 16.2386 25.3697 15.4625 25.3697 14.4476C25.3697 13.4924 24.6026 12.6565 23.7259 12.6565ZM5.36973 16.0595C4.43822 16.0595 3.72589 16.8953 3.72589 17.8506C3.72589 18.8655 4.49302 19.6416 5.36973 19.6416C6.30124 19.6416 7.01357 18.8058 7.01357 17.8506C7.01357 16.8356 6.30124 16.0595 5.36973 16.0595ZM20.1642 16.5968L21.6437 15.5222C21.4793 15.2237 21.4245 14.8655 21.4245 14.4476C21.4245 13.0744 22.4656 11.9998 23.6711 11.9998C24.1643 11.9998 24.6574 12.1789 25.041 12.4774L31.6711 5.07444C31.3971 4.65653 31.2327 4.17892 31.2327 3.58191C31.1779 2.26847 32.219 1.19385 33.4245 1.19385C34.63 1.19385 35.6163 2.26847 35.6163 3.64161C35.6163 4.95504 34.63 6.08937 33.3697 6.08937C32.8766 6.08937 32.4382 5.91026 32.0547 5.61176L25.4793 12.955C25.7533 13.373 25.9725 13.9103 25.9725 14.4476C25.9725 15.8207 24.9314 16.8953 23.7259 16.8953C23.0684 16.8953 22.4108 16.5968 22.0273 16.0595L20.219 17.373M9.86288 19.7013L7.45192 18.8655C7.12315 19.761 6.30124 20.358 5.36973 20.358C4.10946 20.358 3.06836 19.2237 3.06836 17.8506C3.06836 16.4774 4.10946 15.3431 5.36973 15.3431C6.63 15.3431 7.6711 16.4774 7.6711 17.8506C7.6711 17.97 7.6711 18.0894 7.6711 18.2088L9.86288 19.1043M9.58891 38.2088H3.34233V25.2535H9.58891V38.2088ZM26.8492 38.2088H20.5478V21.3132H26.8492V38.2088ZM36.5478 38.2088H30.3012V10.5073H36.5478V38.2088Z" fill="#2D2D2D" />
	</svg>
);

const financeImage = (
	<svg id="finance" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21.5894 32.1794C20.877 31.8809 20.7126 31.463 20.8222 30.8659C20.8222 30.5674 20.8222 30.3883 20.8222 30.2092C20.877 29.7316 20.7126 29.4331 20.2743 29.4331C19.8907 29.4331 19.6715 29.7913 19.7811 30.2689C19.8359 30.5077 19.7811 30.8062 19.7811 31.045C19.8359 31.5824 19.6715 31.8809 19.1784 32.1197C18.0825 32.5973 17.4798 33.9704 17.6989 35.2242C17.9729 36.5376 18.9592 37.4928 20.2195 37.4928C21.4798 37.4928 22.5209 36.7167 22.8496 35.3436C23.1784 34.0898 22.6852 32.657 21.5894 32.1794Z" fill="#2D2D2D" />
		<path d="M9.75428 20.1796C10.0283 20.1796 10.1926 20.1796 10.357 20.1796C10.7954 20.2393 11.0694 20.0602 11.0694 19.5826C11.0694 19.1647 10.7406 18.9855 10.3022 19.0452C10.083 19.1049 9.80908 19.0452 9.5899 19.0452C9.04195 19.1049 8.82277 18.9258 8.6036 18.3885C8.16524 17.1945 6.90497 16.5378 5.69949 16.8363C4.49401 17.1348 3.61729 18.2094 3.5625 19.5826C3.5625 20.9557 4.32962 22.09 5.5351 22.4482C6.74058 22.8064 8.05565 22.2691 8.49401 21.0751C8.82277 20.2393 9.20634 20.0602 9.75428 20.1796Z" fill="#2D2D2D" />
		<path d="M34.9053 16.8956C33.6999 16.5374 32.3848 17.0747 31.9464 18.2687C31.6725 18.9851 31.2341 19.2239 30.6862 19.1045C30.4122 19.1045 30.2478 19.1045 30.0834 19.1045C29.6451 19.0448 29.3711 19.2239 29.3711 19.7016C29.3711 20.1195 29.6999 20.2986 30.1382 20.2389C30.3574 20.1792 30.6314 20.2389 30.8505 20.2389C31.3985 20.1792 31.6177 20.3583 31.8368 20.8956C32.2752 22.0896 33.5355 22.7463 34.741 22.4478C35.9464 22.1493 36.8232 21.0747 36.8779 19.7016C36.9327 18.3284 36.1656 17.2538 34.9053 16.8956Z" fill="#2D2D2D" />
		<path d="M18.9057 6.98531C19.618 7.28381 19.7824 7.70173 19.6728 8.29874C19.6728 8.59725 19.6728 8.77635 19.6728 8.95546C19.618 9.43307 19.7824 9.73158 20.2207 9.73158C20.6043 9.73158 20.8235 9.37337 20.7139 8.89575C20.6591 8.65695 20.7139 8.35844 20.7139 8.11964C20.6591 7.58232 20.8235 7.28381 21.3166 7.04501C22.4125 6.5674 23.0152 5.19426 22.7961 3.94053C22.5221 2.6271 21.5358 1.67188 20.2755 1.67188C19.0152 1.67188 17.9741 2.44799 17.6454 3.82113C17.3166 5.07486 17.8098 6.5077 18.9057 6.98531Z" fill="#2D2D2D" />
		<path d="M10.6856 11.0448C11.3431 10.7463 11.7267 10.9851 12.0554 11.4627C12.1102 11.5821 12.2198 11.7015 12.3294 11.7612C12.4938 11.8209 12.713 11.7612 12.9322 11.8209C12.9322 11.5821 12.987 11.3433 12.8774 11.1642C12.6034 10.806 12.2198 10.5672 11.9459 10.2687C12.0554 9.85076 12.165 9.61195 12.2198 9.31344C12.439 8.35822 12.0007 7.3433 11.1787 6.86568C10.3568 6.38807 9.31572 6.56718 8.65819 7.28359C8.00065 8.00001 7.89106 9.13434 8.32942 9.97016C8.82257 10.8657 9.86367 11.403 10.6856 11.0448Z" fill="#2D2D2D" />
		<path d="M29.8639 28.1194C29.1516 28.4179 28.768 28.1791 28.3844 27.6418C28.3296 27.5821 28.2749 27.4627 28.1653 27.403C28.0009 27.3433 27.7817 27.403 27.5625 27.403C27.5625 27.6418 27.5077 27.8806 27.6173 28.0597C27.8913 28.4179 28.2201 28.6567 28.494 28.9552C28.3844 29.3134 28.2749 29.612 28.2201 29.8508C28.0009 30.806 28.4392 31.8209 29.2612 32.2985C30.0831 32.7761 31.1242 32.6567 31.7817 31.9403C32.4392 31.2239 32.6036 30.0896 32.1105 29.194C31.6721 28.2985 30.6858 27.7612 29.8639 28.1194Z" fill="#2D2D2D" />
		<path d="M28.2746 11.4629C28.713 10.9256 29.0965 10.5077 29.8089 10.9853C29.9184 11.045 30.0828 11.045 30.1376 11.045C31.5075 11.045 32.4938 9.7913 32.2746 8.41817C32.0006 7.04503 30.8499 6.26891 29.6445 6.62712C28.439 6.98533 27.7815 8.29876 28.2746 9.6122C28.3842 9.97041 28.3842 10.2092 28.1102 10.448C27.9458 10.5674 27.8362 10.7465 27.7267 10.8659C27.5075 11.1047 27.2335 11.3435 27.5075 11.7017C27.7815 12.0003 28.0554 11.7615 28.2746 11.4629Z" fill="#2D2D2D" />
		<path d="M12.4931 27.4033C12.3835 27.4033 12.2739 27.5824 12.1643 27.7018C11.7807 28.1794 11.3972 28.6571 10.7396 28.1794C10.63 28.1197 10.4657 28.1197 10.4109 28.1197C9.09579 28.1197 8.10949 29.1944 8.16429 30.4481C8.21908 31.7615 9.26018 32.7168 10.4657 32.5973C11.7259 32.4779 12.6574 31.2242 12.2739 29.9108C12.0547 29.075 12.2739 28.6571 12.8218 28.1794C12.8766 28.1197 13.041 28.0003 13.041 27.9406C13.041 27.7615 13.0958 27.5227 12.9862 27.4033C12.8766 27.4033 12.6574 27.4033 12.4931 27.4033Z" fill="#2D2D2D" />
		<path d="M20.9878 24.0003V24.7764C20.9878 25.1346 20.7139 25.4331 20.3303 25.4331H19.9467C19.618 25.4331 19.2892 25.1346 19.2892 24.7764V24.06C18.9604 24.0003 18.1933 23.8212 17.7002 23.5824C17.4262 23.463 17.3166 23.1048 17.4262 22.8062L17.6454 22.2689C17.755 21.9107 18.1933 21.7316 18.5221 21.9107C18.5221 21.9107 19.344 22.3286 19.9467 22.3286C20.5495 22.3286 20.9878 21.9704 20.9878 21.4928C20.9878 21.1346 20.8234 20.7764 19.8371 20.3585C18.6865 19.8809 17.481 19.2242 17.481 17.6719C17.481 16.4779 18.2481 15.5227 19.3988 15.2242V14.5077C19.3988 14.1495 19.6728 13.851 20.0563 13.851H20.4399C20.7686 13.851 21.0974 14.1495 21.0974 14.5077V15.1645C21.3714 15.2242 21.9741 15.3436 22.3577 15.5227C22.5769 15.6421 22.796 15.9406 22.6317 16.2988L22.4125 16.8361C22.3029 17.1943 21.8645 17.3734 21.5358 17.1943C21.5358 17.1943 20.9878 16.8958 20.3303 16.8958C19.6728 16.8958 19.4536 17.254 19.4536 17.5525C19.4536 17.9107 19.618 18.1495 20.7139 18.6271C22.2481 19.2839 22.9056 20.1197 22.9056 21.4331C22.9604 22.6868 22.1933 23.7018 20.9878 24.0003ZM20.2207 10.9854C15.7823 10.9854 12.2207 14.8659 12.2207 19.5824C12.2207 24.2988 15.8371 28.1794 20.2207 28.1794C24.6043 28.1794 28.2207 24.2988 28.2207 19.5824C28.2207 14.8659 24.6591 10.9854 20.2207 10.9854Z" fill="#2D2D2D" />
	</svg>

);

export { multiLevelImage, policyImage, programmaticImage, researchImage, financeImage, policyImageGuatemala };
